import React, { useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { RouteComponentProps, StaticContext } from 'react-router'
import { Link } from 'react-router-dom'
import { Formik, FormikErrors } from 'formik'
import * as yup from 'yup'

import { AppContext } from '../../App'
import { EntityAccounts } from './EntityAccounts'
import { EntityInstruments } from './EntityInstruments'
import { EntityUsers } from './EntityUsers'
import { DeleteButton } from '../../components/UI/Form/DeleteButton'
import { FormText } from '../../components/UI/Form/Text'
import { Card } from '../../components/UI/Card/Card'
import { Header } from '../../components/UI/Header/Header'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'

import * as EntityType from '../../constants/entityType'
import * as Request from '../../utilities/request'
import { getUrlSearchParam } from '../../utilities/url'

import { Entity, EntityResult } from '../../../../back-end/common/entity'
import { PageBackState } from '../../types/PageBackState'
import { PageStatus } from '../../types/PageStatus'
import { HistoryCardBottom } from '../../components/UI/HistoryCardBottom/HistoryCardBottom'
import { canWriteAccount } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'

type EntityErrorMessages = FormikErrors<Partial<Entity>>

const entityValidationSchema = yup.object().shape({
	entityID: yup.string().optional(),
	entityName: yup.string().required('Entity name is required.'),
	entityABN: yup.string().optional().nullable(),
	entityWebsite: yup.string().optional().nullable(),
	entityNotes: yup.string().optional().nullable(),
})

const inviteUserValidationSchema = yup.object().shape({
	email: yup.string().required('Email is required.'),
	firstName: yup.string().optional(),
	lastName: yup.string().optional(),
	entityName: yup.string().optional(),
})

interface InviteUser {
	email: string
	firstName: string
	lastName: string
	entityName: string
}

interface ScreensEntityMatchParams {
	id: string
}

interface ScreensEntityDetailsStateParams extends PageBackState {}

const newEntity = (type: string) => ({
	entityID: '',
	entityName: '',
	entityABN: '',
	entityWebsite: '',
	entityNotes: '',
	entityIsAdmin: false,
	entityTypeID: type,
	entityTypeName: '',
	entityTestReportPrefix: null,
	entityTestReportCount: null,
	create: {
		ts: '',
		userID: '',
		email: '',
		firstName: '',
		lastName: '',
		fullName: '',
	},
	modified: {
		ts: '',
		userID: '',
		email: '',
		firstName: '',
		lastName: '',
		fullName: '',
	},
	recordStatusID: '',
})

const ScreensEntityDetails = (props: RouteComponentProps<ScreensEntityMatchParams, StaticContext, ScreensEntityDetailsStateParams>) => {
	const context = useContext(AppContext)
	const entityID = props.match!.params.id
	const isNew = entityID === 'new' || entityID === 'invite'
	const isInvite = entityID === 'invite'

	const [messages, setMessages] = useMessageReducer([])
	const [entity, setEntity] = React.useState<Entity | null>(
		isNew && !isInvite ? newEntity(getUrlSearchParam(props.location.search, 'entityType') || EntityType.TestingContractor) : null
	)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	React.useEffect(() => {
		const getData = async () => {
			const [entityResponse] = await Promise.all([Request.get<EntityResult>(`entity?where=entityID==${entityID}`, context.appState.authState)])
			const entity = entityResponse.data.entities[0]
			setEntity(entity)

			setPageStatus(entity && entity.entityName && entity.entityName.endsWith('(needs setup)') ? 'Editing' : 'Ready')
		}

		if (context.appState.authState.isLoggedIn && !isNew) {
			getData()
		} else if (isNew) {
			setPageStatus('Editing')
		}
	}, []) // eslint-disable-line

	const onEntityInvite = async (newUser: InviteUser) => {
		setPageStatus('Submitting')
		const account = getUrlSearchParam(props.location.search, 'account')
		const parent = getUrlSearchParam(props.location.search, 'parent')
		const entityType = getUrlSearchParam(props.location.search, 'entityType')
		const { email, firstName, lastName, entityName } = newUser
		await Request.handleRequest(
			() =>
				Request.post<EntityResult>(
					'entity/invite',
					{ email, firstName, lastName, entityName, accountID: account, entityTypeID: entityType, parentEntityID: parent },
					context.appState.authState
				),
			{
				successFunction: () => props.history.replace(props.location.state.backPath),
				setMessageFunction: setMessages,
				passFailedErrorMessage: true,
				messageAction: 'inviting',
				messageObject: 'entity',
			}
		)
		setPageStatus('Ready')
	}

	const onEntityEdit = async (entity: Entity) => {
		setPageStatus('Submitting')
		const account = getUrlSearchParam(props.location.search, 'account')
		const parent = getUrlSearchParam(props.location.search, 'parent')
		if (isNew) {
			await Request.handleRequest(
				() =>
					Request.post<EntityResult>(
						`entity${account ? `?account=${account}` : ''}${parent ? `${account ? '&' : '?'}parent=${parent}` : ''}`,
						entity,
						context.appState.authState
					),
				{
					setMessageFunction: setMessages,
					messageAction: 'editing',
					messageObject: entity.entityTypeName,
				}
			)
		} else {
			await Request.handleRequest(() => Request.put<EntityResult>(`entity?where=entityID==${entityID}`, entity, context.appState.authState), {
				setMessageFunction: setMessages,
				messageAction: 'editing',
				messageObject: entity.entityTypeName,
			})
		}
		setPageStatus('Ready')
	}

	const onEntityDelete = async (entityID: string) => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.del<EntityResult>(`entity?where=entityID==${entityID}`, context.appState.authState), {
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'entity',
		})
		setPageStatus('Ready')
	}

	return (
		<div className="page">
			<Messages messages={messages} updateMessage={setMessages} />
			{props.location.state && props.location.state.backPath && (
				<Row>
					<Col>
						<Link className="breadcrumb-back" to={props.location.state.backPath}>{`< Back to ${props.location.state.backName}`}</Link>
					</Col>
				</Row>
			)}

			<Row>
				<Col>
					<Header title={isInvite ? 'Invite Entity' : isNew ? 'New Entity' : entity ? entity.entityName : ''} subtitle={''} />
				</Col>
			</Row>

			{isInvite && (
				<Formik
					initialValues={{ email: '', firstName: '', lastName: '', entityName: '' }}
					validationSchema={inviteUserValidationSchema}
					onSubmit={onEntityInvite}
					enableReinitialize
				>
					{({ handleSubmit, errors, values, setFieldValue }) => {
						const onInviteUserChange = (e: { target: { name: string; value: string } }) => {
							const property = e.target.name
							const value = e.target.value
							setFieldValue(property, value)
						}
						return (
							<Card
								title={`Invite`}
								collapsible={false}
								headerComponent={() => (
									<Row className="justify-content-end">
										<Col sm={'auto'}>
											<Button className="btn btn-secondary" onClick={() => handleSubmit()}>
												Invite
											</Button>
										</Col>
										<Col sm={'auto'}>
											<Link className="btn btn-secondary" to={props.location.state.backPath}>
												Cancel
											</Link>
										</Col>
									</Row>
								)}
							>
								<Row>
									<Col>
										<FormText
											name={'email'}
											value={values.email}
											label={'Email'}
											onChange={onInviteUserChange}
											feedback={errors?.email}
											isInvalid={!!errors?.email}
											required
										/>
									</Col>
									<Col>
										<FormText
											name={'entityName'}
											value={values.entityName}
											label={'Entity name'}
											onChange={onInviteUserChange}
											feedback={errors?.entityName}
											isInvalid={!!errors?.entityName}
										/>
									</Col>
									<Col>
										<FormText
											name={'firstName'}
											value={values.firstName}
											label={'First name'}
											onChange={onInviteUserChange}
											feedback={errors?.firstName}
											isInvalid={!!errors?.firstName}
										/>
									</Col>
									<Col>
										<FormText
											name={'lastName'}
											value={values.lastName}
											label={'Last name'}
											onChange={onInviteUserChange}
											feedback={errors?.lastName}
											isInvalid={!!errors?.lastName}
										/>
									</Col>
								</Row>
							</Card>
						)
					}}
				</Formik>
			)}

			{entity ? (
				<div>
					<Formik initialValues={entity} validationSchema={entityValidationSchema} onSubmit={onEntityEdit} enableReinitialize>
						{({ handleSubmit, errors, values, setFieldValue }) => (
							<Card
								title={`${entity.entityTypeName} Information`}
								collapsible={false}
								headerComponent={() =>
									pageStatus !== 'Loading' && entity && canWriteAccount(appStateToPermissionObject(context.appState)) ? (
										pageStatus === 'Ready' ? (
											<Row className="justify-content-end">
												<Col sm={'auto'}>
													<Button className="btn btn-outline-dark span-bold" onClick={() => setPageStatus('Editing')}>
														Edit {entity.entityTypeName}
													</Button>
												</Col>
											</Row>
										) : (
											<Row className="justify-content-end">
												<Col sm={'auto'}>
													<Button className="btn btn-secondary" onClick={() => handleSubmit()}>
														Save
													</Button>
												</Col>
												<Col sm={'auto'}>
													<Button className="btn btn-secondary" onClick={() => setPageStatus('Ready')}>
														Cancel
													</Button>
												</Col>
												<Col sm={'auto'}>
													<DeleteButton id={entity.entityID} onClick={onEntityDelete} />
												</Col>
											</Row>
										)
									) : (
										<></>
									)
								}
							>
								<EntityDetails entity={values} pageStatus={pageStatus} onChange={setFieldValue} errors={errors} />
							</Card>
						)}
					</Formik>

					{!isNew && entity && <EntityUsers pageProps={props} entity={entity} />}

					{entity?.entityTypeID !== EntityType.Institution && !isNew && <EntityAccounts pageProps={props} entityID={entity?.entityID} />}

					{entity?.entityTypeID === EntityType.TestingContractor && !isNew && <EntityInstruments pageProps={props} entityID={entity?.entityID} />}
				</div>
			) : pageStatus === 'Loading' || isNew ? null : (
				<span>Entity is not active</span>
			)}
		</div>
	)
}

interface EntityDetailsProps {
	entity: Entity | null
	pageStatus: PageStatus
	errors?: EntityErrorMessages
	onChange: (field: string, value: string | number | boolean | null) => void
}

const EntityDetails = (props: EntityDetailsProps) => {
	const readOnly = props.pageStatus !== 'Editing'

	if (props.entity) {
		return (
			<div style={{ flexDirection: 'column' }}>
				<Row style={{ marginBottom: '20px' }}>
					<Col>
						<FormText
							name={'entityName'}
							value={props.entity.entityName}
							label={'Entity Name'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
							feedback={props.errors?.entityName}
							isInvalid={!!props.errors?.entityName}
						/>
					</Col>
					<Col>
						<FormText
							name={'entityABN'}
							value={props.entity.entityABN}
							label={'Entity ABN'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
						/>
					</Col>
					<Col>
						<FormText
							name={'entityWebsite'}
							value={props.entity.entityWebsite}
							label={'Entity Website'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormText
							name={'entityNotes'}
							as={'textarea'}
							value={props.entity.entityNotes}
							label={'Entity Notes'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
						/>
					</Col>
				</Row>

				<>
					{props.entity.create && props.entity.modified && (
						<HistoryCardBottom
							historyReferenceID={props.entity.entityID!}
							created={props.entity.create}
							modified={props.entity.modified}
							backState={{
								backPath: `/entity/${props.entity.entityID!}`,
								backName: `${props.entity.entityName}`,
							}}
						/>
					)}
				</>
			</div>
		)
	}
	return null
}

export { ScreensEntityDetails, EntityDetails, entityValidationSchema }
