import React from 'react'
import { AppContext } from '../../App'
import { Amplify, I18n } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import { awsConfig } from '../../constants/config'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { Meh } from 'react-feather'
import { RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router'

Amplify.configure(awsConfig)
I18n.putVocabulariesForLanguage('en', {
	Username: 'Email', // Username label
})

interface SignOutProps {
	push: (goto: string) => void
}
const signOut = (props: SignOutProps) => {
	Auth.signOut()
		.then(() => {
			props.push('')
		})
		.catch((err) => console.log(err))
}

const ScreensHomeAccountDisabled = (props: RouteComponentProps<never, StaticContext, unknown>) => {
	const { setAppState } = React.useContext(AppContext)
	return (
		<Container>
			<Card style={{ marginTop: '20%', textAlign: 'center' }}>
				<Row style={{ marginBottom: '20px' }}>
					<Col>
						<Meh />
					</Col>
				</Row>
				<Row>
					<Col>
						<h1>Your account has been disabled.</h1>
					</Col>
				</Row>
				<Row>
					<Col>
						<h1>
							<a href="mailto:info@generic.com">Contact our team</a> for help, or switch to another account.
						</h1>
					</Col>
				</Row>
				<Row style={{ padding: '20px' }}>
					<Col></Col>
				</Row>
				<Row>
					<Col>
						<Button
							variant={'light'}
							className={'logoutButton'}
							onClick={() => {
								setAppState({ state: 'signOut' })
								signOut({ push: props.history.push })
							}}
						>
							Log out
						</Button>
					</Col>
				</Row>
			</Card>
		</Container>
	)
}

export { ScreensHomeAccountDisabled }
