import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

function useStateParams<T>(initialState: T, paramsName: string, serialize: (state: T) => string, deserialize: (state: string) => T): [T, (state: T) => void] {
	const history = useHistory()
	const search = new URLSearchParams(history.location.search)

	const existingValue = search.get(paramsName)
	const [state, setState] = useState<T>(existingValue ? deserialize(existingValue) : initialState)

	useEffect(() => {
		// Updates state when user navigates backwards or forwards in browser history
		console.log('existingValue', existingValue, state)
		if (existingValue && deserialize(existingValue) !== state) {
			setState(deserialize(existingValue))
		} else if (!existingValue && state !== initialState) {
			setState(initialState)
		}
	}, [existingValue])

	const onChange = (s: T) => {
		setState(s)
		const searchParams = new URLSearchParams(history.location.search)
		if (!s) {
			searchParams.delete(paramsName)
		} else {
			searchParams.set(paramsName, serialize(s))
		}
		const pathname = history.location.pathname
		history.push({ pathname, search: searchParams.toString() })
	}

	return [state, onChange]
}

export { useStateParams }
