import React, { useContext } from 'react'
import { Row, Col, Modal, Alert, Card } from 'react-bootstrap'
import { LocationDescriptor } from 'history'

import * as Request from '../../../utilities/request'
import { Test, TestResult } from '../../../../../back-end/common/test'
import { AppContext } from '../../../App'
import { Header } from '../../../components/UI/Header/Header'
import { Button } from '../../../components/UI/Button/Button'
import { TestReportResult } from '../../../../../back-end/common/testReport'
import { DownloadLink } from '../../../components/UI/Uploader/DownloadLink'
import { formatDateTime } from '../../../utilities/formatDate'
import { TestQuestion } from '../../../../../back-end/common/testQuestion'
import { DetailedQuestion, TestLocationStateParams } from '../TestWrapper'
import { DropdownSelect, DropdownSelectOption } from '../../../components/Forms/Dropdown'
import { ChevronLeft } from 'react-feather'
import { ComplianceCheckBadge, ResultBadge, TabRow } from '../Questions/QuestionComponents'
import { Loading } from '../../../components/UI/Loading/Loading'
import { Lock } from 'react-feather'
import { Asset } from '../../../../../back-end/common/asset'
import { AuthState } from '../../../App.d'
import { MessageAction } from '../../../components/UI/Messages/Message'
import { User } from '../../../../../back-end/common/user'
import { WorkZoneIntegrityTestOutput } from 'dynaflow/utilities/question'
import { FormText } from '../../../components/UI/Form/Text'
import { Safetick } from 'dynaflow/constants/entity'
import { canWriteTest } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../../utilities/permission'

export interface LockScreenProps {
	asset: Asset | null
	onClickBack: () => void
	goToQuestion: (questionID: string) => void
	setMessages: (action: MessageAction) => void
	lockUsers: Pick<User, 'userID' | 'firstName' | 'lastName' | 'fullName' | 'email'>[]
	test: Test | null
	questions: DetailedQuestion[]
	setTest: React.Dispatch<React.SetStateAction<Test | null>>
	tabSelectOptions: DropdownSelectOption<string, object>[]
	historyPush: (location: LocationDescriptor<TestLocationStateParams>, state?: TestLocationStateParams | undefined) => void
}

export interface LockTestFunctionProps extends LockScreenProps {
	authState: AuthState
	currentUserID: string
	canForceTestPass: boolean
	forceTestPass: boolean | undefined
	handleClose: () => void
	comment: string
}

export interface CreateFunctionProps<T> extends LockScreenProps {
	successCallback: (data: T) => void
	failedCallback: () => void
	authState: AuthState
}

const lockTest = (props: LockTestFunctionProps) =>
	Request.handleRequest(
		() =>
			Request.put<TestResult>(
				`test?where=testID==${props.test?.testID}`,
				{
					testID: props.test?.testID,
					technicianUserID: props.test?.technicianUserID,
					performedTs: props.test?.performedTs,
					isLocked: true,
					lockedUserID: props.currentUserID,
					forcePassed: props.canForceTestPass ? props.forceTestPass : undefined,
					extraParams: { ...props.test?.extraParams, comment: props.comment },
				},
				props.authState
			),
		{
			successFunction: async (data) => {
				const newTest = data.tests[0]
				if (!newTest?.testReport?.testReportID || newTest?.testReport?.testReportIsBlank) {
					// create a test report
					await createReport({
						...props,
						successCallback: (testReportData) => {
							const newTestReport = testReportData.testReports[0]
							props.setTest({ ...newTest, testReport: newTestReport })
							props.handleClose()
						},
						failedCallback: () => {
							props.setTest(newTest)
							props.handleClose()
						},
					})
				} else {
					props.setTest(newTest)
					props.handleClose()
				}
			},
			failedFunction: () => {
				props.handleClose()
			},
			setMessageFunction: props.setMessages,
			messageAction: 'editing',
			messageObject: 'test',
		}
	)

const createReport = (props: CreateFunctionProps<TestReportResult>): Promise<void> =>
	Request.handleRequest(
		() =>
			Request.post<TestReportResult>(
				'testReport',
				{
					testID: props?.test?.testID,
					timezoneOffsetInMinutes: new Date().getTimezoneOffset() * -1, // getTimezoneOffset() gets how many minutes to bring back to UTC instead of from UTC to local
				},
				props.authState
			),
		{
			successFunction: props.successCallback,
			failedFunction: props.failedCallback,
			setMessageFunction: props.setMessages,
			messageAction: 'editing',
			messageObject: 'test report',
		}
	)

const redoTest = (props: CreateFunctionProps<TestResult>): Promise<void> =>
	Request.handleRequest(() => Request.post<TestResult>(`test/${props?.test?.testID}`, {}, props.authState), {
		successFunction: props.successCallback,
		failedFunction: props.failedCallback,
		setMessageFunction: props.setMessages,
		messageAction: 'creating',
		messageObject: 'test redo',
		passFailedErrorMessage: true,
	})

const LockTestButtons = (props: LockScreenProps & { allTestsComplete: boolean; forceTestPass: boolean | null }) => {
	const context = useContext(AppContext)
	const { test, setTest, asset, forceTestPass } = props

	const [comment, setComment] = React.useState<string>((props.test?.extraParams as Record<string, string>)?.comment || '')

	const [show, setShow] = React.useState<boolean>(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const [showErrorModal, setShowErrorModal] = React.useState<boolean>(false)
	const handleErrorModalClose = () => setShowErrorModal(false)
	const handleErrorModalShow = () => setShowErrorModal(true)

	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
	const [reportLoading, setReportLoading] = React.useState<boolean>(false)
	const [redoLoading, setRedoLoading] = React.useState<boolean>(false)

	const canLock =
		props.lockUsers.findIndex((user) => user.userID === context.appState.userAttributes.userID) > -1 ||
		context.appState.userPermissions.entityID === Safetick
	const canForceTestPass = asset?.assetTypeCanForceTestPass || false

	return (
		<>
			<Modal show={show} onHide={handleClose} centered>
				<Modal.Header closeButton>
					<Modal.Title>Lock test</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure you want to lock this test? Results cannot be changed after it is locked.</Modal.Body>
				<Modal.Footer>
					{isSubmitting ? (
						<Loading spinner />
					) : (
						<>
							<Button variant="secondary" onClick={handleClose}>
								Cancel
							</Button>
							<Button
								variant="primary"
								onClick={async () => {
									setIsSubmitting(true)
									await lockTest({
										...props,
										authState: context.appState.authState,
										currentUserID: context.appState.userAttributes.userID,
										handleClose,
										canForceTestPass,
										forceTestPass: forceTestPass || false,
										comment,
									})
									setIsSubmitting(false)
								}}
							>
								Lock test
							</Button>
						</>
					)}
				</Modal.Footer>
			</Modal>
			<Modal show={showErrorModal} onHide={handleErrorModalClose} centered>
				<Modal.Body>Error getting report. Please wait a minute and try again.</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleErrorModalClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			{test?.isLegacy === false && (
				<Card className="mt-3">
					<Card.Header>
						<Card.Title>Comments</Card.Title>
					</Card.Header>
					<Card.Body>
						<FormText
							as="textarea"
							rows={3}
							name={'comment'}
							value={comment}
							onChange={(e) => setComment(e.target.value)}
							disabled={test?.isLocked}
							readOnly={test?.isLocked}
						/>
						{test?.basedOnTestID && <small>A test that has been redone needs to have comments before it can be locked</small>}
					</Card.Body>
				</Card>
			)}
			{test?.isLocked ? (
				<Card>
					<Card.Header>
						<Card.Title>
							<Lock style={{ marginRight: '15px', marginBottom: '3px' }} /> Test locked
						</Card.Title>
					</Card.Header>
					<Card.Body>
						<Row className="mb-3">
							<Col>{`by ${test.testLockedUser?.fullName} at ${formatDateTime({ date: test?.lockedTs, format: 'Date' })}`}</Col>
						</Row>
						{test && test.testReport && test.testReport.testReportS3Path && !test.testReport.testReportIsBlank && (
							<Row>
								<Col>
									<DownloadLink
										path={test.testReport.testReportS3Path}
										fileType="application/pdf"
										name={`${test.testReport.testReportName}.pdf`}
										icon="pdf"
										onError={handleErrorModalShow}
									/>
								</Col>
							</Row>
						)}
						{test && test.isLocked && (!test.testReport || test.testReport.testReportIsBlank) && (
							<Button
								className="btn btn-secondary mt-3"
								disabled={reportLoading}
								onClick={() => {
									setReportLoading(true)
									createReport({
										...props,
										authState: context.appState.authState,
										successCallback: (testReportData) => {
											const newTestReport = testReportData.testReports[0]
											setTest({ ...test, testReport: newTestReport })
											setReportLoading(false)
										},
										failedCallback: () => {
											setReportLoading(false)
										},
									})
								}}
							>
								{reportLoading ? <Loading spinner noBorder={true} /> : 'Generate report'}
							</Button>
						)}
						{!test.isLegacy && canWriteTest(appStateToPermissionObject(context.appState)) && (
							<Button
								className="btn btn-seconday mt-3"
								disabled={redoLoading}
								onClick={() => {
									setRedoLoading(true)
									redoTest({
										...props,
										authState: context.appState.authState,
										successCallback: (testData) => {
											const newTest = testData.tests[0]
											props.historyPush(`/test/${newTest.testID}`)
											setRedoLoading(false)
										},
										failedCallback: () => {
											setRedoLoading(false)
										},
									})
								}}
							>
								{redoLoading ? <Loading spinner noBorder={true} /> : 'Re-do test'}
							</Button>
						)}
					</Card.Body>
				</Card>
			) : (
				<Card className="bg-light">
					<Card.Body>
						<Row>
							<Col className="col-6">
								{canLock ? (
									<Button
										className="btn btn-primary round"
										disabled={
											!test ||
											!props.allTestsComplete ||
											(canForceTestPass && forceTestPass === null) ||
											(!!props.test?.basedOnTestID && comment.length === 0)
										}
										onClick={handleShow}
									>
										<Lock style={{ marginRight: '15px', marginBottom: '3px' }} />
										LOCK TEST
									</Button>
								) : (
									<Alert variant="danger">You do not have the correct competencies to lock a test for this asset.</Alert>
								)}
							</Col>
						</Row>
					</Card.Body>
				</Card>
			)}
			{test?.isLegacy === false && (
				<Button className="btn btn-primary round mt-3" onClick={() => props.onClickBack()}>
					<ChevronLeft style={{ verticalAlign: 'text-bottom' }} size="16" />
					PREVIOUS
				</Button>
			)}
		</>
	)
}

interface TestResultOverrideProps {
	disabled: boolean
	forceTestPass: boolean | null
	setForceTestPass: React.Dispatch<React.SetStateAction<boolean | null>>
}

const TestResultOverride = (props: TestResultOverrideProps) => {
	return (
		<Card className="bg-light">
			<Card.Body>
				<Row>
					<Col>
						<DropdownSelect
							key={`forceTestPass`}
							id={`forceTestPass`}
							name={`forceTestPass`}
							label={`Tested and acceptable for intended use (YES / NO)?`}
							disabled={props.disabled}
							variant="secondary"
							showSearch={false}
							searchPlaceholder={''}
							options={[
								{ value: '', text: '-' },
								{ value: 'true', text: 'Yes' },
								{ value: 'false', text: 'No' },
							]}
							value={props.forceTestPass === null ? '-' : props.forceTestPass ? 'Yes' : 'No'}
							onChange={(value, data) => props.setForceTestPass(data.value === 'true' ? true : data.value === 'false' ? false : null)}
						/>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	)
}

interface QuestionResultsListProps {
	testHasComplianceCheck: boolean
	complianceCheckStatus: 'incomplete' | 'complete'
	complianceCheckHasFailResults: boolean
	testQuestions:
		| Pick<
				TestQuestion,
				'testQuestionID' | 'questionVersionID' | 'testQuestionPassed' | 'testQuestionComment' | 'testQuestionResponse' | 'questionID' | 'questionName'
		  >[]
		| null
	questions: DetailedQuestion[]
	isStandardsBased: boolean
}

const getResultBadgeResult = (
	testQuestion:
		| Pick<
				TestQuestion,
				'testQuestionID' | 'questionVersionID' | 'testQuestionPassed' | 'testQuestionComment' | 'testQuestionResponse' | 'questionID' | 'questionName'
		  >
		| undefined,
	isStandardsBased: boolean
) => {
	if (testQuestion) {
		if (isStandardsBased) {
			if (testQuestion.testQuestionPassed === true) {
				return 'pass'
			} else if (testQuestion.testQuestionPassed === false) {
				return 'fail'
			} else if ((testQuestion.testQuestionResponse as unknown as WorkZoneIntegrityTestOutput).areaSuitableForTest === false) {
				return 'test cannot be performed'
			} else {
				return 'incomplete'
			}
		} else {
			return 'complete'
		}
	} else {
		return 'incomplete'
	}
}

const QuestionResultsList = (props: QuestionResultsListProps): JSX.Element | null => {
	return (
		props.questions && (
			<Card>
				<Card.Body>
					<Row>
						<Col>
							{props.questions.map((question) => {
								const testQuestion = props.testQuestions?.find((testQuestion) => testQuestion.questionID === question.questionID)
								return (
									<Row className="mb-1" key={question.questionID}>
										<Col className="col-3">{question.questionName}</Col>
										<Col>
											<ResultBadge result={getResultBadgeResult(testQuestion, props.isStandardsBased)} />
										</Col>
									</Row>
								)
							})}
							{props.testHasComplianceCheck && (
								<Row className="mb-1" key="compliance">
									<Col className="col-3">Compliance check</Col>
									<Col>
										<ComplianceCheckBadge
											result={props.complianceCheckStatus}
											complianceCheckHasFailResults={props.complianceCheckHasFailResults}
										/>
									</Col>
								</Row>
							)}
						</Col>
					</Row>
				</Card.Body>
			</Card>
		)
	)
}

const GenericLockScreen = (props: LockScreenProps) => {
	const { test } = props

	const [forceTestPass, setForceTestPass] = React.useState<boolean | null>(test?.testForcePassed === undefined ? null : test.testForcePassed)

	const allTestsPassed = test?.testQuestions.every((question) => question.testQuestionPassed)
	const allTestsComplete =
		props.questions.every((question) => {
			return !!test?.testQuestions?.find((testQuestion) => testQuestion.questionID === question.questionID)
		}) && (test?.requiresComplianceCheck ? (test?.complianceCheckResponse ? true : false) : true)
	const testDateString = formatDateTime({ date: test?.performedTs, format: 'Date' })
	const complianceCheckStatus = test?.complianceCheckResponse ? 'complete' : 'incomplete'
	const complianceCheckHasFailResults = (test?.complianceCheckResponse || []).some((heading) => heading.children.some((result) => result.response === 'no'))

	let result
	if (test?.isLegacy) {
		result = test.passed ? 'pass' : 'fail'
	} else {
		if (allTestsComplete) {
			if (props.asset?.assetTypeIsStandardsBased) {
				result = allTestsPassed ? 'pass' : 'fail'
			} else {
				result = forceTestPass === null ? 'incomplete' : forceTestPass === true ? 'pass' : 'fail'
			}
		} else {
			result = 'incomplete'
		}
	}

	return (
		<>
			<TabRow tabSelectOptions={props.tabSelectOptions} onClick={(q) => props.goToQuestion(q)} currentQuestion={'Lock'} />
			<Row>
				<Col>
					<Header title={'Results'} subtitle={''} />
					{test?.isLegacy === false && (
						<QuestionResultsList
							testQuestions={test?.testQuestions || null}
							questions={props.questions}
							testHasComplianceCheck={!!test?.requiresComplianceCheck}
							complianceCheckStatus={complianceCheckStatus}
							isStandardsBased={!!props.asset?.assetTypeIsStandardsBased}
							complianceCheckHasFailResults={complianceCheckHasFailResults}
						/>
					)}
					{test?.isLegacy === false && !props.asset?.assetTypeIsStandardsBased && (
						<TestResultOverride disabled={!!test?.isLocked} forceTestPass={forceTestPass} setForceTestPass={setForceTestPass} />
					)}
					{test && (
						<>
							<Card className="mt-3">
								<Card.Header>
									<Card.Title className="mb-3">
										<Col>
											<Row>
												Result
												<ResultBadge result={result} className="ml-3" />
												<Col className="ml-3">{`Test performed at: ${testDateString}`}</Col>
											</Row>
										</Col>
									</Card.Title>
								</Card.Header>
							</Card>
							<Row></Row>
						</>
					)}
					<LockTestButtons {...props} allTestsComplete={allTestsComplete} forceTestPass={forceTestPass} />
				</Col>
			</Row>
		</>
	)
}

export { GenericLockScreen as LockScreen, LockTestButtons, lockTest, createReport }
