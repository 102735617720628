const Institution = '0a7f343f-e43a-48a3-9f89-e02d473edb37'
const FacilitiesCompany = 'acc2f2ff-9c92-4031-ac27-118d0efb9564'
const TestingContractor = 'd7163bcc-c668-4c85-9e45-054a00816cab'
const Admin = 'd742c612-25bc-4dfb-8f24-72853d7bc415'
const Level: Record<string, number> = {
	'0a7f343f-e43a-48a3-9f89-e02d473edb37': 3,
	'acc2f2ff-9c92-4031-ac27-118d0efb9564': 2,
	'd7163bcc-c668-4c85-9e45-054a00816cab': 1,
	'd742c612-25bc-4dfb-8f24-72853d7bc415': 4,
}
export { Institution, FacilitiesCompany, TestingContractor, Admin, Level }
