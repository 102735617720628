import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Row, Col, Form } from 'react-bootstrap'
import { FormText } from '../../../components/UI/Form/Text'
import { TestProps } from './QuestionLookup.d'
import { Formik } from 'formik'
import * as yup from 'yup'
import { calibrateAdditiveValue } from 'dynaflow/utilities/calibration'
import { getPassValueOverrides } from 'dynaflow/utilities/testOverride'
import {
	onQuestionSubmit,
	QuestionElement,
	QuestionNavigationButtons,
	QuestionResultElementGroup,
	TabRow,
	useLoadExistingQuestionResponse,
	QuestionHeader,
} from './QuestionComponents'
import { HighLowVelocityGrid } from './VelocityTest'
import { VelocityAndSmokeTestOutput } from 'dynaflow/utilities/question'

interface GridResult {
	point: number
	high: string
	low: string
}

interface QuestionValues {
	velocity: {
		horizontalPoints: number
		verticalPoints: number
		gridResults: GridResult[]
	}
	smoke: {
		smokeContained: string
		averageFlowRequired: boolean
		averageFlow: string
	}
	comment: string
}

const velocityInputValidation = yup.number().label('Velocity reading').required('All readings are required')

const validationSchema = yup.object().shape({
	velocity: yup.object().shape({
		gridResults: yup.array().of(
			yup.object().shape({
				high: velocityInputValidation,
				low: velocityInputValidation.when('high', (high, schema) => schema.max(high || 0, 'Must be lower than "high" reading')),
			})
		),
	}),
	smoke: yup.object().shape({
		smokeContained: yup.number().label('Smoke contained').required('All readings are required'),
		averageFlow: yup
			.number()
			.label('Average flow')
			.when('averageFlowRequired', (averageFlowRequired, schema) => (averageFlowRequired ? schema.required('All readings are required') : schema)),
	}),
})

const VelocityAndSmokeTest = (props: TestProps) => {
	const { test } = props
	const context = useContext(AppContext)
	const submitCallback = React.useRef(() => {})

	const numberHorizontal = props.asset?.assetHorizontalReadings || 1
	const numberVertical = props.asset?.assetVerticalReadings || 1

	const getVelocityReadingInputs = React.useCallback((numberHorizontal, numberVertical) => {
		const inp = [] as GridResult[]
		for (let i = 0; i < (numberHorizontal || 1) * (numberVertical || 1); i++) {
			inp.push({
				point: i + 1,
				high: '',
				low: '',
			})
		}
		return inp
	}, [])

	const [questionValues, setQuestionValues] = React.useState<QuestionValues>({
		velocity: {
			horizontalPoints: numberHorizontal,
			verticalPoints: numberVertical,
			gridResults: getVelocityReadingInputs(numberHorizontal, numberVertical),
		},
		smoke: {
			smokeContained: '',
			averageFlowRequired: true,
			averageFlow: '',
		},
		comment: '',
	})

	useLoadExistingQuestionResponse({
		...props,
		setQuestionValues,
		formatQuestionValues: React.useCallback((existingValues: VelocityAndSmokeTestOutput): QuestionValues => {
			return {
				...existingValues,
				velocity: {
					horizontalPoints: existingValues.velocity.horizontalPoints,
					verticalPoints: existingValues.velocity.verticalPoints,
					gridResults: existingValues.velocity.gridResults.map(({ instrument }, index) => {
						return { point: index + 1, high: instrument.high.toString(), low: instrument.low.toString() }
					}),
				},
				smoke: {
					...existingValues.smoke,
					smokeContained: existingValues.smoke.smokeContained.toString(),
					averageFlow: existingValues.smoke.averageFlow.toString(),
				},
				comment: '',
			}
		}, []),
	})

	// Assume there's only one instrument type for this question
	const testInstrument =
		test?.testInstruments?.find((instrument) => instrument.instrumentTypeID === props.question?.instrumentTypes[0].instrumentTypeID) || null
	const calibration =
		testInstrument && testInstrument.testInstrumentCalibrationSnapshot ? JSON.parse(testInstrument?.testInstrumentCalibrationSnapshot) : null

	const PASS_VALUES = getPassValueOverrides(props.question)

	if (!calibration || !PASS_VALUES) {
		return null
	}

	return (
		<Formik
			initialValues={questionValues}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={true}
			onSubmit={(values) => {
				onQuestionSubmit({ ...props, values, authState: context.appState.authState, submitCallback })
			}}
			enableReinitialize={true}
		>
			{({ handleSubmit, errors, values, handleChange, handleReset, dirty, isSubmitting }) => {
				// Fill out the calculated fields for display only (it's recalculated on create/update)
				let totalMeanValue
				let averageVelocity = null
				const completeResults = values.velocity.gridResults.filter((i) => i.high && i.low)
				if (completeResults.length === values.velocity.gridResults.length) {
					const calibratedValues = values.velocity.gridResults.map((i) => {
						const val = (calibrateAdditiveValue(Number(i.high), calibration)! + calibrateAdditiveValue(Number(i.low), calibration)!) / 2
						return val
					})
					totalMeanValue = calibratedValues.reduce((partialSum, i) => partialSum! + i!, 0)!
					averageVelocity = totalMeanValue / values.velocity.gridResults.length
				}

				return (
					<>
						<TabRow
							tabSelectOptions={props.tabSelectOptions}
							onClick={(q, save) => {
								if (save) {
									submitCallback.current = () => props.goToQuestion(q)
									handleSubmit()
								} else {
									props.goToQuestion(q)
								}
							}}
							currentQuestion={props.question?.questionID}
							dirty={dirty}
							isSubmitting={isSubmitting}
							errors={errors}
						/>
						<Row>
							<Col>
								<QuestionHeader question={props.question} />
								<QuestionElement>
									<Row className="mb-4">
										<Col>
											<FormText
												name={'horizontalPoints'}
												value={values.velocity.horizontalPoints}
												label={'Horizontal readings'}
												plaintext={true}
												disabled={true}
												feedback={errors?.velocity?.horizontalPoints}
												isInvalid={!!errors?.velocity?.horizontalPoints}
												onChange={(e) => {
													const n = Number(e.target.value)
													setQuestionValues({
														...values,
														velocity: {
															...values.velocity,
															horizontalPoints: n,
															gridResults: getVelocityReadingInputs(n, values.velocity.verticalPoints),
														},
													})
													handleChange(e)
												}}
												type="number"
												min={1}
											/>
										</Col>
										<Col>
											<FormText
												name={'verticalPoints'}
												value={values.velocity.verticalPoints}
												label={'Vertical readings'}
												plaintext={true}
												disabled={true}
												feedback={errors?.velocity?.verticalPoints}
												isInvalid={!!errors?.velocity?.verticalPoints}
												onChange={(e) => {
													const n = Number(e.target.value)
													setQuestionValues({
														...values,
														velocity: {
															...values.velocity,
															verticalPoints: n,
															gridResults: getVelocityReadingInputs(values.velocity.horizontalPoints, n),
														},
													})
													handleChange(e)
												}}
												type="number"
												min={1}
											/>
										</Col>
									</Row>
									<Row>
										{values.velocity.horizontalPoints && values.velocity.verticalPoints && (
											<HighLowVelocityGrid
												values={values.velocity.gridResults}
												errors={errors?.velocity?.gridResults}
												numberHorizontal={numberHorizontal}
												numberVertical={numberVertical}
												formikKey="velocity"
												handleChange={handleChange}
												calibration={calibration}
												disabled={!!test?.isLocked}
												minReading={PASS_VALUES.minimumFaceVelocityReading}
											/>
										)}
									</Row>
									<QuestionResultElementGroup
										className="mb-3"
										results={[
											{ title: 'Total mean value', value: `${totalMeanValue?.toFixed(2) || '-'} m/s` },
											{ title: 'Average velocity', value: `${averageVelocity?.toFixed(2) || '-'} m/s` },
										]}
									/>

									<Row className="mb-3">
										<Col>
											<FormText
												name={'smoke.smokeContained'}
												value={values.smoke.smokeContained}
												label={'Smoke contained (mm)'}
												plaintext={!!test?.isLocked}
												disabled={!!test?.isLocked}
												feedback={errors?.smoke?.smokeContained}
												isInvalid={!!errors?.smoke?.smokeContained}
												onChange={handleChange}
											/>
										</Col>
										<Col>
											<Form.Group controlId="smoke.averageFlowRequired">
												<Form.Check
													id="smoke.averageFlowRequired"
													label="Average flow reading required"
													type="checkbox"
													name="smoke.averageFlowRequired"
													checked={values.smoke.averageFlowRequired}
													onChange={handleChange}
													disabled={!!test?.isLocked}
												/>
											</Form.Group>
											<FormText
												name={'smoke.averageFlow'}
												value={values.smoke.averageFlow}
												label={'Average flow (L/s)'}
												plaintext={!!test?.isLocked}
												disabled={!!test?.isLocked || !values.smoke.averageFlowRequired}
												feedback={errors?.smoke?.averageFlow}
												isInvalid={!!errors?.smoke?.averageFlow}
												onChange={handleChange}
											/>
										</Col>
									</Row>
								</QuestionElement>
								<QuestionElement>
									<FormText
										as="textarea"
										rows={3}
										label="Comments"
										name={'comment'}
										value={values.comment}
										onChange={handleChange}
										disabled={!!test?.isLocked}
									/>
								</QuestionElement>
								<QuestionNavigationButtons
									{...props}
									handleReset={handleReset}
									handleSubmit={handleSubmit}
									submitCallback={submitCallback}
									setSubmitCallback={(fn) => (submitCallback.current = fn)}
									dirty={dirty}
									isSubmitting={isSubmitting}
								/>
							</Col>
						</Row>
					</>
				)
			}}
		</Formik>
	)
}

export { VelocityAndSmokeTest }
