import { FormikErrors } from 'formik'
import * as yup from 'yup'

const asset = yup.object().shape({
	assetID: yup.string().optional(),
	assetActive: yup.boolean().required(),
	assetTypeID: yup.string().required('Asset Type is required.'),
	locationID: yup.string().required('Location is required.'),
	buildingID: yup.string().required('Building is required.'),
	roomID: yup.string().required('Room is required.'),

	assetSerialNumber: yup.string().required('Serial Number is required.'),
	assetWidth: yup.number().typeError('Specify a number.').min(0, 'Minimum value is 0').required('Width is required.'),
	assetHeight: yup.number().typeError('Specify a number.').min(0, 'Minimum value is 0').required('Height is required.'),
	assetChamberHeight: yup.number().typeError('Specify a number.').min(0, 'Minimum value is 0').required('Chamber Height is required.'),
	assetChamberWidth: yup.number().typeError('Specify a number.').min(0, 'Minimum value is 0').required('Chamber Width is required.'),
	assetChamberDepth: yup.number().typeError('Specify a number.').min(0, 'Minimum value is 0').required('Chamber Depth is required.'),
	assetMake: yup.string().required('Make is required.'),
	assetModel: yup.string().required('Model is required.'),
	assetClientRef: yup.string().required('Client Reference is required.'),
	assetVerticalReadings: yup.number().typeError('Specify a number.').min(0, 'Minimum value is 0').required('Vertical Reading is required.'),
	assetHorizontalReadings: yup.number().typeError('Specify a number.').min(0, 'Minimum value is 0').required('Horizontal Reading is required.'),
	assetInstallationDate: yup.date().optional().nullable(),
})

const fumeCabinet = asset.shape({
	assetVerticalReadings: yup.number().nullable(),
	assetHorizontalReadings: yup.number().nullable(),
})

const fumeCupboard = asset.shape({
	assetVerticalReadings: yup.number().nullable(),
	assetHorizontalReadings: yup.number().nullable(),
})

const extractionHood = asset.shape({
	assetSerialNumber: yup.string().nullable(),
	assetWidth: yup.number().typeError('Specify a number.').min(0, 'Minimum value is 0').nullable(),
	assetHeight: yup.number().typeError('Specify a number.').min(0, 'Minimum value is 0').nullable(),
	assetChamberWidth: yup.number().nullable(),
	assetChamberHeight: yup.number().nullable(),
	assetChamberDepth: yup.number().nullable(),
	assetMake: yup.string().nullable(),
	assetModel: yup.string().nullable(),
})

const laminarFlow = asset.shape({
	assetChamberWidth: yup.number().nullable(),
	assetChamberHeight: yup.number().nullable(),
	assetChamberDepth: yup.number().nullable(),
	assetVerticalReadings: yup.number().nullable(),
	assetHorizontalReadings: yup.number().nullable(),
})

const cytotoxic = asset.shape({
	assetChamberWidth: yup.number().nullable(),
	assetChamberHeight: yup.number().nullable(),
	assetChamberDepth: yup.number().nullable(),
	assetVerticalReadings: yup.number().nullable(),
	assetHorizontalReadings: yup.number().nullable(),
})

const bioCabinet1 = asset.shape({
	assetChamberWidth: yup.number().nullable(),
	assetChamberHeight: yup.number().nullable(),
	assetChamberDepth: yup.number().nullable(),
	assetVerticalReadings: yup.number().nullable(),
	assetHorizontalReadings: yup.number().nullable(),
})

const bioCabinet2 = asset.shape({
	assetChamberWidth: yup.number().nullable(),
	assetChamberHeight: yup.number().nullable(),
	assetChamberDepth: yup.number().nullable(),
	assetVerticalReadings: yup.number().nullable(),
	assetHorizontalReadings: yup.number().nullable(),
})

const custom = asset.shape({
	assetChamberWidth: yup.number().nullable(),
	assetChamberHeight: yup.number().nullable(),
	assetChamberDepth: yup.number().nullable(),
	assetMake: yup.string().nullable(),
	assetModel: yup.string().nullable(),
})

const FumeCabinet = '43969324-0a26-4af9-b369-74e286749cbb'
const FumeCupboard = '7a4a54e7-f561-4b2a-8eb8-8f36fc6fb999'
const ExtractionHood = '0c6c46fe-3afd-41d1-b809-351f0bf077a8'
const LaminarFlow = 'a663fbdc-d1d7-43d4-b64c-03980695d5cd'
const CytotoxicDrugSafetyCabinet = 'b215d7f1-97a6-467d-98f6-e075ac1d33c4'
const BiologicalSafetyCabinet1 = 'a1c3649a-51de-4f1f-af63-f15071f04a60'
const BiologicalSafetyCabinet2 = '8f7deab9-bbc1-4da3-a42d-65d9afd98057'

const getAssetValidationSchemaFromParentAssetTypeID = (parentAssetTypeID: string | undefined | null) => {
	switch (parentAssetTypeID) {
		case FumeCabinet:
			return fumeCabinet
		case FumeCupboard:
			return fumeCupboard
		case ExtractionHood:
			return extractionHood
		case LaminarFlow:
			return laminarFlow
		case CytotoxicDrugSafetyCabinet:
			return cytotoxic
		case BiologicalSafetyCabinet1:
			return bioCabinet1
		case BiologicalSafetyCabinet2:
			return bioCabinet2
		default:
			return custom
	}
}

const errorObjectHasAnError = (errors: FormikErrors<Partial<unknown>>) => Object.keys(errors).some((key) => errors[key as keyof typeof errors] !== undefined)

export {
	errorObjectHasAnError,
	getAssetValidationSchemaFromParentAssetTypeID,
	FumeCabinet,
	FumeCupboard,
	ExtractionHood,
	LaminarFlow,
	CytotoxicDrugSafetyCabinet,
	BiologicalSafetyCabinet1,
	BiologicalSafetyCabinet2,
}
