import React, { useContext } from 'react'
import { RouteChildrenProps } from 'react-router-dom'
import { Container, Spinner, Col, Row, Table, Form, Button } from 'react-bootstrap'
import { Location, LocationResult } from '../../../../back-end/common/location'
import * as Request from '../../utilities/request'
import { Header } from '../../components/UI/Header/Header'
import { Card } from '../../components/UI/Card/Card'
import { Paginator, FiltersState, pageFilter } from '../../components/UI/Paginator/Paginator'
import { AppContext } from '../../App'
import { PageStatus } from '../../types/PageStatus'

import addIcon from '../../images/icons/add.svg'
import { DeleteButton } from '../../components/UI/Form/DeleteButton'

const ScreensLocation = (props: RouteChildrenProps) => {
	const context = useContext(AppContext)

	const [locations, setLocations] = React.useState<Location[] | null>(null)
	const [filters, setFilters] = React.useState<FiltersState>({ page: 1, pageSize: 10 })
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [locationSearch, setLocationSearch] = React.useState<string>('')
	const [buildingSearch, setBuildingSearch] = React.useState<string>('')
	const [roomSearch, setRoomSearch] = React.useState<string>('')

	React.useEffect(() => {
		const getData = async () => {
			const [locationReq] = await Promise.all([Request.get<LocationResult>('location', context.appState.authState)])
			setLocations(locationReq.data.locations)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	React.useEffect(() => {
		setFilters({ ...filters, page: 1 })
	}, [locationSearch, buildingSearch, roomSearch]) // eslint-disable-line

	/* Apply filters */
	let filteredRecords: Location[] = locations || []
	let paginatedFilteredRecords: Location[] = []
	if (filteredRecords.length > 0) {
		// Filter by string search
		filteredRecords = stringFilter(filteredRecords, 'location', locationSearch)

		filteredRecords = stringFilter(filteredRecords, 'building', buildingSearch)

		filteredRecords = stringFilter(filteredRecords, 'room', roomSearch)

		// Pagination
		paginatedFilteredRecords = filteredRecords.filter((_record: unknown, index: number) => pageFilter(filters, index))
	}

	return (
		<Container>
			<Header title={'Locations'} subtitle={'View a list of locations'} />

			<Row>
				<Col>
					<Header title={`Locations`} subtitle={'View a list of locations'} />
				</Col>
				<Col sm={'auto'} style={{ paddingTop: '40px' }}>
					<Button className=" btn btn-primary round" onClick={() => props.history.push('/location/new')}>
						<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
						<span className="span-bold" style={{ color: 'white' }}>
							Create new location
						</span>
					</Button>
				</Col>
			</Row>

			<Card
				title={'Locations'}
				collapsible={false}
				headerComponent={() => (
					<Row>
						<Col>
							<Form.Control
								onChange={(event) => {
									setLocationSearch(event.target.value)
								}}
								type="text"
								id="locationSearch"
								key="locationSearch"
								name="locationSearch"
								value={locationSearch}
								placeholder="Filter location"
							/>
						</Col>
						<Col>
							<Form.Control
								onChange={(event) => {
									setBuildingSearch(event.target.value)
								}}
								type="text"
								id="buildingSearch"
								key="buildingSearch"
								name="buildingSearch"
								value={buildingSearch}
								placeholder="Filter building"
							/>
						</Col>
						<Col>
							<Form.Control
								onChange={(event) => {
									setRoomSearch(event.target.value)
								}}
								type="text"
								id="roomSearch"
								key="roomSearch"
								name="roomSearch"
								value={roomSearch}
								placeholder="Filter room"
							/>
						</Col>
					</Row>
				)}
			>
				<>
					<Table responsive borderless>
						<thead>
							<tr>
								<th>Location</th>
								<th>Building</th>
								<th>Room</th>
							</tr>
						</thead>
						<tbody>{pageStatus !== 'Loading' && renderLocationList(paginatedFilteredRecords, () => {})}</tbody>
					</Table>
					{pageStatus === 'Loading' ? (
						<Row>
							<Col sm="auto">
								<Spinner size={'sm'} animation={'border'} />
							</Col>
						</Row>
					) : null}
					<Row className="justify-content-sm-center">
						<Col sm="auto">
							<Paginator filters={filters} setFilterDispatch={setFilters} allPaginatedRecordsLength={filteredRecords.length} />
						</Col>
					</Row>
				</>
			</Card>
		</Container>
	)
}

/* Filters */
const stringFilter = (list: Location[], property: string, text: string) => {
	switch (property) {
		case 'location':
			return list.filter(
				(location: Location) =>
					location.locationName.toLowerCase().indexOf(text.toLowerCase()) !== -1 ||
					location.locationAddress.toLowerCase().indexOf(text.toLowerCase()) !== -1
			)
		case 'building':
			return list.map((location: Location) => ({
				...location,
				buildings: location.buildings.filter(
					(building) =>
						building.buildingName.toLowerCase().indexOf(text.toLowerCase()) !== -1 ||
						building.buildingAddress.toLowerCase().indexOf(text.toLowerCase()) !== -1
				),
			}))
		case 'room':
			return list.map((location: Location) => ({
				...location,
				buildings: location.buildings.map((building) => ({
					...building,
					rooms: building.rooms.filter((room) => room.roomName.toLowerCase().indexOf(text.toLowerCase()) !== -1),
				})),
			}))
		default:
			return list
	}
}

const renderLocationList = (locationRecords: Location[], onDelete: (thingToDelete: string, id: string) => void) => {
	if (locationRecords.length > 0) {
		return locationRecords.map((location) => {
			return (
				<React.Fragment key={location.locationID}>
					{location.buildings.length > 0 ? (
						location.buildings.map((building, index) => (
							<tr key={index}>
								{index === 0 && <td rowSpan={location.buildings.length}>{location.locationName}</td>}
								<td>{building.buildingName}</td>
								<td>{building.rooms.map((room) => room.roomName).join(', ')}</td>
								<td>
									<DeleteButton id={building.buildingID} onClick={async (id) => onDelete && (await onDelete('building', id))} />
								</td>
							</tr>
						))
					) : (
						<tr>
							<td>{location.locationName}</td>
							<td></td>
							<td></td>
							<td></td>
							<td>
								<DeleteButton id={location.locationID} onClick={async (id) => onDelete && (await onDelete('location', id))} />
							</td>
						</tr>
					)}
				</React.Fragment>
			)
		})
	} else {
		return (
			<tr>
				<td>No Locations</td>
			</tr>
		)
	}
}

export { ScreensLocation }
