import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface DeleteModalProps {
	show: boolean
	noun: string
	onDeletePress: () => void
	onNoPress: () => void
}

export const DeleteModal = (props: DeleteModalProps) => {
	return (
		<Modal show={props.show}>
			<Modal.Body>
				<p>Do you really want to delete these {props.noun}?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={props.onNoPress}>
					No
				</Button>
				<Button variant="primary" onClick={props.onDeletePress}>
					Delete Forever
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
