import React from 'react'
import { Row, Col, Card, Container } from 'react-bootstrap'
import { Frown } from 'react-feather'
import { Link } from 'react-router-dom'

const PageNotFound: React.FC = () => {
	return (
		<Container>
			<Card style={{ marginTop: '20%', textAlign: 'center', padding: '2%' }}>
				<Row style={{ marginBottom: '20px' }}>
					<Col>
						<Frown />
					</Col>
				</Row>
				<Row>
					<Col>
						<h1>Ooops! It looks like you got lost</h1>
					</Col>
				</Row>
				<Row>
					<Col>
						<Link to={'/'} className="btn btn-primary">
							Return home
						</Link>
					</Col>
				</Row>
			</Card>
		</Container>
	)
}

export { PageNotFound }
