import React from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { Instrument } from '../../../../back-end/common/instruments'
import { formatDateTime } from '../../utilities/formatDate'
import { PageBackState } from '../../types/PageBackState'

interface InstrumentSetInstrumentListProps {
	instruments: Instrument[] | null
	backState?: PageBackState
}

const InstrumentSetInstrumentList = (props: InstrumentSetInstrumentListProps) => {
	if (props.instruments) {
		return (
			<Table>
				<thead>
					<tr>
						<th>Serial Number</th>
						<th>Type</th>
						<th>Last Modified</th>
						<th>Last Modified By</th>
					</tr>
				</thead>
				<tbody>
					{props.instruments.length === 0 && (
						<tr>
							<td colSpan={4}>No Instruments</td>
						</tr>
					)}
					{props.instruments.length !== 0 &&
						props.instruments.map((instrument) => (
							<tr key={instrument.instrumentID}>
								<td>
									<Link to={{ pathname: `/instrument/${instrument.instrumentID}`, state: props.backState }}>
										{instrument.instrumentSerialNumber}
									</Link>
								</td>
								<td>{instrument.instrumentTypeName}</td>
								<td>{formatDateTime({ date: instrument.modified.ts, format: 'DateAndTime' })}</td>
								<td>
									{instrument.modified.fullName
										? instrument.modified.fullName
										: `${instrument.modified.firstName} ${instrument.modified.lastName}`}
								</td>
							</tr>
						))}
				</tbody>
			</Table>
		)
	}
	return (
		<Spinner animation="border" role="status">
			<span className="visually-hidden">Loading...</span>
		</Spinner>
	)
}

export { InstrumentSetInstrumentList }
export type { InstrumentSetInstrumentListProps }
