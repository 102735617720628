import React from 'react'
import { VelocityTest } from './VelocityTest'
import { SmokeTest } from './SmokeTest'
import { TestProps } from './QuestionLookup.d'
import { QUESTION, QUESTION_VERSION } from 'dynaflow/constants/question'
import { AirVelocityUniformityTest } from './AirVelocityUniformityTest'
import { WorkZoneIntegrityTest } from './WorkZoneIntegrity'
import { FilterInstallationIntegrity } from './FilterInstallationIntegrity'
import { LightingTest } from './LightingTest'
import { SoundLevelTest } from './SoundLevelTest'
import { AirBarrierContainmentTest } from './AirBarrierContainmentTest'
import { UltraVioletRadiationTest } from './UltraVioletRadiationTest'
import { InwardAirVelocityTest } from './InwardAirVelocityTest'
import { VelocityAndSmokeTest } from './VelocityAndSmokeTest'

const QuestionLookup = (props: TestProps) => {
	switch (props.question?.questionID) {
		case QUESTION.VELOCITY_TEST:
			switch (props.question?.questionVersionID) {
				case QUESTION_VERSION.VELOCITY_TEST.V1:
					return <VelocityTest {...props} />
				default:
					return <span>Invalid question version</span>
			}
		case QUESTION.SMOKE_TEST:
			switch (props.question?.questionVersionID) {
				case QUESTION_VERSION.SMOKE_TEST.V1:
					return <SmokeTest {...props} />
				default:
					return <span>Invalid question version</span>
			}
		case QUESTION.AIR_VELOCITY_TEST:
			switch (props.question?.questionVersionID) {
				case QUESTION_VERSION.AIR_VELOCITY_TEST.V1:
					return <AirVelocityUniformityTest {...props} />
				default:
					return <span>Invalid question version</span>
			}
		case QUESTION.WORK_ZONE_INTEGRITY_TEST:
			switch (props.question?.questionVersionID) {
				case QUESTION_VERSION.WORK_ZONE_INTEGRITY_TEST.V1:
					return <WorkZoneIntegrityTest {...props} />
				default:
					return <span>Invalid question version</span>
			}
		case QUESTION.FILTER_INTEGRITY_TEST:
			switch (props.question?.questionVersionID) {
				case QUESTION_VERSION.FILTER_INTEGRITY_TEST.V1:
					return <FilterInstallationIntegrity {...props} />
				default:
					return <span>Invalid question version</span>
			}
		case QUESTION.LIGHTING_TEST:
		case QUESTION.FUME_LIGHTING_TEST:
			switch (props.question?.questionVersionID) {
				case QUESTION_VERSION.LIGHTING_TEST.V1:
				case QUESTION_VERSION.FUME_LIGHTING_TEST.V1:
					return <LightingTest {...props} />
				default:
					return <span>Invalid question version</span>
			}
		case QUESTION.SOUND_LEVEL_TEST:
			switch (props.question?.questionVersionID) {
				case QUESTION_VERSION.SOUND_LEVEL_TEST.V1:
					return <SoundLevelTest {...props} />
				default:
					return <span>Invalid question version</span>
			}
		case QUESTION.AIR_BARRIER_CONTAINMENT_TEST:
			switch (props.question?.questionVersionID) {
				case QUESTION_VERSION.AIR_BARRIER_CONTAINMENT_TEST.V1:
					return <AirBarrierContainmentTest {...props} />
				default:
					return <span>Invalid question version</span>
			}
		case QUESTION.ULTRA_VIOLET_TEST:
			switch (props.question?.questionVersionID) {
				case QUESTION_VERSION.ULTRA_VIOLET_TEST.V1:
					return <UltraVioletRadiationTest {...props} />
				default:
					return <span>Invalid question version</span>
			}
		case QUESTION.INWARD_AIR_VELOCITY_TEST:
			switch (props.question?.questionVersionID) {
				case QUESTION_VERSION.INWARD_AIR_VELOCITY_TEST.V1:
					return <InwardAirVelocityTest {...props} />
				default:
					return <span>Invalid question version</span>
			}
		case QUESTION.VELOCITY_AND_SMOKE_TEST:
			switch (props.question?.questionVersionID) {
				case QUESTION_VERSION.VELOCITY_AND_SMOKE_TEST.V1:
					return <VelocityAndSmokeTest {...props} />
				default:
					return <span>Invalid question version</span>
			}
		default:
			return <span>Invalid question</span>
	}
}

export { QuestionLookup }
