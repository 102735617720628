import React, { ChangeEvent, useContext } from 'react'
import { AppContext } from '../../../App'
import { Row, Col, Card, Alert } from 'react-bootstrap'
import { Calibration } from '../../../../../back-end/common/calibration'
import { FormText } from '../../../components/UI/Form/Text'
import { TestProps } from './QuestionLookup.d'
import { Formik, FormikErrors } from 'formik'
import * as yup from 'yup'
import { calibrateMultiplicativeValue } from 'dynaflow/utilities/calibration'
import { LightingTestOutput } from 'dynaflow/utilities/question'
import { getLightingReadingsCount } from 'dynaflow/utilities/questionResponse'
import { getPassValueOverrides } from 'dynaflow/utilities/testOverride'
import {
	onQuestionSubmit,
	QuestionElement,
	QuestionNavigationButtons,
	QuestionResult,
	QuestionResultElementGroup,
	TabRow,
	useLoadExistingQuestionResponse,
	QuestionHeader,
} from './QuestionComponents'

const VELOCITY_READING_CARD_WIDTH = 120
interface VelocityReadingProps {
	index: number
	formikKey: string
	calibration: Pick<Calibration, 'calibrationID' | 'calibrationDate' | 'calibrationCertificateNumber' | 'calibrationCertificateS3Path' | 'calibrationItems'>
	values?: GridResult
	errors?: FormikErrors<GridResult> | string | string[]
	handleChange: (e: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
	disabled: boolean
}

const LightReading = (props: VelocityReadingProps) => {
	const calibValue = calibrateMultiplicativeValue(Number(props.values?.value), props.calibration)
	return (
		<Card className="ml-2 mr-2" style={{ width: `${VELOCITY_READING_CARD_WIDTH}px` }} title={props.index.toString()}>
			<div className="text-center rounded-top border-bottom bg-light">{props.index + 1}</div>
			<Col>
				<FormText
					name={`${props.formikKey}.results[${props.index}].value`}
					label="Result"
					value={props.values?.value || ''}
					onChange={props.handleChange}
					disabled={props.disabled}
					feedback={(props?.errors as FormikErrors<GridResult>)?.value}
					isInvalid={!!(props?.errors as FormikErrors<GridResult>)?.value}
				/>
			</Col>
			<Col className="ml-2 mt-2 mb-1">{calibValue || ''}&nbsp;</Col>
		</Card>
	)
}

interface LightGridProps {
	formikKey: string
	calibration: Pick<Calibration, 'calibrationID' | 'calibrationDate' | 'calibrationCertificateNumber' | 'calibrationCertificateS3Path' | 'calibrationItems'>
	values: {
		horizontalPoints: number
		results: GridResult[]
	}
	errors?: FormikErrors<GridResult>[] | string | string[]
	handleChange: (e: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
	disabled: boolean
	unit?: string
}

export const LightGrid = (props: LightGridProps) => {
	const rows = []
	const numberHorizontal = props.values.horizontalPoints
	rows.push(
		<Card key={numberHorizontal + 'label'} className="border-none" style={{ display: 'flex', alignSelf: 'flex-end', boxShadow: 'none', border: 'none' }}>
			<div>&nbsp;</div>
			<div className="text-right" style={{ display: 'grid', gridTemplateColumns: '100%' }}>
				<Col className="pr-1 mb-2 font-weight-bold" style={{ textAlign: 'end', alignSelf: 'end' }}>
					Raw data ({props.unit || 'lux'}):
				</Col>
				<Col className="pr-1 mb-2 font-weight-bold" style={{ textAlign: 'end', alignSelf: 'end' }}>
					Calibrated ({props.unit || 'lux'}):
				</Col>
			</div>
		</Card>
	)
	for (let h = 0; h < numberHorizontal; h += 1) {
		rows.push(<LightReading key={h} {...props} values={props.values.results.at(h)} errors={props?.errors?.at(h)} index={h} />)
	}

	const gridStyle = {
		display: 'grid',
		gridTemplateColumns: '180px ' + `${VELOCITY_READING_CARD_WIDTH + 16}px `.repeat(numberHorizontal),
		overflow: 'auto',
	}
	return <div style={gridStyle}>{rows}</div>
}

interface GridResult {
	point: number
	value: string
}

interface QuestionValues {
	lightingReadings: {
		horizontalPoints: number
		results: GridResult[]
	}
	comment: string
}

export const lightInputValidation = yup.number().label('Lighting reading').required('All readings are required')

const lightValidationSchema = yup.object().shape({
	lightingReadings: yup.object().shape({
		results: yup.array().of(
			yup.object().shape({
				value: lightInputValidation,
			})
		),
	}),
	comment: yup.string().optional(),
})

const LightingTest = (props: TestProps) => {
	const { test } = props
	const context = useContext(AppContext)
	const submitCallback = React.useRef(() => {})

	const numberHorizontal = React.useMemo(() => getLightingReadingsCount(props.asset), [props.asset])

	const getVelocityReadingInputs = React.useCallback((numHorizontal) => {
		const inp = [] as GridResult[]
		for (let i = 0; i < (numHorizontal || 1); i++) {
			inp.push({
				point: i + 1,
				value: '',
			})
		}
		return inp
	}, [])

	const [questionValues, setQuestionValues] = React.useState<QuestionValues>({
		lightingReadings: {
			horizontalPoints: numberHorizontal,
			results: getVelocityReadingInputs(numberHorizontal),
		},
		comment: '',
	})

	useLoadExistingQuestionResponse({
		...props,
		setQuestionValues,
		formatQuestionValues: React.useCallback((existingValues: LightingTestOutput): QuestionValues => {
			return {
				...existingValues,
				lightingReadings: {
					horizontalPoints: existingValues.lightingReadings.horizontalPoints,
					results: existingValues.lightingReadings.results.map(({ instrumentValue }, index) => {
						return { point: index + 1, value: instrumentValue.toString() }
					}),
				},
				comment: '',
			}
		}, []),
	})

	// Assume there's only one instrument type for this question
	const testInstrument =
		test?.testInstruments?.find((instrument) => instrument.instrumentTypeID === props.question?.instrumentTypes[0].instrumentTypeID) || null
	const calibration =
		testInstrument && testInstrument.testInstrumentCalibrationSnapshot ? JSON.parse(testInstrument?.testInstrumentCalibrationSnapshot) : null

	const PASS_VALUES = getPassValueOverrides(props.question)
	console.log(calibration, PASS_VALUES)
	if (!calibration || !PASS_VALUES) {
		return null
	}

	return (
		<Formik
			initialValues={questionValues}
			validationSchema={lightValidationSchema}
			validateOnChange={false}
			validateOnBlur={true}
			onSubmit={(values) => {
				onQuestionSubmit({ ...props, values, authState: context.appState.authState, submitCallback })
			}}
			enableReinitialize={true}
		>
			{({ handleSubmit, errors, values, handleChange, handleReset, dirty, isSubmitting }) => {
				// Fill out the calculated fields for display only (it's recalculated on create/update)
				let failed = null
				let totalReading = null
				let averageReading = null
				const completeResults = values.lightingReadings.results.filter((i) => !!i.value)
				if (completeResults.length === values.lightingReadings.results.length) {
					failed = false
					const calibratedValues = values.lightingReadings.results.map((i) => calibrateMultiplicativeValue(Number(i.value), calibration)!)
					if (calibratedValues.some((val) => val < PASS_VALUES.minimumLightReading)) {
						failed = true
					}
					totalReading = calibratedValues.reduce((partialSum, i) => partialSum! + i!, 0)!
					averageReading = totalReading / values.lightingReadings.results.length
					if (averageReading < PASS_VALUES.minimumAverageLightReading) {
						failed = true
					}
				}
				return (
					<>
						<TabRow
							tabSelectOptions={props.tabSelectOptions}
							onClick={(q, save) => {
								if (save) {
									submitCallback.current = () => props.goToQuestion(q)
									handleSubmit()
								} else {
									props.goToQuestion(q)
								}
							}}
							currentQuestion={props.question?.questionID}
							dirty={dirty}
							isSubmitting={isSubmitting}
							errors={errors}
						/>
						<Row>
							<Col>
								<QuestionHeader question={props.question} />
								<QuestionElement>
									<Alert variant="warning" className="text-dark">
										Light Measurements (lux) to be taken on centre-line across work surface of cabinet. Starting 150mm from each edge at
										approx 300mm centres.
									</Alert>
									<Row className="mb-4">
										{values.lightingReadings.horizontalPoints && (
											<LightGrid
												values={values.lightingReadings}
												errors={errors?.lightingReadings?.results}
												formikKey="lightingReadings"
												handleChange={handleChange}
												calibration={calibration}
												disabled={!!test?.isLocked}
											/>
										)}
									</Row>
									<QuestionResultElementGroup
										results={[
											{
												title: 'Sum of readings',
												value: `${totalReading?.toFixed(2) || '-'} lux`,
											},
											{
												title: 'Average',
												value: `${averageReading?.toFixed(2) || '-'} lux`,
											},
										]}
									/>
								</QuestionElement>
								<QuestionResult
									failed={failed}
									questionHasComment={true}
									questionHasForcePass={false}
									commentValue={values.comment}
									handleChange={handleChange}
									disabled={!!test?.isLocked}
									isStandardsBased={props.asset?.assetTypeIsStandardsBased}
								/>
								<QuestionNavigationButtons
									{...props}
									handleReset={handleReset}
									handleSubmit={handleSubmit}
									submitCallback={submitCallback}
									setSubmitCallback={(fn) => (submitCallback.current = fn)}
									dirty={dirty}
									isSubmitting={isSubmitting}
								/>
							</Col>
						</Row>
					</>
				)
			}}
		</Formik>
	)
}

export { LightingTest }
