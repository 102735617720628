import React, { useReducer, Reducer, useEffect, useState, useContext } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { Amplify, I18n } from '@aws-amplify/core'
import { CognitoUserAmplify } from '@aws-amplify/ui'
import { AppState, AppStateAction, ProtectedRouteProps, Attributes } from './App.d'
import * as Request from './utilities/request'
import { awsConfig } from './constants/config'

import { ScreensHome } from './screens/Home/Home'
import { ScreensInstrument } from './screens/Instrument/InstrumentsList'
import { ScreensInstrumentDetails } from './screens/Instrument/InstrumentDetails'
import { ScreensInstrumentSetDetails } from './screens/Instrument/InstrumentSetDetails'
import { ScreensAccount } from './screens/Account/Account'
import { ScreensAccountDetails as ScreensInstitution } from './screens/Account/Institution'
import { ScreensAsset } from './screens/Asset/Asset'
import { ScreensAssetDetails } from './screens/Asset/AssetDetails'
import { ScreensAssetCreate } from './screens/Asset/AssetCreate'
import { ScreensAssetMoveTests } from './screens/Asset/AssetMoveTests'
import { ScreensAssetType } from './screens/Asset/AssetType'
import { ScreensAssetTypeDetails } from './screens/Asset/AssetTypeDetails'
import { ScreensCompetencyDetails } from './screens/Competency/CompetencyDetails'
import { ScreensLocation } from './screens/Location/Location'
import { ScreensLocationDetails } from './screens/Location/LocationDetails'
import { ScreensUser } from './screens/User/User'
import { ScreensUserDetails } from './screens/User/UserDetails'
import { ScreensSettings } from './screens/Settings/Settings'
import { ScreensHomeAccountDisabled } from './screens/Home/HomeAccountDisabled'
import { ScreensHomeEntityPicker } from './screens/Home/HomeEntityPicker'
import { ScreensLogin } from './screens/Login/Login'
import { ErrorBoundary } from './screens/ErrorBoundary/ErrorBoundary'
import { PageWrapper } from './screens/PageWrapper/PageWrapper'
import { ScreensEntityDetails } from './screens/Entity/EntityDetails'
import { MyProfile } from './screens/Settings/MyProfile'
import { ScreensTest } from './screens/Test/Test'
import { ScreensTestDetails } from './screens/Test/TestDetails'
import { ScreensHistory } from './screens/History/History'
import { PageNotFound } from './screens/PageNotFound/PageNotFound'
import { UserResult } from '../../back-end/common/user'
import { Entity, EntityResult } from '../../back-end/common/entity'
import './App.css'
import { Authorisation } from '../../back-end/common/permission'
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react'
import { UseAuthenticator } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useAuthenticator'

import '@aws-amplify/ui-react/styles.css'
import { AmplifyTheme, Components, Services } from './components/Login/AuthComponent/AmplifyComponents'
Amplify.configure(awsConfig)
I18n.putVocabulariesForLanguage('en', {
	Username: 'Email', // Username label
})

const ProppedRoute = ({ newRender: RouteComponent, ...rest }: ProtectedRouteProps) => <Route {...rest} render={(rProps) => <RouteComponent {...rProps} />} />

const ProtectedRoute = ({ newRender: RouteComponent, ...rest }: ProtectedRouteProps) => {
	const context = useContext(AppContext)

	return (
		<Route
			{...rest}
			render={(renderProps) =>
				context.appState.authState.isLoggedIn ? (
					<PageWrapper {...renderProps} renderPage={RouteComponent} />
				) : (
					<Redirect to={`/login?redirect=${renderProps.location ? renderProps.location.pathname + renderProps.location.search : ''}`} />
				)
			}
		/>
	)
}

const ErrorBoundaryWrapper = () => {
	return (
		<ErrorBoundary>
			<PageNotFound />
		</ErrorBoundary>
	)
}

const Routes = () => (
	<Switch>
		<ProtectedRoute exact path="/" newRender={ScreensHome} />

		<ProtectedRoute exact path="/asset" newRender={ScreensAsset} />
		<ProtectedRoute exact path="/asset/new" newRender={ScreensAssetCreate} />
		<ProtectedRoute exact path="/asset/:id" newRender={ScreensAssetDetails} />
		<ProtectedRoute exact path="/asset/movetests/:id" newRender={ScreensAssetMoveTests} />
		<ProtectedRoute exact path="/assettype" newRender={ScreensAssetType} />
		<ProtectedRoute exact path="/assettype/:id" newRender={ScreensAssetTypeDetails} />

		<ProtectedRoute exact path="/competency/:id" newRender={ScreensCompetencyDetails} />

		<ProtectedRoute exact path="/entity/:id" newRender={ScreensEntityDetails} />

		<ProtectedRoute exact path="/instrument" newRender={ScreensInstrument} />
		<ProtectedRoute exact path="/instrument/:id" newRender={ScreensInstrumentDetails} />
		<ProtectedRoute exact path="/instrumentset/:id" newRender={ScreensInstrumentSetDetails} />

		<ProtectedRoute exact path="/institution" newRender={ScreensAccount} />
		<ProtectedRoute exact path="/institution/:id" newRender={ScreensInstitution} />

		<ProtectedRoute exact path="/location" newRender={ScreensLocation} />
		<ProtectedRoute exact path="/location/:id" newRender={ScreensLocationDetails} />

		<ProtectedRoute exact path="/myprofile" newRender={MyProfile} />
		<ProtectedRoute exact path="/settings" newRender={ScreensSettings} />

		<ProtectedRoute exact path="/test" newRender={ScreensTest} />
		<ProtectedRoute path="/test/:id" newRender={ScreensTestDetails} />

		<ProtectedRoute exact path="/user" newRender={ScreensUser} />
		<ProtectedRoute exact path="/user/:id" newRender={ScreensUserDetails} />

		<ProtectedRoute exact path="/history/:id" newRender={ScreensHistory} />

		<ProppedRoute exact path="/login" newRender={ScreensLogin} />
		<ProppedRoute path="*" newRender={ErrorBoundaryWrapper} />
	</Switch>
)

const initialState: AppState = {
	authState: {
		isLoggedIn: false,
		apiToken: null,
	},
	userEntities: [],
	userAttributes: { email: '', email_verified: false, entityID: '', family_name: '', given_name: '', roleID: '', sub: '', userID: '' },
	userPermissions: {
		userID: '',
		entityID: '',
		entityTypeID: '',
		entityTypeLevel: 0,
		entityTypeName: '',
		entityUserID: '',
		roleID: '',
		roleLevel: 0,
		roleName: '',
		subscriberEntityID: '',
		subscriberEntityName: '',
		accounts: [],
	},
	currentPageTitle: 'home',
}

const appReducer: React.Reducer<AppState, AppStateAction> = (state, action) => {
	switch (action.state) {
		case 'signedIn':
			return {
				...state,
				authState: {
					isLoggedIn: true,
					apiToken: action.data.token,
				},
			}
		case 'signIn':
			return {
				...state,
				authState: {
					isLoggedIn: false,
					apiToken: null,
				},
			}
		case 'signOut':
			return {
				...initialState,
			}
		case 'addAppState':
			return {
				...state,
				userEntities: action.data.userEntities,
				userAttributes: action.data.userAttributes,
				userPermissions: action.data.userPermissions,
			}
		case 'updateUserEntity': {
			return {
				...state,
				userAttributes: {
					...state.userAttributes!,
					entityID: action.data.entityID,
				},
			}
		}
		case 'updatePageTitle':
			return {
				...state,
				currentPageTitle: action.data,
			}
		default:
			return state
	}
}

interface AppProps {
	auth: {
		signOut?: UseAuthenticator['signOut']
		user?: CognitoUserAmplify
	}
}

const AppContext = React.createContext<{ appState: AppState; setAppState: React.Dispatch<AppStateAction> }>({ appState: initialState, setAppState: () => {} })

// anything that exists over each page goes here
const App = (props: AppProps) => {
	const [appState, setAppState] = useReducer<Reducer<AppState, AppStateAction>>(appReducer, initialState)
	const [accountStatus, updateAccountStatus] = useState('Loading')
	const history = useHistory()

	const authState = appState.authState

	useEffect(() => {
		const userSession = props.auth.user?.getSignInUserSession()
		if (userSession && userSession.getIdToken().getJwtToken()) {
			setAppState({ state: 'signedIn', data: { token: userSession.getIdToken().getJwtToken() } })
		}
	}, [props.auth.user])

	useEffect(() => {
		const getAppState = async () => {
			if (authState.apiToken !== null && props.auth.user && props.auth.user.attributes) {
				const userAttributes = props.auth.user.attributes
				const Attributes: Attributes = Object.keys(userAttributes).reduce(
					(object, attribute) => ({
						...object,
						[attribute.replace('custom:', '')]: userAttributes[attribute],
					}),
					{} as Attributes
				)

				const [userResult, permissionResult] = await Promise.all([
					Request.get<UserResult>(`user?where=userID==${Attributes.userID}`, authState),
					Request.get<{ permission: Authorisation }>(`permission`, authState),
				])
				const user = userResult.data.users[0]
				const permission = permissionResult.data.permission

				setAppState({
					state: 'addAppState',
					data: {
						userEntities: user.entities! as Entity[],
						userAttributes: Attributes,
						userPermissions: permission,
					},
				})

				if (Attributes.entityID) {
					const entityResponse = await Request.get<EntityResult>(`entity?where=entityID==${Attributes.entityID}`, authState)
					if (entityResponse.data.entities.length && entityResponse.data.entities[0].entityName.endsWith('(needs setup)')) {
						history.push(`/entity/${Attributes.entityID}`)
					}
				}

				updateAccountStatus('Active')
			}
		}
		getAppState()
	}, [authState, history])

	const isLoggedIn = authState.apiToken !== null
	const isFirstLogin = window.localStorage.getItem(`isFirstLogin`) === 'true'
	const userEntitiesCount = appState.userEntities.length

	const updateLoggedInStatus = isLoggedIn && isFirstLogin && userEntitiesCount > 0
	if (updateLoggedInStatus) {
		if (userEntitiesCount > 1) {
			return (
				<AppContext.Provider value={{ appState, setAppState }}>
					<ProppedRoute path="*" newRender={ScreensHomeEntityPicker} />
				</AppContext.Provider>
			)
		}
	}

	if (accountStatus !== 'Disabled') {
		return (
			<AppContext.Provider value={{ appState, setAppState }}>
				{accountStatus === 'Active' ? (
					<ErrorBoundary>
						<Routes />
					</ErrorBoundary>
				) : null}
			</AppContext.Provider>
		)
	} else if (accountStatus === 'Disabled') {
		return (
			<AppContext.Provider value={{ appState, setAppState }}>
				<ProppedRoute path="*" newRender={ScreensHomeAccountDisabled} />
			</AppContext.Provider>
		)
	} else {
		return <div>Here</div>
	}
}

const AppWithRouter: React.FC = () => {
	return (
		<ThemeProvider theme={AmplifyTheme}>
			<Router>
				<ErrorBoundary>
					<Authenticator components={Components} services={Services} hideSignUp={true}>
						{(auth) => <App auth={auth} />}
					</Authenticator>
				</ErrorBoundary>
			</Router>
		</ThemeProvider>
	)
}

export { AppContext }
export default AppWithRouter
