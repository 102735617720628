import React from 'react'
import { Badge, Button, Col, Form, Row } from 'react-bootstrap'
import { History } from 'history'

import { DropdownSelect } from '../Forms/Dropdown'
import { FormText } from '../UI/Form/Text'
import { AccountErrorMessages, Institute } from '../../screens/Account/Institution'
import * as Request from '../../utilities/request'

import { AppContext } from '../../App'
import { PageStatus } from '../../types/PageStatus'
import * as Role from '../../constants/role'
import { User, UserResult } from '../../../../back-end/common/user'
import { X } from 'react-feather'
import { PageBackState } from '../../types/PageBackState'

interface ScreensLocationStateParams extends PageBackState {}

interface InstitutionDetailsProps {
	institute: Institute | null
	pageStatus: PageStatus
	showSeeMore: boolean
	history: History<ScreensLocationStateParams>
	errors?: AccountErrorMessages
	onChange: (newInstitute: Institute) => void
	backState?: PageBackState
}

const InstitutionDetails = (props: InstitutionDetailsProps): JSX.Element | null => {
	const context = React.useContext(AppContext)
	const readOnly = props.pageStatus !== 'Editing'

	const [users, setUsers] = React.useState<User[]>([])
	const [excludedUserID, setExcludedUserID] = React.useState<string>('')

	const onChange = (changes: Partial<Institute>) => {
		props.onChange({ ...props.institute, ...changes } as Institute)
	}

	React.useEffect(() => {
		const getData = async () => {
			const users = await Request.get<UserResult>(
				`user?where=entityID==${props.institute!.entityID}&where=roleID==${Role.InstitutionManager}`,
				context.appState.authState
			)
			setUsers(users.data.users)
		}

		if (props.institute && readOnly) {
			getData()
		}
	}, [props.institute, readOnly, context.appState.authState])

	React.useEffect(() => {
		if (excludedUserID) {
			onChange({ accountExcludedManagers: [...(props.institute?.accountExcludedManagers || []), excludedUserID] })
			setExcludedUserID('')
		}
	}, [excludedUserID]) //eslint-disable-line

	const removeExcludedUser = (userIDToRemove: string) => {
		onChange({ accountExcludedManagers: props.institute?.accountExcludedManagers.filter((userID) => userID !== userIDToRemove) || [] })
	}

	if (props.institute) {
		return (
			<div style={{ flexDirection: 'column' }}>
				<Row style={{ marginBottom: '20px' }}>
					<Col>
						<FormText
							name={'entityName'}
							value={props.institute.entityName}
							label={'Institute Name'}
							onChange={(e) => onChange({ [e.target.name]: e.target.value })}
							plaintext={readOnly}
							disabled={readOnly}
							required
							feedback={props.errors?.entityName}
							isInvalid={!!props.errors?.entityName}
						/>
					</Col>
					<Col>
						<FormText
							name={'entityABN'}
							value={props.institute.entityABN}
							label={'ABN'}
							onChange={(e) => onChange({ [e.target.name]: e.target.value })}
							plaintext={readOnly}
							disabled={readOnly}
						/>
					</Col>
					<Col>
						<FormText
							name={'entityWebsite'}
							value={props.institute.entityWebsite}
							label={'Website'}
							onChange={(e) => onChange({ [e.target.name]: e.target.value })}
							plaintext={readOnly}
							disabled={readOnly}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<FormText
							name={'accountReadableID'}
							value={props.institute.accountReadableID}
							label={'Shortcode'}
							onChange={(e) => onChange({ [e.target.name]: e.target.value })}
							plaintext={readOnly}
							disabled={readOnly}
							feedback={props.errors?.accountReadableID}
							isInvalid={!!props.errors?.accountReadableID}
						/>
					</Col>
					<Col md={8}>
						<FormText
							name={'accountNotes'}
							value={props.institute.accountNotes}
							label={'Notes'}
							onChange={(e) => onChange({ [e.target.name]: e.target.value })}
							plaintext={readOnly}
							disabled={readOnly}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>Monthly Manager Email</Form.Label>
						<p>These emails go out to the managers of the institution with a list of assets that have been tested in the last month</p>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={6} lg={2}>
						<Form.Group controlId="formBasicCheckbox">
							<Form.Check
								label="Send Manager Email?"
								type="checkbox"
								name="accountSendManagerEmail"
								disabled={readOnly}
								checked={props.institute.accountSendManagerEmail}
								onChange={(e) => {
									if (e.target.checked) {
										onChange({ accountSendManagerEmail: true })
									} else {
										onChange({ accountSendManagerEmail: false, accountHasPublicUrl: false, accountExcludedManagers: [] })
									}
								}}
							/>
						</Form.Group>
					</Col>
					<Col xs={12} md={6} lg={2}>
						<Form.Group controlId="formBasicCheckbox">
							<Form.Check
								label="Make URLs Public?"
								type="checkbox"
								name="accountHasPublicUrl"
								disabled={readOnly || props.institute.accountSendManagerEmail === false}
								checked={props.institute.accountHasPublicUrl}
								onChange={(e) => {
									if (e.target.checked) {
										onChange({ accountHasPublicUrl: true })
									} else {
										onChange({ accountHasPublicUrl: false })
									}
								}}
							/>
						</Form.Group>
					</Col>
					<Col xs={12} md={12} lg={8}>
						<Form.Label style={{ color: readOnly || props.institute.accountSendManagerEmail === false ? 'grey' : 'inherit' }}>
							Excluded managers from email
						</Form.Label>
						<span style={{ marginLeft: '10px' }}>
							{props.institute.accountExcludedManagers.length === 0 && 'None'}
							{props.institute.accountExcludedManagers.map((userID) => (
								<Badge pill key={userID} bg="light" style={{ marginRight: '5px' }}>
									{users.find((u) => u.userID === userID)?.fullName}
									{!readOnly && <X style={{ marginLeft: '3px' }} onClick={() => removeExcludedUser(userID)} />}
								</Badge>
							))}
						</span>
						{!readOnly && props.institute.accountSendManagerEmail && (
							<DropdownSelect
								name="excludedUserID"
								value={excludedUserID}
								onChange={(value) => setExcludedUserID(value)}
								disabled={false}
								id="excludedUserID"
								variant="secondary"
								showSearch={true}
								searchPlaceholder="Filter"
								options={users
									.filter((user) => !props.institute?.accountExcludedManagers.includes(user.userID))
									.map((user) => ({ ...user, value: user.userID, text: user.fullName }))}
							/>
						)}
					</Col>
				</Row>
				{props.showSeeMore && (
					<Row>
						<Col>
							<Button
								className="btn btn-outline-dark span-bold"
								onClick={() => props.history.push(`/entity/${props.institute!.entityID}`, props.backState)}
							>
								View full institution details
							</Button>
						</Col>
					</Row>
				)}
			</div>
		)
	}

	return null
}

export { InstitutionDetails }
export type { InstitutionDetailsProps }
