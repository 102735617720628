import React, { useContext } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { RouteComponentProps, StaticContext } from 'react-router'
import { Link } from 'react-router-dom'
import { Formik, FormikErrors } from 'formik'
import * as yup from 'yup'

import { DropdownSelect } from '../../components/Forms/Dropdown'
import { Card } from '../../components/UI/Card/Card'
import { DeleteButton } from '../../components/UI/Form/DeleteButton'
import { Header } from '../../components/UI/Header/Header'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { FormText } from '../../components/UI/Form/Text'
import { HistoryCardBottom } from '../../components/UI/HistoryCardBottom/HistoryCardBottom'
import { LocationAccessDetails } from '../../components/Account/LocationAccessDetails'

import { AppContext } from '../../App'
import { Account, AccountResult } from '../../../../back-end/common/account'
import { Asset, AssetResult } from '../../../../back-end/common/asset'
import { Entity, EntityResult } from '../../../../back-end/common/entity'
import { Location, LocationResult } from '../../../../back-end/common/location'
import { User, UserResult } from '../../../../back-end/common/user'
import * as EntityType from '../../constants/entityType'
import * as Role from '../../constants/role'
import { PageBackState } from '../../types/PageBackState'
import { PageStatus } from '../../types/PageStatus'
import * as Request from '../../utilities/request'
import { getUrlSearchParam } from '../../utilities/url'
import { CompetencyListing } from '../../components/Competency/Competency'
import { Competency, CompetencyResult } from '../../../../back-end/common/competency'
import { canWriteUser } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'
import { Level } from '../../constants/entityType'

type UserErrorMessages = FormikErrors<Partial<User>>

const userValidationSchema = yup.object().shape({
	email: yup.string().email().required('A valid email is required.'),
	roleID: yup.string().required('A role is required.'),
	firstName: yup.string().required('First Name is required.'),
	lastName: yup.string().required('Last Name is required.'),
})

interface ScreensUserMatchParams {
	id: string
}
interface ScreensUserStateParams extends PageBackState {}

const newUser = (user: Partial<User>): Partial<User> => ({
	firstName: user.firstName || '',
	lastName: user.lastName || '',
	email: user.email || '',
	roleID: user.roleID || '',
})

const ScreensUserDetails = (props: RouteComponentProps<ScreensUserMatchParams, StaticContext, ScreensUserStateParams>) => {
	const context = useContext(AppContext)
	const userID = props.match!.params.id
	const isNew = userID === 'new'
	const entityIDFromSearchParams = getUrlSearchParam(props.location.search, 'entity')

	const [messages, setMessages] = useMessageReducer([])

	const [user, setUser] = React.useState<User | null>(isNew ? (newUser({}) as User) : null)
	const [entity, setEntity] = React.useState<Entity | null>(null)
	const [account, setAccount] = React.useState<Account | null>(null)
	const [locations, setLocations] = React.useState<Location[] | null>(null)
	const [assets, setAssets] = React.useState<Asset[] | null>(null)
	const [competencies, setCompetencies] = React.useState<Competency[]>([])

	const [isInstitutionOnlyUser, setIsInstitutionOnlyUser] = React.useState<boolean>(false)

	const [createLogin, setCreateLogin] = React.useState<boolean>(false)
	const addLogin = React.useRef<boolean>(false)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>(isNew ? 'Editing' : 'Loading')

	React.useEffect(() => {
		const getLocationAndAssetData = async (accountID: string) => {
			const [location, asset] = await Promise.all([
				Request.get<LocationResult>(`location?where=accountID==${accountID}`, context.appState.authState),
				Request.get<AssetResult>(`asset?where=accountID==${accountID}`, context.appState.authState),
			])
			setLocations(location.data.locations)
			setAssets(asset.data.assets)
		}

		const getEntityData = async (entityID: string, numEntities?: number) => {
			const [entity, account] = await Promise.all([
				Request.get<EntityResult>(`entity?where=entityID==${entityID}`, context.appState.authState),
				numEntities && numEntities === 1
					? Request.get<AccountResult>(`account?where=e.entityID==${entityID}`, context.appState.authState)
					: Promise.resolve(),
			])
			const entities = entity.data.entities
			const accounts = account?.data?.accounts
			setEntity(entities[0])
			if (numEntities && numEntities === 1 && accounts) {
				setAccount(accounts[0])
				setIsInstitutionOnlyUser(accounts.length === 1 && entities[0].entityTypeID === EntityType.Institution)
				getLocationAndAssetData(accounts[0].accountID)
			}
		}

		const getData = async () => {
			if (!isNew) {
				const [userResponse, competencyResponse] = await Promise.all([
					Request.get<UserResult>(`user?where=userID==${userID}&confirmed`, context.appState.authState),
					Request.get<CompetencyResult>(`competency?where=userID==${userID}`, context.appState.authState),
				])
				const users = userResponse.data.users
				if (users.length > 0) {
					setUser(users[0])
					getEntityData(users[0].entityID!, users[0].entities?.length)
				}
				setCompetencies(competencyResponse.data.competencies)
				setPageStatus('Ready')
			} else {
				if (entityIDFromSearchParams) {
					getEntityData(entityIDFromSearchParams)
				}
			}
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, []) // eslint-disable-line

	//#region Update and Delete Functions
	const onUserEdit = async (user: User) => {
		setPageStatus('Submitting')
		const createCognitoLogin = createLogin || addLogin.current
		if (isNew) {
			await Request.handleRequest(
				() =>
					Request.post<UserResult>(
						`user?entity=${entityIDFromSearchParams}${createCognitoLogin ? '&cognito=1' : ''}`,
						user,
						context.appState.authState
					),
				{
					successFunction: (data) => {
						if (data.users.length > 0) {
							setUser(data.users[0])
							props.history.push(`/user/${data.users[0].userID}`, {
								backPath: props.location.state.backPath,
								backName: props.location.state.backName,
							})
							setPageStatus('Ready')
						}
					},
					passFailedErrorMessage: true,
					setMessageFunction: setMessages,
					messageAction: 'creating',
					messageObject: 'user',
				}
			)
		} else {
			await Request.handleRequest(
				() => Request.put<UserResult>(`user/${userID}${createCognitoLogin ? '?cognito=1' : ''}`, user, context.appState.authState),
				{
					successFunction: (data) => {
						if (data.users.length > 0) {
							setUser(data.users[0])
							setPageStatus('Ready')
						}
					},
					failedFunction: () => setPageStatus('Editing'),
					errorFunction: () => setPageStatus('Editing'),
					passFailedErrorMessage: true,
					setMessageFunction: setMessages,
					messageAction: 'editing',
					messageObject: 'user',
				}
			)
		}
	}

	// To make the onUserEdit compatible with both the formik submit and 'add login' button
	const onAddUserLogin = async () => {
		if (!user || user.cognitoID) {
			return
		}
		addLogin.current = true
		await onUserEdit(user)
	}

	const onUserDelete = async (userID: string) => {
		Request.handleRequest(() => Request.del<UserResult>(`user?where=userID==${userID}`, context.appState.authState), {
			successFunction: () => {
				if (props.location.state && props.location.state.backPath) {
					props.history.push(props.location.state.backPath)
				}
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'user',
			passFailedErrorMessage: true,
		})
	}

	const onResendInvite = async () => {
		if (!user || !user.cognitoID) {
			return
		}
		Request.handleRequest(() => Request.post<UserResult>('user/reinvite', { ...user }, context.appState.authState), {
			setMessageFunction: setMessages,
			messageAction: 'inviting',
			messageObject: 'user',
			passFailedErrorMessage: true,
		})
	}
	//#endregion

	const roleOptions = getRoleOptionsFromEntityType(entity?.entityTypeID)

	return (
		<div className="page">
			<Messages messages={messages} updateMessage={setMessages} />

			{props.location.state && props.location.state.backPath && (
				<Row>
					<Col>
						<Link className="breadcrumb-back" to={props.location.state.backPath}>{`< Back to ${props.location.state.backName}`}</Link>
					</Col>
				</Row>
			)}

			<Header title={'User Details'} subtitle={''} />

			{user ? (
				<Formik initialValues={user} validationSchema={userValidationSchema} onSubmit={onUserEdit} enableReinitialize>
					{({ handleSubmit, errors, values, setFieldValue, isSubmitting }) => (
						<Card
							title={isNew ? 'Create a user' : user ? `${user.firstName} ${user.lastName}` : ''}
							collapsible={true}
							headerComponent={() => {
								if (pageStatus === 'Loading' || !user || !entity) {
									return <></>
								}
								if (
									!canWriteUser(appStateToPermissionObject(context.appState), {
										entityID: entity.entityID,
										entityLevel: Level[entity.entityTypeID],
									})
								) {
									return <></>
								}

								return pageStatus === 'Ready' ? (
									<Row className="justify-content-end">
										<Col sm={'auto'}>
											<Button className="btn btn-outline-dark span-bold" onClick={() => setPageStatus('Editing')}>
												Edit
											</Button>
										</Col>
									</Row>
								) : (
									<Row className="justify-content-end">
										<Col sm={'auto'}>
											<Button className="btn btn-secondary" onClick={() => handleSubmit()} disabled={isSubmitting}>
												Save
											</Button>
										</Col>
										{!isNew && (
											<>
												<Col sm={'auto'}>
													<Button className="btn btn-secondary" onClick={() => setPageStatus('Ready')} disabled={isSubmitting}>
														Cancel
													</Button>
												</Col>

												<Col sm={'auto'}>
													<DeleteButton id={user.userID ? user.userID : 'userID'} onClick={onUserDelete} disabled={isSubmitting} />
												</Col>
											</>
										)}
									</Row>
								)
							}}
						>
							<UserDetails
								user={values}
								createLogin={createLogin}
								setCreateLogin={setCreateLogin}
								roleOptions={roleOptions}
								pageStatus={pageStatus}
								errors={errors}
								onChange={setFieldValue}
								isNew={isNew}
								onAddUserLogin={onAddUserLogin}
								onResendInvite={onResendInvite}
							/>
						</Card>
					)}
				</Formik>
			) : null}

			{isInstitutionOnlyUser && account && locations && assets && user ? (
				<Card title="User Location Access" collapsible={true}>
					<LocationAccessDetails
						user={user}
						account={account}
						locations={locations}
						assets={assets}
						updateAccess={(access) => setUser({ ...user, access })}
						setMessages={setMessages}
						disabled={false}
					/>
				</Card>
			) : null}

			{!isNew && (
				<Card title="Competencies" collapsible={true}>
					<CompetencyListing
						competencies={competencies}
						isLoading={pageStatus === 'Loading'}
						perspective="user"
						perspectiveID={user?.userID || ''}
						actions={[]}
						backState={{ backPath: props.location.pathname, backName: user?.fullName || 'User' }}
						goTo={props.history.push}
					/>
				</Card>
			)}
		</div>
	)
}

interface UserDetailsProps {
	user: User | null
	createLogin: boolean
	setCreateLogin: React.Dispatch<React.SetStateAction<boolean>>
	roleOptions: {
		value: string
		text: string
	}[]
	pageStatus: PageStatus
	errors?: UserErrorMessages
	onChange: (field: string, value: string | number | boolean | null) => void
	isNew: boolean
	onAddUserLogin: () => void
	onResendInvite: () => void
}

const UserDetails = (props: UserDetailsProps) => {
	const readOnly = props.pageStatus !== 'Editing'

	if (props.user) {
		return (
			<div style={{ flexDirection: 'column' }}>
				<div>
					<Row>
						<Col>
							<FormText
								name={'email'}
								value={props.user.email}
								label={'Email'}
								onChange={(e) => props.onChange(e.target.name, e.target.value)}
								plaintext={readOnly}
								disabled={readOnly || !props.isNew}
								required
								feedback={props.errors?.email}
								isInvalid={!!props.errors?.email}
							/>
						</Col>
						<Col>
							<DropdownSelect
								id={'Role'}
								variant={'secondary'}
								name={'Role'}
								value={props.roleOptions.find((option) => option.value === props.user!.roleID)?.text || ''}
								label={'Role'}
								onChange={(value) => props.onChange('roleID', value)}
								disabled={readOnly}
								options={props.roleOptions}
								showSearch={false}
								searchPlaceholder="Filter"
								feedback={props.errors?.roleID}
								isInvalid={!!props.errors?.roleID}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormText
								name={'firstName'}
								value={props.user.firstName}
								label={'First Name'}
								onChange={(e) => props.onChange(e.target.name, e.target.value)}
								plaintext={readOnly}
								disabled={readOnly}
								feedback={props.errors?.firstName}
								isInvalid={!!props.errors?.firstName}
							/>
						</Col>
						<Col>
							<FormText
								name={'lastName'}
								value={props.user.lastName}
								label={'Last Name'}
								onChange={(e) => props.onChange(e.target.name, e.target.value)}
								plaintext={readOnly}
								disabled={readOnly}
								feedback={props.errors?.lastName}
								isInvalid={!!props.errors?.lastName}
							/>
						</Col>
					</Row>
					<Row style={{ marginTop: '10px' }}>
						<Col xs="auto">
							{props.isNew ? (
								<Form.Group controlId="formBasicCheckbox">
									<Form.Check
										label="Set up with login?"
										type="checkbox"
										name="createCognito"
										checked={props.createLogin}
										onChange={(e) => props.setCreateLogin(e.target.checked)}
									/>
								</Form.Group>
							) : props.user.cognitoID ? (
								<span className="span-grey">{props.user.emailConfirmed === true ? 'User can login' : 'User has not accepted invitation'}</span>
							) : (
								<Button className="btn btn-secondary" onClick={props.onAddUserLogin}>
									Set up with login
								</Button>
							)}
						</Col>
						<Col xs="auto">
							{!props.isNew && props.user.cognitoID && props.user.emailConfirmed === false && (
								<Button className="btn btn-secondary" onClick={props.onResendInvite}>
									Resend Invitation
								</Button>
							)}
						</Col>
					</Row>
					<>
						{props.user.userID && props.user.create && props.user.modified && (
							<HistoryCardBottom
								historyReferenceID={props.user.userID!}
								created={props.user.create}
								modified={props.user.modified}
								backState={{
									backPath: `/user/${props.user.userID!}`,
									backName: props.user.fullName,
								}}
							/>
						)}
					</>
				</div>
			</div>
		)
	}
	return null
}

const getRoleOptionsFromEntityType = (entityTypeID?: string) => {
	switch (entityTypeID) {
		case EntityType.Admin:
			return [
				{ value: Role.AdminOwner, text: 'Owner' },
				{ value: Role.AdminManager, text: 'Manager' },
			]
		case EntityType.Institution:
			return [
				{ value: Role.InstitutionOwner, text: 'Owner' },
				{ value: Role.InstitutionManager, text: 'Manager' },
				{ value: Role.InstitutionSupervisor, text: 'Supervisor' },
				{ value: Role.InstitutionLabUser, text: 'Lab User' },
			]
		case EntityType.FacilitiesCompany:
			return [
				{ value: Role.FacilitiesOwner, text: 'Owner' },
				{ value: Role.FacilitiesManager, text: 'Manager' },
			]
		case EntityType.TestingContractor:
			return [
				{ value: Role.TestingOwner, text: 'Owner' },
				{ value: Role.TestingManager, text: 'Manager' },
				{ value: Role.TestingSupervisor, text: 'Supervisor' },
				{ value: Role.TestingTechnician, text: 'Technician' },
			]
	}

	return []
}

export { ScreensUserDetails, UserDetails }
