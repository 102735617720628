import { PermissionObject } from 'dynaflow/definitions/access'
import { AppState } from '../App.d'
import { Admin } from '../constants/entityType'

const appStateToPermissionObject = (appState: AppState): PermissionObject => {
	const permission: PermissionObject = {
		isAdmin: appState.userPermissions.entityTypeID === Admin,
		cognitoID: appState.userAttributes.sub,
		entityID: appState.userPermissions.entityID,
		roleID: appState.userPermissions.roleID,
		userID: appState.userPermissions.userID,
		scope: appState.userPermissions,
	}
	return permission
}

export { appStateToPermissionObject }
