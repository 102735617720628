import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Formik, FormikErrors } from 'formik'
import * as yup from 'yup'

import { ComplianceQuestionForm } from './Question'
import { Card } from '../UI/Card/Card'
import { Loading } from '../UI/Loading/Loading'
import { MessageAction } from '../UI/Messages/Message'
import * as Request from '../../utilities/request'
import generateUuid from '../../utilities/uuid'

import { AppContext } from '../../App'
import { PageStatus } from '../../types/PageStatus'
import { ComplianceQuestion, ComplianceQuestionResult } from '../../../../back-end/common/complianceQuestion'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { canWriteAsset } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'
import { AssetType, AssetTypeResult } from 'dynaflow/common/assetType'

type PageComplianceQuestion = Pick<
	ComplianceQuestion,
	'complianceQuestionID' | 'clause' | 'text' | 'order' | 'isHeading' | 'parentHeadingID' | 'isFreeResponse'
> & {
	isNew: boolean
	isUpdated: boolean
	isDeleted: boolean
	assetTypeID: string
	response?: 'yes' | 'no' | 'n/a'
}

type SetValuesType = (values: React.SetStateAction<PageComplianceQuestion[]>, shouldValidate?: boolean | undefined) => void

interface ComplianceQuestionProps {
	assetTypeID: string
	setMessages?: (action: MessageAction) => void
}

const complianceValidationSchema = yup.array().of(
	yup.object().shape({
		complianceQuestionID: yup.string().optional(),
		clause: yup
			.string()
			.min(1)
			.max(45, 'Clause cannot be more than 45 character long')
			.when(['isHeading', 'isDeleted'], {
				is: (isHeading: boolean, isDeleted: boolean) => !isHeading && !isDeleted,
				then: yup.string().required('Clause is required'),
			}),
		text: yup
			.string()
			.min(1)
			.max(200)
			.when('isDeleted', {
				is: false,
				then: yup.string().required('Text is required'),
			}),
		order: yup.number().required().min(0),
		isHeading: yup.boolean().required(),
		isDeleted: yup.boolean(),
		parentHeadingID: yup.string().optional().nullable(true),
	})
)

const complianceQuestionOrder = (a: PageComplianceQuestion, b: PageComplianceQuestion) => a.order - b.order

const ComplianceQuestionDetails = (props: ComplianceQuestionProps) => {
	const context = React.useContext(AppContext)

	const [assetType, setAssetType] = React.useState<AssetType | null>(null)
	const [parentQuestions, setParentQuestions] = React.useState<ComplianceQuestion[] | null>(null)
	const [complianceQuestions, setComplianceQuestions] = React.useState<PageComplianceQuestion[] | null>(null)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [messages, setMessages] = useMessageReducer([])

	React.useEffect(() => {
		const getData = async () => {
			const [complianceReq, assetReq] = await Promise.all([
				Request.get<ComplianceQuestionResult>(`compliancequestion?where=assetTypeID==${props.assetTypeID}`, context.appState.authState),
				Request.get<AssetTypeResult>(`assettype?where=assetTypeID==${props.assetTypeID}`, context.appState.authState),
			])
			const parentQuestions = complianceReq.data.complianceQuestion.filter((cq) => cq.isFromParentAssetType)
			const myQuestions = complianceReq.data.complianceQuestion.filter((cq) => !cq.isFromParentAssetType)
			setParentQuestions(parentQuestions)
			setComplianceQuestions(myQuestions.map((cq) => ({ ...cq, isNew: false, isUpdated: false, isDeleted: false })))
			setAssetType(assetReq.data.assetTypes[0] || null)
			setPageStatus('Ready')
		}

		getData()
	}, [context.appState.authState, props.assetTypeID])

	const onComplianceQuestionSubmit = async (values: PageComplianceQuestion[], resetForm: () => void) => {
		setPageStatus('Submitting')

		const cqSuccess: Array<PageComplianceQuestion> = []

		//wait for creation/update of heading to be complete
		await Promise.all(
			values
				.filter((cq) => cq.isHeading)
				.map((cq) => {
					if (cq.isNew) {
						return Request.handleRequest(() => Request.post<ComplianceQuestionResult>(`compliancequestion`, cq, context.appState.authState), {
							setMessageFunction: setMessages,
							messageAction: 'creating',
							messageObject: 'heading',
							successFunction: (value) => {
								if (value.success) {
									cqSuccess.push(cq)
								}
							},
						})
					} else if (cq.isDeleted) {
						const abortDelete = () => {
							cq.isDeleted = false
							cqSuccess.push(cq)
						}
						const children = values.filter((quesiton) => quesiton.parentHeadingID === cq.complianceQuestionID) || null
						return Request.handleRequest(
							() =>
								Request.del<ComplianceQuestionResult>(
									`compliancequestion?where=compliancequestionid=in(${
										cq.complianceQuestionID + ',' + children.map((c) => c.complianceQuestionID).join(',')
									})`,
									context.appState.authState
								),
							{
								setMessageFunction: setMessages,
								messageAction: 'deleting',
								messageObject: 'heading',
								errorFunction: abortDelete,
								failedFunction: abortDelete,
							}
						)
					} else if (cq.isUpdated) {
						const abortUpdate = () => {
							if (complianceQuestions) {
								const oldQuestion = complianceQuestions.find(
									(complianceQuesiton) => cq.complianceQuestionID === complianceQuesiton.complianceQuestionID
								)
								if (oldQuestion) {
									cqSuccess.push(oldQuestion)
								}
							}
						}
						return Request.handleRequest(
							() =>
								Request.put<ComplianceQuestionResult>(
									`compliancequestion?where=compliancequestionid==${cq.complianceQuestionID}`,
									cq,
									context.appState.authState
								),
							{
								setMessageFunction: setMessages,
								messageAction: 'editing',
								messageObject: 'heading',
								failedFunction: abortUpdate,
								errorFunction: abortUpdate,
								successFunction: (value) => {
									if (value.success) {
										cqSuccess.push(cq)
									}
								},
							}
						)
					} else {
						cqSuccess.push(cq)
						return Promise.resolve()
					}
				})
		)

		await Promise.all(
			values
				.filter((cq) => !cq.isHeading)
				.map((cq) => {
					if (cq.isNew) {
						return Request.handleRequest(() => Request.post<ComplianceQuestionResult>(`compliancequestion`, cq, context.appState.authState), {
							setMessageFunction: setMessages,
							messageAction: 'creating',
							messageObject: 'question',
							successFunction: (value) => {
								if (value.success) {
									cqSuccess.push(cq)
								}
							},
						})
					} else if (cq.isDeleted) {
						const abortDelete = () => {
							cq.isDeleted = false
							cqSuccess.push(cq)
						}
						return Request.handleRequest(
							() =>
								Request.del<ComplianceQuestionResult>(
									`compliancequestion?where=compliancequestionid==${cq.complianceQuestionID}`,
									context.appState.authState
								),
							{
								setMessageFunction: setMessages,
								messageAction: 'deleting',
								messageObject: 'question',
								errorFunction: abortDelete,
								failedFunction: abortDelete,
							}
						)
					} else if (cq.isUpdated) {
						const abortUpdate = () => {
							if (complianceQuestions) {
								const oldQuestion = complianceQuestions.find(
									(complianceQuesiton) => cq.complianceQuestionID === complianceQuesiton.complianceQuestionID
								)
								if (oldQuestion) {
									cqSuccess.push(oldQuestion)
								}
							}
						}
						return Request.handleRequest(
							() =>
								Request.put<ComplianceQuestionResult>(
									`compliancequestion?where=compliancequestionid==${cq.complianceQuestionID}`,
									cq,
									context.appState.authState
								),
							{
								setMessageFunction: setMessages,
								messageAction: 'editing',
								messageObject: 'question',
								successFunction: (value) => {
									if (value.success) {
										cqSuccess.push(cq)
									}
								},
								failedFunction: abortUpdate,
								errorFunction: abortUpdate,
							}
						)
					} else {
						cqSuccess.push(cq)
						return Promise.resolve()
					}
				})
		)

		setComplianceQuestions(cqSuccess.filter((cq) => !cq.isDeleted).map((cq) => ({ ...cq, isNew: false, isDeleted: false, isUpdated: false })))
		resetForm()
		setPageStatus('Ready')
	}

	const onComplianceQuestionEdit = async (complianceQuestion: PageComplianceQuestion, values: PageComplianceQuestion[], setValues: SetValuesType) => {
		const updatedComplianceQuestions = values.map((cq) => (cq.complianceQuestionID === complianceQuestion.complianceQuestionID ? complianceQuestion : cq))
		setValues(updatedComplianceQuestions)
	}

	const onComplianceQuestionDelete = async (id: string, values: PageComplianceQuestion[], setValues: SetValuesType) => {
		const updatedComplianceQuestions = values
			.map((cq) => (cq.complianceQuestionID === id ? { ...cq, isDeleted: true } : cq))
			.filter((cq) => (cq.text !== '' && cq.clause !== '') || cq.parentHeadingID !== id)
		setValues(updatedComplianceQuestions)
	}

	const onComplianceQuestionReorder = (
		complianceQuestion: PageComplianceQuestion,
		newOrder: number,
		values: PageComplianceQuestion[],
		setValues: SetValuesType
	) => {
		if (complianceQuestion.isHeading) {
			// Order the headings (ignoring all the child questions)
			const orderDifference = newOrder - complianceQuestion.order
			const headings = values
				.filter((cq) => cq.isHeading)
				.sort(complianceQuestionOrder)
				.map((cq, index) => ({ ...cq, order: index }))
			const filteredNewOrder = (headings.find((cq) => cq.complianceQuestionID === complianceQuestion.complianceQuestionID)?.order || 0) + orderDifference
			const headingRemovedValues = headings
				.filter((cq) => cq.complianceQuestionID !== complianceQuestion.complianceQuestionID)
				.map((cq) => (cq.order >= complianceQuestion.order ? { ...cq, order: cq.order - 1, isUpdated: true } : { ...cq }))
			const headingAddedValues = [
				...headingRemovedValues.map((cq) => (cq.order >= filteredNewOrder ? { ...cq, order: cq.order + 1, isUpdated: true } : { ...cq })),
				{ ...complianceQuestion, order: filteredNewOrder, isUpdated: true },
			].sort(complianceQuestionOrder)
			const returnValues = [] as PageComplianceQuestion[]
			// Go through and re-add the children in between, re-indexing as we go
			for (let index = 0; index < headingAddedValues.length; index++) {
				const currentHeader = { ...headingAddedValues[index] }
				const previousValue = returnValues.length > 0 ? returnValues[returnValues.length - 1] : null
				currentHeader.order = previousValue ? previousValue.order + 1 : 0
				returnValues.push(currentHeader)
				returnValues.push(
					...values
						.filter((cq) => cq.parentHeadingID === currentHeader.complianceQuestionID)
						.sort(complianceQuestionOrder)
						.map((cq, childIndex) => ({
							...cq,
							order: currentHeader.order + childIndex + 1,
							isUpdated: true,
						}))
				)
			}
			setValues(returnValues)
		} else {
			// Remove the question from the array and fix the order
			const questionRemovedValues = values
				.filter((cq) => cq.complianceQuestionID !== complianceQuestion.complianceQuestionID)
				.map((cq) => (cq.order >= complianceQuestion.order ? { ...cq, order: cq.order - 1, isUpdated: true } : { ...cq }))
			// Re-add the question in the correct order, and fix the order
			const questionAddedValues = [
				...questionRemovedValues.map((cq) => (cq.order >= newOrder ? { ...cq, order: cq.order + 1, isUpdated: true } : { ...cq })),
				{ ...complianceQuestion, order: newOrder, isUpdated: true },
			]
			setValues(questionAddedValues)
		}
	}

	const onNewComplianceQuestion = (isHeading: boolean, parentHeadingID: string | undefined, values: PageComplianceQuestion[], setValues: SetValuesType) => {
		setValues([...values, newComplianceQuestion({ isHeading, parentHeadingID, order: values.filter((cq) => !cq.isDeleted)?.length || 0 })])
	}

	const newComplianceQuestion = React.useCallback(
		(complianceQuestion: Partial<ComplianceQuestion>): PageComplianceQuestion => ({
			complianceQuestionID: generateUuid(),
			clause: complianceQuestion.clause || '',
			text: complianceQuestion.text || '',
			order: complianceQuestion.order || 0,
			isHeading: complianceQuestion.isHeading || false,
			isFreeResponse: complianceQuestion.isFreeResponse || false,
			parentHeadingID: complianceQuestion.parentHeadingID || null,
			isNew: true,
			isUpdated: false,
			isDeleted: false,
			assetTypeID: props.assetTypeID,
		}),
		[props.assetTypeID]
	)

	if (assetType && complianceQuestions) {
		return (
			<>
				<Card title={'Compliance Questions'} collapsible={true}>
					<RenderComplianceQuestions
						values={parentQuestions as unknown as PageComplianceQuestion[]}
						setValues={(_id) => {
							console.log(_id)
						}}
						errors={[]}
						pageStatus={'Ready'}
					/>
				</Card>
				<Messages messages={messages} updateMessage={setMessages} />
				<Formik
					initialValues={complianceQuestions}
					validationSchema={complianceValidationSchema}
					onSubmit={(values, { resetForm }) => {
						onComplianceQuestionSubmit(values, resetForm)
					}}
					enableReinitialize={true}
				>
					{({ handleSubmit, errors, values, setValues, resetForm }) => (
						<Card
							title={`Institution Specific Questions`}
							collapsible={false}
							headerComponent={() => {
								if (pageStatus === 'Loading') {
									return <></>
								}
								if (!canWriteAsset(appStateToPermissionObject(context.appState), { accountID: assetType.accountID, cud: 'update' })) {
									return <></>
								}
								if (pageStatus === 'Ready') {
									return (
										<Row className="justify-content-end">
											<Col sm={'auto'}>
												<Button className="btn btn-outline-dark span-bold" onClick={() => setPageStatus('Editing')}>
													{`Edit Institution Specific Questions`}
												</Button>
											</Col>
										</Row>
									)
								} else {
									return (
										<Row className="justify-content-end">
											<Col sm={'auto'}>
												<Button
													className="btn btn-secondary"
													onClick={() => {
														handleSubmit()
													}}
												>
													Save
												</Button>
											</Col>
											<Col sm={'auto'}>
												<Button
													className="btn btn-secondary"
													onClick={() => {
														setPageStatus('Ready')
														resetForm()
													}}
												>
													Cancel
												</Button>
											</Col>
										</Row>
									)
								}
							}}
						>
							<>
								<Row>
									<Col>
										{values.length === 0 && pageStatus === 'Ready' ? (
											<p>{"You don't have any compliance questions, click 'Edit compliance question' to get started"}</p>
										) : (
											<p>Create additional questions for an asset type that will be asked with yes, no or n/a responses</p>
										)}
									</Col>
								</Row>
								<RenderComplianceQuestions
									values={values}
									setValues={setValues}
									errors={errors}
									pageStatus={pageStatus}
									onComplianceQuestionDelete={onComplianceQuestionDelete}
									onComplianceQuestionEdit={onComplianceQuestionEdit}
									onComplianceQuestionReorder={onComplianceQuestionReorder}
									onNewComplianceQuestion={onNewComplianceQuestion}
								/>
								{pageStatus === 'Editing' && (
									<Row>
										<Col></Col>
										<Col xs="auto">
											<Button onClick={() => onNewComplianceQuestion(true, undefined, values, setValues)}>Add Section</Button>
										</Col>
									</Row>
								)}
							</>
						</Card>
					)}
				</Formik>
			</>
		)
	} else {
		return (
			<Card title={'Asset Type Information'} collapsible={false}>
				<Loading />
			</Card>
		)
	}
}

interface RenderComplianceQuestionsProps {
	values: PageComplianceQuestion[]
	setValues: SetValuesType
	errors: (FormikErrors<PageComplianceQuestion> | undefined)[]
	pageStatus: PageStatus
	onComplianceQuestionDelete?: (id: string, values: PageComplianceQuestion[], setValues: SetValuesType) => void
	onComplianceQuestionEdit?: (complianceQuestion: PageComplianceQuestion, values: PageComplianceQuestion[], setValues: SetValuesType) => void
	onComplianceQuestionReorder?: (
		complianceQuestion: PageComplianceQuestion,
		newOrder: number,
		values: PageComplianceQuestion[],
		setValues: SetValuesType
	) => void
	onNewComplianceQuestion?: (isHeading: boolean, parentHeadingID: string | undefined, values: PageComplianceQuestion[], setValues: SetValuesType) => void
}

const RenderComplianceQuestions = (props: RenderComplianceQuestionsProps) => {
	return (
		<>
			{props.values
				.filter((cq) => cq.isHeading && !cq.isDeleted)
				.sort(complianceQuestionOrder)
				.map((ch, parentIndex) => (
					<ComplianceQuestionForm
						question={ch}
						errors={props.errors[props.values.findIndex((c) => c.complianceQuestionID === ch.complianceQuestionID)]}
						context={props.pageStatus === 'Ready' ? 'answering' : 'building'}
						onDelete={(id) => {
							if (props.onComplianceQuestionDelete) {
								props.onComplianceQuestionDelete(id, props.values, props.setValues)
							}
						}}
						onEditChange={(updatedComplianceQuestion) => {
							if (props.onComplianceQuestionEdit) {
								props.onComplianceQuestionEdit(updatedComplianceQuestion, props.values, props.setValues)
							}
						}}
						onReorder={(newOrder) => {
							if (props.onComplianceQuestionReorder) {
								props.onComplianceQuestionReorder(ch, newOrder, props.values, props.setValues)
							}
						}}
						showUpReorder={parentIndex > 0}
						showDownReorder={parentIndex < props.values.filter((cq) => cq.isHeading && !cq.isDeleted).length - 1}
						pageStatus={props.pageStatus}
						key={ch.complianceQuestionID}
						disabled={true}
					>
						<>
							{props.values
								.filter((cq) => cq.parentHeadingID === ch.complianceQuestionID && !cq.isDeleted)
								.sort(complianceQuestionOrder)
								.map((cq, childIndex) => (
									<ComplianceQuestionForm
										question={cq}
										errors={props.errors[props.values.findIndex((c) => c.complianceQuestionID === cq.complianceQuestionID)]}
										context={props.pageStatus === 'Ready' ? 'answering' : 'building'}
										onDelete={(id) => {
											if (props.onComplianceQuestionDelete) {
												props.onComplianceQuestionDelete(id, props.values, props.setValues)
											}
										}}
										onEditChange={(updatedComplianceQuestion) => {
											if (props.onComplianceQuestionEdit) {
												props.onComplianceQuestionEdit(updatedComplianceQuestion, props.values, props.setValues)
											}
										}}
										onReorder={(newOrder) => {
											if (props.onComplianceQuestionReorder) {
												props.onComplianceQuestionReorder(cq, newOrder, props.values, props.setValues)
											}
										}}
										showUpReorder={childIndex > 0}
										showDownReorder={
											childIndex < props.values.filter((cq) => cq.parentHeadingID === ch.complianceQuestionID && !cq.isDeleted).length - 1
										}
										pageStatus={props.pageStatus}
										key={cq.complianceQuestionID}
										disabled={true}
										parentClause={ch.clause}
									/>
								))}
							{props.pageStatus === 'Editing' && (
								<Row>
									<Col></Col>
									<Col xs="auto">
										<Button
											className="mt-3"
											onClick={() => {
												if (props.onNewComplianceQuestion) {
													props.onNewComplianceQuestion(false, ch.complianceQuestionID, props.values, props.setValues)
												}
											}}
										>
											Add Question
										</Button>
									</Col>
								</Row>
							)}
						</>
					</ComplianceQuestionForm>
				))}
		</>
	)
}

export { ComplianceQuestionDetails }
export type { PageComplianceQuestion }
