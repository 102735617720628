import React from 'react'
import { Spinner, Table, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { Location } from '../../../../back-end/common/location'

// icons
import roomIcon from '../../images/icons/room.svg'
import buildingIcon from '../../images/icons/building.svg'
import locationIcon from '../../images/icons/location.svg'
import { PageBackState } from '../../types/PageBackState'

interface AccountLocationListProps {
	locations: Location[] | null
	backState?: PageBackState
}

const AccountLocationList = (props: AccountLocationListProps): JSX.Element => {
	if (props.locations) {
		return (
			<Table>
				<thead>
					<tr>
						<th>Location</th>
						<th>Building</th>
						<th>Rooms</th>
					</tr>
				</thead>
				<tbody>
					{props.locations.length === 0 && (
						<tr>
							<td colSpan={4}>No Locations</td>
						</tr>
					)}
					{props.locations.length !== 0 &&
						props.locations.map((location) => (
							<React.Fragment key={location.locationID}>
								{location.buildings.length > 0 ? (
									location.buildings.map((building, buildingIndex) => (
										<tr key={building.buildingID}>
											<td>
												{buildingIndex === 0 && (
													<Link to={{ pathname: `/location/${location.locationID}`, state: props.backState }}>
														<Row>
															<Col sm={'1'}>
																<img src={locationIcon} alt={`A Location Icon`}></img>
															</Col>
															<Col>{location.locationName}</Col>
														</Row>
													</Link>
												)}
											</td>
											<td>
												<Row>
													<Col sm={'1'}>
														<img src={buildingIcon} alt={`A Building Icon`}></img>
													</Col>
													<Col>{building.buildingName}</Col>
												</Row>
											</td>
											<td>
												{building.rooms.length > 0 ? (
													<Row>
														<Col sm={'auto'}>
															<img src={roomIcon} style={{ marginRight: '5px' }} alt={`A Room Icon`}></img>
														</Col>
														<Col>{building.rooms.map((room) => room.roomName).join(', ')}</Col>
													</Row>
												) : null}
											</td>
										</tr>
									))
								) : (
									<tr>
										<td>
											<Link to={{ pathname: `/location/${location.locationID}`, state: props.backState }}>
												<img src={locationIcon} style={{ height: '30px' }} alt={`A Location Icon`}></img>
												{location.locationName}
											</Link>
										</td>
										<td></td>
										<td></td>
									</tr>
								)}
							</React.Fragment>
						))}
				</tbody>
			</Table>
		)
	}
	return (
		<Spinner animation="border" role="status">
			<span className="visually-hidden">Loading...</span>
		</Spinner>
	)
}

export { AccountLocationList }
export type { AccountLocationListProps }
