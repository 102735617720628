import React from 'react'
import { StaticContext } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { PageBackState } from '../../types/PageBackState'

import { InstrumentList } from './Instrument'
import { InstrumentSetList } from './InstrumentSet'

interface ScreensInstrumentSetStateParams extends PageBackState {}
interface ScreensInstrumentSetMatchParams {}

const ScreensInstrument = (props: RouteComponentProps<ScreensInstrumentSetMatchParams, StaticContext, ScreensInstrumentSetStateParams>) => {
	return (
		<div className="page">
			<InstrumentSetList {...props} />

			<InstrumentList {...props} />
		</div>
	)
}

export { ScreensInstrument }
