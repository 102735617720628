import React from 'react'
import { Badge, Card, CardGroup, Col, Modal, Row, Form } from 'react-bootstrap'
import { DropdownSelectOption } from '../../../components/Forms/Dropdown'
import { Button } from '../../../components/UI/Button/Button'
import { FormText } from '../../../components/UI/Form/Text'
import { MutableRefObject } from 'react'
import * as Request from '../../../utilities/request'
import { TestQuestionResult } from '../../../../../back-end/common/testQuestion'
import { AuthState } from '../../../App.d'
import { Loading } from '../../../components/UI/Loading/Loading'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { TestProps } from './QuestionLookup.d'
import { FormikErrors } from 'formik'
import { Header } from '../../../components/UI/Header/Header'
import { DetailedQuestion } from '../TestWrapper'

interface QuestionSubmitProps<T> extends TestProps {
	values: T
	params?: object
	submitCallback: MutableRefObject<() => void>
	authState: AuthState
}

const onQuestionSubmit = <T,>(args: QuestionSubmitProps<T>) => {
	if (args.test?.isLocked) {
		args.submitCallback.current()
		return
	}

	// Send any extra values through for calculations
	const testQuestionResponse = { ...args.values, params: args.params }

	const currentQuestion = args.test?.testQuestions.find((question) => question.questionVersionID === args.question?.questionVersionID)
	if (currentQuestion) {
		Request.handleRequest(
			() =>
				Request.put<TestQuestionResult>(
					`testQuestion?where=testQuestionID==${currentQuestion.testQuestionID}`,
					{
						testID: args.test?.testID,
						questionVersionID: currentQuestion.questionVersionID,
						testQuestionResponse,
					},
					args.authState
				),
			{
				successFunction: () => {
					args.refetchTest()
					args.submitCallback.current()
				},
				setMessageFunction: args.setMessages,
				messageAction: 'editing',
				messageObject: 'question',
			}
		)
	} else {
		// create test question
		Request.handleRequest(
			() =>
				Request.post<TestQuestionResult>(
					'testQuestion',
					{
						testID: args.test?.testID,
						questionVersionID: args.question?.questionVersionID,
						testQuestionResponse,
					},
					args.authState
				),
			{
				successFunction: () => {
					args.refetchTest()
					args.submitCallback.current()
				},
				setMessageFunction: args.setMessages,
				messageAction: 'creating',
				messageObject: 'question',
			}
		)
	}
}

interface LoadExistingQuestionArgs<InputType, OutputType> extends TestProps {
	setQuestionValues: (values: InputType) => void
	formatQuestionValues?: (values: OutputType) => InputType
}

// Ensure that formatQuestionValues, if used, is memoized to avoid infinite re-renders
const useLoadExistingQuestionResponse = <InputType, OutputType>(args: LoadExistingQuestionArgs<InputType, OutputType>) => {
	const { test, setQuestionValues, formatQuestionValues } = args
	React.useEffect(() => {
		if (test) {
			try {
				const currentQuestion = test.testQuestions.find((question) => question.questionVersionID === args.question?.questionVersionID)
				if (currentQuestion && currentQuestion.testQuestionResponse) {
					const existingValues = currentQuestion.testQuestionResponse as unknown as OutputType
					// Explicitly set the form values
					let formattedValues = existingValues as unknown as InputType
					if (formatQuestionValues) {
						formattedValues = formatQuestionValues(existingValues)
					}
					if (currentQuestion.testQuestionComment) {
						formattedValues = { ...formattedValues, comment: currentQuestion.testQuestionComment || '' }
					}
					setQuestionValues(formattedValues)
				}
			} catch (e) {
				console.error('Error loading question values')
				console.error(e)
			}
		}
	}, [test, setQuestionValues, formatQuestionValues, args.question?.questionVersionID])
}

interface QuestionButtonsProps extends Omit<TestProps, 'asset'> {
	dirty: boolean
	handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
	handleReset: () => void
	submitCallback: MutableRefObject<() => void>
	setSubmitCallback: (callback: () => void) => void
	isSubmitting: boolean
}

const QuestionNavigationButtons = (props: QuestionButtonsProps) => {
	return (
		<Col>
			<Row className="mb-3 mt-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
				{props.isSubmitting ? (
					<Loading spinner />
				) : (
					<>
						<Row>
							<Col>
								<Button
									className="btn btn-primary round"
									onClick={() => {
										if (!props.dirty) {
											props.onClickBack()
											return
										}
										props.setSubmitCallback(props.onClickBack)
										props.handleSubmit()
									}}
									disabled={props.isSubmitting}
								>
									<ChevronLeft style={{ verticalAlign: 'text-bottom' }} size="16" />
									PREVIOUS
								</Button>
							</Col>
						</Row>
						{props.test?.testQuestions.find((question) => question.questionVersionID === props.question?.questionVersionID) && (
							<Button className="btn btn-secondary round justify-content-end" onClick={() => props.handleReset()} disabled={props.isSubmitting}>
								REVERT
							</Button>
						)}
						<Row>
							<Col>
								<Button
									className="btn btn-primary round"
									onClick={() => {
										if (!props.dirty) {
											props.onClickNext()
											return
										}
										props.setSubmitCallback(props.onClickNext)
										props.handleSubmit()
									}}
									disabled={props.isSubmitting}
								>
									NEXT
									<ChevronRight style={{ verticalAlign: 'text-bottom' }} size="16" />
								</Button>
							</Col>
						</Row>
					</>
				)}
			</Row>
		</Col>
	)
}

const ResultBadge = (props: { result: string; className?: string; style?: object }) => {
	let resultText = ''
	let badgeColor = ''
	let textColor = ''
	switch (props.result.toLowerCase()) {
		case 'pass':
		case 'passed':
			resultText = 'PASSED'
			badgeColor = 'success'
			textColor = 'text-white'
			break
		case 'fail':
		case 'failed':
			resultText = 'FAILED'
			badgeColor = 'danger'
			textColor = 'text-white'
			break
		case 'complete':
			resultText = 'COMPLETE'
			badgeColor = 'success'
			textColor = 'text-white'
			break
		case 'incomplete':
		case 'n/a':
		case null:
		default:
			resultText = props.result?.toUpperCase()
			badgeColor = 'light'
			break
	}
	return (
		<Badge className={`${textColor} ${props.className}`} style={{ width: '120px', ...props.style }} bg={badgeColor}>
			{resultText}
		</Badge>
	)
}

const ComplianceCheckBadge = (props: { result: 'incomplete' | 'complete'; className?: string; style?: object; complianceCheckHasFailResults?: boolean }) => {
	let badgeColor = ''
	let textColor = ''
	switch (props.result.toLowerCase()) {
		case 'complete':
		case 'passed':
			badgeColor = props.complianceCheckHasFailResults ? 'warning' : 'success'
			textColor = 'text-white'
			break
		case 'incomplete':
		default:
			badgeColor = 'light'
			break
	}
	return (
		<Badge className={`${textColor} ${props.className}`} style={{ width: '120px', ...props.style }} bg={badgeColor}>
			{props.result.toUpperCase()}
		</Badge>
	)
}

const QuestionHeader = (props: { question: DetailedQuestion | null }) => {
	if (!props.question) {
		return <Header title={''} subtitle={''} />
	}
	const title = props.question?.questionName || ''
	let subtitle = ''
	if (props.question.complianceClause && props.question.complianceClause !== '') {
		subtitle = props.question.complianceClause
	}
	return <Header title={title} subtitle={subtitle} />
}

interface QuestionResultProps {
	failed: boolean | null
	questionHasComment: boolean
	questionHasForcePass: boolean
	commentValue?: string
	forcePassValue?: boolean
	handleChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
	disabled?: boolean
	resultNullText?: string
	isStandardsBased?: boolean
	specialPassOverride?: string
}

const QuestionResult = (props: QuestionResultProps) => {
	return (
		<Card>
			<Card.Header style={!props.questionHasComment ? { borderBottom: 'none' } : {}}>
				<Card.Title className="mb-3">
					Result
					<ResultBadge
						result={
							props.failed === null
								? props.specialPassOverride || 'incomplete'
								: props.isStandardsBased
								? props.failed
									? 'fail'
									: 'pass'
								: 'complete'
						}
						className="ml-3"
					/>
					{props.questionHasForcePass && (
						<Form.Group controlId="forcePass">
							<Form.Check
								id="forcePass"
								label="Force Pass: Meets manufacturers airflow requirement"
								type="checkbox"
								name="forcePass"
								style={{ fontSize: '1rem', fontWeight: 'normal' }}
								checked={props.forcePassValue}
								onChange={props.handleChange}
								disabled={props.disabled}
							/>
						</Form.Group>
					)}
				</Card.Title>
			</Card.Header>
			{props.questionHasComment && (
				<Card.Body>
					<FormText
						as="textarea"
						rows={3}
						label="Comments"
						name={'comment'}
						value={props.commentValue}
						onChange={props.handleChange}
						disabled={props.disabled}
					/>
				</Card.Body>
			)}
		</Card>
	)
}

interface QuestionElementProps {
	children: React.ReactNode
	title?: string
}

const QuestionElement = (props: QuestionElementProps) => {
	return (
		<Card>
			{props.title && (
				<>
					<Row className="card-title">
						<Card.Title>{props.title}</Card.Title>
					</Row>
					<hr />
				</>
			)}
			<Card.Body>
				<Col>{props.children}</Col>
			</Card.Body>
		</Card>
	)
}

const QuestionResultElement = (props: { title: string; value: string }) => {
	return (
		<Card className="text-center">
			<Card.Header className="h-100">{props.title}</Card.Header>
			<Card.Body>{props.value}</Card.Body>
		</Card>
	)
}

const QuestionResultElementGroup = (props: { results: { title: string; value: string }[]; center?: boolean; className?: string }) => {
	return (
		<CardGroup className={(props.className || '') + (props.center ? ' justify-content-center' : '')}>
			{props.results.map((r, index) => (
				<Card key={index} className="text-center" style={{ minWidth: '140px', maxWidth: '200px' }}>
					<Card.Header className="h-100">{r.title}</Card.Header>
					<Card.Body>{r.value}</Card.Body>
				</Card>
			))}
		</CardGroup>
	)
}

const TabRow = (props: {
	tabSelectOptions: DropdownSelectOption<string, object>[]
	currentQuestion?: string
	onClick: (targetQuestion: string, save: boolean) => void
	dirty?: boolean
	isSubmitting?: boolean
	disabled?: boolean
	errors?: FormikErrors<object>
}) => {
	const targetQuestion = React.useRef<string | null>(null)
	const [show, setShow] = React.useState<boolean>(false)

	React.useLayoutEffect(() => {
		const element = document.getElementById(`tab-${props.currentQuestion}`)
		element?.scrollIntoView({ behavior: 'smooth', block: 'start' })
	}, [props.currentQuestion])

	const handleClose = () => {
		targetQuestion.current = null
		setShow(false)
	}
	const handleShow = (_targetQuestion: string) => {
		targetQuestion.current = _targetQuestion
		setShow(true)
	}

	if (!props.currentQuestion) {
		return null
	}

	const { tabSelectOptions, currentQuestion, onClick, dirty, isSubmitting } = props
	const currentQuestionIndex = tabSelectOptions.findIndex((opt) => opt.value === currentQuestion)
	const hasErrors = props.errors && Object.keys(props.errors).length > 0

	return (
		<>
			<Row className="bg-light no-scrollbar" style={{ overflowX: 'auto', flexWrap: 'nowrap', borderRadius: '24px' }}>
				<div className="bg-dark" style={{ borderRadius: '24px', minWidth: 'max-content' }}>
					{tabSelectOptions
						.filter((tab, index) => index <= currentQuestionIndex)
						.map(({ value, text }, index) => {
							const isCurrentQuestion = index === currentQuestionIndex
							return (
								<button
									style={{ minWidth: '200px', maxWidth: '200px', border: 'none' }}
									key={value}
									id={`tab-${value}`}
									className={`btn round pt-2 pb-2 h-100 ${isCurrentQuestion ? 'btn-primary' : 'btn-dark'}`}
									onClick={() => (dirty ? handleShow(value) : onClick(value, false))}
									disabled={props.disabled}
								>
									{text}
								</button>
							)
						})}
				</div>
				<div style={{ minWidth: 'max-content' }}>
					{tabSelectOptions
						.filter((tab, index) => index > currentQuestionIndex)
						.map(({ value, text }) => {
							return (
								<button
									style={{ minWidth: '200px', maxWidth: '200px', border: 'none' }}
									key={value}
									id={`tab-${value}`}
									className={`btn round pt-2 pb-2 btn-light h-100`}
									onClick={() => (dirty ? handleShow(value) : onClick(value, false))}
									disabled={props.disabled}
								>
									{text}
								</button>
							)
						})}
				</div>
			</Row>
			<Modal show={show} onHide={handleClose} centered>
				<Modal.Header closeButton>
					<Modal.Title>Unsaved changes</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{hasErrors ? (
						<span className="text-danger">
							There are incomplete or invalid entries in the form. Discard the changes to continue or cancel to fix them.
						</span>
					) : (
						'Do you want to save or discard changes before you leave this page?'
					)}
				</Modal.Body>
				<Modal.Footer>
					{isSubmitting ? (
						<Loading spinner />
					) : (
						<>
							<Button variant="secondary" style={{ marginRight: 'auto' }} onClick={handleClose}>
								Cancel
							</Button>
							<Button variant="secondary" onClick={() => onClick(targetQuestion.current!, false)}>
								Discard
							</Button>
							<Button disabled={hasErrors} variant="primary" onClick={() => onClick(targetQuestion.current!, true)}>
								Save changes
							</Button>
						</>
					)}
				</Modal.Footer>
			</Modal>
		</>
	)
}

export {
	QuestionResult,
	QuestionElement,
	QuestionResultElement,
	QuestionResultElementGroup,
	TabRow,
	onQuestionSubmit,
	useLoadExistingQuestionResponse,
	QuestionNavigationButtons,
	ResultBadge,
	ComplianceCheckBadge,
	QuestionHeader,
}
