import React, { useContext } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { Link, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router'

import { InstrumentSetInstrumentList } from './InstrumentSetInstrumentList'
import { Card } from '../../components/UI/Card/Card'
import { DeleteButton } from '../../components/UI/Form/DeleteButton'
import { DropdownSelect, DropdownSelectOption } from '../../components/Forms/Dropdown'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { Header } from '../../components/UI/Header/Header'
import { HistoryCardBottom } from '../../components/UI/HistoryCardBottom/HistoryCardBottom'

import { formatDateTime } from '../../utilities/formatDate'
import * as Request from '../../utilities/request'

import { AppContext } from '../../App'
import * as EntityType from '../../constants/entityType'
import { Entity, EntityResult } from '../../../../back-end/common/entity'
import { Instrument, InstrumentResult } from '../../../../back-end/common/instruments'
import { InstrumentSet, InstrumentSetResult } from '../../../../back-end/common/instrumentSet'
import { User, UserResult } from '../../../../back-end/common/user'
import { PageStatus } from '../../types/PageStatus'
import { PageBackState } from '../../types/PageBackState'
import { canWriteInstrument } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'

interface ScreensInstrumentSetMatchParams {
	id: string
}

interface ScreensInstrumentSetStateParams extends PageBackState {}

const newInstrumentSet = (instrumentSet: Partial<InstrumentSet>): Partial<InstrumentSet> => ({
	instrumentSetName: instrumentSet.instrumentSetName || '',
	instrumentSetOwnerEntityID: instrumentSet.instrumentSetOwnerEntityID || undefined,
	instrumentSetOwnerUserID: instrumentSet.instrumentSetOwnerUserID || undefined,
})

const ScreensInstrumentSetDetails = (props: RouteComponentProps<ScreensInstrumentSetMatchParams, StaticContext, ScreensInstrumentSetStateParams>) => {
	const context = useContext(AppContext)
	const instrumentSetID = props.match!.params.id
	const isNew = instrumentSetID === 'new'

	const [messages, setMessages] = useMessageReducer([])
	const [instrumentSet, setInstrumentSet] = React.useState<Partial<InstrumentSet> | null>(isNew ? newInstrumentSet({}) : null)
	const [entities, setEntities] = React.useState<Entity[] | null>(null)
	const [users, setUsers] = React.useState<User[] | null>(null)
	const [instruments, setInstruments] = React.useState<Instrument[] | null>(null)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>(isNew ? 'Editing' : 'Loading')

	React.useEffect(() => {
		const getInstrumentData = async () => {
			const [instrumentSetReq] = await Promise.all([
				Request.get<InstrumentSetResult>(`instrumentset?where=instrumentSetID==${instrumentSetID}`, context.appState.authState),
			])
			setInstrumentSet(instrumentSetReq.data.instrumentSets[0])
			setPageStatus('Ready')
		}

		const getData = async () => {
			const [entityReq, userReq, instrumentReq] = await Promise.all([
				Request.get<EntityResult>(
					`entity${
						context.appState.userPermissions.entityTypeID !== EntityType.Admin
							? `?where=entityID==${context.appState.userPermissions.entityID}`
							: `?where=entityTypeID==${EntityType.TestingContractor}`
					}`,
					context.appState.authState
				),
				Request.get<UserResult>(
					`user${
						context.appState.userPermissions.entityTypeID !== EntityType.Admin
							? `?where=entityID==${context.appState.userPermissions.entityID}`
							: `?where=entityTypeID==${EntityType.TestingContractor}`
					}`,
					context.appState.authState
				),
				Request.get<InstrumentResult>(`instrument?where=instrumentSetID==${instrumentSetID}`, context.appState.authState),
			])
			setEntities(entityReq.data.entities)
			setUsers(userReq.data.users)
			setInstruments(instrumentReq.data.instruments)
		}

		if (context.appState.authState.isLoggedIn && !isNew) {
			getInstrumentData()
		}
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, []) // eslint-disable-line

	const onInstrumentSetCreate = async (instrumentSet: Partial<InstrumentSet>) => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.post<InstrumentSetResult>(`instrumentset`, instrumentSet, context.appState.authState), {
			successFunction: (data) => {
				if (data.instrumentSets.length > 0) {
					setInstrumentSet(data.instrumentSets[0])
					props.history.push(
						`/instrumentset/${data.instrumentSets[0].instrumentSetID}`,
						props.location.state ? { backPath: props.location.state.backPath, backName: props.location.state.backName } : undefined
					)
				}
			},
			setMessageFunction: setMessages,
			messageAction: 'creating',
			messageObject: 'instrument set',
		})
		setPageStatus('Ready')
	}

	const onInstrumentSetEdit = async (instrumentSet: Partial<InstrumentSet>) => {
		setPageStatus('Submitting')
		await Request.handleRequest(
			() => Request.put<InstrumentSetResult>(`instrumentset?where=instrumentSetID==${instrumentSetID}`, instrumentSet, context.appState.authState),
			{
				setMessageFunction: setMessages,
				messageAction: 'editing',
				messageObject: 'instrument set',
			}
		)
		setPageStatus('Ready')
	}

	const onInstrumentSetDelete = async (instrumentSetID: string) => {
		if (instruments && instruments?.length > 0) {
			setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Cannot delete the instrument set while it contains instruments. Move them to another set or delete them first.`,
					dismissible: true,
					timeout: 8000,
				},
			})
			return
		}
		setPageStatus('Submitting')
		await Request.handleRequest(
			() => Request.del<InstrumentSetResult>(`instrumentset?where=instrumentSetID==${instrumentSetID}`, context.appState.authState),
			{
				successFunction: () => {
					if (props.location.state && props.location.state.backPath) {
						props.history.push(props.location.state.backPath)
					}
				},
				setMessageFunction: setMessages,
				messageAction: 'editing',
				messageObject: 'instrument set',
			}
		)
		setPageStatus('Ready')
	}

	const onChange = (e: React.ChangeEvent<HTMLInputElement> | { target: { value?: string; checked?: boolean; name: string } }) => {
		const property = e.target.name
		const value = e.target.value || e.target.checked
		if (instrumentSet) {
			const instrumentSetOwnerEntityID = property === 'instrumentSetOwnerUserID' ? undefined : instrumentSet.instrumentSetOwnerEntityID
			const instrumentSetOwnerUserID = property === 'instrumentSetOwnerEntityID' ? undefined : instrumentSet.instrumentSetOwnerUserID

			const findOwnerEntity = entities?.find((entity) =>
				property === 'instrumentSetOwnerEntityID' ? entity.entityID === value : entity.entityID === instrumentSetOwnerEntityID
			)
			const findOwnerUser = users?.find((user) =>
				property === 'instrumentSetOwnerUserID' ? user.userID === value : user.userID === instrumentSetOwnerUserID
			)
			const instrumentSetOwner = findOwnerEntity
				? findOwnerEntity.entityName
				: findOwnerUser
				? `${findOwnerUser.firstName} ${findOwnerUser.lastName}`
				: instrumentSet.instrumentSetOwner

			setInstrumentSet({ ...instrumentSet, instrumentSetOwnerUserID, instrumentSetOwnerEntityID, instrumentSetOwner, [property]: value })
		}
	}

	const entitiesAndUserOptions: DropdownSelectOption<string, Partial<Entity> & Partial<User>>[] = [
		...(entities || []).map((e) => ({ ...e, value: e.entityID!, text: `Entity: ${e.entityName}` })),
		...(users || []).map((u) => ({ ...u, value: u.userID!, text: `User: ${u.firstName} ${u.lastName}` })),
	]

	return (
		<Container>
			<Messages messages={messages} updateMessage={setMessages} />
			{props.location.state && props.location.state.backPath ? (
				<Row>
					<Col>
						<Link className="breadcrumb-back" to={props.location.state.backPath}>{`< Back to ${props.location.state.backName}`}</Link>
					</Col>
				</Row>
			) : (
				<Row>
					<Col>
						<Link className="breadcrumb-back" to={`/instrument`}>{`< Back to Instruments`}</Link>
					</Col>
				</Row>
			)}

			<Row>
				<Col>
					<Header title={instrumentSet ? instrumentSet.instrumentSetName || '' : ''} subtitle={''} />
				</Col>
				<Col sm={'auto'} style={{ paddingTop: '40px' }}>
					<span className="span-bold">Status:</span> {instrumentSet ? (instrumentSet.recordStatusID ? 'Active' : 'Inactive') : ''}{' '}
					<span className={`dot ${instrumentSet ? (instrumentSet.recordStatusID ? 'activeGreen' : 'activeRed') : 'grey'}`}></span>
					<span className="span-grey">
						(Last Modified:{' '}
						{instrumentSet && instrumentSet.modified ? formatDateTime({ date: instrumentSet.modified.ts, format: 'DateAndTimeAndTimezone' }) : ''})
					</span>
				</Col>
			</Row>

			<Card
				title={!isNew && instrumentSet ? 'Instrument Set Information' : 'Create an Instrument Set'}
				collapsible={true}
				headerComponent={() =>
					pageStatus !== 'Loading' && instrumentSet && canWriteInstrument(appStateToPermissionObject(context.appState)) ? (
						pageStatus === 'Ready' ? (
							context.appState.userPermissions.entityTypeID === EntityType.TestingContractor ||
							context.appState.userPermissions.entityTypeID === EntityType.Admin ? (
								<Row className="justify-content-end">
									<Col xs="auto">
										<Button className="btn btn-primary" onClick={() => setPageStatus('Editing')}>
											Edit
										</Button>
									</Col>
								</Row>
							) : (
								<></>
							)
						) : (
							<Row className="justify-content-end">
								<Col xs="auto" />
								<Col xs="auto">
									<Button
										className="btn btn-primary"
										onClick={() => (isNew ? onInstrumentSetCreate(instrumentSet) : onInstrumentSetEdit(instrumentSet))}
										disabled={pageStatus === 'Submitting'}
									>
										Save
									</Button>
								</Col>
								<Col xs="auto">
									<Button className="btn btn-secondary" onClick={() => setPageStatus('Ready')} disabled={pageStatus === 'Submitting'}>
										Cancel
									</Button>
								</Col>
								{instrumentSet.instrumentSetID && (
									<Col xs="auto">
										<DeleteButton id={instrumentSet.instrumentSetID} onClick={onInstrumentSetDelete} />
									</Col>
								)}
							</Row>
						)
					) : (
						<></>
					)
				}
			>
				<Row>
					<Col xs="2">
						<strong>Name</strong>
					</Col>
					<Col>
						{pageStatus === 'Editing' ? (
							<Form.Control
								onChange={onChange}
								type="text"
								id="instrumentSetName"
								name="instrumentSetName"
								disabled={pageStatus !== 'Editing'}
								value={instrumentSet?.instrumentSetName}
							/>
						) : (
							instrumentSet?.instrumentSetName
						)}
					</Col>
				</Row>
				<Row style={{ marginTop: '10px' }}>
					<Col xs="2">
						<strong>Owner/Set</strong>
					</Col>
					<Col>
						{pageStatus === 'Editing' ? (
							<DropdownSelect
								id="owner"
								name="owner"
								disabled={pageStatus !== 'Editing'}
								variant="secondary"
								showSearch={true}
								searchPlaceholder={'Filter'}
								options={entitiesAndUserOptions}
								value={instrumentSet?.instrumentSetOwner || ''}
								onChange={(value, data) => {
									console.log(data)
									if (data.userID) {
										onChange({ target: { value, name: 'instrumentSetOwnerUserID' } })
									} else {
										onChange({ target: { value, name: 'instrumentSetOwnerEntityID' } })
									}
								}}
							/>
						) : (
							instrumentSet?.instrumentSetOwner
						)}
					</Col>
				</Row>

				<>
					{!isNew && instrumentSet && instrumentSet.create && instrumentSet.modified && (
						<HistoryCardBottom
							historyReferenceID={instrumentSet.instrumentSetID!}
							created={instrumentSet.create}
							modified={instrumentSet.modified}
							backState={{
								backPath: `/instrumentset/${instrumentSet.instrumentSetID!}`,
								backName: `${instrumentSet.instrumentSetName}`,
							}}
						/>
					)}
				</>
			</Card>
			{!isNew && instruments && (
				<Card
					title="Instruments"
					collapsible={true}
					headerComponent={() => (
						<>
							{pageStatus === 'Ready' && instrumentSet && canWriteInstrument(appStateToPermissionObject(context.appState)) && (
								<Row className="justify-content-end">
									<Col sm={'auto'}>
										<Button
											className="btn btn-outline-dark span-bold"
											onClick={() =>
												props.history.push(`/instrument/new?instrumentset=${instrumentSet.instrumentSetID}`, {
													backPath: props.location.pathname,
													backName: instrumentSet.instrumentSetName || 'Instrument Set',
												})
											}
										>
											Add instrument
										</Button>
									</Col>
								</Row>
							)}
						</>
					)}
				>
					<InstrumentSetInstrumentList
						instruments={instruments}
						backState={{ backPath: props.location.pathname, backName: instrumentSet?.instrumentSetName || 'Instrument Set' }}
					/>
				</Card>
			)}
		</Container>
	)
}

export { ScreensInstrumentSetDetails }
