import { AssetTypeQuestion } from '../common/assetTypeQuestion'
import { Overrides, QUESTION_OVERRIDES } from '../constants/question'

const getPassValueOverrides = (question: Partial<AssetTypeQuestion> | null) => {
	if (question?.questionID) {
		let passValues: Overrides
		if (question?.passRequirementOverrides) {
			passValues = question.passRequirementOverrides
		} else {
			passValues = QUESTION_OVERRIDES[question?.questionID]
		}
		// Simplify the pass overrides to just the values
		const passValueObject = {} as { [key: string]: number }
		Object.keys(passValues).forEach((key) => (passValueObject[key] = passValues[key].value))
		return passValueObject
	}
	return null
}

export { getPassValueOverrides }
