import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatDateTime } from '../../../utilities/formatDate'
import { Created, Modified } from '../../../../../back-end/common/history'
import { PageBackState } from '../../../types/PageBackState'

interface HistoryCardBottomProps {
	historyReferenceID: string
	created: Created
	modified: Modified
	backState?: PageBackState
}

export const HistoryCardBottom = (props: HistoryCardBottomProps) => {
	return (
		<>
			<hr />
			<Row className="span-grey" style={{ textAlign: 'center' }}>
				<Col></Col>
				<Col sm={'auto'}>
					<Form.Label>Created</Form.Label>
				</Col>
				<Col sm={'auto'}>
					{formatDateTime({ date: props.created.ts, format: 'DateAndTimeAndTimezone' })} by{' '}
					<i>
						{props.created.firstName} {props.created.lastName}
					</i>
				</Col>
				<Col></Col>
			</Row>
			<Row className="span-grey">
				<Col></Col>
				<Col sm={'auto'}>
					<Form.Label>Modified</Form.Label>
				</Col>
				<Col sm={'auto'}>
					{formatDateTime({ date: props.modified.ts, format: 'DateAndTimeAndTimezone' })} by{' '}
					<i>
						{props.modified.firstName} {props.modified.lastName}
					</i>
				</Col>
				<Col>
					<Link to={{ pathname: `/history/${props.historyReferenceID}`, state: props.backState }}>
						{' '}
						<i>see more...</i>{' '}
					</Link>
				</Col>
			</Row>
		</>
	)
}
