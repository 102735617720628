import React, { useContext } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { Asset, AssetResult } from 'dynaflow/common/asset'
import * as Request from '../../utilities/request'
import { AppContext } from '../../App'
import { RouteComponentProps } from 'react-router'
import { Header } from '../../components/UI/Header/Header'
import { Card } from '../../components/UI/Card/Card'
import { PageStatus } from '../../types/PageStatus'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { FormText } from '../../components/UI/Form/Text'
import { formatDateTime } from '../../utilities/formatDate'
import { Link } from 'react-router-dom'
import { Loading } from '../../components/UI/Loading/Loading'
import { FormSelect, SelectOption } from '../../components/UI/Form/Select'
import { useStateParams } from '../../utilities/hooks'
import { Test, TestResult, MoveTestsBody } from 'dynaflow/common/test'
import { ConfirmModal } from '../../components/UI/ConfirmModal/confirmModal'
import { ArrowLeft } from 'react-feather'
import { canWriteAsset } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'

const AssetInfo = (props: { asset?: Asset }) => {
	return props.asset ? (
		<>
			<Row className="align-items-center">
				<Col>
					<Form.Label className="mb-0">Location</Form.Label>
				</Col>
				<Col>
					<FormText name="locationName" value={props.asset.locationName} plaintext disabled />
				</Col>
			</Row>
			<Row className="align-items-center">
				<Col>
					<Form.Label className="mb-0">Building</Form.Label>
				</Col>
				<Col>
					<FormText name="locationName" value={props.asset.buildingName} plaintext disabled />
				</Col>
			</Row>
			<Row className="align-items-center">
				<Col>
					<Form.Label className="mb-0">Room</Form.Label>
				</Col>
				<Col>
					<FormText name="locationName" value={props.asset.roomName} plaintext disabled />
				</Col>
			</Row>
			<Row className="align-items-center">
				<Col>
					<Form.Label className="mb-0">Unit Reference</Form.Label>
				</Col>
				<Col>
					<Link to={`/asset/${props.asset.assetID}`}>{props.asset.assetUnitReference}</Link>
				</Col>
			</Row>
			<Row className="align-items-center">
				<Col>
					<Form.Label className="mb-0">Client Asset Reference</Form.Label>
				</Col>
				<Col>
					<FormText name="assetClientRef" value={props.asset.assetClientRef} plaintext disabled />
				</Col>
			</Row>
			<Row className="align-items-center pb-3">
				<Col>
					<Form.Label className="mb-0">Asset Type</Form.Label>
				</Col>
				<Col>
					<FormText name="assetClientRef" value={props.asset.assetTypeName} plaintext disabled />
				</Col>
			</Row>
		</>
	) : null
}

const AssetTests = (props: { assetID?: string }) => {
	const context = useContext(AppContext)

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [tests, setTests] = React.useState<Test[]>([])

	React.useEffect(() => {
		const getData = async () => {
			const testResponse = await Request.get<TestResult>(`test?where=AssetID==${props.assetID}`, context.appState.authState)
			setTests(testResponse.data.tests.sort((a, b) => (a.performedTs > b.performedTs ? -1 : 1)))

			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			if (props.assetID) {
				getData()
			} else {
				setPageStatus('Ready')
			}
		}
	}, [context.appState.authState, props.assetID])

	return props.assetID ? (
		<Row>
			<Col>
				<Card title={'Tests'} collapsible={false}>
					{pageStatus === 'Loading' ? (
						<Loading size={2} spinner={true} />
					) : tests.length === 0 ? (
						<h2>No Tests</h2>
					) : (
						<>
							<Row>
								<Col>
									<span className="span-bold">Name</span>
								</Col>
								<Col>
									<span className="span-bold">Date</span>
								</Col>
							</Row>
							{tests.map((test) => (
								<Row key={test.testID}>
									<Col>
										<Link to={`/test/${test.testID}`}>{test.testReport?.testReportName || '<no-name>'}</Link>
									</Col>
									<Col>
										<span>{test.performedTs ? formatDateTime({ date: test.performedTs, format: 'Date' }) : '<no-date>'}</span>
									</Col>
								</Row>
							))}
						</>
					)}
				</Card>
			</Col>
		</Row>
	) : null
}

export interface ScreensAssetMoveTestsParams {
	id: string
}

const ScreensAssetMoveTests = (props: RouteComponentProps<ScreensAssetMoveTestsParams>) => {
	const context = useContext(AppContext)
	const [messages, setMessages] = useMessageReducer([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [reload, setReload] = React.useState(false)

	const accountID = props.match!.params.id
	const [toAssetID, setToAssetID] = useStateParams(
		'',
		'to',
		(d) => d,
		(d) => d
	)
	const [fromAssetID, setFromAssetID] = useStateParams(
		'',
		'from',
		(d) => d,
		(d) => d
	)

	const [assets, setAssets] = React.useState<Asset[]>([])
	const toAsset = assets.find((asset) => asset.assetID === toAssetID)
	const fromAsset = assets.find((asset) => asset.assetID === fromAssetID)

	const [showConfirm, setShowConfirm] = React.useState(false)

	React.useEffect(() => {
		const getData = async () => {
			const assetResponse = await Request.get<AssetResult>(`asset?where=accountID==${accountID}`, context.appState.authState)
			setAssets(assetResponse.data.assets)
			setPageStatus('Ready')
		}

		setPageStatus('Loading')
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context.appState.authState, reload])

	React.useEffect(() => {
		console.log('toAssetID', toAssetID)
		console.log('fromAssetID', fromAssetID)
	}, [toAssetID, fromAssetID])

	const mergeAssets = async () => {
		setPageStatus('Submitting')
		const moveTestBody: MoveTestsBody = {
			toAssetID,
			fromAssetID,
		}
		await Request.handleRequest(() => Request.put<TestResult>('test/move', moveTestBody, context.appState.authState), {
			successFunction: () => {
				setShowConfirm(false)
				setReload(!reload)
			},
			errorFunction: () => {
				setShowConfirm(false)
			},
			failedFunction: () => {
				setShowConfirm(false)
			},
			setMessageFunction: setMessages,
			messageAction: 'editing',
			messageObject: 'test',
		})
		setPageStatus('Ready')
	}

	return (
		<div className="page">
			<Messages messages={messages} updateMessage={setMessages} />

			<Row>
				<Col>
					<Link className="breadcrumb-back" to={`/asset${toAssetID ? `/${toAssetID}` : ''}`}>{`< Back to Asset${toAssetID ? '' : 's'}`}</Link>
				</Col>
			</Row>

			<Row>
				<Col>
					<Header title="Move Tests" subtitle={''} />
				</Col>
			</Row>
			{canWriteAsset(appStateToPermissionObject(context.appState), { accountID: accountID, cud: 'update' }) ? (
				<>
					<Card title={'Select Assets'} collapsible={false}>
						{pageStatus === 'Loading' ? (
							<Loading size={4} spinner={true} />
						) : assets.length === 0 ? (
							<h2>No Assets in this Institution</h2>
						) : (
							<>
								<Row>
									<Col />
									<Col sm={4}>
										<Row className="pb-3">
											<Col>
												<FormSelect
													name="toAssetID"
													label="To Asset"
													options={assets
														.filter((asset) => (fromAssetID ? asset.assetID !== fromAssetID : true))
														.map((asset) => ({ value: asset.assetID, label: asset.assetUnitReference }))}
													value={toAssetID}
													onChange={(e) => {
														const newToAssetID = e ? (e as SelectOption<string>).value : ''
														setFromAssetID('')
														setToAssetID(newToAssetID)
													}}
												/>
											</Col>
										</Row>
										<AssetInfo asset={toAsset} />
										<AssetTests assetID={toAssetID} />
									</Col>
									<Col sm={1} className="d-flex justify-content-center pt-4">
										<ArrowLeft size={48} />
									</Col>
									<Col sm={4}>
										<Row className="pb-3">
											<Col>
												<FormSelect
													name="fromAssetID"
													label={`From Asset${toAssetID ? ` (${toAsset?.assetTypeName})` : ''}`}
													options={assets
														.filter((asset) => (toAssetID ? asset.assetID !== toAssetID : true))
														.filter((asset) => asset.assetTypeID === toAsset?.assetTypeID)
														.map((asset) => ({ value: asset.assetID, label: asset.assetUnitReference }))}
													value={fromAssetID}
													onChange={(e) => setFromAssetID(e ? (e as SelectOption<string>).value : '')}
													isClearable
												/>
											</Col>
										</Row>
										<AssetInfo asset={fromAsset} />
										<AssetTests assetID={fromAssetID} />
									</Col>
									<Col />
								</Row>
								<Row className="pb-3">
									<Col />
									<Col sm="auto">
										<Button
											className="btn btn-outline-dark span-bold"
											onClick={() => setShowConfirm(true)}
											disabled={toAssetID === '' || fromAssetID === ''}
										>
											Move Tests
										</Button>
									</Col>
									<Col />
								</Row>
							</>
						)}
					</Card>

					<ConfirmModal
						show={showConfirm}
						title="Confirm Test Move"
						message={`Are you sure you want to move these tests?`}
						confirmButtonText="Confirm"
						onConfirmPress={mergeAssets}
						onCancelPress={() => setShowConfirm(false)}
						submitting={pageStatus === 'Submitting'}
					/>
				</>
			) : (
				<span>You do not have permissions to access this resource</span>
			)}
		</div>
	)
}

export { ScreensAssetMoveTests }
