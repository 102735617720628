import React from 'react'
import { Redirect, RouteChildrenProps } from 'react-router-dom'
import { AppContext } from '../../App'
import { getUrlSearchParam } from '../../utilities/url'

const getRedirectToLocation = (location: string | null): string => (location ? location : '/')

const ScreensLogin = (props: RouteChildrenProps<never>) => {
	const context = React.useContext(AppContext)
	return (
		<div>
			{context.appState.authState.isLoggedIn ? <Redirect to={getRedirectToLocation(getUrlSearchParam(props.location.search, 'redirect'))} /> : null}
		</div>
	)
}

export { ScreensLogin }
