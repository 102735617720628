import React, { useContext } from 'react'
import { RouteChildrenProps } from 'react-router-dom'
import { Container, Spinner, Col, Row, Table, Form, Dropdown } from 'react-bootstrap'
import { User, UserResult } from '../../../../back-end/common/user'
import * as Request from '../../utilities/request'
import { Header } from '../../components/UI/Header/Header'
import { Card } from '../../components/UI/Card/Card'
import { Button } from '../../components/UI/Button/Button'
import { Paginator, FiltersState, pageFilter, calculatePageStart, calculatePageTo } from '../../components/UI/Paginator/Paginator'
import { AppContext } from '../../App'
import { Link } from 'react-router-dom'
import { PageStatus } from '../../types/PageStatus'

const ScreensUser = (props: RouteChildrenProps) => {
	const context = useContext(AppContext)

	const [users, setUsers] = React.useState<User[] | null>(null)
	const [filters, setFilters] = React.useState<FiltersState>({ page: 1, pageSize: 10 })
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	React.useEffect(() => {
		const getData = async () => {
			const [user] = await Promise.all([Request.get<UserResult>('user', context.appState.authState)])
			setUsers(user.data.users)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	// Filters
	const [searchUserEmail, setSearchUserEmail] = React.useState<string>('')
	const [searchUserFirstName, setSearchUserFirstName] = React.useState<string>('')
	const [searchUserLastName, setSearchUserLastName] = React.useState<string>('')
	const [selectUser, setSelectUsers] = React.useState<string[]>([])

	const setSelectUser = (userID: string, checked: boolean) => {
		if (checked && userID === 'All') {
			setSelectUsers(filteredRecords.map((acc) => (acc.userID ? acc.userID : '')))
		} else if (checked) {
			setSelectUsers([...selectUser, userID])
		} else if (userID === 'All') {
			setSelectUsers([])
		} else {
			setSelectUsers(selectUser.filter((acc) => acc !== userID))
		}
	}

	React.useEffect(() => {
		setFilters({ ...filters, page: 1 })
	}, [searchUserEmail, searchUserFirstName, searchUserLastName, selectUser]) // eslint-disable-line

	/* Apply filters */
	let filteredRecords: User[] = users ? users : []
	const unFilteredRecords: User[] = filteredRecords
	let paginatedFilteredRecords: User[] = []
	if (filteredRecords.length > 0) {
		// Filter by User Email
		filteredRecords = filter('email')(filteredRecords, searchUserEmail)

		// Filter by User First Name
		filteredRecords = filter('firstName')(filteredRecords, searchUserFirstName)

		// Filter by User Last Name
		filteredRecords = filter('lastName')(filteredRecords, searchUserLastName)

		// Pagination
		paginatedFilteredRecords = filteredRecords.filter((_record: unknown, index: number) => pageFilter(filters, index))
	}

	return (
		<Container>
			<Header title={`Users (${unFilteredRecords.length})`} subtitle={'View a list of users'} />

			<Row style={{ marginBottom: '20px' }}>
				<Col sm={2}>
					<Dropdown>
						<Dropdown.Toggle variant="secondary" id="dropdown-action">
							Actions
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => console.log('Action 1')}>Action 1</Dropdown.Item>
							<Dropdown.Item onClick={() => console.log('Action 2')}>Action 2</Dropdown.Item>
							<Dropdown.Item onClick={() => console.log('Action 3')}>Action 3</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</Col>
				<Col>{selectUser.length} users selected</Col>
				<Col>
					<Row>
						<Col sm="auto">
							<Dropdown>
								<Dropdown.Toggle variant="secondary" id="dropdown-action">
									{filters.pageSize} records per page
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => setFilters({ page: 1, pageSize: 10 })}>10 per page</Dropdown.Item>
									<Dropdown.Item onClick={() => setFilters({ page: 1, pageSize: 25 })}>25 per page</Dropdown.Item>
									<Dropdown.Item onClick={() => setFilters({ page: 1, pageSize: 50 })}>50 per page</Dropdown.Item>
									<Dropdown.Item onClick={() => setFilters({ page: 1, pageSize: 100 })}>100 per page</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Col>
						<Col sm="auto">
							<Paginator filters={filters} setFilterDispatch={setFilters} allPaginatedRecordsLength={filteredRecords.length} />
						</Col>
					</Row>
				</Col>
			</Row>

			<Row>
				<Col>
					<Card title={'Users'} collapsible={true}>
						<>
							<Table responsive borderless>
								<thead>
									<tr>
										<th>
											<Row>
												<Col>Select</Col>
											</Row>
											<Row className="card-title-filter">
												<Col>
													<Form.Control
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															setSelectUser(event.target.value, event.target.checked)
														}}
														type="checkbox"
														id="Search"
														key="Search"
														name="Search"
														value={'All'}
													/>
												</Col>
											</Row>
										</th>
										<th>
											<Row>
												<Col>User ID</Col>
											</Row>
											<Row className="card-title-filter">
												<Col>
													<Form.Control
														onChange={(event) => {
															setSearchUserEmail(event.target.value)
														}}
														type="text"
														id="Search"
														key="Search"
														name="Search"
														value={searchUserEmail}
														placeholder="Type to filter"
													/>
												</Col>
											</Row>
										</th>
										<th>
											<Row>
												<Col>First Name</Col>
											</Row>
											<Row className="card-title-filter">
												<Col>
													<Form.Control
														onChange={(event) => {
															setSearchUserFirstName(event.target.value)
														}}
														type="text"
														id="Search"
														key="Search"
														name="Search"
														value={searchUserFirstName}
														placeholder="Type to filter"
													/>
												</Col>
											</Row>
										</th>
										<th>
											<Row>
												<Col>Last Name</Col>
											</Row>
											<Row className="card-title-filter">
												<Col>
													<Form.Control
														onChange={(event) => {
															setSearchUserLastName(event.target.value)
														}}
														type="text"
														id="Search"
														key="Search"
														name="Search"
														value={searchUserLastName}
														placeholder="Type to filter"
													/>
												</Col>
											</Row>
										</th>
									</tr>
								</thead>
								<tbody>{pageStatus !== 'Loading' && renderUserList(paginatedFilteredRecords, setSelectUser, selectUser)}</tbody>
							</Table>

							{pageStatus === 'Loading' ? (
								<Row>
									<Col sm="auto">
										<Spinner size={'sm'} animation={'border'} />
									</Col>
								</Row>
							) : null}

							<Row className="justify-content-sm-center">
								<Col sm="auto">
									{calculatePageStart(filters)} to {calculatePageTo(filters, unFilteredRecords.length)} of {unFilteredRecords.length} users
								</Col>
								<Col sm="auto">
									<Paginator filters={filters} setFilterDispatch={setFilters} allPaginatedRecordsLength={filteredRecords.length} />
								</Col>
								<Col>
									<Button onClick={() => props.history.push('/user/new')}>Create new user</Button>
								</Col>
							</Row>
						</>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}

/* Filters */
const filter = (propertyToFilter: keyof User) => {
	return (list: User[], filterText?: string) => {
		if (filterText) {
			return list.filter(
				(user) => user && user[propertyToFilter] && user[propertyToFilter]!.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1
			)
		}
		return list
	}
}

const renderUserList = (userRecords: User[], setSelectUser: (userID: string, checked: boolean) => void, selectedUsers: string[]) => {
	if (userRecords.length > 0) {
		return userRecords.map((user: User, i: number) => {
			return (
				<tr key={i}>
					<td>
						<Form.Control
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setSelectUser(event.target.value, event.target.checked)
							}}
							type="checkbox"
							id={`Select_${user.userID}`}
							key="Search"
							name="Search"
							checked={selectedUsers.find((a) => a === user.userID) !== undefined}
							value={user.userID}
						/>
					</td>
					<td style={{ whiteSpace: 'nowrap' }}>
						<Link to={`/user/${user.userID}`}>{user.email}</Link>
					</td>
					<td>{user.firstName}</td>
					<td>{user.lastName}</td>
				</tr>
			)
		})
	} else {
		return (
			<tr>
				<td>No Users to list...</td>
			</tr>
		)
	}
}

export { ScreensUser }
