import React, { useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { AppContext } from '../../App'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Header } from '../../components/UI/Header/Header'
import { PageStatus } from '../../types/PageStatus'
import { Account, AccountResult } from '../../../../back-end/common/account'
import * as Request from '../../utilities/request'
import { Listing } from '../../components/UI/Listing/Listing'

import addIcon from '../../images/icons/add.svg'
import { canWriteAccount } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'

interface EntityAccountsProps {
	pageProps: RouteComponentProps
	entityID: string
}

const EntityAccounts = (props: EntityAccountsProps) => {
	const context = useContext(AppContext)

	const [entityAccounts, setEntityAccounts] = React.useState<Account[] | null>(null)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	React.useEffect(() => {
		const getData = async () => {
			const [entityAccountsResponse] = await Promise.all([
				Request.get<AccountResult>(`account?where=entityID==${props.entityID}`, context.appState.authState),
			])
			const entityAccounts = entityAccountsResponse.data.accounts
			setEntityAccounts(entityAccounts)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, []) // eslint-disable-line

	if (entityAccounts !== null) {
		return (
			<div id={'EntityAccounts'}>
				<div id={'EntityAccountsHeader'}>
					<Row>
						<Col>
							<Header title={`Institutions (${entityAccounts?.length || 0})`} subtitle={''} />
						</Col>
						{canWriteAccount(appStateToPermissionObject(context.appState)) && (
							<Col sm={'auto'} style={{ paddingTop: '40px' }}>
								<Button className=" btn btn-primary round" onClick={() => props.pageProps.history.push('/institution/new')}>
									<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
									<span className="span-bold" style={{ color: 'white' }}>
										Add Institution
									</span>
								</Button>
							</Col>
						)}
					</Row>

					<Listing
						name="Institution"
						namePlural="Institutions"
						list={entityAccounts || []}
						getIDFunc={(item) => item.accountID}
						selectedActions={[]}
						columns={[
							{
								value: (item) => item.accountName,
								render: (item) => (
									<Link
										to={{
											pathname: `/institution/${item.accountID}`,
											state: { backPath: props.pageProps.location.pathname, backName: 'Entity' },
										}}
									>
										{item.accountName}
									</Link>
								),
								showHeader: true,
								headerText: 'Name',
								sortColumnName: 'accountName',
								filterType: 'string',
								filterOptions: {
									columnName: 'accountName',
								},
							},
							{
								value: (item) => item.accountEntitySubscriberName,
								render: (item) => <>{item.accountEntitySubscriberName}</>,
								showHeader: true,
								headerText: 'Subscriber',
								sortColumnName: 'accountEntitySubscriberName',
								filterType: 'string',
								filterOptions: {
									columnName: 'accountEntitySubscriberName',
								},
							},
						]}
						defaultSort={{ column: 'accountName', order: 'ASC' }}
						isLoading={pageStatus === 'Loading'}
					/>
				</div>
			</div>
		)
	}
	return null
}

export { EntityAccounts }
