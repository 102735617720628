import React, { useContext } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { AssetType, AssetTypeResult } from '../../../../back-end/common/assetType'
import * as Request from '../../utilities/request'
import { AppContext } from '../../App'
import { RouteComponentProps, StaticContext } from 'react-router'
import { Header } from '../../components/UI/Header/Header'
import { Card } from '../../components/UI/Card/Card'
import { PageStatus } from '../../types/PageStatus'
import { DeleteButton } from '../../components/UI/Form/DeleteButton'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { getUrlSearchParam } from '../../utilities/url'
import { Loading } from '../../components/UI/Loading/Loading'
import { PageBackState } from '../../types/PageBackState'
import { Link } from 'react-router-dom'
import { FormText } from '../../components/UI/Form/Text'
import { HistoryCardBottom } from '../../components/UI/HistoryCardBottom/HistoryCardBottom'
import * as yup from 'yup'
import { Formik, FormikErrors } from 'formik'
import * as EntityType from '../../constants/entityType'
import { Uploader, uploadToSignedUrl } from '../../components/UI/Uploader/Uploader'
import { DocumentResult } from '../../../../back-end/common/document'
import generateUuid from '../../utilities/uuid'
import { CompetencyListing } from '../../components/Competency/Competency'
import { Competency, CompetencyResult } from '../../../../back-end/common/competency'
import { ComplianceQuestionDetails } from '../../components/ComplianceQuestion/ComplianceQuestionDetails'
import { QuestionResult } from '../../../../back-end/common/question'
import { DropdownSelect, DropdownSelectOption } from '../../components/Forms/Dropdown'
import { AssetTypeQuestion, AssetTypeQuestionResult } from '../../../../back-end/common/assetTypeQuestion'
import { QUESTION, QUESTION_OVERRIDES } from 'dynaflow/constants/question'
import { DownloadLink } from '../../components/UI/Uploader/DownloadLink'
import { ArrowDown, ArrowUp } from 'react-feather'
import { canWriteAsset } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'

interface ScreensAssetTypeDetailsMatchParams {
	id: string
}

interface ScreensAssetTypeDetailsStateParams extends PageBackState {}

interface AssetTypeParam {
	id: keyof AssetType
	label: string
}

type AssetTypeErrorMessages = FormikErrors<Partial<AssetType> & { questions: DropdownSelectOption<string, Partial<AssetTypeQuestion>>[] }>

type AssetTypeWithQuestions = Partial<AssetType> & { questions: DropdownSelectOption<string, Partial<AssetTypeQuestion>>[] }

const overrideValidation = yup.object().shape({
	value: yup.number().min(0, 'Value must be greater than zero'),
})

const assetTypeValidationSchema = yup.object().shape({
	name: yup.string().required('Name is required.'),
	readableShortcode: yup.string().required(' Readable Shortcode is required.'),
	complianceClause: yup.string().optional().nullable(),
	assetTypeTestReportTemplateS3Path: yup.string().required('Test Report Template is required.'),
	questions: yup.array().of(
		yup.object().shape({
			complianceClause: yup.string().optional().nullable(),
			passRequirementOverrides: yup.object().shape({
				minimumAverageFaceVelocity: overrideValidation,
				minimumFaceVelocityReading: overrideValidation,
				minimumAverageVelocity: overrideValidation,
				maximumAverageVelocity: overrideValidation,
				minimumDownflowVelocity: overrideValidation,
				maximumIndividualPatchSize: overrideValidation,
				maximumTotalPatchSizePercent: overrideValidation,
				minimumLightReading: overrideValidation,
				minimumAverageLightReading: overrideValidation,
				maximumSoundLevel: overrideValidation,
				minimumAverageUVReading: overrideValidation,
				minimumVelocityReading: overrideValidation,
				maximumVelocityReading: overrideValidation,
			}),
		})
	),
})

const newAssetType = (assetType: Partial<AssetType>): AssetTypeWithQuestions => ({
	name: assetType.name || '',
	readableShortcode: assetType.readableShortcode || '',
	isStandardsBased: assetType.isStandardsBased || false,
	showChamberWidth: assetType.showChamberWidth || false,
	showChamberHeight: assetType.showChamberHeight || false,
	showChamberDepth: assetType.showChamberDepth || false,
	assetTypeTestReportTemplateS3Path: assetType.assetTypeTestReportTemplateS3Path || '',
	complianceClause: assetType.complianceClause || '',
	customProperties: assetType.customProperties || [],
	canForceTestPass: assetType.canForceTestPass || true,
	accountID: assetType.accountID || '',
	questions: [],
})

const params: AssetTypeParam[] = [
	{
		id: 'showChamberWidth',
		label: 'Chamber Width',
	},
	{
		id: 'showChamberHeight',
		label: 'Chamber Height',
	},
	{
		id: 'showChamberDepth',
		label: 'Chamber Depth',
	},
]

const ScreensAssetTypeDetails = (
	props: RouteComponentProps<ScreensAssetTypeDetailsMatchParams, StaticContext, ScreensAssetTypeDetailsStateParams | undefined>
) => {
	const context = useContext(AppContext)
	const assetTypeID = props.match!.params.id
	const accountID = getUrlSearchParam(props.location.search, 'account')
	const isNew = assetTypeID === 'new'

	const [messages, setMessages] = useMessageReducer([])
	const [assetType, setAssetType] = React.useState<AssetTypeWithQuestions | null>(
		assetTypeID === 'new' ? newAssetType({ accountID: accountID || '', isStandardsBased: false }) : null
	)
	const [initialQuestions, setInitialQuestions] = React.useState<DropdownSelectOption<string, Partial<AssetTypeQuestion>>[]>([])
	const [competencies, setCompetencies] = React.useState<Competency[]>([])
	const [questionOptions, setQuestionOptions] = React.useState<DropdownSelectOption<string, Partial<AssetTypeQuestion>>[]>([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>(assetTypeID === 'new' ? 'Editing' : 'Loading')
	const [errorOverride, setErrorOverride] = React.useState<boolean>(false)

	React.useEffect(() => {
		const getData = async () => {
			const [assetTypeReq, competencyReq] = await Promise.all([
				Request.get<AssetTypeResult>(`assettype?where=assetTypeID==${assetTypeID}`, context.appState.authState),
				Request.get<CompetencyResult>(`competency?where=assetTypeID==${assetTypeID}`, context.appState.authState),
			])
			const assetType = assetTypeReq.data.assetTypes[0]
			const currentQuestionsReq = await Request.get<AssetTypeQuestionResult>(
				`assettypequestion?where=assetTypeID==${
					assetType.isStandardsBased && assetType.parentAssetTypeID ? assetType.parentAssetTypeID : assetType.assetTypeID
				}`,
				context.appState.authState
			)
			const competenciesOfParent =
				assetType.parentAssetTypeID && assetType.isStandardsBased
					? await Request.get<CompetencyResult>(`competency?where=assetTypeID==${assetType.parentAssetTypeID}`, context.appState.authState)
					: { data: { competencies: [] } }
			const questions = currentQuestionsReq.data.assetTypeQuestions.map((assetTypeQuestion) => ({
				assetTypeQuestionID: assetTypeQuestion.assetTypeQuestionID,
				questionID: assetTypeQuestion.questionID,
				assetTypeQuestionOrder: assetTypeQuestion.assetTypeQuestionOrder,
				questionName: assetTypeQuestion.questionName,
				assetTypeID: assetTypeQuestion.assetTypeID,
				complianceClause: assetTypeQuestion.complianceClause,
				value: assetTypeQuestion.questionID,
				text: assetTypeQuestion.questionName,
				passRequirementOverrides: assetTypeQuestion.passRequirementOverrides || QUESTION_OVERRIDES[assetTypeQuestion.questionID],
			}))
			setAssetType({
				...assetType,
				questions,
			})
			setInitialQuestions(questions)
			setCompetencies([...competencyReq.data.competencies, ...competenciesOfParent.data.competencies])
			setPageStatus('Ready')
		}

		const getQuestions = async () => {
			// don't get fume lighting question because it doubles up 'lighting' and it's only there to handle copying down from safetick account into new insts
			const questionReq = await Request.get<QuestionResult>(`question?where=questionid<>${QUESTION.FUME_LIGHTING_TEST}`, context.appState.authState)
			setQuestionOptions(
				questionReq.data.questions
					.map((question) => ({
						...question,
						complianceClause: question.questionComplianceClause,
						text: question.questionName,
						value: question.questionID,
						passRequirementOverrides: QUESTION_OVERRIDES[question.questionID],
					}))
					.sort((a, b) => a.questionName.localeCompare(b.questionName))
			)
		}

		if (context.appState.authState.isLoggedIn) {
			if (!isNew) {
				getData()
			}
			getQuestions()
		}
	}, [])

	const onAssetTypeCreate = async (assetType: AssetTypeWithQuestions) => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.post<AssetTypeResult>(`assettype`, assetType, context.appState.authState), {
			successFunction: async (data) => {
				await updateAssetTypeQuestions({ ...assetType, assetTypeID: data.assetTypes[0].assetTypeID })
				props.history.push(`/assettype/${data.assetTypes[0].assetTypeID}`, props.location.state)
			},
			setMessageFunction: setMessages,
			messageAction: 'creating',
			messageObject: 'asset type',
		})
		setPageStatus('Ready')
	}

	const onAssetTypeEdit = async (assetType: AssetTypeWithQuestions) => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.put<AssetTypeResult>(`assettype?where=assetTypeID==${assetTypeID}`, assetType, context.appState.authState), {
			successFunction: async () => {
				await updateAssetTypeQuestions(assetType)
			},
			setMessageFunction: setMessages,
			messageAction: 'editing',
			messageObject: 'asset type',
		})
		setPageStatus('Ready')
	}

	const onAssetTypeDelete = async (assetTypeID: string) => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.del<AssetTypeResult>(`assettype?where=assetTypeID==${assetTypeID}`, context.appState.authState), {
			successFunction: () => {
				if (props.location.state && props.location.state.backPath) {
					props.history.push(props.location.state.backPath)
				}
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'asset type',
			passFailedErrorMessage: true,
		})
		setPageStatus('Ready')
	}

	const updateAssetTypeQuestions = async (assetType: AssetTypeWithQuestions) => {
		const questionIDs = [] as string[]
		const newQuestions = await Promise.all(
			assetType.questions.map((question, index) => {
				// Set the override values to number
				if (question.passRequirementOverrides) {
					Object.keys(question.passRequirementOverrides).forEach((key) => {
						question.passRequirementOverrides![key].value = Number(question.passRequirementOverrides![key].value)
					})
				} else {
					question.passRequirementOverrides = QUESTION_OVERRIDES[question.questionID!]
				}
				if (!question.assetTypeQuestionID) {
					// New ATQ
					return Request.post<AssetTypeQuestionResult>(
						`assettypequestion`,
						{
							...question,
							assetTypeQuestionOrder: index,
							assetTypeID: assetType.assetTypeID,
							passRequirementOverrides: question?.passRequirementOverrides,
						},
						context.appState.authState
					)
				} else {
					questionIDs.push(question.assetTypeQuestionID)
					// Update existing ATQ
					return Request.put<AssetTypeQuestionResult>(
						`assettypequestion?where=assetTypeQuestionID==${question.assetTypeQuestionID}`,
						{
							...question,
							assetTypeQuestionOrder: index,
							passRequirementOverrides: question?.passRequirementOverrides,
						},
						context.appState.authState
					)
				}
			})
		)
		await Promise.all(
			initialQuestions.map((question) => {
				if (question.assetTypeQuestionID && !questionIDs.includes(question.assetTypeQuestionID!)) {
					// question was deleted
					return Request.del<AssetTypeQuestionResult>(
						`assettypequestion?where=assetTypeQuestionID==${question.assetTypeQuestionID}`,
						context.appState.authState
					)
				}
				return null
			})
		)
		setInitialQuestions(
			newQuestions.flatMap((resp) =>
				resp.data.assetTypeQuestions.map((question) => ({
					...question,
					text: question.questionName,
					value: question.questionID,
				}))
			)
		)
	}

	return (
		<div className="page">
			<Messages messages={messages} updateMessage={setMessages} />
			{props.location.state && props.location.state.backPath && props.location.state.backName && (
				<Row>
					<Col>
						<Link className="breadcrumb-back" to={props.location.state.backPath}>{`< Back to ${props.location.state.backName}`}</Link>
					</Col>
				</Row>
			)}

			<Header title={'Asset Type Details'} subtitle={'View an asset types details'} />

			{assetType ? (
				<div>
					<Formik
						initialValues={assetType}
						validationSchema={assetTypeValidationSchema}
						onSubmit={isNew ? onAssetTypeCreate : onAssetTypeEdit}
						enableReintialize
					>
						{({ handleSubmit, errors, values, setFieldValue, setValues }) => {
							const hasError = Object.keys(errors).length > 0 || errorOverride
							return (
								<Card
									title={'Asset Type Information'}
									collapsible={false}
									headerComponent={() =>
										pageStatus !== 'Loading' &&
										assetType &&
										((!assetType.parentAssetTypeID && !assetType.isStandardsBased) ||
											context.appState.userPermissions.entityTypeID === EntityType.Admin) &&
										canWriteAsset(appStateToPermissionObject(context.appState), { accountID: assetType.accountID!, cud: 'update' }) ? (
											pageStatus === 'Ready' ? (
												<Row className="justify-content-end">
													<Col sm={'auto'}>
														<Button className="btn btn-outline-dark span-bold" onClick={() => setPageStatus('Editing')}>
															Edit Asset Type
														</Button>
													</Col>
												</Row>
											) : (
												<Row className="justify-content-end">
													<Col sm={'auto'}>
														<Button className="btn btn-secondary" disabled={hasError} onClick={() => handleSubmit()}>
															Save
														</Button>
													</Col>
													<Col sm={'auto'}>
														<Button className="btn btn-secondary" onClick={() => setPageStatus('Ready')}>
															Cancel
														</Button>
													</Col>
													<Col sm={'auto'}>
														{assetType.assetTypeID !== undefined ? (
															<DeleteButton id={assetType.assetTypeID} onClick={onAssetTypeDelete} />
														) : (
															<></>
														)}
													</Col>
												</Row>
											)
										) : (
											<></>
										)
									}
								>
									<AssetTypeDetails
										assetType={values}
										pageStatus={pageStatus}
										errors={errors}
										onChange={setFieldValue}
										setValues={setValues}
										questionOptions={questionOptions}
										setErrorOverride={setErrorOverride}
									/>

									<>
										{isNew && assetType && assetType.create && assetType.modified && (
											<HistoryCardBottom
												historyReferenceID={assetType.assetTypeID!}
												created={assetType.create}
												modified={assetType.modified}
												backState={{
													backPath: `/assettype/${assetType.assetTypeID!}`,
													backName: `${assetType.name}`,
												}}
											/>
										)}
									</>
								</Card>
							)
						}}
					</Formik>
				</div>
			) : null}

			{!isNew && (
				<CompetencyListing
					competencies={competencies}
					isLoading={pageStatus === 'Loading'}
					perspective="assettype"
					perspectiveID={assetType?.assetTypeID || ''}
					actions={[]}
					backState={{ backPath: props.location.pathname, backName: assetType?.name || 'Asset Type' }}
					goTo={props.history.push}
				/>
			)}

			{!isNew && <ComplianceQuestionDetails assetTypeID={assetTypeID} />}
		</div>
	)
}

interface AssetTypeDetailsProps {
	assetType: AssetTypeWithQuestions
	pageStatus: PageStatus
	errors?: AssetTypeErrorMessages
	onChange: (field: string, value: string | number | boolean | null | string[]) => void
	setValues: (values: React.SetStateAction<AssetTypeWithQuestions>, shouldValidate?: boolean | undefined) => void
	setErrorOverride: React.Dispatch<React.SetStateAction<boolean>>
	questionOptions?: DropdownSelectOption<string, Partial<AssetTypeQuestion>>[]
}

const AssetTypeDetails = (props: AssetTypeDetailsProps) => {
	const context = React.useContext(AppContext)
	const readOnly = props.pageStatus !== 'Editing'

	// Because we couldn't get yup to require the value to exist without requiring ALL of them to exist
	const nullErrors = React.useMemo(() => {
		const errors = props.assetType.questions
			.flatMap((question) => {
				if (!question || !question?.passRequirementOverrides) {
					return null
				}
				return Object.keys(question?.passRequirementOverrides).map((key) => {
					const override = question.passRequirementOverrides![key]
					if ((override.value as unknown as string) === '') {
						return question.questionID + key
					}
					return null
				})
			})
			.filter((x) => x !== null) as string[]
		props.setErrorOverride(errors.length > 0)
		return errors
	}, [props.assetType.questions, props.setErrorOverride])

	if (props.assetType) {
		return (
			<div>
				<Row>
					<Col>
						<FormText
							name={'name'}
							key={'name'}
							value={props?.assetType.name ? props.assetType.name : ''}
							label={'Name'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
							feedback={props.errors?.name}
							isInvalid={!!props.errors?.name}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormText
							name={'readableShortcode'}
							key={'readableShortcode'}
							value={props?.assetType.readableShortcode ? props.assetType.readableShortcode : ''}
							label={'Shortcode New'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
							feedback={props.errors?.readableShortcode}
							isInvalid={!!props.errors?.readableShortcode}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormText
							name={'complianceClause'}
							key={'complianceClause'}
							value={props?.assetType.complianceClause ? props.assetType.complianceClause : ''}
							label={'Clause for asset compliance questions'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
							feedback={props.errors?.complianceClause}
							isInvalid={!!props.errors?.complianceClause}
						/>
					</Col>
				</Row>
				{context.appState.userPermissions.entityTypeID === EntityType.Admin ? (
					<Row>
						<Col xs="2">
							<strong>Standards Based?</strong>
						</Col>
						<Col>
							{!readOnly ? (
								<Form.Check
									type="checkbox"
									label=" "
									key="isStandardsBased"
									name="isStandardsBased"
									disabled={readOnly}
									checked={props.assetType?.isStandardsBased}
									onChange={(e) => props.onChange(e.target.name, e.target.checked)}
									value={''}
								/>
							) : props.assetType?.isStandardsBased ? (
								'Yes'
							) : (
								'No'
							)}
						</Col>
					</Row>
				) : null}
				<Row style={{ marginTop: '30px' }}>
					<Col xs="2">
						<strong>Parameters</strong>
					</Col>
					<Col xs="5">
						{props.assetType ? (
							!readOnly ? (
								<>
									{params.map((param) => (
										<Form.Check
											type="checkbox"
											label={param.label}
											key={param.id}
											name={param.id}
											disabled={readOnly}
											checked={!!props.assetType[param.id]}
											onChange={(e) => props.onChange(e.target.name, e.target.checked)}
											value={''}
										/>
									))}
									{props.assetType.customProperties?.map((property, index) => (
										<Row key={'customProperties' + index}>
											<Col>
												<FormText
													name={'customProperties'}
													value={property}
													onChange={(e) => {
														const newArr =
															props.assetType.customProperties?.map((prop, i) => (i === index ? e.target.value : prop)) || []
														props.onChange('customProperties', newArr)
													}}
													plaintext={readOnly}
													disabled={readOnly}
												/>
											</Col>
											<Col>
												<Button
													onClick={() =>
														props.onChange(
															'customProperties',
															(props.assetType.customProperties || []).filter((_, i) => i !== index)
														)
													}
												>
													Remove
												</Button>
											</Col>
										</Row>
									))}
									<Button onClick={() => props.onChange('customProperties', [...(props.assetType.customProperties || []), ''])}>
										Add Custom Parameter
									</Button>
								</>
							) : (
								<>
									{params
										.filter((param) => props.assetType[param.id])
										.map((param) => (
											<div key={param.id}>
												<Form.Label>{param.label}</Form.Label>
											</div>
										))}
									{props.assetType.customProperties?.map((property) => (
										<div key={property}>
											<Form.Label>{property}</Form.Label>
										</div>
									))}
								</>
							)
						) : (
							<Loading />
						)}
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>Upload test report template</Form.Label>
						{!readOnly && (
							<Uploader
								name="assetTypeTestReportTemplateS3Path"
								multiple={false}
								accept="application/docx"
								maxCount={1}
								defaultFileList={
									props.assetType.assetTypeTestReportTemplateS3Path
										? [
												{
													name: 'template.docx',
													percent: 100,
													status: 'done',
													thumbUrl: '',
													uid: props.assetType.assetTypeID!,
													url: props.assetType.assetTypeTestReportTemplateS3Path,
												},
										  ]
										: []
								}
								onRemove={() => props.onChange('assetTypeTestReportTemplateS3Path', null)}
								action={async () => {
									// get s3 url
									const s3Path = `report/template/${generateUuid()}.docx`
									const req = await Request.post<DocumentResult>(
										'document',
										{
											type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
											s3Location: s3Path,
											operation: 'putObject',
										},
										context.appState.authState
									)
									// set field
									// setIsUploading(true)
									props.onChange('assetTypeTestReportTemplateS3Path', s3Path)
									return req.data.file.signedUrl!
								}}
								customRequest={async (info) => {
									if (typeof info.file !== 'string') {
										const res = await uploadToSignedUrl(info.action, info.file, info.filename)
										info.onSuccess && info.onSuccess(res)
									}
									// setIsUploading(false)
								}}
								error={props.errors?.assetTypeTestReportTemplateS3Path}
							/>
						)}
						{readOnly && props.assetType.assetTypeTestReportTemplateS3Path && (
							<DownloadLink
								path={props.assetType.assetTypeTestReportTemplateS3Path}
								fileType="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
								name={'template.docx'}
								icon="File"
							/>
						)}
					</Col>
				</Row>
				<Row className="mt-3">
					<Col>
						<Form.Label>Test questions</Form.Label>
						{props.assetType.questions
							.sort((a, b) => (a.assetTypeQuestionOrder || 0) - (b.assetTypeQuestionOrder || 0))
							.map((question, index) => (
								<Row key={`${question.questionID || ''}_${index}`} className="mb-3">
									<Col xs="auto">
										<ArrowUp
											style={{ visibility: readOnly ? 'hidden' : 'initial' }}
											color={index === 0 ? 'grey' : 'black'}
											onClick={() =>
												index > 0 &&
												props.setValues({
													...props.assetType,
													questions: props.assetType.questions.map((question, i) => ({
														...question,
														assetTypeQuestionOrder: i === index ? i - 1 : i === index - 1 ? i + 1 : i, // set me down 1 and the previous one up 1
													})),
												})
											}
										/>
										<ArrowDown
											style={{ visibility: readOnly ? 'hidden' : 'initial' }}
											color={index + 1 === props.assetType.questions.length ? 'grey' : 'black'}
											onClick={() =>
												index + 1 < props.assetType.questions.length &&
												props.setValues({
													...props.assetType,
													questions: props.assetType.questions.map((question, i) => ({
														...question,
														assetTypeQuestionOrder: i === index ? i + 1 : i === index + 1 ? i - 1 : i, // set me up 1 and the next one down 1
													})),
												})
											}
										/>
									</Col>
									<Col>
										<Row className="mb-2">
											<Col sm={6}>
												<DropdownSelect
													id="selectedQuestions"
													name="selectedQuestions"
													label={`Question ${index + 1}`}
													disabled={
														readOnly ||
														(!!props.assetType.isStandardsBased &&
															context.appState.userPermissions.entityTypeID !== EntityType.Admin)
													}
													variant="secondary"
													showSearch={true}
													searchPlaceholder={''}
													options={props.questionOptions!.filter(
														(option) =>
															props.assetType.questions.find((question) => question.questionID === option.questionID) ===
															undefined
													)}
													value={props.assetType.questions[index].text || '-'}
													onChange={(value, data) => {
														props.setValues({
															...props.assetType,
															questions: props.assetType.questions.map((question, i) => {
																if (index === i) {
																	return data
																}
																return question
															}),
														})
													}}
												/>
											</Col>
											<Col sm={6}>
												<FormText
													name={`questions[${index}].complianceClause`}
													key={`questions[${index}].complianceClause`}
													value={props.assetType.questions[index].complianceClause || ''}
													label="Question compliance clause"
													onChange={(e) => props.onChange(e.target.name, e.target.value)}
													plaintext={
														readOnly ||
														(!!props.assetType.isStandardsBased &&
															context.appState.userPermissions.entityTypeID !== EntityType.Admin)
													}
													disabled={
														readOnly ||
														(!!props.assetType.isStandardsBased &&
															context.appState.userPermissions.entityTypeID !== EntityType.Admin)
													}
													// @ts-expect-error: Formik bad https://github.com/jaredpalmer/formik/issues/3355
													feedback={props.errors?.questions?.[index]?.complianceClause}
													// @ts-expect-error: As above
													isInvalid={!!props.errors?.questions?.[index]?.complianceClause}
												/>
											</Col>
											{!(
												readOnly ||
												(!!props.assetType.isStandardsBased && context.appState.userPermissions.entityTypeID !== EntityType.Admin)
											) && (
												<Col xs={1} className="d-flex pb-2" style={{ alignItems: 'flex-end' }}>
													<DeleteButton
														id={question.questionID || ''}
														customOnClick={() => {
															props.setValues({
																...props.assetType,
																questions: props.assetType.questions.filter((q, i) => i !== index),
															})
														}}
													/>
												</Col>
											)}
										</Row>
										<Row className="ml-4">
											{question?.passRequirementOverrides &&
												Object.keys(question.passRequirementOverrides).map((key) => {
													const override = question.passRequirementOverrides![key]
													const nullError = !!nullErrors.find((err) => err === question.questionID + key)
													return (
														<Col xs="auto" key={key}>
															<FormText
																name={`questions[${index}].passRequirementOverrides[${key}].value`}
																key={`questions[${index}].passRequirementOverrides[${key}].value`}
																value={override.value}
																label={override.description}
																type="number"
																min={0}
																onChange={(e) => props.onChange(e.target.name, e.target.value)}
																plaintext={
																	readOnly ||
																	(!!props.assetType.isStandardsBased &&
																		context.appState.userPermissions.entityTypeID !== EntityType.Admin)
																}
																disabled={
																	readOnly ||
																	(!!props.assetType.isStandardsBased &&
																		context.appState.userPermissions.entityTypeID !== EntityType.Admin)
																}
																feedback={
																	// @ts-expect-error: Formik bad https://github.com/jaredpalmer/formik/issues/3355
																	(props.errors?.questions?.[index]?.passRequirementOverrides?.[key] &&
																		// @ts-expect-error: As above
																		props.errors?.questions?.[index]?.passRequirementOverrides?.[key].value) ||
																	(nullError && 'Value is required') ||
																	undefined
																}
																isInvalid={
																	// @ts-expect-error: As above
																	(!!props.errors?.questions?.[index]?.passRequirementOverrides?.[key] &&
																		// @ts-expect-error: As above
																		props.errors?.questions?.[index]?.passRequirementOverrides?.[key]?.value) ||
																	nullError
																}
															/>
														</Col>
													)
												})}
										</Row>
									</Col>
								</Row>
							))}
						{!(readOnly || (!!props.assetType.isStandardsBased && context.appState.userPermissions.entityTypeID !== EntityType.Admin)) && (
							<Button
								className="mt-3"
								onClick={() => {
									props.setValues({
										...props.assetType,
										questions: [...props.assetType.questions, { text: '', value: '' }],
									})
								}}
							>
								Add Question
							</Button>
						)}
					</Col>
				</Row>
			</div>
		)
	}
	return null
}

export { ScreensAssetTypeDetails }
