import React from 'react'
import { AppContext } from '../../../App'
import { Dropdown } from 'react-bootstrap'
import * as MessageTypes from '../Messages/Message'

import profile from '../../../images/icons/profile.svg'
import chevron from '../../../images/icons/chevron.svg'
import { Auth } from '@aws-amplify/auth'
import { switchEntity } from '../../Login/AuthComponent/EntitySwitcherFunctions'

interface UserInfoProps {
	setMessages: (action: MessageTypes.MessageAction) => void
}

export const UserInfo = (props: UserInfoProps) => {
	const { appState, setAppState } = React.useContext(AppContext)

	const logOff = () => {
		window.localStorage.clear()
		setAppState({ state: 'signOut' })
		Auth.signOut().catch((err) => console.log(err))
	}

	return (
		<div>
			<Dropdown className="dropdown-userinfo-header">
				<Dropdown.Toggle className="dropdown-userinfo shadow-none">
					<div style={{ paddingRight: '40px' }}>
						<img src={profile} alt={`A User Icon`} style={{ marginRight: '10px' }}></img>
						<span className="header-username">
							{appState.userAttributes.given_name} {appState.userAttributes.family_name}
						</span>
						<img src={chevron} alt={`A Chevron arrow pointing down`} style={{ marginRight: '10px' }}></img>
					</div>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item className="dropdown-userinfo-pointer" href="/myprofile">
						My Profile
					</Dropdown.Item>
					<Dropdown.Item className="dropdown-userinfo-pointer" href="https://app.safetick.com.au/files/InstitutionDocumentation.pdf">
						Client Help Documentation
					</Dropdown.Item>
					<Dropdown.Divider />
					{appState.userEntities.map((e) => {
						return (
							<Dropdown.Item
								key={e.entityID}
								disabled={e.entityID === appState.userAttributes.entityID}
								className="dropdown-userinfo-pointer"
								onClick={() => switchEntity(e.entityID, props.setMessages, setAppState)}
							>
								{e.entityName}
							</Dropdown.Item>
						)
					})}

					<Dropdown.Divider />

					<Dropdown.Item className="dropdown-userinfo-pointer" onClick={() => logOff()}>
						Log Out
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}
