import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../../App'
import { Button, Col, Row } from 'react-bootstrap'
import { Header } from '../../components/UI/Header/Header'
import addIcon from '../../images/icons/add.svg'
import { Listing, ListingAction, ListingColumn } from '../../components/UI/Listing/Listing'
import { Competency } from '../../../../back-end/common/competency'
import { formatDateTime } from '../../utilities/formatDate'
import { PageBackState } from '../../types/PageBackState'
import * as Role from '../../constants/role'
import { ChevronRight } from 'react-feather'

interface CompetencyListingProps {
	competencies: Competency[]
	isLoading: boolean
	backState: PageBackState
	actions: ListingAction[]
	perspective: 'user' | 'assettype'
	perspectiveID: string
	goTo: (path: string) => void
}

const canWriteCompetency = (roleID: string): boolean => {
	if (
		// Review: comprehensive list?
		[
			Role.AdminManager,
			Role.TestingManager,
			Role.FacilitiesManager,
			Role.InstitutionManager,
			Role.AdminOwner,
			Role.TestingOwner,
			Role.FacilitiesOwner,
			Role.InstitutionOwner,
		].includes(roleID)
	) {
		return true
	}
	return false
}

const CompetencyListing = (props: CompetencyListingProps) => {
	const context = useContext(AppContext)
	const canEditCompetencies = canWriteCompetency(context.appState.userPermissions.roleID)
	return (
		<>
			<Row>
				<Col>
					<Header title={`Competencies (${props.competencies?.length || 0})`} subtitle={'View a list of competencies'} />
				</Col>
				<Col sm={'auto'} style={{ paddingTop: '40px' }}>
					{canEditCompetencies && (
						<Link
							className="btn btn-primary round"
							to={{ pathname: `/competency/new`, search: `${props.perspective}=${props.perspectiveID}`, state: props.backState }}
						>
							<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
							<span className="span-bold" style={{ color: 'white' }}>
								Create new competency
							</span>
						</Link>
					)}
				</Col>
			</Row>

			<Listing
				name="Competency"
				namePlural="Competencies"
				list={props.competencies}
				getIDFunc={(item) => item.competencyID}
				selectedActions={props.actions}
				columns={
					[
						{
							value: (item) => item.type,
							render: (item) => (
								<Link to={{ pathname: `/competency/${item.competencyID}`, state: props.backState }}>
									{item.type[0].toUpperCase() + item.type.substring(1)}
								</Link>
							),
							showHeader: true,
							headerText: 'Competency',
							sortColumnName: 'type',
							filterType: 'string',
							filterOptions: {
								columnName: 'type',
							},
						},
						props.perspective === 'assettype'
							? {
									value: (item) => item.user.fullName,
									render: (item) => <Link to={{ pathname: `/user/${item.userID}`, state: props.backState }}>{item.user.fullName}</Link>,
									showHeader: true,
									headerText: 'User',
									sortColumnName: 'userFullName',
									filterType: 'string',
									filterOptions: {
										columnName: 'userFullName',
									},
							  }
							: {
									value: (item) => item.assetType.name,
									render: (item) => (
										<Link to={{ pathname: `/assettype/${item.assetTypeID}`, state: props.backState }}>{item.assetType.name}</Link>
									),
									showHeader: true,
									headerText: 'Asset Type',
									sortColumnName: 'assetTypeName',
									filterType: 'string',
									filterOptions: {
										columnName: 'assetTypeName',
									},
							  },
						{
							value: (item) => item.fromDate,
							render: (item) => <>{formatDateTime({ date: item.fromDate, format: 'Date' })}</>,
							showHeader: true,
							headerText: 'From',
							sortColumnName: 'fromDate',
							filterType: 'string',
							filterOptions: {
								columnName: 'fromDate',
							},
						},
						{
							value: (item) => item.toDate,
							render: (item) => <>{formatDateTime({ date: item.toDate, format: 'Date' })}</>,
							showHeader: true,
							headerText: 'To',
							sortColumnName: 'toDate',
							filterType: 'string',
							filterOptions: {
								columnName: 'toDate',
							},
						},
						{
							value: (item) => item.competencyID,
							render: (item) => (
								<Button variant="secondary" onClick={() => props.goTo(`/competency/${item.competencyID}`)}>
									More <ChevronRight />
								</Button>
							),
							showHeader: false,
						},
					] as ListingColumn<Competency>[]
				}
				defaultSort={{ column: props.perspective === 'user' ? 'assetTypeName' : 'userFullName', order: 'ASC' }}
				isLoading={props.isLoading}
			/>
		</>
	)
}

export { CompetencyListing, canWriteCompetency }
