import React, { useContext } from 'react'
import { Col, Row, Modal } from 'react-bootstrap'
import { Header } from '../../components/UI/Header/Header'
import { Button } from '../../components/UI/Button/Button'
import { FilterDropdownOption, getApiQueryStringWhereFromFilters, Listing, ListingAction, WhereFilter } from '../../components/UI/Listing/Listing'
import { Link, RouteChildrenProps } from 'react-router-dom'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import * as Request from '../../utilities/request'
import { AppContext } from '../../App'
import { PageStatus } from '../../types/PageStatus'

import addIcon from '../../images/icons/add.svg'
import { Test, TestResult } from '../../../../back-end/common/test'
import { formatDateTime } from '../../utilities/formatDate'
import { Asset, AssetResult } from 'dynaflow/common/asset'
import { Loading } from '../../components/UI/Loading/Loading'
import { Account, AccountResult } from 'dynaflow/common/account'
import { DropdownSelect } from '../../components/Forms/Dropdown'
import BatchTestModal from '../../components/Test/BatchTestModal'
import { BatchTestReportResult } from 'dynaflow/common/testReport'
import { CheckForZipResponse, DocumentResult } from 'dynaflow/common/document'
import { DateTime } from 'luxon'
import { canWriteTest } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'
import { DeleteModal } from '../../components/UI/DeleteModal/deleteModal'

const ScreensTest = (props: RouteChildrenProps) => {
	const context = useContext(AppContext)

	const [messages, setMessages] = useMessageReducer([])
	const [tests, setTests] = React.useState<Test[] | null>(null)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [toDelete, setToDelete] = React.useState<string[]>([])

	const [assets, setAssets] = React.useState<Asset[] | null>(null)
	const [accounts, setAccounts] = React.useState<Account[] | null>(null)
	const [account, setAccount] = React.useState<Account | null>(null)

	const [show, setShow] = React.useState<boolean>(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const [batchTestLink, setBatchTestLink] = React.useState<boolean | string>(false)

	const assetTypes = React.useMemo(() => {
		return (
			assets?.reduce((accumulator, asset) => {
				const typeIndex = accumulator.findIndex((acc) => acc.value === asset.assetTypeID)
				if (typeIndex < 0) {
					accumulator.push({ value: asset.assetTypeID, text: asset.assetTypeName })
				}
				return accumulator
			}, [] as FilterDropdownOption[]) || []
		)
	}, [assets])

	const getData = async (replace: boolean, page?: number, filters?: WhereFilter[]) => {
		const [testsReq] = await Promise.all([
			Request.get<TestResult>(`test?minify${page ? `&page=${page}` : ''}${getApiQueryStringWhereFromFilters(filters)}`, context.appState.authState),
		])

		setTests(replace ? testsReq.data.tests : [...(tests || []), ...testsReq.data.tests])
		setPageStatus('Ready')
	}

	React.useEffect(() => {
		const getAccounts = async () => {
			const [account] = await Promise.all([Request.get<AccountResult>(`account`, context.appState.authState)])
			setAccounts(account.data.accounts)
		}
		if (context.appState.authState.isLoggedIn) {
			getData(true, 1)
			getAccounts()
		}
	}, [context])

	const handleDeleteSelected = async (selected: string[]) => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.del<TestResult>(`test?where=testID=in(${selected.join(',')})`, context.appState.authState), {
			successFunction: () => {
				setToDelete([])
				setTests(tests?.filter((test) => !selected.includes(test.testID)) || [])
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'tests',
		})
	}

	const handleDownloadLockedTestsSelected = async (selected: string[]) => {
		const lockedTestReportIDs = tests
			?.filter((test) => selected.includes(test.testID) && test.isLocked && test.testReport?.testReportName !== '')
			.map((test) => test.testID)

		if (!lockedTestReportIDs || lockedTestReportIDs.length === 0) {
			setMessages({
				data: { id: 1, severity: 'danger', message: 'Please select locked tests to download', dismissible: true, timeout: 5000 },
				type: 'add',
			})
			return
		}

		setPageStatus('Submitting')
		setBatchTestLink(true)

		Request.handleRequest(() => Request.post<BatchTestReportResult>(`testreport/batch`, { tests: lockedTestReportIDs }, context.appState.authState), {
			successFunction: async (data) => {
				handleFetchReportsZip(data.zipURL)
				await new Promise((r) => setTimeout(r, 3000))
			},
			errorFunction: (err) => {
				console.log(JSON.stringify(err))
				setBatchTestLink('Error: please retry')
			},
			setMessageFunction: setMessages,
			messageAction: 'creating',
			messageObject: 'tests',
		})
	}

	const handleFetchReportsZip = async (zipURL: string, initTime = DateTime.now()) => {
		Request.handleRequest(() => Request.post<CheckForZipResponse>(`testreport/zip`, { zipURL: zipURL }, context.appState.authState), {
			successFunction: async (data) => {
				if (data.success && data.message) {
					setBatchTestLink(data.message)
				} else if (data.success && data.ready) {
					let fileLink = undefined
					do {
						const doc = await Request.post<DocumentResult>(
							'document',
							{ operation: 'getObject', type: 'application/zip', s3Location: zipURL },
							context.appState.authState
						)
						fileLink = doc.data.file.signedUrl
						setBatchTestLink(fileLink || '')
					} while (!fileLink)
				} else if (data.success && !data.ready && initTime.plus({ minutes: 1 }) > DateTime.now()) {
					await new Promise((r) => setTimeout(r, 1000))
					handleFetchReportsZip(zipURL, initTime)
				} else {
					setBatchTestLink('Error: The report batch timed out, please try again.')
				}
			},
			errorFunction: () => {
				setBatchTestLink('Error: Could not zip test reports, please try again.')
			},
		})
	}

	const getAssets = async (accountID: string) => {
		const asset = await Request.get<AssetResult>(`asset?where=accountID==${accountID}`, context.appState.authState)
		setAssets(asset.data.assets)
	}

	const selectedActions: ListingAction[] = [{ name: 'Download locked tests', method: handleDownloadLockedTestsSelected }]

	if (canWriteTest(appStateToPermissionObject(context.appState))) {
		selectedActions.unshift({ name: 'Delete selected', method: (selected) => setToDelete(selected) })
	}

	return (
		<>
			<div className="page">
				<Messages messages={messages} updateMessage={setMessages} />

				<BatchTestModal
					show={batchTestLink !== false}
					onHide={() => setBatchTestLink(false)}
					loadingMessage="Zipping test reports..."
					link={batchTestLink === true || batchTestLink === false ? '' : batchTestLink}
					setBatchTestLink={setBatchTestLink}
				/>

				<Row>
					<Col>
						<Header title={`Tests`} subtitle={'View a list of tests'} />
					</Col>
					{canWriteTest(appStateToPermissionObject(context.appState)) && (
						<Col sm={'auto'} style={{ paddingTop: '40px' }}>
							<Button className=" btn btn-primary round" onClick={handleShow}>
								<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
								<span className="span-bold" style={{ color: 'white' }}>
									Create new test
								</span>
							</Button>
						</Col>
					)}
				</Row>

				<DeleteModal show={toDelete.length > 0} noun="Tests" onDeletePress={() => handleDeleteSelected(toDelete)} onNoPress={() => setToDelete([])} />

				<Listing
					paginateRequest
					name="Test"
					namePlural="Tests"
					list={tests || []}
					listFunction={getData}
					getIDFunc={(item) => item.testID}
					selectedActions={selectedActions}
					columns={[
						{
							value: (item) => item.performedTs || '1990-01-01T00:00:00',
							render: (item) => (
								<>
									{item.isLocked || canWriteTest(appStateToPermissionObject(context.appState)) ? (
										<Link to={{ pathname: `/test/${item.testID}`, state: { backPath: props.location.pathname, backName: 'Tests' } }}>
											{item.performedTs ? formatDateTime({ date: item.performedTs, format: 'Date' }) : 'No test date'}
										</Link>
									) : item.performedTs ? (
										formatDateTime({ date: item.performedTs, format: 'Date' })
									) : (
										'No test date'
									)}
								</>
							),
							showHeader: true,
							headerText: 'Test date',
							sortColumnName: 'testPerformedTs',
							filterType: 'dateRange',
							filterOptions: {
								columnName: 'testPerformedTs',
							},
						},
						{
							value: (item) => item.create.ts,
							render: (item) => <>{formatDateTime({ date: item.create.ts, format: 'DateAndTime' })}</>,
							showHeader: true,
							headerText: 'Created at',
							sortColumnName: 'testCreatedTs',
							filterType: 'dateRange',
							filterOptions: {
								columnName: 'testCreatedTs',
							},
						},
						{
							value: (item) => `${item.asset.assetClientRef} (${item.asset.assetUnitReference})`,
							render: (item) => (
								<Link to={{ pathname: `/asset/${item.assetID}`, state: { backPath: props.location.pathname, backName: 'Tests' } }}>
									{`${item.asset.assetClientRef} (${item.asset.assetUnitReference})`}
								</Link>
							),
							showHeader: true,
							headerText: 'Asset Reference',
							sortColumnName: 'assetReadableID',
							filterType: 'string',
							filterOptions: {
								columnName: 'assetReadableID',
							},
						},
						{
							value: (item) => item.asset.assetTypeName,
							render: (item) => (
								<Link
									to={{ pathname: `/assettype/${item.asset.assetTypeID}`, state: { backPath: props.location.pathname, backName: 'Tests' } }}
								>
									{item.asset.assetTypeName}
								</Link>
							),
							showHeader: true,
							headerText: 'Asset Type',
							sortColumnName: 'assetTypeName',
							filterType: 'string',
							filterOptions: {
								columnName: 'assetTypeName',
							},
						},
						{
							value: (item) => item.accountName,
							render: (item) => <>{item.accountName}</>,
							showHeader: true,
							headerText: 'Institution',
							sortColumnName: 'accountName',
							filterType: 'string',
							filterOptions: {
								columnName: 'accountName',
							},
						},
						{
							value: (item) => `${item.asset.locationName}, ${item.asset.buildingName}, ${item.asset.roomName}`,
							render: (item) => <>{`${item.asset.locationName}, ${item.asset.buildingName}, ${item.asset.roomName}`}</>,
							showHeader: true,
							headerText: 'Location',
							sortColumnName: 'location',
							filterType: 'string',
							filterOptions: {
								columnName: 'location',
								paginateCustomFilters: (value: string) =>
									value.split(' ').map((word) => [
										{
											key: 'LocationName',
											operator: '=~',
											value: word,
											or: true,
										},
										{
											key: 'BuildingName',
											operator: '=~',
											value: word,
											or: true,
										},
										{
											key: 'RoomName',
											operator: '=~',
											value: word,
											or: true,
										},
									]),
							},
						},
						{
							value: (item) => (item.isLocked ? 'Locked' : ' '),
							render: (item) => <>{item.isLocked ? 'Locked' : ' '}</>,
							showHeader: true,
							headerText: 'Locked',
							sortColumnName: 'testIsLocked',
							filterType: 'dropdown',
							filterOptions: {
								columnName: 'testIsLocked',
								options: [
									{ value: 'Locked', text: 'Locked' },
									{ value: ' ', text: 'Not locked' },
								],
							},
						},
						{
							value: (item) => item.testReport?.testReportName || '',
							render: (item) => <>{item.testReport?.testReportName}</>,
							showHeader: true,
							headerText: 'Cert. No.',
							sortColumnName: 'testReportName',
							filterType: 'string',
							filterOptions: {
								columnName: 'testReportName',
							},
						},
						{
							value: (item) => (item.isLocked ? (item.testForcePassed || item.passed ? 'Pass' : 'Fail') : '-'),
							render: (item) => <>{item.isLocked ? (item.testForcePassed || item.passed ? 'Pass' : 'Fail') : '-'}</>,
							showHeader: true,
							headerText: 'Result',
							sortColumnName: 'testPassed',
							filterType: 'dropdown',
							filterOptions: {
								columnName: 'testPassed',
								options: [
									{ value: 'Pass', text: 'Pass' },
									{ value: 'Fail', text: 'Fail' },
									{ value: '-', text: '-' },
								],
							},
						},
					]}
					defaultSort={{ column: 'testCreatedTs', order: 'DSC' }}
					isLoading={pageStatus === 'Loading'}
				/>
			</div>
			<Modal show={show} onHide={handleClose} centered size="xl">
				<Modal.Header closeButton>
					<Modal.Title>Select asset to test</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row style={{ marginBottom: '20px' }}>
						<Col>
							<DropdownSelect
								name="accountID"
								label="Institution"
								value={account?.accountName || ''}
								onChange={(value, account) => {
									setAccount(account)
									getAssets(value)
								}}
								disabled={accounts?.length === 1}
								id="accountID"
								variant="secondary"
								showSearch={true}
								searchPlaceholder="Filter"
								options={accounts?.map((account) => ({ ...account, value: account.accountID, text: account.accountName })) || []}
							/>
						</Col>
					</Row>
					{assets && (
						<Listing
							name="Asset"
							namePlural="Assets"
							list={assets || []}
							getIDFunc={(item) => item.assetID}
							columns={[
								{
									value: (item) => item.assetUnitReference,
									render: (item) => (
										<Button onClick={() => props.history.push(`/test/new/${item.assetID}`)} disabled={!item.assetActive}>
											Create test
										</Button>
									),
									showHeader: false,
								},
								{
									value: (item) => item.assetUnitReference,
									render: (item) => <>{item.assetUnitReference}</>,
									showHeader: true,
									headerText: 'Unit Ref',
									sortColumnName: 'assetUnitReference',
									filterType: 'string',
									filterOptions: {
										columnName: 'assetUnitReference',
									},
								},
								{
									value: (item) => item.assetTypeID,
									render: (item) => <>{item.assetTypeName}</>,
									showHeader: true,
									headerText: 'Type',
									sortColumnName: 'assetTypeName',
									filterType: 'dropdown',
									filterOptions: {
										columnName: 'assetTypeName',
										options: assetTypes,
									},
								},
								{
									value: (item) => item.locationName,
									render: (item) => <>{item.locationName}</>,
									showHeader: true,
									headerText: 'Location',
									sortColumnName: 'locationName',
									filterType: 'string',
									filterOptions: {
										columnName: 'locationName',
									},
								},
								{
									value: (item) => item.buildingName,
									render: (item) => <>{item.buildingName}</>,
									showHeader: true,
									headerText: 'Building',
									sortColumnName: 'buildingName',
									filterType: 'string',
									filterOptions: {
										columnName: 'buildingName',
									},
								},
								{
									value: (item) => item.roomName,
									render: (item) => <>{item.roomName}</>,
									showHeader: true,
									headerText: 'Room',
									sortColumnName: 'roomName',
									filterType: 'string',
									filterOptions: {
										columnName: 'roomName',
									},
								},
								{
									value: (item) => item.assetClientRef,
									render: (item) => <>{item.assetClientRef}</>,
									showHeader: true,
									headerText: 'Client Ref',
									sortColumnName: 'assetClientRef',
									filterType: 'string',
									filterOptions: {
										columnName: 'assetClientRef',
									},
								},
								{
									value: (item) => item.accountName,
									render: (item) => <>{item.accountName}</>,
									showHeader: true,
									headerText: 'Institution',
									sortColumnName: 'accountName',
									filterType: 'string',
									filterOptions: {
										columnName: 'accountName',
									},
								},
							]}
							defaultSort={{ column: 'assetUnitReference', order: 'ASC' }}
							isLoading={pageStatus === 'Loading'}
						/>
					)}
					{!assets && account && <Loading spinner />}
				</Modal.Body>
			</Modal>
		</>
	)
}

export { ScreensTest }
