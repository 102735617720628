import React from 'react'
import { Spinner } from 'react-bootstrap'

interface LoadingProps {
	size?: number
	spinner?: boolean
	noBorder?: boolean
}

export const Loading = (props: LoadingProps) => {
	if (props.spinner) {
		return (
			<Spinner
				animation="border"
				role="status"
				style={
					props.noBorder
						? {
								margin: '0px',
								display: 'block',
								height: props.size ? `${props.size}rem` : '2rem',
								width: props.size ? `${props.size}rem` : '2rem',
						  }
						: {
								display: 'block',
								height: props.size ? `${props.size}rem` : '2rem',
								width: props.size ? `${props.size}rem` : '2rem',
						  }
				}
			>
				<span className="sr-only">Loading...</span>
			</Spinner>
		)
	} else {
		return <div></div>
	}
}
