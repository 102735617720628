import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios'
import * as Config from '../constants/config'
import { AuthState } from '../App.d'
import { MessageAction } from '../components/UI/Messages/Message'

const setConfig = (authState: AuthState): AxiosRequestHeaders => ({
	Authorization: authState.apiToken!,
})

const getUrl = (endpoint: string): string => `${process.env.NODE_ENV !== 'production' ? 'http' : 'https'}://${Config.ApiConfig.BaseUrl}/${endpoint}`

const handleRefreshTokens = <T>(args: AxiosRequestConfig<T>) =>
	new Promise<AxiosResponse<T>>((resolve, reject) => {
		axios(args)
			.catch((error: AxiosError<T>) => {
				if (error.response && error.response.status === 401) {
					window.location.href = '/login?redirect=' + encodeURIComponent(window.location.pathname)
				}
				reject(error)
			})
			.then((value: AxiosResponse<T> | void) => {
				if (value) {
					resolve(value)
				}
			})
	})

const get = <T>(endpoint: string, authState?: AuthState) =>
	handleRefreshTokens<T>({ method: 'get', url: getUrl(endpoint), headers: authState ? setConfig(authState) : undefined })

// eslint-disable-next-line
const post = <T>(endpoint: string, body: any, authState: AuthState) =>
	handleRefreshTokens<T>({ method: 'post', url: getUrl(endpoint), data: body, headers: setConfig(authState) })

// eslint-disable-next-line
const put = <T>(endpoint: string, body: any, authState: AuthState) =>
	handleRefreshTokens<T>({ method: 'put', url: getUrl(endpoint), data: body, headers: setConfig(authState) })

const del = <T>(endpoint: string, authState: AuthState) => handleRefreshTokens<T>({ method: 'delete', url: getUrl(endpoint), headers: setConfig(authState) })

type Request = {
	errors?: AxiosError
	success: boolean
	message?: string
}

interface HandleRequestParams<T> {
	successFunction?: (data: T) => void | Promise<void>
	failedFunction?: (data: T) => void | Promise<void>
	errorFunction?: (err: AxiosError<T>) => void | Promise<void>
	passFailedErrorMessage?: boolean
	setMessageFunction?: (action: MessageAction) => void
	messageAction?: 'deleting' | 'creating' | 'editing' | 'inviting'
	messageObject?: string
}

const handleRequest = async <T extends Request>(requestFunction: () => Promise<AxiosResponse<T>>, params: HandleRequestParams<T>) => {
	try {
		const req = await requestFunction()

		if (req.data.success) {
			if (params.setMessageFunction) {
				if (!params.messageAction || !params.messageObject) {
					console.error('You need to specify messageAction and messageObject')
				}
				params.setMessageFunction({
					type: 'add',
					data: {
						severity: 'success',
						message: `Success ${params.messageAction} the ${params.messageObject}`,
						dismissible: true,
						timeout: 5000,
					},
				})
			}
			if (params.successFunction) {
				await params.successFunction(req.data)
			}
		} else {
			if (params.setMessageFunction) {
				const message = req?.data?.message && params.passFailedErrorMessage ? `: ${req?.data?.message}` : null
				params.setMessageFunction({
					type: 'add',
					data: {
						severity: 'danger',
						message: `Failed ${params.messageAction} the ${params.messageObject}${message ? message : ''}`,
						dismissible: true,
						timeout: 5000,
					},
				})
			}
			if (params.failedFunction) {
				await params.failedFunction(req.data)
			}
		}
		// eslint-disable-next-line
	} catch (err: any) {
		const message = err?.response?.data?.message && params.passFailedErrorMessage ? `: ${err?.response?.data?.message}` : null
		if (params.setMessageFunction) {
			params.setMessageFunction({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Failed ${params.messageAction} the ${params.messageObject}${message ? message : ''}`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
		if (params.errorFunction) {
			await params.errorFunction(err)
		}
	}
}

export { del, get, post, put, handleRequest }
export type { Request }
