import React, { useContext } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { RouteComponentProps, StaticContext } from 'react-router'
import { Link } from 'react-router-dom'
import { Formik, FormikErrors } from 'formik'
import * as yup from 'yup'

import { DropdownSelect, DropdownSelectOption } from '../../components/Forms/Dropdown'
import { Card } from '../../components/UI/Card/Card'
import { DeleteButton } from '../../components/UI/Form/DeleteButton'
import { FormText } from '../../components/UI/Form/Text'
import { Header } from '../../components/UI/Header/Header'
import { HistoryCardBottom } from '../../components/UI/HistoryCardBottom/HistoryCardBottom'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { DownloadLink } from '../../components/UI/Uploader/DownloadLink'
import { Uploader, uploadToSignedUrl } from '../../components/UI/Uploader/Uploader'

import { AppContext } from '../../App'
import { PageStatus } from '../../types/PageStatus'
import { PageBackState } from '../../types/PageBackState'
import * as Request from '../../utilities/request'
import { getUrlSearchParam } from '../../utilities/url'
import generateUuid from '../../utilities/uuid'
import { AssetType, AssetTypeResult } from '../../../../back-end/common/assetType'
import { Competency, CompetencyResult } from '../../../../back-end/common/competency'
import { DocumentResult } from '../../../../back-end/common/document'
import { User, UserResult } from '../../../../back-end/common/user'
import { Loading } from '../../components/UI/Loading/Loading'
import { canWriteCompetency } from '../../components/Competency/Competency'
interface ScreensCompetencyDetailsMatchParams {
	id: string
}

interface ScreensCompetencyDetailsStateParams extends PageBackState {}

type CompetencyErrorMessages = FormikErrors<Partial<Competency>>

const competencyValidationSchema = yup.object().shape({
	type: yup.string().oneOf(['lock', 'test']).required(),
	assetTypeID: yup.string().required('Asset Type is required'),
	userID: yup.string().required('User is required'),
	fromDate: yup.string().required('A from date is required'),
	toDate: yup.string().required('A to date is required'),
	fileS3Path: yup.string().optional().nullable(),
	fileName: yup.string().optional().nullable(),
})

const newCompetency = (competency: Partial<Competency>): Partial<Competency> => ({
	assetTypeID: competency.assetTypeID || '',
	userID: competency.userID || '',
	fromDate: competency.fromDate || '',
	toDate: competency.toDate || '',
	fileS3Path: competency.fileS3Path || null,
	fileName: competency.fileName || null,
})

const ScreensCompetencyDetails = (
	props: RouteComponentProps<ScreensCompetencyDetailsMatchParams, StaticContext, ScreensCompetencyDetailsStateParams | undefined>
) => {
	const context = useContext(AppContext)
	const competencyID = props.match!.params.id.split('?')[0]
	const defaultUserID = getUrlSearchParam(props.location.search, 'user') || ''
	const defaultAssetTypeID = getUrlSearchParam(props.location.search, 'assettype') || ''
	const isNew = competencyID === 'new'

	const [messages, setMessages] = useMessageReducer([])
	const [assetTypes, setAssetTypes] = React.useState<AssetType[]>([])
	const [users, setUsers] = React.useState<User[]>([])
	const [competency, setCompetency] = React.useState<Competency | null>(
		isNew ? (newCompetency({ assetTypeID: defaultAssetTypeID, userID: defaultUserID }) as Competency) : null
	)

	const [isUploading, setIsUploading] = React.useState<boolean>(false)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>(isNew ? 'Editing' : 'Loading')

	React.useEffect(() => {
		const getCompetency = async () => {
			const competencyReq = await Request.get<CompetencyResult>(`competency?where=competencyID==${competencyID}`, context.appState.authState)
			setCompetency(competencyReq.data.competencies[0])
			setPageStatus('Ready')
		}

		const getData = async () => {
			const [masterAssetTypeReq, orphanAssetTypeReq, userReq] = await Promise.all([
				Request.get<AssetTypeResult>(
					`assettype?where=AccountID==${context.appState.userPermissions.accounts[0].accountID}&where=ParentAssetTypeID!=NULL`,
					context.appState.authState
				),
				Request.get<AssetTypeResult>(`assettype?where=ParentAssetTypeID==NULL`, context.appState.authState),
				Request.get<UserResult>(`user`, context.appState.authState),
			])
			setAssetTypes([...masterAssetTypeReq.data.assetTypes, ...orphanAssetTypeReq.data.assetTypes])
			setUsers(userReq.data.users)
		}

		if (context.appState.authState.isLoggedIn && !isNew) {
			getCompetency()
		}
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, []) // eslint-disable-line

	const onCompetencyCreate = async (competency: Partial<Competency>) => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.post<CompetencyResult>(`competency`, competency, context.appState.authState), {
			successFunction: (data) => props.history.push(`/competency/${data.competencies[0].competencyID}`, props.location.state),
			setMessageFunction: setMessages,
			messageAction: 'creating',
			messageObject: 'competency',
		})
		setPageStatus('Ready')
	}

	const onCompetencyEdit = async (competency: Partial<Competency>) => {
		setPageStatus('Submitting')
		await Request.handleRequest(
			() => Request.put<CompetencyResult>(`competency?where=competencyID==${competencyID}`, competency, context.appState.authState),
			{
				setMessageFunction: setMessages,
				messageAction: 'editing',
				messageObject: 'competency',
			}
		)
		setPageStatus('Ready')
	}

	const onCompetencyDelete = async (competencyID: string) => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.del<CompetencyResult>(`competency?where=competencyID==${competencyID}`, context.appState.authState), {
			successFunction: () => {
				if (props.location.state && props.location.state.backPath) {
					props.history.push(props.location.state.backPath)
				}
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'competency',
			passFailedErrorMessage: true,
		})
		setPageStatus('Ready')
	}

	return (
		<div className="page">
			<Messages messages={messages} updateMessage={setMessages} />
			{props.location.state && props.location.state.backPath && (
				<Row>
					<Col>
						<Link className="breadcrumb-back" to={props.location.state.backPath}>{`< Back to ${props.location.state.backName}`}</Link>
					</Col>
				</Row>
			)}

			<Header title={'Competency Details'} subtitle={'View competency details'} />

			{competency ? (
				<div>
					<Formik
						initialValues={competency}
						validationSchema={competencyValidationSchema}
						onSubmit={isNew ? onCompetencyCreate : onCompetencyEdit}
						enableReintialize
					>
						{({ handleSubmit, errors, values, setValues }) => (
							<Card
								title={'Competency Information'}
								collapsible={false}
								headerComponent={() =>
									pageStatus !== 'Loading' && competency && canWriteCompetency(context.appState.userPermissions.roleID) ? (
										pageStatus === 'Ready' ? (
											<Row className="justify-content-end">
												<Col sm={'auto'}>
													<Button className="btn btn-outline-dark span-bold" onClick={() => setPageStatus('Editing')}>
														Edit Competency
													</Button>
												</Col>
											</Row>
										) : (
											<Row className="justify-content-end">
												<Col sm={'auto'}>
													<Button
														className="btn btn-secondary"
														onClick={() => handleSubmit()}
														disabled={pageStatus === 'Submitting' || isUploading}
													>
														{isUploading ? 'Preparing doc' : 'Save'}
													</Button>
												</Col>
												<Col sm={'auto'}>
													<Button
														className="btn btn-secondary"
														onClick={() => setPageStatus('Ready')}
														disabled={pageStatus === 'Submitting' || isUploading}
													>
														Cancel
													</Button>
												</Col>
												<Col sm={'auto'}>
													{competency.competencyID !== undefined && pageStatus !== 'Submitting' ? (
														<DeleteButton id={competency.competencyID} onClick={onCompetencyDelete} />
													) : (
														<></>
													)}
												</Col>
											</Row>
										)
									) : (
										<></>
									)
								}
							>
								<CompetencyDetails
									competency={values}
									assetTypes={assetTypes}
									users={users}
									pageStatus={pageStatus}
									errors={errors}
									onChange={setValues}
									setIsUploading={setIsUploading}
								/>

								<>
									{isNew && competency && competency.create && competency.modified && (
										<HistoryCardBottom
											historyReferenceID={competency.competencyID!}
											created={competency.create}
											modified={competency.modified}
											backState={{
												backPath: `/competency/${competency.competencyID!}`,
												backName: `Competency`,
											}}
										/>
									)}
								</>
							</Card>
						)}
					</Formik>
				</div>
			) : null}
		</div>
	)
}

interface CompetencyDetailsProps {
	competency: Competency
	assetTypes: AssetType[]
	users: User[]
	pageStatus: PageStatus
	errors?: CompetencyErrorMessages
	onChange: (values: Competency) => void
	setIsUploading: (value: boolean) => void
}

const CompetencyDetails = (props: CompetencyDetailsProps) => {
	const context = React.useContext(AppContext)
	const readOnly = props.pageStatus !== 'Editing'

	const onChange = (competency: Partial<Competency>) => {
		props.onChange({ ...props.competency, ...competency })
	}

	const assetTypeOptions = getAssetTypeOptions(props.assetTypes)

	return (
		<div>
			<Row>
				<Col>
					<DropdownSelect
						name="type"
						label="Competency"
						value={props.competency.type ? props.competency.type[0].toUpperCase() + props.competency.type.substring(1) : ''}
						onChange={(value: 'test' | 'lock') => onChange({ type: value })}
						disabled={readOnly}
						id="type"
						variant="secondary"
						showSearch={false}
						searchPlaceholder="Filter"
						options={[
							{ value: 'test', text: 'Test' },
							{ value: 'lock', text: 'Lock' },
						]}
						feedback={props.errors?.type}
						isInvalid={!!props.errors?.type}
					/>
				</Col>
				<Col>
					<DropdownSelect
						name="userID"
						label="User"
						value={props.users.find((user) => user.userID === props.competency.userID)?.fullName || ''}
						onChange={(value) => onChange({ userID: value })}
						disabled={readOnly}
						id="userID"
						variant="secondary"
						showSearch={true}
						searchPlaceholder="Filter"
						options={props.users.map((u) => ({ ...u, value: u.userID, text: u.fullName }))}
						feedback={props.errors?.userID}
						isInvalid={!!props.errors?.userID}
					/>
				</Col>
				<Col>
					<DropdownSelect
						name="assetTypeID"
						label="Asset Type"
						value={
							props.assetTypes.find(
								(assetType) =>
									assetType.assetTypeID === props.competency.assetTypeID || assetType.parentAssetTypeID === props.competency.assetTypeID
							)?.name || ''
						}
						onChange={(value: string) => onChange({ assetTypeID: value })}
						disabled={readOnly}
						id="assetTypeID"
						variant="secondary"
						showSearch={true}
						searchPlaceholder="Filter"
						options={assetTypeOptions}
						feedback={props.errors?.assetTypeID}
						isInvalid={!!props.errors?.assetTypeID}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormText
						name={'fromDate'}
						label="From"
						type="date"
						value={props.competency.fromDate?.slice(0, 10)}
						onChange={(e) => onChange({ [e.target.name]: e.target.value })}
						plaintext={readOnly}
						disabled={readOnly}
						feedback={props.errors?.fromDate}
						isInvalid={!!props.errors?.fromDate}
					/>
				</Col>
				<Col>
					<FormText
						name={'toDate'}
						label="To"
						type="date"
						value={props.competency.toDate?.slice(0, 10)}
						onChange={(e) => onChange({ [e.target.name]: e.target.value })}
						plaintext={readOnly}
						disabled={readOnly}
						feedback={props.errors?.toDate}
						isInvalid={!!props.errors?.toDate}
					/>
				</Col>
				<Col>
					<Form.Group controlId="fileS3Path">
						<Form.Label>File</Form.Label>
						{!readOnly && (
							<Uploader
								name="fileS3Path"
								multiple={false}
								accept="application/pdf"
								maxCount={1}
								defaultFileList={
									props.competency.fileS3Path && props.competency.fileName
										? [
												{
													name: props.competency.fileName,
													percent: 100,
													status: 'done',
													thumbUrl: '',
													uid: props.competency.competencyID,
													url: props.competency.fileS3Path,
												},
										  ]
										: []
								}
								onRemove={() => {
									onChange({ fileS3Path: null, fileName: null })
								}}
								action={async (file) => {
									// get s3 url
									const s3Path = `competency/${generateUuid()}.pdf`
									const req = await Request.post<DocumentResult>(
										'document',
										{ type: 'application/pdf', s3Location: s3Path, operation: 'putObject' },
										context.appState.authState
									)
									// set field
									props.setIsUploading(true)
									onChange({ fileS3Path: s3Path, fileName: file.name })
									return req.data.file.signedUrl!
								}}
								customRequest={async (info) => {
									if (typeof info.file !== 'string') {
										const res = await uploadToSignedUrl(info.action, info.file, info.filename)
										info.onSuccess && info.onSuccess(res)
									}
									props.setIsUploading(false)
								}}
								itemRender={(_origin, _file, _fileList, actions) => (
									<>
										{_file.status === 'uploading' && <Loading size={1} />}
										<DownloadLink
											path={props.competency.fileS3Path!}
											fileType="application/pdf"
											name={props.competency.fileName!}
											icon="File"
											onDelete={actions.remove}
										/>
									</>
								)}
							/>
						)}
						{readOnly && props.competency.fileS3Path && props.competency.fileName && (
							<DownloadLink path={props.competency.fileS3Path} fileType="application/pdf" name={props.competency.fileName} icon="File" />
						)}
						{readOnly && !props.competency.fileS3Path && 'No File'}
					</Form.Group>
				</Col>
			</Row>
		</div>
	)
}

const getAssetTypeOptions = (assetTypes: AssetType[]) => {
	return assetTypes
		.reduce((options, at) => {
			const isFundamentalType = at.parentAssetTypeID
			if (!isFundamentalType) {
				return [
					...options,
					{
						...at,
						value: at.assetTypeID,
						text: `${at.name} (${at.accountName})`,
					},
				]
			}
			// if it's a copied from admin account then we want to remove copies and use the parent asset type as the value
			const atIndex = options.findIndex((opt) => opt.value === at.parentAssetTypeID)
			if (atIndex < 0) {
				return [
					...options,
					{
						...at,
						value: at.parentAssetTypeID,
						text: at.name,
					},
				]
			} else {
				return options
			}
			// eslint-disable-next-line
		}, [] as DropdownSelectOption<AssetType, any>[])
		.sort((a, b) => a.text.localeCompare(b.text))
}

export { ScreensCompetencyDetails }
