import React, { useContext } from 'react'
import { Badge, Button, Col, Row } from 'react-bootstrap'
import { RouteComponentProps, StaticContext } from 'react-router'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'

import { AppContext } from '../../App'
import { Card } from '../../components/UI/Card/Card'
import { Header } from '../../components/UI/Header/Header'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { InstitutionDetails } from '../../components/Account/InstitutionDetails'
import { AccountAssetTypeList } from '../../components/Account/AccountAssetTypeList'
import { AccountEntityDetails } from '../../components/Account/AccountEntityDetails'
import { AccountLocationList } from '../../components/Account/AccountLocationList'
import { Loading } from '../../components/UI/Loading/Loading'

import { formatDateTime } from '../../utilities/formatDate'
import * as Request from '../../utilities/request'
import { PageStatus } from '../../types/PageStatus'
import * as EntityType from '../../constants/entityType'
import { Account, AccountResult } from '../../../../back-end/common/account'
import { EntityResult, EntityWithAccountContext } from '../../../../back-end/common/entity'
import { AssetType, AssetTypeResult } from '../../../../back-end/common/assetType'
import { Location, LocationResult } from '../../../../back-end/common/location'
import { PageBackState } from '../../types/PageBackState'
import { Asset, AssetResult } from '../../../../back-end/common/asset'
import { AccountEntityResult } from '../../../../back-end/common/accountEntity'
import { AccessResult } from '../../../../back-end/common/access'
import { canWriteAccount, canWriteAsset, canWriteLocation } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'

type ErrorMessages<T> = {
	[Property in keyof T]: string
}

type AccountErrorMessages = ErrorMessages<
	Partial<Pick<EntityWithAccountContext, 'entityID' | 'entityName' | 'entityABN' | 'entityTypeID' | 'entityABN' | 'entityWebsite' | 'accountReadableID'>>
>
interface ScreensLocationStateParams extends PageBackState {}

const instituteValidationSchema = yup.object().shape({
	entityID: yup.string().optional(),
	entityName: yup.string().required('Institution name is required.'),
	entityABN: yup.string().optional().nullable(),
	entityWebsite: yup.string().optional().nullable(),
	entityNotes: yup.string().optional().nullable(),
	accountReadableID: yup.string().required('Shortcode is required'),
})

interface ScreensAccountMatchParams {
	id: string
}

type Institute = Pick<
	EntityWithAccountContext,
	'entityID' | 'entityName' | 'entityABN' | 'entityTypeID' | 'entityABN' | 'entityWebsite' | 'accountEntityIsSubscriber'
> &
	Pick<Account, 'accountID' | 'accountReadableID' | 'accountNotes' | 'accountSendManagerEmail' | 'accountHasPublicUrl' | 'accountExcludedManagers'>
const newAccount = (entity: Partial<EntityWithAccountContext>): Institute => ({
	entityID: '',
	entityName: entity.entityName || '',
	entityTypeID: EntityType.Institution,
	entityABN: entity.entityABN || '',
	entityWebsite: entity.entityWebsite || '',
	accountID: '',
	accountReadableID: '',
	accountEntityIsSubscriber: false,
	accountNotes: '',
	accountSendManagerEmail: false,
	accountHasPublicUrl: false,
	accountExcludedManagers: [],
})

const ScreensAccountDetails = (props: RouteComponentProps<ScreensAccountMatchParams, StaticContext, ScreensLocationStateParams>) => {
	const context = useContext(AppContext)
	const accountID = props.match!.params.id
	const isNew = accountID === 'new'

	const [messages, setMessages] = useMessageReducer([])
	const [account, setAccount] = React.useState<Account | null>(null)
	const [locations, setLocations] = React.useState<Location[] | null>(null)
	const [assetTypes, setAssetTypes] = React.useState<AssetType[] | null>(null)
	const [assets, setAssets] = React.useState<Asset[] | null>(null)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>(isNew ? 'Editing' : 'Loading')

	React.useEffect(() => {
		const getData = async () => {
			const [account, assetType, location, asset] = await Promise.all([
				Request.get<AccountResult>(`account?where=accountID==${accountID}`, context.appState.authState),
				Request.get<AssetTypeResult>(`assettype?where=accountID==${accountID}`, context.appState.authState),
				Request.get<LocationResult>(`location?where=accountID==${accountID}`, context.appState.authState),
				Request.get<AssetResult>(`asset?where=accountID==${accountID}`, context.appState.authState),
			])

			setAccount(account.data.accounts[0])
			setAssetTypes(assetType.data.assetTypes)
			setLocations(location.data.locations)
			setAssets(asset.data.assets)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn && !isNew) {
			getData()
		}
	}, [isNew, accountID]) // eslint-disable-line

	const onInstituteEdit = async (institution: Institute) => {
		setPageStatus('Submitting')
		if (isNew) {
			await Request.handleRequest(() => Request.post<AccountResult>(`account`, institution, context.appState.authState), {
				successFunction: (data) => props.history.replace(`/institution/${data.accounts[0].accountID}`),
				setMessageFunction: setMessages,
				messageAction: 'creating',
				messageObject: 'institution',
			})
		} else {
			await Request.handleRequest(
				async () => {
					const [entityResult] = await Promise.all([
						Request.put<EntityResult>(`entity?where=entityID==${institution.entityID}`, institution, context.appState.authState),
						Request.put<AccountResult>(`account?where=accountID==${accountID}`, institution, context.appState.authState),
					])
					return entityResult
				},
				{
					successFunction: (data) => {
						if (account && data.entities.length > 0) {
							setAccount({ ...account, accountName: data.entities[0].entityName })
						}
					},
					setMessageFunction: setMessages,
					messageAction: 'editing',
					messageObject: 'institution',
				}
			)
		}
		setPageStatus('Ready')
	}

	const onAccountEntityEdit = async (entityID: string, entity: Partial<EntityWithAccountContext>) => {
		setPageStatus('Submitting')
		await Request.handleRequest(
			() =>
				Request.put<AccountEntityResult>(`accountentity?where=accountID==${accountID}&where=entityID==${entityID}`, entity, context.appState.authState),
			{
				successFunction: () => {
					if (account) {
						const newAccountEntities = account.entities.map((e) => {
							if (e.entityID === entityID) {
								return entity
							}
							return e
						})
						setAccount({ ...account, entities: newAccountEntities })
					}
				},
				setMessageFunction: setMessages,
				messageAction: 'editing',
				messageObject: 'entity',
			}
		)
		setPageStatus('Ready')
	}

	const updateSubscriber = async (entityID: string, makeSub: boolean) => {
		setPageStatus('Submitting')
		await Request.handleRequest(
			async () => {
				const accountEntity = await Request.put<AccountEntityResult>(
					`accountentity?where=accountID==${accountID}&where=entityID==${entityID}`,
					{
						accountEntityIsSubscriber: makeSub,
						accountEntityCanWriteAssets: makeSub,
						accountEntityCanWriteLocations: makeSub,
						accountEntityCanWriteTestQuestions: makeSub,
						accountEntityCanWriteAppSettings: makeSub,
						accountEntityCanWriteEntities: makeSub,
						accountEntityHideTcDetails: 0,
						accountEntityHideInstUserDetails: 0,
					},
					context.appState.authState
				)
				// give full access if making them a subscriber
				if (accountEntity.data.accountEntities.length > 0 && makeSub) {
					const accountEntityID = accountEntity.data.accountEntities[0].accountEntityID
					await Request.del<AccessResult>(`access?where=accountEntityID==${accountEntityID}`, context.appState.authState)
					await Request.post<AccessResult>(`access`, { accountID, accountEntityID: accountEntityID }, context.appState.authState)
				}

				return accountEntity
			},
			{
				successFunction: () => {
					if (account) {
						const newAccountEntities: Partial<EntityWithAccountContext>[] = account.entities.map((e) => ({
							...e,
							accountEntityIsSubscriber: makeSub && e.entityID === entityID,
							accountEntityCanWriteAssets: makeSub && e.entityID === entityID,
							accountEntityCanWriteLocations: makeSub && e.entityID === entityID,
							accountEntityCanWriteTestQuestions: makeSub && e.entityID === entityID,
							accountEntityCanWriteAppSettings: makeSub && e.entityID === entityID,
							accountEntityCanWriteEntities: makeSub && e.entityID === entityID,
							accountEntityHideTcDetails: makeSub && e.entityID === entityID,
							accountEntityHideInstUserDetails: makeSub && e.entityID === entityID,
							access: e.entityID === entityID ? [{ accountID, accountEntityID: e.accountEntityID }] : e.access,
						}))
						setAccount({ ...account, entities: newAccountEntities })
					}
				},
				setMessageFunction: setMessages,
				messageAction: 'editing',
				messageObject: 'subscriber',
			}
		)
		setPageStatus('Ready')
	}

	const removeEntityFromAccount = async (entityID: string, entity: Partial<EntityWithAccountContext>, accountEntityID: string) => {
		try {
			const req = await Request.del<AccountEntityResult>(
				`accountentity?where=accountID==${accountID}&where=entityID==${entityID}&where=accountEntityID==${accountEntityID}`,
				context.appState.authState
			)

			if (req.data.success && account) {
				const newAccountEntities = account.entities.filter((entity) => entity.entityID !== entityID)
				setAccount({ ...account, entities: newAccountEntities })
				setMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: `Successfully removed the entity`,
						dismissible: true,
						timeout: 5000,
					},
				})
			} else {
				setMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `An error occurred while removing the entity`,
						dismissible: true,
						timeout: 5000,
					},
				})
			}
		} catch (err) {
			setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `An error occurred while removing the entity`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
	}

	const institute = isNew
		? newAccount({})
		: ({
				...account?.entities.find((entity) => entity.entityTypeID === EntityType.Institution),
				accountID: account?.accountID,
				accountReadableID: account?.accountReadableID,
				accountNotes: account?.accountNotes,
				accountSendManagerEmail: account?.accountSendManagerEmail,
				accountHasPublicUrl: account?.accountHasPublicUrl,
				accountExcludedManagers: account?.accountExcludedManagers,
		  } as Institute)
	const myAccount = context.appState.userPermissions.accounts.find((acc) => acc.accountID === accountID)
	const testingContractors =
		myAccount && myAccount.hideTcDetails
			? account?.entities.filter(
					(entity) => entity.entityID === context.appState.userPermissions.entityID && entity.entityTypeID === EntityType.TestingContractor
			  ) || [] // just get my entity
			: account?.entities.filter((entity) => entity.entityTypeID === EntityType.TestingContractor) || []
	const facilities = account?.entities.filter((entity) => entity.entityTypeID === EntityType.FacilitiesCompany) || []
	const fcOperation = context.appState.userPermissions && context.appState.userPermissions.entityTypeID === EntityType.TestingContractor ? 'new' : 'invite'

	return (
		<div className="page">
			<Messages messages={messages} updateMessage={setMessages} />

			{props.location.state && props.location.state.backPath && (
				<Row>
					<Col>
						<Link className="breadcrumb-back" to={props.location.state.backPath}>{`< Back to ${props.location.state.backName}`}</Link>
					</Col>
				</Row>
			)}

			<Row>
				<Col>
					<Header title={account ? account.accountName : ''} subtitle={''} />
				</Col>
				{!isNew && (
					<Col sm={'auto'} style={{ paddingTop: '40px' }}>
						<span className="span-bold">Status:</span> {account ? (account.accountActive ? 'Active' : 'Inactive') : ''}{' '}
						<span className={`dot ${account ? (account.accountActive ? 'activeGreen' : 'activeRed') : 'grey'}`}></span>
						<span className="span-grey">
							(Last Modified {account ? formatDateTime({ date: account.modifiedTs, format: 'DateAndTimeAndTimezone' }) : ''})
						</span>
					</Col>
				)}
			</Row>

			{institute && (isNew || institute.entityID) ? (
				<Formik initialValues={institute} validationSchema={instituteValidationSchema} onSubmit={onInstituteEdit} enableReinitialize>
					{({ handleSubmit, errors, values, setValues }) => (
						<Card
							title={isNew ? 'New Institution' : 'Institution Details'}
							collapsible={true}
							headerComponent={() =>
								pageStatus !== 'Loading' && (account || isNew) ? (
									<Row>
										{values.accountEntityIsSubscriber && (
											<Col xs="auto">
												<Badge bg="warning">Subscriber</Badge>
											</Col>
										)}
										{context.appState.userPermissions &&
											context.appState.userPermissions.entityTypeID === EntityType.Admin &&
											!values.accountEntityIsSubscriber &&
											account?.entities.every((entity) => !entity.accountEntityIsSubscriber) &&
											!isNew && (
												<Col xs="auto">
													<Badge
														bg="secondary"
														onClick={() => updateSubscriber(values.entityID, true)}
														role="button"
														className="cursor-pointer"
													>
														Make Subscriber
													</Badge>
												</Col>
											)}
										{context.appState.userPermissions &&
											context.appState.userPermissions.entityTypeID === EntityType.Admin &&
											values.accountEntityIsSubscriber &&
											values.entityID &&
											!isNew && (
												<Col xs="auto">
													<Badge
														bg="secondary"
														onClick={() => updateSubscriber(values.entityID, false)}
														role="button"
														className="cursor-pointer"
													>
														Revoke Subscriber
													</Badge>
												</Col>
											)}
										<Col></Col>
										{canWriteAccount(appStateToPermissionObject(context.appState)) &&
											(pageStatus === 'Ready' ? (
												<Col sm={'auto'}>
													<Button className="btn btn-outline-dark span-bold" onClick={() => setPageStatus('Editing')}>
														Edit institution
													</Button>
												</Col>
											) : (
												<Row className="justify-content-end">
													<Col sm={'auto'}>
														<Button
															className="btn btn-secondary"
															onClick={() => handleSubmit()}
															disabled={pageStatus === 'Submitting'}
														>
															{pageStatus === 'Submitting' ? <Loading spinner size={1} noBorder /> : 'Save'}
														</Button>
													</Col>
													<Col sm={'auto'}>
														<Button
															className="btn btn-secondary"
															onClick={() => (isNew ? props.history.push('/institution') : setPageStatus('Ready'))}
															disabled={pageStatus === 'Submitting'}
														>
															Cancel
														</Button>
													</Col>
												</Row>
											))}
									</Row>
								) : (
									<></>
								)
							}
						>
							<InstitutionDetails
								institute={values}
								pageStatus={pageStatus}
								showSeeMore={
									pageStatus === 'Ready' &&
									(context.appState.userPermissions.entityTypeID === EntityType.Admin || (myAccount && !myAccount.hideInstUserDetails))!
								}
								onChange={(newValue) => {
									setValues(newValue)
								}}
								history={props.history}
								errors={errors}
								backState={{ backPath: props.location.pathname, backName: account?.accountName || 'Institution' }}
							/>
						</Card>
					)}
				</Formik>
			) : null}

			{!isNew && locations && (
				<Card
					title={'Locations'}
					collapsible={true}
					headerComponent={() => (
						<>
							{pageStatus === 'Ready' &&
								account &&
								canWriteLocation(appStateToPermissionObject(context.appState), { accountID: account.accountID }) && (
									<Row className="justify-content-end">
										<Col sm={'auto'}>
											<Button
												className="btn btn-outline-dark span-bold"
												onClick={() =>
													props.history.push(`/location/new?account=${accountID}`, {
														backPath: props.location.pathname,
														backName: institute?.entityName || 'Institution',
													})
												}
											>
												Add location
											</Button>
										</Col>
									</Row>
								)}
						</>
					)}
				>
					<AccountLocationList
						locations={locations}
						backState={{ backPath: props.location.pathname, backName: institute?.entityName || 'Institution' }}
					/>
				</Card>
			)}

			{!isNew && account && (
				<Card
					title={'Facilities Companies'}
					collapsible={true}
					headerComponent={() => (
						<>
							{pageStatus === 'Ready' && account && canWriteAccount(appStateToPermissionObject(context.appState)) && (
								<Row className="justify-content-end">
									<Col sm={'auto'}>
										<Button
											className="btn btn-outline-dark span-bold"
											onClick={() =>
												props.history.push(
													`/entity/${fcOperation}?account=${accountID}&entityType=${EntityType.FacilitiesCompany}&parent=${institute?.entityID}`,
													{
														backPath: props.location.pathname,
														backName: 'Institution',
													}
												)
											}
										>
											{context.appState.userPermissions && context.appState.userPermissions.entityTypeID === EntityType.TestingContractor
												? 'Create facilities company'
												: 'Invite facilities company'}
										</Button>
									</Col>
								</Row>
							)}
						</>
					)}
				>
					{facilities.map((fc) => (
						<AccountEntityDetails
							key={fc.entityID}
							accountID={accountID}
							account={account}
							entity={fc}
							locations={locations}
							assets={assets}
							setMessages={setMessages}
							onAccountEntityChange={onAccountEntityEdit}
							onRemoveAccountEntity={removeEntityFromAccount}
							showTestingContractors={true}
							updateSubscriber={updateSubscriber}
							backState={{ backPath: props.location.pathname, backName: institute?.entityName || 'Institution' }}
							testingContractors={testingContractors.filter((entity) => entity.parentEntityID === fc.entityID)}
							testingContractorHeaderComponent={(entityID) =>
								function testingContractorHeaderComponent() {
									return (
										<>
											{pageStatus === 'Ready' && account && canWriteAccount(appStateToPermissionObject(context.appState)) && (
												<Row className="justify-content-end">
													<Col sm={'auto'}>
														<Button
															className="btn btn-outline-dark span-bold"
															onClick={() =>
																props.history.push(
																	`/entity/invite?account=${accountID}&entityType=${EntityType.TestingContractor}&parent=${entityID}`,
																	{
																		backPath: props.location.pathname,
																		backName: institute?.entityName || 'Institution',
																	}
																)
															}
														>
															Invite testing contractor
														</Button>
													</Col>
												</Row>
											)}
										</>
									)
								}
							}
						/>
					))}
				</Card>
			)}

			{!isNew && account && (
				<Card
					title={'Testing Contractors'}
					collapsible={true}
					headerComponent={() => (
						<>
							{pageStatus === 'Ready' && account && canWriteAccount(appStateToPermissionObject(context.appState)) && (
								<Row className="justify-content-end">
									<Col sm={'auto'}>
										<Button
											className="btn btn-outline-dark span-bold"
											onClick={() =>
												props.history.push(
													`/entity/invite?account=${accountID}&entityType=${EntityType.TestingContractor}&parent=${institute?.entityID}`,
													{
														backPath: props.location.pathname,
														backName: 'Institution',
													}
												)
											}
										>
											Invite testing contractor
										</Button>
									</Col>
								</Row>
							)}
						</>
					)}
				>
					{testingContractors
						.filter((entity) => entity.parentEntityID === institute?.entityID)
						.map((fc) => (
							<AccountEntityDetails
								accountID={account.accountID}
								key={fc.entityID}
								entity={fc}
								account={account}
								locations={locations}
								assets={assets}
								setMessages={setMessages}
								onAccountEntityChange={onAccountEntityEdit}
								onRemoveAccountEntity={removeEntityFromAccount}
								updateSubscriber={updateSubscriber}
								showTestingContractors={false}
								testingContractors={[]}
								backState={{ backPath: props.location.pathname, backName: institute?.entityName || 'Institution' }}
							/>
						))}
				</Card>
			)}

			{!isNew && assetTypes && (
				<Card
					title={'Asset Types'}
					collapsible={true}
					headerComponent={() => (
						<>
							{pageStatus === 'Ready' &&
								account &&
								canWriteAsset(appStateToPermissionObject(context.appState), { accountID: account.accountID, cud: 'create' }) && (
									<Row className="justify-content-end">
										<Col sm={'auto'}>
											<Button
												className="btn btn-outline-dark span-bold"
												onClick={() =>
													props.history.push(`/assettype/new?account=${accountID}`, {
														backPath: props.location.pathname,
														backName: institute?.entityName || 'Institution',
													})
												}
											>
												Add asset type
											</Button>
										</Col>
									</Row>
								)}
						</>
					)}
				>
					<AccountAssetTypeList
						assetTypes={assetTypes}
						backState={{ backPath: props.location.pathname, backName: institute?.entityName || 'Institution' }}
					/>
				</Card>
			)}
		</div>
	)
}

export { ScreensAccountDetails, instituteValidationSchema }
export type { AccountErrorMessages, Institute }
