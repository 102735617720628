import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Row, Col, Form } from 'react-bootstrap'
import { TestProps } from './QuestionLookup.d'
import { Formik } from 'formik'
import * as yup from 'yup'
import { getPassValueOverrides } from 'dynaflow/utilities/testOverride'
import {
	onQuestionSubmit,
	QuestionElement,
	QuestionNavigationButtons,
	QuestionResult,
	QuestionResultElementGroup,
	TabRow,
	useLoadExistingQuestionResponse,
	QuestionHeader,
} from './QuestionComponents'
import { LightGrid, lightInputValidation } from './LightingTest'
import { calibrateMultiplicativeValue } from 'dynaflow/utilities/calibration'
import { UltraVioletRadiationTestOutput } from 'dynaflow/utilities/question'
import { getLightingReadingsCount } from 'dynaflow/utilities/questionResponse'

interface GridResult {
	point: number
	value: string
}

interface QuestionValues {
	lightStatus: 'fittedAndOperational' | 'notFitted' | 'fittedButNotOperational'
	lightingReadings: {
		horizontalPoints: number
		results: GridResult[]
	}
}

const validationSchema = yup.object().shape({
	lightStatus: yup.string().oneOf(['fittedAndOperational', 'notFitted', 'fittedButNotOperational']).required(),
	lightingReadings: yup.object().when('lightStatus', {
		is: 'fittedAndOperational',
		then: yup.object().shape({
			results: yup.array().of(
				yup.object().shape({
					value: lightInputValidation,
				})
			),
		}),
	}),
})

const UltraVioletRadiationTest = (props: TestProps) => {
	const { test } = props
	const context = useContext(AppContext)
	const submitCallback = React.useRef(() => {})

	const getInitialValues = React.useCallback(() => {
		const points = getLightingReadingsCount(props.asset)
		const results = []
		for (let index = 0; index < points; index++) {
			results.push({ point: index + 1, value: '' })
		}
		return {
			lightStatus: 'fittedAndOperational',
			lightingReadings: {
				horizontalPoints: points,
				results,
			},
		} as QuestionValues
	}, [])

	const [questionValues, setQuestionValues] = React.useState<QuestionValues>(() => getInitialValues())

	useLoadExistingQuestionResponse({
		...props,
		setQuestionValues,
		formatQuestionValues: React.useCallback(
			(existingValues: UltraVioletRadiationTestOutput): QuestionValues => {
				const responses = { lightStatus: existingValues.lightStatus } as QuestionValues
				if (existingValues.lightingReadings) {
					responses.lightingReadings = {
						horizontalPoints: existingValues.lightingReadings.horizontalPoints,
						results: existingValues.lightingReadings!.results.map((result) => ({
							...result,
							value: result.instrumentValue.toString(),
						})),
					}
				} else {
					const initialValues = getInitialValues()
					responses.lightingReadings = {
						...initialValues.lightingReadings,
					}
				}
				return responses
			},
			[getInitialValues]
		),
	})

	const testInstrument =
		test?.testInstruments?.find((instrument) => instrument.instrumentTypeID === props.question?.instrumentTypes[0].instrumentTypeID) || null
	const calibration =
		testInstrument && testInstrument.testInstrumentCalibrationSnapshot ? JSON.parse(testInstrument?.testInstrumentCalibrationSnapshot) : null

	const PASS_VALUES = getPassValueOverrides(props.question)

	if (!calibration || !PASS_VALUES) {
		return null
	}

	return (
		<Formik
			initialValues={questionValues}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={true}
			onSubmit={(values) => {
				onQuestionSubmit({ ...props, values, authState: context.appState.authState, submitCallback })
			}}
			enableReinitialize={true}
		>
			{({ handleSubmit, errors, values, handleChange, setFieldValue, handleReset, dirty, isSubmitting }) => {
				let failed = null
				let totalReading = null
				let averageReading = null
				const completeResults = values.lightingReadings.results.filter((i) => !!i.value)
				if (completeResults.length === values.lightingReadings.results.length) {
					failed = false
					const calibratedValues = values.lightingReadings.results.map((i) => calibrateMultiplicativeValue(Number(i.value), calibration)!)
					totalReading = calibratedValues.reduce((partialSum, i) => partialSum! + i!, 0)!
					averageReading = totalReading / values.lightingReadings.results.length
					if (averageReading < PASS_VALUES.minimumAverageUVReading) {
						failed = true
					}
				}
				if (values.lightStatus === 'fittedButNotOperational') {
					failed = true
				} else if (values.lightStatus === 'notFitted') {
					failed = false
				}

				return (
					<>
						<TabRow
							tabSelectOptions={props.tabSelectOptions}
							onClick={(q, save) => {
								if (save) {
									submitCallback.current = () => props.goToQuestion(q)
									handleSubmit()
								} else {
									props.goToQuestion(q)
								}
							}}
							currentQuestion={props.question?.questionID}
							dirty={dirty}
							isSubmitting={isSubmitting}
							errors={errors}
						/>
						<Row>
							<Col>
								<QuestionHeader question={props.question} />
								<QuestionElement>
									<Row>
										<Col className="text-right" style={{ maxWidth: 'fit-content' }}>
											<span>UV light status:</span>
										</Col>
										<Col>
											<Row>
												<Col>
													<Form.Check
														type="radio"
														inline
														id={'lightStatus.fittedAndOperational'}
														value={'fittedAndOperational'}
														checked={values.lightStatus === 'fittedAndOperational'}
														onChange={() => setFieldValue('lightStatus', 'fittedAndOperational')}
														name="lightStatus"
														label="UV light fitted and operational"
														disabled={!!test?.isLocked}
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<Form.Check
														type="radio"
														inline
														id={'lightStatus.notFitted'}
														value={'notFitted'}
														checked={values.lightStatus === 'notFitted'}
														onChange={() => setFieldValue('lightStatus', 'notFitted')}
														name="lightStatus"
														label="UV light not fitted"
														disabled={!!test?.isLocked}
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<Form.Check
														type="radio"
														inline
														id={'lightStatus.fittedButNotOperational'}
														value={'fittedButNotOperational'}
														checked={values.lightStatus === 'fittedButNotOperational'}
														onChange={() => setFieldValue('lightStatus', 'fittedButNotOperational')}
														name="lightStatus"
														label="UV light fitted and NOT operational"
														disabled={!!test?.isLocked}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row className="mt-4">
										{values.lightingReadings.horizontalPoints && (
											<LightGrid
												values={values.lightingReadings}
												errors={errors?.lightingReadings?.results}
												formikKey="lightingReadings"
												handleChange={handleChange}
												calibration={calibration}
												disabled={!!test?.isLocked || values.lightStatus !== 'fittedAndOperational'}
												unit={'µW/cm²'}
											/>
										)}
									</Row>
									<QuestionResultElementGroup
										results={[
											{ title: 'Sum of readings', value: `${totalReading?.toFixed(2) || '-'} µW/cm²` },
											{ title: 'Average', value: `${averageReading?.toFixed(2) || '-'} µW/cm²` },
											{ title: 'Final average', value: `${averageReading ? (averageReading * 10).toFixed(2) : '-'} mW/m²` },
										]}
									/>
								</QuestionElement>
								<QuestionResult
									failed={failed}
									questionHasComment={false}
									questionHasForcePass={false}
									isStandardsBased={props.asset?.assetTypeIsStandardsBased}
								/>
								<QuestionNavigationButtons
									{...props}
									handleReset={handleReset}
									handleSubmit={handleSubmit}
									submitCallback={submitCallback}
									setSubmitCallback={(fn) => (submitCallback.current = fn)}
									dirty={dirty}
									isSubmitting={isSubmitting}
								/>
							</Col>
						</Row>
					</>
				)
			}}
		</Formik>
	)
}

export { UltraVioletRadiationTest }
