import React from 'react'
import * as icons from 'react-feather'

import { AppContext } from '../../../App'
import { DocumentResult } from '../../../../../back-end/common/document'
import * as Request from '../../../utilities/request'
import { DeleteButton } from '../Form/DeleteButton'

type IconName = keyof typeof icons | 'pdf'
type IconProps = {
	name: IconName
} & icons.IconProps

const Icon = ({ name, ...rest }: IconProps) => {
	if (name === 'pdf') {
		return <img src={'/images/pdf.png'} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A PFD File Icon'} />
	} else {
		const IconComponent = icons[name as keyof typeof icons]
		return <IconComponent {...rest} />
	}
}

interface DownloadLinkProps {
	path: string
	name: string
	fileType: string
	icon?: IconName
	onError?: () => void
	onDelete?: () => void
}

const DownloadLink = (props: DownloadLinkProps) => {
	const context = React.useContext(AppContext)

	const [isDownloading, setIsDownloading] = React.useState<boolean>(false)

	const onClick = async () => {
		setIsDownloading(true)
		try {
			const res = await Request.post<DocumentResult>(
				'document',
				{ type: props.fileType, s3Location: props.path, operation: 'getObject' },
				context.appState.authState
			)
			window.open(res.data.file.signedUrl, '_blank')
		} catch (e) {
			// Handle a broken link rather than redirect to a broken page
			console.log(e)
			if (props.onError) {
				props.onError()
			}
		}
		setIsDownloading(false)
	}

	return (
		<>
			<span onClick={!isDownloading ? onClick : undefined} className="download-link">
				{props.icon && <Icon name={props.icon} />}
				{props.name}
			</span>
			{props.onDelete && <DeleteButton id={props.path} customOnClick={props.onDelete} />}
		</>
	)
}

export { DownloadLink }
