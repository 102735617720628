import React from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, Upload, UploadProps } from 'antd'
import axios from 'axios'
import { RcFile } from 'antd/lib/upload'

interface UploaderProps extends UploadProps {
	icon?: () => JSX.Element
	buttonText?: string
	error?: string
}

const isRcFile = (file: RcFile | Blob): file is RcFile => {
	return (file as RcFile).arrayBuffer !== undefined && (file as RcFile).name !== undefined
}

const uploadToSignedUrl = async (signedUrl: string, file: RcFile | Blob, fileName?: string) => {
	const options = {
		headers: { 'Content-Type': `${file.type}`, 'Content-Disposition': `attachment; filename="${isRcFile(file) ? file.name : fileName}"` },
	}

	return axios.put<unknown>(signedUrl, file, options)
}

const Uploader = (props: UploaderProps) => {
	const Icon = props.icon || UploadOutlined

	const className = (props.className || '') + (props.error ? ' is-invalid' : '')
	return (
		<>
			<Upload {...props} className={className}>
				<Button disabled={props.disabled} icon={<Icon />} className={`btn btn-default ${props.listType === 'picture' ? 'btn-large' : ''}`}>
					{props.buttonText || 'Click to Upload'}
				</Button>
			</Upload>
			{props.error ? <span style={{ width: '100%', marginTop: '0.25rem', fontSize: '80%', color: '#dc3545' }}>{props.error}</span> : null}
		</>
	)
}

export { Uploader, uploadToSignedUrl }
