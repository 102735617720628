import React from 'react'
import { Col, Dropdown, Form, Row } from 'react-bootstrap'
import useFocus from '../../utilities/focus'

// eslint-disable-next-line
type DropdownSelectOption<ValueType, Extends extends Record<string, any>> = Extends & {
	value: ValueType
	text: string
}

interface DropdownSelectProps<T, E> {
	id: string
	name: string
	disabled: boolean
	variant: string
	showSearch: boolean
	searchPlaceholder: string
	options: DropdownSelectOption<T, E>[]
	value: string
	onChange: (value: T, data: DropdownSelectOption<T, E>) => void
	label?: string
	feedback?: string
	isInvalid?: boolean
}

const DropdownSelect = <T, E>(props: DropdownSelectProps<T, E>) => {
	const [search, setSearch] = React.useState<string>('')
	const [inputRef, setInputFocus] = useFocus()

	const filteredOptions = props.showSearch ? props.options.filter((option) => option.text.toLowerCase().includes(search.toLowerCase())) : props.options

	return (
		<>
			{props.label && (
				<Row className="form-label" style={{ marginBottom: '.5rem' }}>
					<Col>{props.label}</Col>
				</Row>
			)}
			<Row>
				<Col>
					{props.disabled ? (
						props.value
					) : (
						<Dropdown>
							<Dropdown.Toggle
								className={`form form-control${props.isInvalid ? ' invalid-feedback-custom-border' : ''}`}
								variant={props.variant}
								id={props.id + 'toggle'}
								disabled={props.disabled}
								onMouseDown={() => setTimeout(() => setInputFocus(), 150)}
							>
								{props.value}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{props.showSearch && (
									<span className="dropdown-item">
										<Form.Control
											onChange={(e) => setSearch(e.target.value)}
											type="text"
											id={props.id + 'search'}
											name={props.name + 'search'}
											placeholder={props.searchPlaceholder}
											disabled={props.disabled}
											value={search}
											ref={inputRef}
										/>
									</span>
								)}
								{filteredOptions.map((option, index) => (
									<Dropdown.Item key={`${option.value}${index}`} onClick={() => props.onChange(option.value, option)}>
										{option.text}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					)}
				</Col>
			</Row>
			<Row>
				<Col>{props.feedback ? <div className="invalid-feedback-custom">{props.feedback}</div> : null}</Col>
			</Row>
		</>
	)
}

export { DropdownSelect }
export type { DropdownSelectProps, DropdownSelectOption }
