import React from 'react'

// components
import { Form } from 'react-bootstrap'
import Select, { SingleValue, MultiValue } from 'react-select'

interface Option<T> {
	label: T
	value: string
}
type OptionValue = SingleValue<Option<string>> | MultiValue<Option<string>>

interface FormSelectProps {
	name: string
	options: Option<string>[]
	label?: string
	required?: boolean
	feedback?: string
	isMulti?: boolean
	value: string | null | undefined
	onChange: (values: OptionValue) => void
	isClearable?: boolean
}

const FormSelect = (props: FormSelectProps) => {
	const selectedValue = props.options.filter((option) => option.value === props.value)
	return (
		<Form.Group controlId={props.name}>
			{props.label ? (
				<Form.Label>
					{props.label}
					{props.required ? ' *' : ''}
				</Form.Label>
			) : null}
			<Select {...props} value={selectedValue} />
			{props.feedback ? <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback> : null}
		</Form.Group>
	)
}

export { FormSelect }
export type SelectOption<T> = Option<T>
