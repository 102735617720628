import React from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { AssetType } from '../../../../back-end/common/assetType'
import { PageBackState } from '../../types/PageBackState'
import { formatDateTime } from '../../utilities/formatDate'

interface AccountAssetTypeListProps {
	assetTypes: AssetType[] | null
	backState?: PageBackState
}

const AccountAssetTypeList = (props: AccountAssetTypeListProps): JSX.Element => {
	if (props.assetTypes) {
		return (
			<Table>
				<thead>
					<tr>
						<th>Name</th>
						<th>Type</th>
						<th>Last Modified</th>
						<th>Last Modified By</th>
					</tr>
				</thead>
				<tbody>
					{props.assetTypes.length === 0 && (
						<tr>
							<td colSpan={4}>No Asset Types</td>
						</tr>
					)}
					{props.assetTypes.length !== 0 &&
						props.assetTypes.map((assetType) => (
							<tr key={assetType.assetTypeID}>
								<td>
									<Link to={{ pathname: `/assettype/${assetType.assetTypeID}`, state: props.backState }}>{assetType.name}</Link>
								</td>
								<td>{assetType.isStandardsBased ? 'Standard' : 'No Standard'}</td>
								<td>{formatDateTime({ date: assetType.modified.ts, format: 'DateAndTime' })}</td>
								<td>
									{assetType.modified.fullName
										? assetType.modified.fullName
										: `${assetType.modified.firstName} ${assetType.modified.lastName}`}
								</td>
							</tr>
						))}
				</tbody>
			</Table>
		)
	}
	return (
		<Spinner animation="border" role="status">
			<span className="visually-hidden">Loading...</span>
		</Spinner>
	)
}

export { AccountAssetTypeList }
export type { AccountAssetTypeListProps }
