import React, { useContext } from 'react'
import { Row, Col, Button, Dropdown } from 'react-bootstrap'
import * as Request from '../../utilities/request'
import { AppContext } from '../../App'
import { RouteComponentProps } from 'react-router'
import { Header } from '../../components/UI/Header/Header'
import { Card } from '../../components/UI/Card/Card'
import { PageStatus } from '../../types/PageStatus'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { FormText } from '../../components/UI/Form/Text'
import { Formik, FormikErrors } from 'formik'
import { Link } from 'react-router-dom'
import { getAssetValidationSchemaFromParentAssetTypeID, ExtractionHood } from '../../utilities/assetValidationSchemas'

import { Asset, AssetResult } from '../../../../back-end/common/asset'
import { AssetType, AssetTypeResult } from '../../../../back-end/common/assetType'
import { Location, LocationResult } from '../../../../back-end/common/location'
import { Account, AccountResult } from '../../../../back-end/common/account'
import { AssetTypeDropdown, AssetLocationDropdown, AssetBuildingDropdown, AssetRoomDropdown } from './AssetHelperFunctions'
import { DropdownSelect } from '../../components/Forms/Dropdown'

type AssetErrorMessages = FormikErrors<Asset>

interface ScreensAssetMatchParams {
	id: string
}

const defaultAsset = (asset: Partial<Asset>): Partial<Asset> => ({
	assetID: asset.assetID || '',
	assetUnitReference: asset.assetUnitReference || '',
	assetClientRef: asset.assetClientRef || '',
	assetActive: asset.assetActive || false,

	assetChamberWidth: asset.assetChamberWidth || null,
	assetChamberHeight: asset.assetChamberHeight || null,
	assetChamberDepth: asset.assetChamberDepth || null,
	assetVerticalReadings: asset.assetVerticalReadings || null,
	assetHorizontalReadings: asset.assetHorizontalReadings || null,
	assetMake: asset.assetMake || '',
	assetModel: asset.assetModel || '',
	assetSerialNumber: asset.assetSerialNumber || '',
	assetWidth: asset.assetWidth || 0,
	assetHeight: asset.assetHeight || 0,
	assetCustomProperties: asset.assetCustomProperties || [],
	assetInstallationDate: asset.assetInstallationDate || null,

	accountID: asset.accountID || '',
	accountName: asset.accountName || '',

	assetTypeID: asset.assetTypeID || '',
	assetTypeName: asset.assetTypeName || '',

	roomID: asset.roomID || '',
	roomName: asset.roomName || '',

	buildingID: asset.buildingID || '',
	buildingName: asset.buildingName || '',

	locationID: asset.locationID || '',
	locationName: asset.locationName || '',

	lastTestTs: asset.lastTestTs || '',
	recordStatusID: asset.recordStatusID || '',
})

const ScreensAssetCreate = (props: RouteComponentProps<ScreensAssetMatchParams>) => {
	const context = useContext(AppContext)

	const [messages, setMessages] = useMessageReducer([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [asset, setAsset] = React.useState<Asset>(defaultAsset({ assetActive: true }) as Asset)

	const [accounts, setAccounts] = React.useState<Account[]>([])
	const [assetTypes, setAssetTypes] = React.useState<AssetType[]>([])
	const [selectedAssetType, setSelectedAssetType] = React.useState<AssetType | null>(null)
	const [locations, setLocations] = React.useState<Location[]>([])

	React.useEffect(() => {
		const getData = async () => {
			const [accountsResponse, locationsResponse] = await Promise.all([
				Request.get<AccountResult>(`account`, context.appState.authState),
				Request.get<LocationResult>(`location`, context.appState.authState),
			])
			const accounts = accountsResponse.data.accounts
			const locations = locationsResponse.data.locations
			setAccounts(accounts)
			setLocations(locations)

			/* If there is only one account available default to that account */
			if (accounts.length === 1) {
				setAsset({ ...asset, accountID: accounts[0].accountID, accountName: accounts[0].accountName })
			}

			setPageStatus('Editing')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, []) // eslint-disable-line

	const getAssetTypes = async (accountID: string) => {
		const assetTypesResponse = await Request.get<AssetTypeResult>(`assetType?where=AccountID==${accountID}`, context.appState.authState)
		const assetTypes = assetTypesResponse.data.assetTypes
		setAssetTypes(assetTypes)
	}

	React.useEffect(() => {
		if (asset.accountID) {
			setPageStatus('Loading')
			getAssetTypes(asset.accountID)
			setPageStatus('Editing')
		}
	}, [asset.accountID])

	const onAssetCreate = async (asset: Partial<Asset>) => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.post<AssetResult>(`asset`, asset, context.appState.authState), {
			successFunction: (data) => {
				if (data.assets.length > 0) {
					props.history.push(`/asset/${data.assets[0].assetID}`)
				}
			},
			setMessageFunction: setMessages,
			messageAction: 'creating',
			messageObject: 'asset',
		})
		setPageStatus('Ready')
	}

	return (
		<div className="page">
			<Messages messages={messages} updateMessage={setMessages} />

			<Row>
				<Col>
					<Link className="breadcrumb-back" to="/asset">{`< Back to Assets`}</Link>
				</Col>
			</Row>

			<Row>
				<Col>
					<Header title={asset ? asset.assetUnitReference || 'New Asset' : 'Asset'} subtitle={''} />
				</Col>
			</Row>

			<Formik
				initialValues={asset}
				validationSchema={getAssetValidationSchemaFromParentAssetTypeID(selectedAssetType?.parentAssetTypeID)}
				onSubmit={onAssetCreate}
				enableReinitialize
			>
				{({ handleSubmit, errors, values, setFieldValue }) => {
					return (
						<Card
							title={'New Asset'}
							collapsible={false}
							headerComponent={() =>
								pageStatus !== 'Loading' && asset ? (
									<Row className="justify-content-end">
										<Col sm={'auto'}>
											<Button className="btn btn-secondary" onClick={() => handleSubmit()} disabled={pageStatus !== 'Editing'}>
												Save
											</Button>
										</Col>
										<Col sm={'auto'}>
											<Link to="/asset">
												<Button className="btn btn-secondary">Cancel</Button>
											</Link>
										</Col>
									</Row>
								) : (
									<></>
								)
							}
						>
							<AssetCreate
								asset={values}
								accounts={accounts}
								assetTypes={assetTypes}
								selectedAssetType={selectedAssetType}
								setSelectedAssetType={setSelectedAssetType}
								locations={locations}
								pageStatus={pageStatus}
								onChange={setFieldValue}
								setAsset={setAsset}
								errors={errors}
							/>
						</Card>
					)
				}}
			</Formik>
		</div>
	)
}

interface AssetCreateProps {
	asset: Asset
	accounts: Account[]
	assetTypes: AssetType[]
	selectedAssetType: AssetType | null
	setSelectedAssetType: (assetType: AssetType) => void
	locations: Location[]
	pageStatus: PageStatus
	errors?: AssetErrorMessages
	onChange: (field: string, value: string | number | boolean | null | { key: string; value: string }[]) => void
	setAsset: (asset: Asset) => void
}

const AssetCreate = (props: AssetCreateProps) => {
	const readOnly = props.pageStatus !== 'Editing'

	if (props.asset) {
		return (
			<div style={{ flexDirection: 'column' }}>
				<Row style={{ marginBottom: '20px' }}>
					<Col>
						<DropdownSelect
							name="accountID"
							label="Institution"
							value={props.asset.accountName || ''}
							onChange={(value, account) => props.setAsset(defaultAsset({ accountID: value, accountName: account.accountName }) as Asset)}
							disabled={props.accounts.length === 1}
							id="accountID"
							variant="secondary"
							showSearch={true}
							searchPlaceholder="Filter"
							options={props.accounts.map((account) => ({ ...account, value: account.accountID, text: account.accountName }))}
							feedback={props.errors?.accountID}
							isInvalid={!!props.errors?.accountID}
						/>
					</Col>
				</Row>

				{props.asset.accountID ? (
					<>
						<Row style={{ marginBottom: '20px' }}>
							<Col>
								<AssetTypeDropdown
									asset={props.asset}
									assetTypes={props.assetTypes}
									setAsset={props.setAsset}
									setSelectedAssetType={props.setSelectedAssetType}
									errors={props.errors}
								/>
							</Col>
							<Col>
								<Row className="form-label" style={{ marginBottom: '.5rem' }}>
									<Col>Active</Col>
								</Row>
								<Row>
									<Col>
										<Dropdown>
											<Dropdown.Toggle className={`form form-control`} variant="secondary" id="dropdown-assetActive">
												{props.asset.assetActive ? 'Yes' : 'No'}
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item onClick={() => props.setAsset({ ...props.asset, assetActive: true })}>Yes</Dropdown.Item>
												<Dropdown.Item onClick={() => props.setAsset({ ...props.asset, assetActive: false })}>No</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row style={{ marginBottom: '20px' }}>
							<Col>
								<AssetLocationDropdown asset={props.asset} locations={props.locations} setAsset={props.setAsset} errors={props.errors} />
							</Col>
							<Col>
								<AssetBuildingDropdown asset={props.asset} locations={props.locations} setAsset={props.setAsset} errors={props.errors} />
							</Col>
							<Col>
								<AssetRoomDropdown asset={props.asset} locations={props.locations} setAsset={props.setAsset} errors={props.errors} />
							</Col>
						</Row>
						<Row style={{ marginBottom: '20px' }}>
							<div className="col-5-dynamic">
								<FormText
									name={'assetUnitReference'}
									value={props.asset.assetUnitReference || undefined}
									label={'Unit Reference'}
									onChange={(e) => props.onChange(e.target.name, e.target.value)}
									plaintext={readOnly}
									disabled={readOnly}
									feedback={props.errors?.assetUnitReference}
									isInvalid={!!props.errors?.assetUnitReference}
								/>
							</div>
							<div className="col-5-dynamic">
								<FormText
									name={'assetSerialNumber'}
									value={props.asset.assetSerialNumber || undefined}
									label={'Serial'}
									onChange={(e) => props.onChange(e.target.name, e.target.value)}
									plaintext={readOnly}
									disabled={readOnly}
									feedback={props.errors?.assetSerialNumber}
									isInvalid={!!props.errors?.assetSerialNumber}
								/>
							</div>

							<div className="col-5-dynamic">
								<FormText
									name={'assetMake'}
									value={props.asset.assetMake || undefined}
									label={'Make'}
									onChange={(e) => props.onChange(e.target.name, e.target.value)}
									plaintext={readOnly}
									disabled={readOnly}
									feedback={props.errors?.assetMake}
									isInvalid={!!props.errors?.assetMake}
								/>
							</div>
							<div className="col-5-dynamic">
								<FormText
									name={'assetModel'}
									value={props.asset.assetModel || undefined}
									label={'Model'}
									onChange={(e) => props.onChange(e.target.name, e.target.value)}
									plaintext={readOnly}
									disabled={readOnly}
									feedback={props.errors?.assetModel}
									isInvalid={!!props.errors?.assetModel}
								/>
							</div>
							<div className="col-5-dynamic">
								<FormText
									name={'assetClientRef'}
									value={props.asset.assetClientRef}
									label={'Client Asset Reference'}
									onChange={(e) => props.onChange(e.target.name, e.target.value)}
									plaintext={readOnly}
									disabled={readOnly}
									feedback={props.errors?.assetClientRef}
									isInvalid={!!props.errors?.assetClientRef}
								/>
							</div>
						</Row>
						<Row style={{ marginBottom: '20px' }}>
							<Col sm={8}>
								<Row style={{ marginBottom: '20px' }}>
									<Col sm={3}>
										<FormText
											name={'assetWidth'}
											value={props.asset.assetWidth!}
											label={props.selectedAssetType?.parentAssetTypeID === ExtractionHood ? 'Unit Width (mm)' : 'Sash Width (mm)'}
											onChange={(e) => props.onChange(e.target.name, e.target.value === '' ? null : e.target.value)}
											plaintext={readOnly}
											disabled={readOnly}
											feedback={props.errors?.assetWidth}
											isInvalid={!!props.errors?.assetWidth}
										/>
									</Col>
									<Col sm={3}>
										<FormText
											name={'assetHeight'}
											value={props.asset.assetHeight!}
											label={props.selectedAssetType?.parentAssetTypeID === ExtractionHood ? 'Unit Height (mm)' : 'Sash Height (mm)'}
											onChange={(e) => props.onChange(e.target.name, e.target.value === '' ? null : e.target.value)}
											plaintext={readOnly}
											disabled={readOnly}
											feedback={props.errors?.assetHeight}
											isInvalid={!!props.errors?.assetHeight}
										/>
									</Col>
									{props.selectedAssetType?.parentAssetTypeID === ExtractionHood || props.selectedAssetType?.parentAssetTypeID === null ? (
										<>
											<Col sm={3}>
												<FormText
													name={'assetHorizontalReadings'}
													value={props.asset.assetHorizontalReadings!}
													label={'Horizontal Readings'}
													onChange={(e) => props.onChange(e.target.name, e.target.value)}
													plaintext={readOnly}
													disabled={readOnly}
													feedback={props.errors?.assetHorizontalReadings}
													isInvalid={!!props.errors?.assetHorizontalReadings}
													type="number"
													min={0}
												/>
											</Col>
											<Col sm={3}>
												<FormText
													name={'assetVerticalReadings'}
													value={props.asset.assetVerticalReadings!}
													label={'Vertical Readings'}
													onChange={(e) => props.onChange(e.target.name, e.target.value)}
													plaintext={readOnly}
													disabled={readOnly}
													feedback={props.errors?.assetVerticalReadings}
													isInvalid={!!props.errors?.assetVerticalReadings}
													type="number"
													min={0}
												/>
											</Col>
										</>
									) : null}
								</Row>
								<Row style={{ marginBottom: '20px' }}>
									{props.selectedAssetType && props.selectedAssetType.showChamberWidth ? (
										<Col sm={3}>
											<FormText
												name={'assetChamberWidth'}
												value={props.asset.assetChamberWidth!}
												label={'Chamber Width (mm)'}
												onChange={(e) => props.onChange(e.target.name, e.target.value)}
												plaintext={readOnly}
												disabled={readOnly}
												feedback={props.errors?.assetChamberWidth}
												isInvalid={!!props.errors?.assetChamberWidth}
											/>
										</Col>
									) : null}
									{props.selectedAssetType && props.selectedAssetType.showChamberHeight ? (
										<Col sm={3}>
											<FormText
												name={'assetChamberHeight'}
												value={props.asset.assetChamberHeight!}
												label={'Chamber Height (mm)'}
												onChange={(e) => props.onChange(e.target.name, e.target.value)}
												plaintext={readOnly}
												disabled={readOnly}
												feedback={props.errors?.assetChamberHeight}
												isInvalid={!!props.errors?.assetChamberHeight}
											/>
										</Col>
									) : null}
									{props.selectedAssetType && props.selectedAssetType.showChamberDepth ? (
										<Col sm={3}>
											<FormText
												name={'assetChamberDepth'}
												value={props.asset.assetChamberDepth!}
												label={'Chamber Depth (mm)'}
												onChange={(e) => props.onChange(e.target.name, e.target.value)}
												plaintext={readOnly}
												disabled={readOnly}
												feedback={props.errors?.assetChamberDepth}
												isInvalid={!!props.errors?.assetChamberDepth}
											/>
										</Col>
									) : null}

									<Col sm={3}></Col>
								</Row>
							</Col>
						</Row>
						<Row style={{ marginBottom: '20px' }}>
							{props.asset &&
								props.selectedAssetType &&
								props.selectedAssetType.customProperties &&
								props.selectedAssetType.customProperties.map((atCustomProp, index) => {
									const aCustomProp = props.asset.assetCustomProperties?.find((prop) => prop.key === atCustomProp)
									return (
										<Col key={index}>
											<FormText
												name={`assetCustomProperties.${atCustomProp}`}
												label={atCustomProp}
												value={aCustomProp?.value || ''}
												onChange={(e) => {
													const newArr = [...(props.asset.assetCustomProperties || [])]
													const foundIndex = newArr.findIndex((prop) => prop.key === atCustomProp)
													if (foundIndex >= 0) {
														newArr[foundIndex].value = e.target.value
													} else {
														newArr.push({ key: atCustomProp, value: e.target.value })
													}
													props.onChange('assetCustomProperties', newArr)
												}}
												plaintext={readOnly}
												disabled={readOnly}
											/>
										</Col>
									)
								})}
						</Row>
					</>
				) : null}
			</div>
		)
	}
	return null
}

export { ScreensAssetCreate }
