import React, { useContext } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { StaticContext } from 'react-router'
import { Col, Row } from 'react-bootstrap'

import { Header } from '../../components/UI/Header/Header'
import { Button } from '../../components/UI/Button/Button'
import { Listing } from '../../components/UI/Listing/Listing'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'

import { AppContext } from '../../App'
import * as EntityType from '../../constants/entityType'
import { InstrumentSet, InstrumentSetResult } from '../../../../back-end/common/instrumentSet'
import { PageBackState } from '../../types/PageBackState'
import { PageStatus } from '../../types/PageStatus'
import * as Request from '../../utilities/request'

import addIcon from '../../images/icons/add.svg'
import { canWriteInstrument } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'
import { DeleteModal } from '../../components/UI/DeleteModal/deleteModal'

interface ScreensInstrumentSetStateParams extends PageBackState {}
interface ScreensInstrumentSetMatchParams {}

const InstrumentSetList = (props: RouteComponentProps<ScreensInstrumentSetMatchParams, StaticContext, ScreensInstrumentSetStateParams>) => {
	const context = useContext(AppContext)

	const [messages, setMessages] = useMessageReducer([])
	const [instrumentSets, setInstrumentSets] = React.useState<InstrumentSet[] | null>(null)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [toDelete, setToDelete] = React.useState<string[]>([])

	React.useEffect(() => {
		const getData = async () => {
			const [instrumentSetReq] = await Promise.all([Request.get<InstrumentSetResult>('instrumentset', context.appState.authState)])
			setInstrumentSets(instrumentSetReq.data.instrumentSets)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	const handleDeleteSelected = async (selected: string[]) => {
		const selectedInstrumentSets = instrumentSets?.filter(({ instrumentSetID }) => selected.includes(instrumentSetID)) || []
		for (const set of selectedInstrumentSets) {
			if (set.instruments.length > 0) {
				setMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `Cannot delete instrument set "${set.instrumentSetName}" while it contains instruments. Move them to another set or delete them first.`,
						dismissible: true,
						timeout: 8000,
					},
				})
				return
			}
		}
		try {
			const req = await Request.del<InstrumentSetResult>(`instrumentset?where=instrumentSetID=in(${selected.join(',')})`, context.appState.authState)
			if (req.data.success) {
				setInstrumentSets(instrumentSets?.filter((instrumentSet) => !selected.includes(instrumentSet.instrumentSetID)) || [])
				setMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: `Successfully deleted the instrument sets`,
						dismissible: true,
						timeout: 5000,
					},
				})
				setToDelete([])
			} else {
				setMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `An error occurred while deleting the instrument sets`,
						dismissible: true,
						timeout: 5000,
					},
				})
			}
		} catch (err) {
			setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `An error occurred while deleting the instrument sets`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
	}

	return (
		<>
			<Messages messages={messages} updateMessage={setMessages} />

			<Row>
				<Col>
					<Header title={`Instrument Sets (${instrumentSets?.length || 0})`} subtitle={'View a list of instrument sets'} />
				</Col>
				<Col sm={'auto'} style={{ paddingTop: '40px' }}>
					{(context.appState.userPermissions.entityTypeID || context.appState.userPermissions.entityTypeID === EntityType.Admin) &&
						canWriteInstrument(appStateToPermissionObject(context.appState)) && (
							<Button
								className=" btn btn-primary round"
								onClick={() => props.history.push('/instrumentset/new', { backPath: props.location.pathname, backName: 'Instruments' })}
							>
								<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
								<span className="span-bold" style={{ color: 'white' }}>
									Create new instrument set
								</span>
							</Button>
						)}
				</Col>
			</Row>

			<DeleteModal show={toDelete.length > 0} noun="Instruments" onDeletePress={() => handleDeleteSelected(toDelete)} onNoPress={() => setToDelete([])} />

			<Listing
				name="Instrument Set"
				namePlural="Instrument Sets"
				list={instrumentSets || []}
				getIDFunc={(item) => item.instrumentSetID}
				selectedActions={
					canWriteInstrument(appStateToPermissionObject(context.appState))
						? [{ name: 'Delete selected', method: (selected) => setToDelete(selected) }]
						: []
				}
				columns={[
					{
						value: (item) => item.instrumentSetName,
						render: (item) => (
							<Link
								to={{
									pathname: `/instrumentset/${item.instrumentSetID}`,
									state: { backPath: props.location.pathname, backName: 'Instruments' },
								}}
							>
								{item.instrumentSetName}
							</Link>
						),
						showHeader: true,
						headerText: 'Name',
						sortColumnName: 'instrumentSetName',
						filterType: 'string',
						filterOptions: {
							columnName: 'instrumentSetName',
						},
					},
					{
						value: (item) => item.instrumentSetOwner,
						render: (item) => (
							<>
								{item.instrumentSetOwner} ({item.instrumentSetOwnerEntityName || item.instrumentSetOwnerUserEntityName})
							</>
						),
						showHeader: true,
						headerText: 'Owner/Set',
						sortColumnName: 'instrumentSetOwner',
						filterType: 'string',
						filterOptions: {
							columnName: 'instrumentSetOwner',
						},
					},
				]}
				defaultSort={{ column: 'instrumentSetName', order: 'ASC' }}
				isLoading={pageStatus === 'Loading'}
			/>
		</>
	)
}

export { InstrumentSetList }
