import React, { useContext } from 'react'
import { StaticContext } from 'react-router'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { DateTime } from 'luxon'

import { Header } from '../../components/UI/Header/Header'
import { Button } from '../../components/UI/Button/Button'
import { FilterDropdownOption, Listing } from '../../components/UI/Listing/Listing'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'

import { formatDateTime } from '../../utilities/formatDate'
import * as Request from '../../utilities/request'

import { AppContext } from '../../App'
import * as EntityType from '../../constants/entityType'
import { Instrument, InstrumentResult } from '../../../../back-end/common/instruments'
import { PageBackState } from '../../types/PageBackState'
import { PageStatus } from '../../types/PageStatus'

import addIcon from '../../images/icons/add.svg'
import { getInstrumentCalibrationFunction } from './InstrumentCalibration'
import { canWriteInstrument } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'
import { DeleteModal } from '../../components/UI/DeleteModal/deleteModal'

interface ScreensInstrumentSetStateParams extends PageBackState {}
interface ScreensInstrumentSetMatchParams {}

const InstrumentList = (props: RouteComponentProps<ScreensInstrumentSetMatchParams, StaticContext, ScreensInstrumentSetStateParams>) => {
	const context = useContext(AppContext)

	const [messages, setMessages] = useMessageReducer([])
	const [instruments, setInstruments] = React.useState<Instrument[] | null>(null)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [toDelete, setToDelete] = React.useState<string[]>([])

	React.useEffect(() => {
		const getData = async () => {
			const [instrument] = await Promise.all([Request.get<InstrumentResult>('instrument', context.appState.authState)])

			setInstruments(instrument.data.instruments)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	const instrumentTypes =
		instruments?.reduce((accumulator, instrument) => {
			const typeIndex = accumulator.findIndex((acc) => acc.value === instrument.instrumentTypeID)
			if (typeIndex < 0) {
				accumulator.push({ value: instrument.instrumentTypeID, text: instrument.instrumentTypeName })
			}
			return accumulator
		}, [] as FilterDropdownOption[]) || []

	const handleDeleteSelected = async (selected: string[]) => {
		try {
			const req = await Request.del<InstrumentResult>(`instrument?where=instrumentID=in(${selected.join(',')})`, context.appState.authState)
			if (req.data.success) {
				setInstruments(instruments?.filter((instrument) => !selected.includes(instrument.instrumentID)) || [])
				setMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: `Successfully deleted the assets`,
						dismissible: true,
						timeout: 5000,
					},
				})
				setToDelete([])
			} else {
				setMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `An error occurred while deleting the assets`,
						dismissible: true,
						timeout: 5000,
					},
				})
			}
		} catch (err) {
			setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `An error occurred while deleting the assets`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
	}

	return (
		<>
			<Messages messages={messages} updateMessage={setMessages} />

			<Row>
				<Col>
					<Header title={`Instruments (${instruments?.length || 0})`} subtitle={'View a list of instruments'} />
				</Col>
				<Col sm={'auto'} style={{ paddingTop: '40px' }}>
					{(context.appState.userPermissions.entityTypeID === EntityType.TestingContractor ||
						context.appState.userPermissions.entityTypeID === EntityType.Admin) &&
						canWriteInstrument(appStateToPermissionObject(context.appState)) && (
							<Button
								className=" btn btn-primary round"
								onClick={() => props.history.push('/instrument/new', { backPath: props.location.pathname, backName: 'Instruments' })}
							>
								<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
								<span className="span-bold" style={{ color: 'white' }}>
									Create new instrument
								</span>
							</Button>
						)}
				</Col>
			</Row>

			<DeleteModal show={toDelete.length > 0} noun="Instruments" onDeletePress={() => handleDeleteSelected(toDelete)} onNoPress={() => setToDelete([])} />

			<Listing
				name="Instrument"
				namePlural="Instruments"
				list={instruments || []}
				getIDFunc={(item) => item.instrumentID}
				selectedActions={
					canWriteInstrument(appStateToPermissionObject(context.appState))
						? [{ name: 'Delete selected', method: (selected) => setToDelete(selected) }]
						: []
				}
				columns={[
					{
						value: (item) => item.instrumentSerialNumber,
						render: (item) => (
							<Link to={{ pathname: `/instrument/${item.instrumentID}`, state: { backPath: props.location.pathname, backName: 'Instruments' } }}>
								{item.instrumentSerialNumber}
							</Link>
						),
						showHeader: true,
						headerText: 'Serial',
						sortColumnName: 'instrumentSerialNumber',
						filterType: 'string',
						filterOptions: {
							columnName: 'instrumentSerialNumber',
						},
					},
					{
						value: (item) => item.instrumentTypeID,
						render: (item) => <>{item.instrumentTypeName}</>,
						showHeader: true,
						headerText: 'Type',
						sortColumnName: 'instrumentTypeName',
						filterType: 'dropdown',
						filterOptions: {
							columnName: 'instrumentTypeName',
							options: instrumentTypes,
						},
					},
					{
						value: (item) => item.instrumentMake,
						render: (item) => <>{item.instrumentMake}</>,
						showHeader: true,
						headerText: 'Make',
						sortColumnName: 'instrumentMake',
						filterType: 'string',
						filterOptions: {
							columnName: 'instrumentMake',
						},
					},
					{
						value: (item) => item.instrumentModel,
						render: (item) => <>{item.instrumentModel}</>,
						showHeader: true,
						headerText: 'Model',
						sortColumnName: 'instrumentModel',
						filterType: 'string',
						filterOptions: {
							columnName: 'instrumentModel',
						},
					},
					{
						value: (item) => item.instrumentSetName,
						render: (item) => (
							<>
								{item.instrumentSetName} ({item.instrumentSetOwnerEntityName || item.instrumentSetOwnerUserEntityName})
							</>
						),
						showHeader: true,
						headerText: 'Owner/Set',
						sortColumnName: 'instrumentSetName',
						filterType: 'string',
						filterOptions: {
							columnName: 'instrumentSetName',
						},
					},
					{
						value: (item) => {
							if (item.calibrations.length > 0) {
								const date = DateTime.fromISO(item.calibrations[item.calibrations.length - 1].calibrationDate)
								const trafficLightColour =
									date.diffNow('months').months < -12 ? 'red' : date.diffNow('months').months < -11 ? 'orange' : 'green'
								return (
									item.calibrations[item.calibrations.length - 1].calibrationDate +
									' ' +
									formatDateTime({
										date: item.calibrations[item.calibrations.length - 1].calibrationDate,
										format: 'Date',
									}) +
									' ' +
									trafficLightColour
								)
							} else {
								return 'never'
							}
						},
						render: (item) => {
							if (item.calibrations.length > 0) {
								const date = DateTime.fromISO(item.calibrations[item.calibrations.length - 1].calibrationDate)
								const trafficLightColour =
									date.diffNow('months').months < -12 ? 'light-red' : date.diffNow('months').months < -11 ? 'light-orange' : 'light-green'
								return (
									<>
										{formatDateTime({
											date: item.calibrations[item.calibrations.length - 1].calibrationDate,
											format: 'Date',
										})}
										<span className={`traffic-light ${trafficLightColour}`}></span>
									</>
								)
							} else if (getInstrumentCalibrationFunction(item)[1] === 'none') {
								return <>No calibration required</>
							} else {
								return <>Never calibrated</>
							}
						},
						showHeader: true,
						headerText: 'Calibration Date',
						sortColumnName: 'calibrationDate',
						filterType: 'string',
						filterOptions: {
							columnName: 'calibrationDate',
						},
					},
				]}
				defaultSort={{ column: 'instrumentSerialNumber', order: 'ASC' }}
				isLoading={pageStatus === 'Loading'}
			/>
		</>
	)
}

export { InstrumentList }
