import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Row, Col, Form, Card, Badge, Alert } from 'react-bootstrap'
import { TestProps } from './QuestionLookup.d'
import { Formik, FormikErrors } from 'formik'
import { WorkZoneIntegrityTestOutput } from 'dynaflow/utilities/question'
import {
	onQuestionSubmit,
	QuestionElement,
	QuestionNavigationButtons,
	QuestionResult,
	TabRow,
	useLoadExistingQuestionResponse,
	QuestionHeader,
} from './QuestionComponents'
import { Button } from '../../../components/UI/Button/Button'

interface CheckGridProps {
	numberHorizontal: number
	numberVertical: number
	label: string
	formikKey: string
	values: LeakTest
	errors?: FormikErrors<LeakTest> | string | string[]
	handleChange: (field: string, value: string, shouldValidate?: boolean) => void
	disabled: boolean
	recordMax?: boolean
}

const getNextItem = (array: string[], currentItem: string): string => {
	const index = array.indexOf(currentItem)
	const nextIndex = index + 1
	const nextItem = array[nextIndex] || array[0]
	return nextItem
}

const checkLabel = {
	false: '',
	filled: '✖',
	notFilled: '〇',
	maxVelocity: 'a',
}

const checkLeak = ({ leak }: { leak: string }): boolean => ['notFilled'].includes(leak)

const CheckGrid = (props: CheckGridProps) => {
	const checkOptions = ['false', 'filled', 'notFilled']
	// Only allow a single max velocity to be set
	if (props.recordMax && !props.values.gridResults.some((r) => r.leak === 'maxVelocity')) {
		checkOptions.push('maxVelocity')
	}
	const checks = []
	for (let v = 0; v < props.numberVertical; v += 1) {
		for (let h = 0; h < props.numberHorizontal; h += 1) {
			const index = v * props.numberHorizontal + h
			checks.push(
				<Button
					key={index}
					className="filter-button header-subtitle"
					variant={props.values.gridResults[index].leak === 'false' ? 'light' : 'danger'}
					id={`${props.formikKey}.gridResults[${index}].leak`}
					value={checkLabel[props.values.gridResults[index].leak]}
					onClick={() => {
						const currentVal = props.values.gridResults[index].leak
						const newValue = getNextItem(checkOptions, currentVal)
						props.handleChange(`${props.formikKey}.gridResults[${index}].leak`, newValue, true)
					}}
					disabled={props.disabled}
				>
					{checkLabel[props.values.gridResults[index].leak]}
				</Button>
			)
		}
	}
	const leaks = props.values.gridResults.some(({ leak }) => ['notFilled'].includes(leak))
	return (
		<Card bg="light" style={{ maxWidth: 'fit-content' }}>
			<Card.Title className="text-center">{props.label}</Card.Title>
			<div
				className="mb-2 p-2 pl-5 pr-5"
				style={{
					display: 'grid',
					gridTemplateColumns: `repeat(${props.numberHorizontal}, 40px)`,
					gridTemplateRows: `repeat(${props.numberVertical}, 40px)`,
					flexGrow: 1,
					alignContent: 'center',
				}}
			>
				{checks}
			</div>
			<Card.Footer style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<Card.Subtitle className="text-center mb-2">Leak detected:</Card.Subtitle>
				<Badge className={props.disabled ? '' : 'text-white'} style={{ width: '120px' }} bg={props.disabled ? 'light' : !leaks ? 'success' : 'danger'}>
					{props.disabled ? 'N/A' : !leaks ? 'NO' : 'YES'}
				</Badge>
			</Card.Footer>
		</Card>
	)
}

interface GridResult {
	point: number
	leak: 'false' | 'filled' | 'notFilled' | 'maxVelocity'
}

interface LeakTest {
	gridResults: GridResult[]
}

interface QuestionValues {
	areaSuitableForTest: boolean
	leftSide: LeakTest
	rightSide: LeakTest
	frontCabinet: LeakTest
	workAccessOpening: LeakTest
	workFloor: LeakTest
}

const createGridInputs = (nH: number, nV: number): LeakTest => {
	const input = [] as GridResult[]
	for (let i = 0; i < nH * nV; i++) {
		input.push({
			point: i + 1,
			leak: 'false',
		})
	}
	return { gridResults: input }
}

const WorkZoneIntegrityTest = (props: TestProps) => {
	const { test } = props
	const context = useContext(AppContext)
	const submitCallback = React.useRef(() => {})

	const depth = React.useMemo(() => Math.ceil((props.asset?.assetHeight || 600) / 100), [props.asset?.assetHeight])
	const width = React.useMemo(() => Math.ceil((props.asset?.assetWidth || 1600) / 100), [props.asset?.assetWidth])

	const [questionValues, setQuestionValues] = React.useState<QuestionValues>(() => {
		return {
			areaSuitableForTest: true,
			leftSide: createGridInputs(depth, 6),
			rightSide: createGridInputs(depth, 6),
			frontCabinet: createGridInputs(width, 4),
			workAccessOpening: createGridInputs(width, 2),
			workFloor: createGridInputs(width, 2),
		}
	})

	useLoadExistingQuestionResponse({
		...props,
		setQuestionValues,
		formatQuestionValues: React.useCallback((existingValues: WorkZoneIntegrityTestOutput): QuestionValues => {
			return {
				areaSuitableForTest: existingValues.areaSuitableForTest,
				leftSide: {
					gridResults: existingValues.leftSide.gridResults.map((r, i) => ({ point: i + 1, leak: r })),
				},
				rightSide: {
					gridResults: existingValues.rightSide.gridResults.map((r, i) => ({ point: i + 1, leak: r })),
				},
				frontCabinet: {
					gridResults: existingValues.frontCabinet.gridResults.map((r, i) => ({ point: i + 1, leak: r })),
				},
				workAccessOpening: {
					gridResults: existingValues.workAccessOpening.gridResults.map((r, i) => ({ point: i + 1, leak: r })),
				},
				workFloor: {
					gridResults: existingValues.workFloor.gridResults.map((r, i) => ({ point: i + 1, leak: r })),
				},
			}
		}, []),
	})

	return (
		<Formik
			initialValues={questionValues}
			onSubmit={(values) => {
				onQuestionSubmit({ ...props, values, authState: context.appState.authState, submitCallback })
			}}
			enableReinitialize={true}
		>
			{({ handleSubmit, errors, values, handleReset, setFieldValue, isSubmitting }) => {
				let failed = null
				if (values.areaSuitableForTest) {
					if (values.frontCabinet.gridResults.some(checkLeak)) {
						failed = true
					} else if (values.leftSide.gridResults.some(checkLeak)) {
						failed = true
					} else if (values.rightSide.gridResults.some(checkLeak)) {
						failed = true
					} else if (values.workAccessOpening.gridResults.some(checkLeak)) {
						failed = true
					} else if (values.workFloor.gridResults.some(checkLeak)) {
						failed = true
					} else {
						failed = false
					}
				}

				return (
					<>
						<TabRow
							tabSelectOptions={props.tabSelectOptions}
							onClick={(q, save) => {
								if (save) {
									submitCallback.current = () => props.goToQuestion(q)
									handleSubmit()
								} else {
									props.goToQuestion(q)
								}
							}}
							currentQuestion={props.question?.questionID}
							dirty={true}
							isSubmitting={isSubmitting}
							errors={errors}
						/>
						<Row>
							<Col>
								<QuestionHeader question={props.question} />
								<QuestionElement>
									<Alert variant="warning" className="text-dark">
										Release of aerosol outside the workstation may contaminate the facility and a risk assessment should be undertaken prior
										to performing test.
									</Alert>
									<Form.Group>
										<Row>
											<Col sm={6} md={3}>
												<span>Area suitable to perform test?</span>
											</Col>
											<Col>
												<div key={`default-radio`} className="mb-3">
													<Form.Check
														type="radio"
														id={`default-radio`}
														value={1}
														checked={values.areaSuitableForTest}
														onChange={() => setFieldValue('areaSuitableForTest', true)}
														name="areaSuitableForTest"
														label="Yes - proceed with test"
														disabled={!!test?.isLocked}
													/>
													<Form.Check
														type="radio"
														id={`disabled-default-radio`}
														value={0}
														checked={!values.areaSuitableForTest}
														onChange={() => setFieldValue('areaSuitableForTest', false)}
														label="No - test cannot be performed"
														name="areaSuitableForTest"
														disabled={!!test?.isLocked}
													/>
												</div>
											</Col>
										</Row>
									</Form.Group>
									{values.areaSuitableForTest && (
										<>
											<Row className="border-top pt-2">
												<Col>
													<Row>
														<span className="ml-3 mr-3 mt-3 mb-0">
															Discharge aerosol at exterior of cabinet 150mm from joint/seal and measure with photometer inside
															work zone, 25mm from edge with photometer for 10 seconds at each point. Measure all sides of cabinet
															including work floor and viewing window. For work floor, place probe on work surface as per
															standard.
														</span>
													</Row>
													<Row>
														<span className="m-3">
															If applicable, indicate presence of leak (filled ={' '}
															<Badge className="text-white" bg="danger">
																✖
															</Badge>{' '}
															or not filled ={' '}
															<Badge className="text-white" bg="danger">
																〇
															</Badge>{' '}
															) by marking the appropriate area below
														</span>
													</Row>
												</Col>
											</Row>
											<Row>
												<Col style={{ minWidth: 'fit-content', overflowX: 'auto' }}>
													<Row>
														<Col className="d-flex justify-content-center">
															<CheckGrid
																numberHorizontal={depth}
																numberVertical={6}
																values={values.leftSide}
																errors={errors.leftSide}
																label="Left side of cabinet"
																handleChange={setFieldValue}
																disabled={!!test?.isLocked || !values.areaSuitableForTest}
																formikKey="leftSide"
															/>
														</Col>
														<Col className="d-flex justify-content-center">
															<CheckGrid
																numberHorizontal={width}
																numberVertical={4}
																values={values.frontCabinet}
																errors={errors.frontCabinet}
																label="Front of cabinet"
																handleChange={setFieldValue}
																disabled={!!test?.isLocked || !values.areaSuitableForTest}
																formikKey="frontCabinet"
															/>
														</Col>
														<Col className="d-flex justify-content-center">
															<CheckGrid
																numberHorizontal={depth}
																numberVertical={6}
																values={values.rightSide}
																errors={errors.rightSide}
																label="Right side of cabinet"
																handleChange={setFieldValue}
																disabled={!!test?.isLocked || !values.areaSuitableForTest}
																formikKey="rightSide"
															/>
														</Col>
													</Row>
													<Row>
														<Col className="d-flex justify-content-center">
															<CheckGrid
																numberHorizontal={width}
																numberVertical={2}
																values={values.workAccessOpening}
																errors={errors.workAccessOpening}
																label="Work access opening"
																handleChange={setFieldValue}
																disabled={!!test?.isLocked || !values.areaSuitableForTest}
																formikKey="workAccessOpening"
															/>
														</Col>
													</Row>
													<Row>
														<Col className="d-flex justify-content-center">
															<CheckGrid
																numberHorizontal={width}
																numberVertical={2}
																values={values.workFloor}
																errors={errors.workFloor}
																label="Work floor"
																handleChange={setFieldValue}
																disabled={!!test?.isLocked || !values.areaSuitableForTest}
																formikKey="workFloor"
															/>
														</Col>
													</Row>
												</Col>
											</Row>
										</>
									)}
								</QuestionElement>
								<QuestionResult
									failed={failed}
									specialPassOverride={!values.areaSuitableForTest ? 'Test cannot be performed' : undefined}
									resultNullText={'N/A'}
									questionHasComment={false}
									questionHasForcePass={false}
									isStandardsBased={props.asset?.assetTypeIsStandardsBased}
								/>
								<QuestionNavigationButtons
									{...props}
									handleReset={handleReset}
									handleSubmit={handleSubmit}
									submitCallback={submitCallback}
									setSubmitCallback={(fn) => (submitCallback.current = fn)}
									dirty={true}
									isSubmitting={isSubmitting}
								/>
							</Col>
						</Row>
					</>
				)
			}}
		</Formik>
	)
}

export { WorkZoneIntegrityTest, checkLeak, CheckGrid }
