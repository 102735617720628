import React from 'react'
import { Button as BootstrapButton, ButtonProps as BootstrapButtonProps } from 'react-bootstrap'

interface ButtonProps extends BootstrapButtonProps {}

const Button = (props: ButtonProps) => {
	return <BootstrapButton {...props}>{props.children}</BootstrapButton>
}

export { Button }
