import React, { useContext } from 'react'
import { RouteChildrenProps } from 'react-router-dom'
import { Container, Spinner, Col, Row, Table, Form, Button } from 'react-bootstrap'
import { AssetType, AssetTypeResult } from '../../../../back-end/common/assetType'
import * as Request from '../../utilities/request'
import { Header } from '../../components/UI/Header/Header'
import { Card } from '../../components/UI/Card/Card'
import { Paginator, FiltersState, pageFilter } from '../../components/UI/Paginator/Paginator'
import { AppContext } from '../../App'
import { Link } from 'react-router-dom'
import { PageStatus } from '../../types/PageStatus'

import addIcon from '../../images/icons/add.svg'

const ScreensAssetType = (props: RouteChildrenProps) => {
	const context = useContext(AppContext)

	const [assetTypes, setAssetTypes] = React.useState<AssetType[] | null>(null)
	const [filters, setFilters] = React.useState<FiltersState>({ page: 1, pageSize: 10 })
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [search, setSearch] = React.useState<string>('')

	React.useEffect(() => {
		const getData = async () => {
			const [assetTypeReq] = await Promise.all([Request.get<AssetTypeResult>('assettype', context.appState.authState)])
			setAssetTypes(assetTypeReq.data.assetTypes)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	React.useEffect(() => {
		setFilters({ ...filters, page: 1 })
	}, [search]) // eslint-disable-line

	/* Apply filters */
	let filteredRecords: AssetType[] = assetTypes ? assetTypes : []
	let paginatedFilteredRecords: AssetType[] = []
	if (filteredRecords.length > 0) {
		// Filter by string search
		filteredRecords = stringFilter(filteredRecords, search)

		// Pagination
		paginatedFilteredRecords = filteredRecords.filter((_record: unknown, index: number) => pageFilter(filters, index))
	}

	return (
		<Container>
			<Header title={'Asset Types'} subtitle={'View a list of asset types'} />

			<Row>
				<Col>
					<Header title={`Asset Types`} subtitle={'View a list of accounts'} />
				</Col>
				<Col sm={'auto'} style={{ paddingTop: '40px' }}>
					<Button className=" btn btn-primary round" onClick={() => props.history.push('/assetType/new')}>
						<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
						<span className="span-bold" style={{ color: 'white' }}>
							Create new asset type
						</span>
					</Button>
				</Col>
			</Row>

			<Card
				title={'Assets'}
				collapsible={true}
				headerComponent={() => (
					<Row>
						<Col>
							<Form.Control
								onChange={(event) => {
									setSearch(event.target.value)
								}}
								type="text"
								id="Search"
								key="Search"
								name="Search"
								value={search}
								placeholder="Search for an asset type"
							/>
						</Col>
					</Row>
				)}
			>
				<>
					<Table responsive borderless>
						<thead>
							<tr>
								<th>Name</th>
								<th>Shortcode</th>
								<th>Standards Based</th>
							</tr>
						</thead>
						<tbody>{pageStatus !== 'Loading' && renderAssetTypeList(paginatedFilteredRecords)}</tbody>
					</Table>
					{pageStatus === 'Loading' ? (
						<Row>
							<Col sm="auto">
								<Spinner size={'sm'} animation={'border'} />
							</Col>
						</Row>
					) : null}
					<Row className="justify-content-sm-center">
						<Col sm="auto">
							<Paginator filters={filters} setFilterDispatch={setFilters} allPaginatedRecordsLength={filteredRecords.length} />
						</Col>
					</Row>
				</>
			</Card>
		</Container>
	)
}

/* Filters */
const stringFilter = (list: AssetType[], text: string) => {
	return list.filter(
		(assetType: AssetType) =>
			assetType.assetTypeID.toLowerCase().indexOf(text.toLowerCase()) !== -1 || assetType.name.toLowerCase().indexOf(text.toLowerCase()) !== -1
	)
}

const renderAssetTypeList = (assetTypeRecords: AssetType[]) => {
	if (assetTypeRecords.length > 0) {
		return assetTypeRecords.map((assetType: AssetType, i: number) => {
			return (
				<tr key={i}>
					<td style={{ whiteSpace: 'nowrap' }}>
						<Link to={`/asset/${assetType.assetTypeID}`}>{assetType.name}</Link>
					</td>
					<td>{assetType.readableShortcode}</td>
					<td>{assetType.isStandardsBased ? 'Yes' : 'No'}</td>
				</tr>
			)
		})
	} else {
		return (
			<tr>
				<td>No Asset Types to list...</td>
			</tr>
		)
	}
}

export { ScreensAssetType }
