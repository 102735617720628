import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Row, Col } from 'react-bootstrap'
import { FormText } from '../../../components/UI/Form/Text'
import { TestProps } from './QuestionLookup.d'
import { Formik } from 'formik'
import * as yup from 'yup'
import { calibrateAdditiveValue } from 'dynaflow/utilities/calibration'
import { InwardAirVelocityTestOutput } from 'dynaflow/utilities/question'
import { Asset } from '../../../../../back-end/common/asset'
import { getPassValueOverrides } from 'dynaflow/utilities/testOverride'
import {
	onQuestionSubmit,
	QuestionElement,
	QuestionNavigationButtons,
	QuestionResult,
	QuestionResultElementGroup,
	TabRow,
	useLoadExistingQuestionResponse,
	QuestionHeader,
} from './QuestionComponents'
import { GridResult, VelocityGrid } from './AirVelocityUniformityTest'

// TODO: Figure out how to handle BSC1 counts
const getAssetHorizontalCount = (asset: Asset | null): number => {
	if (!asset) {
		return 1
	}
	if (asset.assetTypeIsStandardsBased) {
		const width = asset.assetWidth!
		// 50 mm in from the sides, and assuming a 110 mm diameter of the anenometer
		return Math.max(Math.ceil((width - (50 + 55) * 2) / 100) + 1, 1)
	} else {
		return asset.assetHorizontalReadings || 1
	}
}

interface QuestionValues {
	airVelocity: {
		horizontalPoints: number
		verticalPoints: number
		gridResults: GridResult[]
	}
	comment: string
}

export const velocityInputValidation = yup.number().label('Velocity reading').required('All readings are required')

const validationSchema = yup.object().shape({
	airVelocity: yup.object().shape({
		gridResults: yup.array().of(
			yup.object().shape({
				value: velocityInputValidation,
			})
		),
	}),
})

const InwardAirVelocityTest = (props: TestProps) => {
	const { test } = props
	const context = useContext(AppContext)
	const submitCallback = React.useRef(() => {})

	const numberHorizontal = React.useMemo(() => getAssetHorizontalCount(props.asset), [props.asset])
	// AS1807 Test Method 8 - always two vertical readings
	const numberVertical = 2

	const getVelocityReadingInputs = React.useCallback((numHorizontal, numVertical) => {
		const inp = [] as GridResult[]
		for (let i = 0; i < (numHorizontal || 1) * (numVertical || 1); i++) {
			inp.push({
				point: i + 1,
				value: '',
			})
		}
		return inp
	}, [])

	const [questionValues, setQuestionValues] = React.useState<QuestionValues>({
		airVelocity: {
			horizontalPoints: numberHorizontal,
			verticalPoints: numberVertical,
			gridResults: getVelocityReadingInputs(numberHorizontal, numberVertical),
		},
		comment: '',
	})

	useLoadExistingQuestionResponse({
		...props,
		setQuestionValues,
		formatQuestionValues: React.useCallback((existingValues: InwardAirVelocityTestOutput): QuestionValues => {
			return {
				...existingValues,
				airVelocity: {
					horizontalPoints: existingValues.airVelocity.horizontalPoints,
					verticalPoints: existingValues.airVelocity.verticalPoints,
					gridResults: existingValues.airVelocity.gridResults.map(({ instrumentValue }, index) => {
						return { point: index + 1, value: instrumentValue.toString() }
					}),
				},
				comment: '',
			}
		}, []),
	})

	// Assume there's only one instrument type for this question
	const testInstrument =
		test?.testInstruments?.find((instrument) => instrument.instrumentTypeID === props.question?.instrumentTypes[0].instrumentTypeID) || null
	const calibration =
		testInstrument && testInstrument.testInstrumentCalibrationSnapshot ? JSON.parse(testInstrument?.testInstrumentCalibrationSnapshot) : null

	const PASS_VALUES = getPassValueOverrides(props.question)

	if (!calibration || !PASS_VALUES) {
		return null
	}

	return (
		<Formik
			initialValues={questionValues}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={true}
			onSubmit={(values) => {
				onQuestionSubmit({ ...props, values, authState: context.appState.authState, submitCallback })
			}}
			enableReinitialize={true}
		>
			{({ handleSubmit, errors, values, handleChange, handleReset, dirty, isSubmitting }) => {
				// Fill out the calculated fields for display only (it's recalculated on create/update)
				let failed = null
				let averageVelocity = null
				const lowestAcceptableReading = PASS_VALUES.minimumVelocityReading
				let lowestActualReading = null
				const highestAcceptableReading = PASS_VALUES.maximumVelocityReading
				let highestActualReading = null
				const completeResults = values.airVelocity.gridResults.filter((i) => !!i.value)
				if (completeResults.length === values.airVelocity.gridResults.length) {
					failed = false
					const calibratedValues = values.airVelocity.gridResults.map((i) => calibrateAdditiveValue(Number(i.value), calibration)!)
					averageVelocity = calibratedValues.reduce((partialSum, i) => partialSum! + i!, 0)! / values.airVelocity.gridResults.length
					lowestActualReading = Math.min(...calibratedValues!)
					highestActualReading = Math.max(...calibratedValues!)
					if (averageVelocity < lowestAcceptableReading || averageVelocity > highestAcceptableReading) {
						failed = true
					}
					if (
						(lowestActualReading < lowestAcceptableReading || highestActualReading > highestAcceptableReading) &&
						!(averageVelocity < PASS_VALUES.minimumAverageVelocity || averageVelocity > PASS_VALUES.maximumAverageVelocity)
					) {
						if (!values.comment.includes('Airflow is adequate but unstable')) {
							values.comment = 'Airflow is adequate but unstable\n' + values.comment
						}
					} else {
						values.comment = values.comment.replaceAll('Airflow is adequate but unstable', '')
					}
				}

				return (
					<>
						<TabRow
							tabSelectOptions={props.tabSelectOptions}
							onClick={(q, save) => {
								if (save) {
									submitCallback.current = () => props.goToQuestion(q)
									handleSubmit()
								} else {
									props.goToQuestion(q)
								}
							}}
							currentQuestion={props.question?.questionID}
							dirty={dirty}
							isSubmitting={isSubmitting}
							errors={errors}
						/>
						<Row>
							<Col>
								<QuestionHeader question={props.question} />
								<QuestionElement>
									<Row>
										{!props.asset?.assetTypeIsStandardsBased && (
											<>
												<Col className="mb-3">
													<FormText
														name={'horizontalPoints'}
														value={values.airVelocity.horizontalPoints}
														label={'Horizontal readings'}
														plaintext={!!test?.isLocked}
														disabled={!!test?.isLocked}
														feedback={errors?.airVelocity?.horizontalPoints}
														isInvalid={!!errors?.airVelocity?.horizontalPoints}
														onChange={(e) => {
															const n = Number(e.target.value)
															setQuestionValues({
																...values,
																airVelocity: {
																	...values.airVelocity,
																	horizontalPoints: n,
																	gridResults: getVelocityReadingInputs(n, values.airVelocity.verticalPoints),
																},
															})
															handleChange(e)
														}}
														type="number"
														min={1}
													/>
												</Col>
												<Col>
													<FormText
														name={'verticalPoints'}
														value={values.airVelocity.verticalPoints}
														label={'Vertical readings'}
														plaintext={!!test?.isLocked}
														disabled={!!test?.isLocked}
														feedback={errors?.airVelocity?.verticalPoints}
														isInvalid={!!errors?.airVelocity?.verticalPoints}
														onChange={(e) => {
															const n = Number(e.target.value)
															setQuestionValues({
																...values,
																airVelocity: {
																	...values.airVelocity,
																	verticalPoints: n,
																	gridResults: getVelocityReadingInputs(values.airVelocity.horizontalPoints, n),
																},
															})
															handleChange(e)
														}}
														type="number"
														min={1}
													/>
												</Col>
											</>
										)}
									</Row>
									<Row className="mb-4">
										{values.airVelocity.horizontalPoints && values.airVelocity.verticalPoints && (
											<VelocityGrid
												values={values.airVelocity}
												errors={errors?.airVelocity?.gridResults}
												formikKey="airVelocity"
												handleChange={handleChange}
												calibration={calibration}
												disabled={!!test?.isLocked}
											/>
										)}
									</Row>
									<QuestionResultElementGroup
										results={[
											{ title: 'Average face velocity', value: `${averageVelocity?.toFixed(2) || '-'} m/s` },
											{ title: 'Lowest acceptable reading', value: `${lowestAcceptableReading?.toFixed(2) || '-'} m/s` },
											{ title: 'Lowest actual reading', value: `${lowestActualReading?.toFixed(2) || '-'} m/s` },
											{ title: 'Highest acceptable reading', value: `${highestAcceptableReading?.toFixed(2) || '-'} m/s` },
											{ title: 'Highest actual reading', value: `${highestActualReading?.toFixed(2) || '-'} m/s` },
										]}
									/>
								</QuestionElement>
								<QuestionResult
									failed={failed}
									questionHasComment={true}
									questionHasForcePass={false}
									commentValue={values.comment}
									handleChange={handleChange}
									disabled={!!test?.isLocked}
									isStandardsBased={props.asset?.assetTypeIsStandardsBased}
								/>
								<QuestionNavigationButtons
									{...props}
									handleReset={handleReset}
									handleSubmit={handleSubmit}
									submitCallback={submitCallback}
									setSubmitCallback={(fn) => (submitCallback.current = fn)}
									dirty={dirty}
									isSubmitting={isSubmitting}
								/>
							</Col>
						</Row>
					</>
				)
			}}
		</Formik>
	)
}

export { InwardAirVelocityTest }
