import * as EntityType from '../constants/entityType'
import { PermissionObject } from '../definitions/access'

enum Role {
	SafeTickOwner = '2df8c558-7914-48e4-996c-6f9df768ce69',
	SafeTickManager = '5cd6601a-1250-445a-a29a-470f77ca782e',
	InstitutionOwner = '6ec54684-6360-4719-bd85-1197b6f85ba7',
	InstitutionManager = '06924a98-11f7-4d38-86e4-4bd43aadfaec',
	InstitutionSupervisor = 'ff854c83-bf4d-4460-a939-00e50ea3c49e',
	InstitutionLabUser = 'e9838224-8c6b-41a1-9cdf-41bad12089bb',
	FacilitiesCompanyOwner = 'b290fa9f-6581-4cd2-ad29-77f86753666e',
	FacilitiesCompanyManager = '40ddaf4a-c270-4e65-ad16-beac0f1d86a9',
	TestingContractorOwner = '198d2ea1-766e-4bc3-abd3-0d0d55bae149',
	TestingContractorManager = 'c390c00f-6562-486e-8bce-c7214f320220',
	TestingContractorSupervisor = '481c092b-0c68-4d9f-be28-ddfee79ea0a1',
	TestingContractorTechnician = '19d8a70b-f998-4f32-98fe-e6a5c0bc3742',
}

/* All Permission check functions should return false by default! */

const writeUserInSameEntityRoles = [
	Role.SafeTickOwner,
	Role.InstitutionOwner,
	Role.InstitutionManager,
	Role.InstitutionSupervisor,
	Role.FacilitiesCompanyOwner,
	Role.TestingContractorOwner,
	Role.TestingContractorManager,
	Role.TestingContractorSupervisor,
]
const writeUserInAboveEntityRoles = [
	Role.FacilitiesCompanyOwner,
	Role.FacilitiesCompanyManager,
	Role.TestingContractorOwner,
	Role.TestingContractorManager,
	Role.TestingContractorSupervisor,
]
const writeUserInBelowEntityRoles = [
	Role.SafeTickOwner,
	Role.SafeTickManager,
	Role.InstitutionOwner,
	Role.InstitutionManager,
	Role.FacilitiesCompanyOwner,
	Role.FacilitiesCompanyManager,
]

interface WriteUserContext {
	entityID: string
	userRoleLevel?: number
	userRoleID?: string
	entityLevel: number
}

const canWriteUser = (permission: PermissionObject, context: WriteUserContext): boolean => {
	if (permission.isAdmin) {
		return true
	}

	if (permission.scope) {
		const scope = permission.scope
		const roleRelativity = scope.roleLevel - (context.userRoleLevel || 0)
		if (permission.entityID === context.entityID) {
			// for front-end usage
			if (context.userRoleLevel === undefined) {
				return writeUserInSameEntityRoles.some((role) => role === permission.roleID)
			}
			// if setup in own entity the new users role must simply be lower than mine
			// or in the case of an owner anyone
			if (roleRelativity > 0 || isOwner(permission.roleID)) {
				return true
			}
		} else {
			return scope.accounts.some((acc) => {
				// for front-end usage
				if (context.userRoleID === undefined || context.userRoleLevel === undefined) {
					if (
						scope.entityTypeLevel - context.entityLevel > 0 &&
						acc.isSubscriberRelativity === 0 &&
						writeUserInBelowEntityRoles.some((role) => role === permission.roleID)
					) {
						return true
					} else if (
						scope.entityTypeLevel - context.entityLevel < 0 &&
						acc.isSubscriberRelativity === 0 &&
						writeUserInAboveEntityRoles.some((role) => role === permission.roleID)
					) {
						return true
					}
					return false
				}
				// if entity of user trying to be added is lower down the heirarchy
				// we must be sub or sub is higher and we can only add owners and only if we are manager or owner
				if (
					scope.entityTypeLevel - context.entityLevel > 0 &&
					acc.isSubscriberRelativity === 0 &&
					(isOwner(permission.roleID) || isManager(permission.roleID)) &&
					isOwner(context.userRoleID)
				) {
					return true
				}
				// if entity of user trying to be added is higher up to heirarchy
				// we must be the subscriber, and be a manager or owner or tc supervisor
				if (
					scope.entityTypeLevel - context.entityLevel < 0 &&
					acc.isSubscriberRelativity === 0 &&
					(isOwner(permission.roleID) || isManager(permission.roleID) || permission.roleID === Role.TestingContractorSupervisor)
				) {
					return true
				}

				return false
			})
		}
	}

	return false
}

interface WriteLocationContext {
	accountID: string
}

const writeLocationRoles = [
	Role.SafeTickOwner,
	Role.SafeTickManager,
	Role.InstitutionOwner,
	Role.InstitutionManager,
	Role.FacilitiesCompanyOwner,
	Role.FacilitiesCompanyManager,
	Role.TestingContractorOwner,
	Role.TestingContractorManager,
	Role.TestingContractorSupervisor,
]
const canWriteLocation = (permission: PermissionObject, context: WriteLocationContext): boolean => {
	if (permission.isAdmin) {
		return true
	}

	if (permission.scope) {
		const contextAccount = permission.scope.accounts.find((acc) => acc.accountID === context.accountID)
		if (contextAccount) {
			const iAmLocationCreationRole = !!writeLocationRoles.find((role) => role === permission.roleID)
			// to add a location need to be a subscriber or have write access, additionally need to be in a certain role
			if ((contextAccount.isSubscriberRelativity === 0 || contextAccount.canWriteLocations) && iAmLocationCreationRole) {
				return true
			}
		}
	}

	return false
}
const canWriteBuilding = (permission: PermissionObject, context: WriteLocationContext): boolean => {
	if (permission.isAdmin) {
		return true
	}

	if (permission.scope) {
		const contextAccount = permission.scope.accounts.find((acc) => acc.accountID === context.accountID)
		if (contextAccount) {
			if (contextAccount.isSubscriberRelativity === 0 || contextAccount.canWriteLocations) {
				return true
			}
		}
	}

	return false
}

interface WriteAssetContext {
	accountID: string
	cud: 'create' | 'update' | 'delete'
}

const writeAssetRoles = [
	Role.SafeTickOwner,
	Role.SafeTickManager,
	Role.InstitutionOwner,
	Role.InstitutionManager,
	Role.FacilitiesCompanyOwner,
	Role.FacilitiesCompanyManager,
	Role.TestingContractorOwner,
	Role.TestingContractorManager,
	Role.TestingContractorSupervisor,
]
const canWriteAsset = (permission: PermissionObject, context: WriteAssetContext): boolean => {
	if (permission.isAdmin) {
		return true
	}

	if (permission.scope) {
		const contextAccount = permission.scope.accounts.find((acc) => acc.accountID === context.accountID)
		if (contextAccount) {
			const iAmAssetCreationRole = writeAssetRoles
				.concat(context.cud === 'create' ? [Role.TestingContractorTechnician] : [])
				.some((role) => role === permission.roleID)
			// to add a asset need to be a subscriber or have write access, additionally need to be in a certain role
			if ((contextAccount.isSubscriberRelativity === 0 || contextAccount.canWriteAssets) && iAmAssetCreationRole) {
				return true
			}
		}
	}

	return false
}

const writeAccountRoles = [
	Role.SafeTickOwner,
	Role.SafeTickManager,
	Role.FacilitiesCompanyOwner,
	Role.FacilitiesCompanyManager,
	Role.TestingContractorOwner,
	Role.TestingContractorManager,
	Role.TestingContractorSupervisor,
]
const canWriteAccount = (permission: PermissionObject): boolean => {
	if (permission.isAdmin) {
		return true
	}

	if (permission.scope) {
		return writeAccountRoles.some((role) => role === permission.roleID)
	}

	return false
}

const writeInstrumentRoles = [Role.TestingContractorOwner, Role.TestingContractorManager, Role.TestingContractorSupervisor]

const canWriteInstrument = (permission: PermissionObject): boolean => {
	if (permission.isAdmin) {
		return true
	}

	if (permission.scope) {
		const iAmTestingContractor = permission.scope.entityTypeID === EntityType.TestingContractor
		const hasAllowedRole = writeInstrumentRoles.some((role) => role === permission.roleID)
		if (iAmTestingContractor && hasAllowedRole) {
			return true
		}
	}

	return false
}

const writeCompetencyRoles = [
	Role.SafeTickOwner,
	Role.SafeTickManager,
	Role.FacilitiesCompanyOwner,
	Role.FacilitiesCompanyManager,
	Role.TestingContractorOwner,
	Role.TestingContractorManager,
	Role.InstitutionManager,
	Role.InstitutionOwner,
]
const canWriteCompetency = (permission: PermissionObject): boolean => {
	if (permission.isAdmin) {
		return true
	}

	if (permission.scope) {
		return writeCompetencyRoles.some((role) => role === permission.roleID)
	}

	return false
}

const writeTestRoles = [
	Role.SafeTickOwner,
	Role.SafeTickManager,
	Role.TestingContractorOwner,
	Role.TestingContractorManager,
	Role.TestingContractorSupervisor,
	Role.TestingContractorTechnician,
]
const canWriteTest = (permission: PermissionObject): boolean => {
	if (permission.isAdmin) {
		return true
	}

	if (permission.scope) {
		return writeTestRoles.some((role) => role === permission.roleID)
	}

	return false
}

/* helpful functions */

const ownerRoleIDs = [Role.SafeTickOwner, Role.InstitutionOwner, Role.FacilitiesCompanyOwner, Role.TestingContractorOwner]
const isOwner = (roleID: string): boolean => {
	return !!ownerRoleIDs.find((role) => role === roleID)
}
const managerRoleIDs = [Role.SafeTickManager, Role.InstitutionManager, Role.FacilitiesCompanyManager, Role.TestingContractorManager]
const isManager = (roleID: string): boolean => {
	return !!managerRoleIDs.find((role) => role === roleID)
}

export { canWriteAccount, canWriteAsset, canWriteInstrument, canWriteLocation, canWriteBuilding, canWriteUser, canWriteCompetency, canWriteTest }
