import React from 'react'
import { RouteComponentProps, StaticContext } from 'react-router'
import { TestLocationStateParams, TestWrapper } from './TestWrapper'

export interface ScreensTestMatchParams {
	id: string
	assetID: string
}

const ScreensTestDetails = (props: RouteComponentProps<ScreensTestMatchParams, StaticContext, TestLocationStateParams>) => {
	const matchParam = props.match!.params.id
	const assetID = matchParam.includes('new') ? props.location.pathname.split('/').pop() : undefined
	const testID = !matchParam.includes('new') ? matchParam : undefined
	console.log(matchParam, assetID, testID)

	return <TestWrapper testID={testID} assetID={assetID} header={assetID ? 'Create new test' : 'View test'} {...props} />
}

export { ScreensTestDetails }
