import React from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
import { File } from 'react-feather'
import { Loading } from '../UI/Loading/Loading'

interface BatchTestModalProps extends ModalProps {
	loadingMessage?: string
	link: string
	setBatchTestLink: React.Dispatch<React.SetStateAction<boolean | string>>
}

const BatchTestModal = (props: BatchTestModalProps) => {
	const { loadingMessage, link, setBatchTestLink, ...modalProps } = props

	return (
		<Modal {...modalProps} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Body>
				{link ? (
					link.slice(0, 5) === 'Error' ? (
						<>
							<p>{link}</p>
							<Button
								onClick={() => {
									setBatchTestLink(false)
								}}
							>
								OK
							</Button>
						</>
					) : (
						<>
							<File />
							<a href={link} target="_blank" rel="noreferrer">
								Reports.zip
							</a>
						</>
					)
				) : (
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						{loadingMessage && <p>{loadingMessage}</p>}
						<Loading spinner={true} size={4} />
					</div>
				)}
			</Modal.Body>
		</Modal>
	)
}

export default BatchTestModal
