import React from 'react'
import { FormikErrors } from 'formik'
import { Row, Col, Dropdown } from 'react-bootstrap'
import { Asset } from '../../../../back-end/common/asset'
import { AssetType } from '../../../../back-end/common/assetType'
import { Location } from '../../../../back-end/common/location'

type AssetErrorMessages = FormikErrors<Asset>

interface AssetTypeDropdownProps {
	asset: Asset
	assetTypes: AssetType[]
	setAsset: (asset: Asset) => void
	setSelectedAssetType: (assetType: AssetType) => void
	errors?: AssetErrorMessages
}

const AssetTypeDropdown = (props: AssetTypeDropdownProps) => {
	const renderAssetTypeOptions = (
		AssetTypes: AssetType[],
		asset: Asset,
		setAsset: (asset: Asset) => void,
		setSelectedAssetType: (assetType: AssetType) => void
	) => {
		const assetTypeOptions = AssetTypes.filter((assetType: AssetType) => assetType.accountID === asset.accountID)

		return assetTypeOptions.map((assetType, i) => {
			return (
				<Dropdown.Item
					key={`AssetTypeSelect${i}`}
					onClick={() => {
						setAsset({
							...asset,
							assetTypeName: assetType.name,
							assetTypeID: assetType.assetTypeID,
							assetChamberHeight: assetType.showChamberHeight ? 0 : null,
							assetChamberWidth: assetType.showChamberWidth ? 0 : null,
							assetChamberDepth: assetType.showChamberHeight ? 0 : null,
						})
						setSelectedAssetType(assetType)
					}}
				>
					{assetType.name}
				</Dropdown.Item>
			)
		})
	}

	return (
		<>
			<Row className="form-label" style={{ marginBottom: '.5rem' }}>
				<Col>Asset Type</Col>
			</Row>
			<Row>
				<Col>
					<Dropdown>
						<Dropdown.Toggle
							className={`form form-control ${props.errors?.assetTypeID ? 'invalid-feedback-custom-border' : ''}`}
							variant="secondary"
							id="dropdown-assetType"
						>
							{props.asset.assetTypeName}
						</Dropdown.Toggle>
						<Dropdown.Menu>{renderAssetTypeOptions(props.assetTypes, props.asset, props.setAsset, props.setSelectedAssetType)}</Dropdown.Menu>
					</Dropdown>
					{props.errors?.assetTypeID ? <div className="invalid-feedback-custom">{props.errors?.assetTypeID}</div> : null}
				</Col>
			</Row>
		</>
	)
}

interface AssetLocationDropdownProps {
	asset: Asset
	locations: Location[]
	setAsset: (asset: Asset) => void
	errors?: AssetErrorMessages
}

const AssetLocationDropdown = (props: AssetLocationDropdownProps) => {
	const renderLocationOptions = (Locations: Location[], asset: Asset, setAsset: (asset: Asset) => void) => {
		const locationOptions = Locations.filter((location: Location) => location.accountID === asset.accountID)

		return locationOptions.map((e: Location, i: number) => {
			return (
				<Dropdown.Item
					key={`LocationSelect${i}`}
					onClick={() =>
						setAsset({
							...asset,
							locationName: e.locationName,
							locationID: e.locationID,
							buildingID: '',
							buildingName: '',
							roomID: '',
							roomName: '',
						})
					}
				>
					{e.locationName}
				</Dropdown.Item>
			)
		})
	}

	return (
		<>
			<Row className="form-label" style={{ marginBottom: '.5rem' }}>
				<Col>Location</Col>
			</Row>
			<Row>
				<Col>
					<Dropdown>
						<Dropdown.Toggle
							className={`form form-control ${props.errors?.locationID ? 'invalid-feedback-custom-border' : ''}`}
							variant="secondary"
							id="dropdown-assetType"
						>
							{props.asset.locationName}
						</Dropdown.Toggle>
						<Dropdown.Menu>{renderLocationOptions(props.locations, props.asset, props.setAsset)}</Dropdown.Menu>
					</Dropdown>
					{props.errors?.locationID ? <div className="invalid-feedback-custom">{props.errors?.locationID}</div> : null}
				</Col>
			</Row>
		</>
	)
}

const AssetBuildingDropdown = (props: AssetLocationDropdownProps) => {
	const renderBuildingOptions = (Locations: Location[], asset: Asset, setAsset: (asset: Asset) => void) => {
		const selectedLocation = Locations.find((location: Location) => location.locationID === asset.locationID)
		if (selectedLocation) {
			return selectedLocation.buildings.map((e, i) => {
				return (
					<Dropdown.Item
						key={`BuildingSelect${i}`}
						onClick={() => setAsset({ ...asset, buildingID: e.buildingID, buildingName: e.buildingName, roomID: '', roomName: '' })}
					>
						{e.buildingName}
					</Dropdown.Item>
				)
			})
		}
	}
	return props.asset.locationID ? (
		<div>
			<Row className="form-label" style={{ marginBottom: '.5rem' }}>
				<Col>Building</Col>
			</Row>
			<Row>
				<Col>
					<Dropdown>
						<Dropdown.Toggle
							className={`form form-control ${props.errors?.buildingID ? 'invalid-feedback-custom-border' : ''}`}
							variant="secondary"
							id="dropdown-assetType"
						>
							{props.asset.buildingName}
						</Dropdown.Toggle>
						<Dropdown.Menu>{renderBuildingOptions(props.locations, props.asset, props.setAsset)}</Dropdown.Menu>
					</Dropdown>
					{props.errors?.buildingID ? <div className="invalid-feedback-custom">{props.errors?.buildingID}</div> : null}
				</Col>
			</Row>
		</div>
	) : null
}

const AssetRoomDropdown = (props: AssetLocationDropdownProps) => {
	const renderRoomOptions = (Locations: Location[], asset: Asset, setAsset: (asset: Asset) => void) => {
		const selectedLocation = Locations.find((location: Location) => location.locationID === asset.locationID)
		if (selectedLocation) {
			const selectedBuilding = selectedLocation.buildings.find((building) => building.buildingID === asset.buildingID)

			if (selectedBuilding) {
				return selectedBuilding.rooms.map((e, i) => {
					return (
						<Dropdown.Item key={`RoomSelect${i}`} onClick={() => setAsset({ ...asset, roomID: e.roomID, roomName: e.roomName })}>
							{e.roomName}
						</Dropdown.Item>
					)
				})
			}
		}
	}
	return props.asset.buildingID ? (
		<div>
			<Row className="form-label" style={{ marginBottom: '.5rem' }}>
				<Col>Room</Col>
			</Row>
			<Row>
				<Col>
					<Dropdown>
						<Dropdown.Toggle
							className={`form form-control ${props.errors?.roomID ? 'invalid-feedback-custom-border' : ''}`}
							variant="secondary"
							id="dropdown-assetType"
						>
							{props.asset.roomName}
						</Dropdown.Toggle>
						<Dropdown.Menu>{renderRoomOptions(props.locations, props.asset, props.setAsset)}</Dropdown.Menu>
						{props.errors?.roomID ? <div className="invalid-feedback-custom">{props.errors?.roomID}</div> : null}
					</Dropdown>
				</Col>
			</Row>
		</div>
	) : null
}

export { AssetTypeDropdown, AssetLocationDropdown, AssetBuildingDropdown, AssetRoomDropdown }
