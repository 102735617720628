import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Loading } from '../Loading/Loading'

interface ConfirmModalProps {
	show: boolean
	title?: string
	message: string
	confirmButtonText?: string
	onConfirmPress: () => void
	onCancelPress: () => void
	submitting?: boolean
}

export const ConfirmModal = (props: ConfirmModalProps) => {
	return (
		<Modal
			show={props.show}
			onHide={() => {
				if (!props.submitting) {
					props.onCancelPress()
				}
			}}
		>
			{props.title && <Modal.Header>{props.title}</Modal.Header>}
			<Modal.Body>
				<p>{props.message}</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" disabled={props.submitting} onClick={props.onCancelPress}>
					Cancel
				</Button>
				<Button variant="primary" disabled={props.submitting} onClick={props.onConfirmPress}>
					{props.submitting ? <Loading size={1} spinner noBorder /> : props.confirmButtonText || 'Confirm'}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
