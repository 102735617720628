import React from 'react'
import { Row, Col } from 'react-bootstrap'

interface HeaderProps {
	title: string
	subtitle: string
}

export const Header = (props: HeaderProps) => {
	return (
		<header>
			<Row>
				<Col className="header-title" sm={'auto'} style={{ flex: '1 1 auto' }}>
					{props.title}
				</Col>
			</Row>
			<Row>
				<Col className="header-subtitle" sm={'auto'} style={{ flex: '1 1 auto' }}>
					{props.subtitle}
				</Col>
			</Row>
		</header>
	)
}
