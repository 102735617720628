import React from 'react'
import { Button, ButtonGroup, Spinner } from 'react-bootstrap'
import { Trash2 } from 'react-feather'

interface DeleteButtonProps {
	id: string
	onClick?: (id: string) => Promise<void>
	customOnClick?: () => Promise<void> | void
	overlay?: boolean
	disabled?: boolean
}

export const DeleteButton = (props: DeleteButtonProps) => {
	const [isWaiting, setIsWaiting] = React.useState<boolean>(false)
	const [showConfirm, setShowConfirm] = React.useState<boolean>(false)

	const handleClick = () => {
		if (props.customOnClick) {
			return props.customOnClick()
		}
		if (props.onClick) {
			return props.onClick!(props.id)
		}
	}

	return (
		<span
			style={{
				color: 'var(--danger)',
				float: 'right',
				textAlign: 'right',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end',
				...(props.overlay && showConfirm
					? {
							background: 'white',
							padding: '10px',
							borderRadius: '3px',
							boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.06)',
					  }
					: {}),
			}}
		>
			{!showConfirm ? (
				<Trash2 style={{ cursor: 'pointer' }} onClick={() => setShowConfirm(true)} />
			) : (
				<>
					Delete?
					<ButtonGroup style={{ marginLeft: '10px', height: '100%' }}>
						<Button
							variant="danger"
							disabled={isWaiting || props.disabled}
							onClick={async () => {
								setIsWaiting(true)

								handleClick()

								setIsWaiting(false)
								setShowConfirm(false)
							}}
						>
							{isWaiting ? <Spinner animation={'border'} size={'sm'} /> : 'Yes'}
						</Button>
						<Button variant="light" disabled={isWaiting} onClick={() => setShowConfirm(false)}>
							No
						</Button>
					</ButtonGroup>
				</>
			)}
		</span>
	)
}
