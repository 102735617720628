import React from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap'

import { Header } from '../../components/UI/Header/Header'
import { FormText } from '../../components/UI/Form/Text'
import { DeleteButton } from '../../components/UI/Form/DeleteButton'

import { AppContext } from '../../App'
import * as Request from '../../utilities/request'
import * as EntityType from '../../constants/entityType'
import { Calibration } from '../../../../back-end/common/calibration'
import { CalibrationItem } from '../../../../back-end/common/calibrationItem'
import { DocumentResult } from '../../../../back-end/common/document'
import { PageStatus } from '../../types/PageStatus'
import { Uploader, uploadToSignedUrl } from '../../components/UI/Uploader/Uploader'
import generateUuid from '../../utilities/uuid'
import { DownloadLink } from '../../components/UI/Uploader/DownloadLink'
import { Instrument } from '../../../../back-end/common/instruments'
import { INSTRUMENT_TYPE } from '../../constants/instrumentType'
import { calibrateAdditiveValue, calibrateMultiplicativeValue, calibrateNoChangeInValue } from 'dynaflow/utilities/calibration'
import { FormikErrors } from 'formik'
import { canWriteInstrument } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'

type CalibrationType = 'add' | 'multiply' | 'none' | 'dated'

const getInstrumentCalibrationFunction = (
	instrument: Partial<Instrument>
): [(value: number | undefined | null, calibration: Pick<Calibration, 'calibrationItems'>) => number | null, CalibrationType] => {
	switch (instrument.instrumentTypeID) {
		case INSTRUMENT_TYPE.AEROSOL_PHOTOMETER:
		case INSTRUMENT_TYPE.SOUND_LEVEL_METER_CALIBRATOR:
			return [calibrateNoChangeInValue, 'dated']
		case INSTRUMENT_TYPE.SOUND_LEVEL_METER:
			return [calibrateNoChangeInValue, 'none']
		case INSTRUMENT_TYPE.LUX_METER:
		case INSTRUMENT_TYPE.UV_METER:
			return [calibrateMultiplicativeValue, 'multiply']
		case INSTRUMENT_TYPE.HOTWIRE_ANEMOMETER:
		case INSTRUMENT_TYPE.VANE_ANEMOMETER:
		default:
			return [calibrateAdditiveValue, 'add']
	}
}

const getInstrumentCalibrationUnits = (instrument: Partial<Instrument>) => {
	switch (instrument.instrumentTypeID) {
		case INSTRUMENT_TYPE.SOUND_LEVEL_METER:
		case INSTRUMENT_TYPE.SOUND_LEVEL_METER_CALIBRATOR:
			return 'db'
		case INSTRUMENT_TYPE.LUX_METER:
			return 'lux'
		case INSTRUMENT_TYPE.HOTWIRE_ANEMOMETER:
		case INSTRUMENT_TYPE.VANE_ANEMOMETER:
			return 'm/s'
		case INSTRUMENT_TYPE.UV_METER:
		case INSTRUMENT_TYPE.AEROSOL_PHOTOMETER:
		default:
			return ''
	}
}

type PageCalibration = Pick<
	Calibration,
	| 'calibrationID'
	| 'calibrationDate'
	| 'calibrationCertificateNumber'
	| 'calibrationCertificateS3Path'
	| 'calibrationCertificateFileName'
	| 'calibrationItems'
>
type PageCalibrationItem = Pick<
	CalibrationItem,
	'calibrationItemID' | 'calibrationItemReferenceNumber' | 'calibrationItemReferenceReading' | 'calibrationItemInstrumentReading'
>

interface InstrumentCalibrationProps {
	calibrations: PageCalibration[]
	instrument: Partial<Instrument>
	pageStatus: PageStatus
	onNewCalibration?: () => void
	onEditCalibration?: () => void
	onNewCalibrationItem?: () => void
	onUpdateCalibration?: (calibrations: PageCalibration[]) => void
	onSave?: (type: CalibrationType) => void
	errors?: FormikErrors<PageCalibration>
	readOnly?: boolean
}

const InstrumentCalibration = (props: InstrumentCalibrationProps) => {
	const context = React.useContext(AppContext)
	const readOnly = props.readOnly || props.pageStatus === 'Ready'
	const lastElement = props.calibrations[props.calibrations.length - 1]

	const [isUploading, setIsUploading] = React.useState<boolean>(false)

	const onChange = (elements: Partial<PageCalibration>) => {
		if (props.calibrations && props.onUpdateCalibration) {
			const updatedCalibrations = [...props.calibrations.slice(0, -1), { ...lastElement, ...elements }]
			props.onUpdateCalibration(updatedCalibrations)
		}
	}

	const calibrationType = getInstrumentCalibrationFunction(props.instrument)[1]

	if (calibrationType === 'none') {
		return (
			<div>
				<Row>
					<Col xs="auto">
						<Header title={'Instrument Calibration'} subtitle={'No calibration required'} />
					</Col>
				</Row>
			</div>
		)
	}

	return (
		<div id="calibration-table">
			<Row>
				<Col xs="auto">
					<Header title={'Instrument Calibration'} subtitle={''} />
				</Col>
				<Col></Col>
				{(props.pageStatus === 'Editing' || props.pageStatus === 'Submitting') && (
					<Col xs="auto">
						<Button
							onClick={async () => {
								if (props.onSave) {
									props.onSave(calibrationType)
								}
							}}
							style={buttonStyle}
							disabled={props.pageStatus === 'Submitting' || isUploading}
						>
							{isUploading ? 'Preparing doc' : 'Save Calibration'}
						</Button>
					</Col>
				)}
				{props.pageStatus === 'Ready' &&
					!props.readOnly &&
					(context.appState.userPermissions.entityTypeID === EntityType.TestingContractor ||
						context.appState.userPermissions.entityTypeID === EntityType.Admin) &&
					canWriteInstrument(appStateToPermissionObject(context.appState)) && (
						<>
							<Col xs="auto">
								<Button onClick={props.onNewCalibration} style={buttonStyle}>
									Create new Calibration
								</Button>
							</Col>
							<Col xs="auto">
								<Button disabled={props.calibrations.length < 1} onClick={props.onEditCalibration} style={buttonStyle}>
									Edit Calibration
								</Button>
							</Col>
						</>
					)}
			</Row>
			{lastElement ? (
				<Row>
					<Col className="col-sm-3">
						{calibrationType !== 'dated' && (
							<Row>
								<Col className="col-sm-4" style={{ fontWeight: 'bold' }}>
									Range:
								</Col>
								<Col>{displayRange(lastElement.calibrationItems, props.instrument)}</Col>
							</Row>
						)}
						<Row>
							<Col className="col-sm-4" style={{ fontWeight: 'bold' }}>
								Certificate:
							</Col>
							<Col>
								{!readOnly && (
									<Uploader
										name="calibrationCertificateS3Path"
										multiple={false}
										accept="application/pdf"
										maxCount={1}
										defaultFileList={
											lastElement.calibrationCertificateS3Path && lastElement.calibrationCertificateFileName
												? [
														{
															name: lastElement.calibrationCertificateFileName,
															percent: 100,
															status: 'done',
															thumbUrl: '',
															uid: lastElement.calibrationID,
															url: lastElement.calibrationCertificateS3Path,
														},
												  ]
												: []
										}
										onRemove={() => {
											onChange({ calibrationCertificateS3Path: null, calibrationCertificateFileName: null })
										}}
										action={async (file) => {
											// get s3 url
											const s3Path = `calibration/${generateUuid()}.pdf`
											const req = await Request.post<DocumentResult>(
												'document',
												{ type: 'application/pdf', s3Location: s3Path, operation: 'putObject' },
												context.appState.authState
											)
											// set field
											setIsUploading(true)
											onChange({ calibrationCertificateS3Path: s3Path, calibrationCertificateFileName: file.name })
											return req.data.file.signedUrl!
										}}
										customRequest={async (info) => {
											if (typeof info.file !== 'string') {
												const res = await uploadToSignedUrl(info.action, info.file, info.filename)
												info.onSuccess && info.onSuccess(res)
											}
											setIsUploading(false)
										}}
										itemRender={(_origin, _file, _fileList, actions) => (
											<DownloadLink
												path={lastElement.calibrationCertificateS3Path!}
												fileType="application/pdf"
												name={lastElement.calibrationCertificateFileName!}
												icon="File"
												onDelete={actions.remove}
											/>
										)}
									/>
								)}
								{readOnly && lastElement.calibrationCertificateS3Path && lastElement.calibrationCertificateFileName && (
									<DownloadLink
										path={lastElement.calibrationCertificateS3Path}
										fileType="application/pdf"
										name={lastElement.calibrationCertificateFileName}
										icon="File"
									/>
								)}
								{readOnly && !lastElement.calibrationCertificateS3Path && 'No File'}
							</Col>
						</Row>
						<Row>
							<Col className="col-sm-4" style={{ fontWeight: 'bold' }}>
								Cert. No:
							</Col>
							<Col>
								<FormText
									name={'calibrationCertificateNumber'}
									value={lastElement.calibrationCertificateNumber || ''}
									onChange={(e) => onChange({ [e.target.name]: e.target.value })}
									plaintext={readOnly}
									disabled={readOnly}
									feedback={props.errors?.calibrationCertificateNumber}
									isInvalid={!!props.errors?.calibrationCertificateNumber}
								/>
							</Col>
						</Row>
						<Row>
							<Col className="col-sm-4" style={{ fontWeight: 'bold' }}>
								Test Date:
							</Col>
							<Col>
								<FormText
									name={'calibrationDate'}
									type="date"
									value={lastElement.calibrationDate?.slice(0, 10)}
									onChange={(e) => onChange({ [e.target.name]: e.target.value })}
									plaintext={readOnly}
									disabled={readOnly}
									feedback={props.errors?.calibrationDate}
									isInvalid={!!props.errors?.calibrationDate}
								/>
							</Col>
						</Row>
					</Col>
					{calibrationType !== 'dated' && (
						<Col>
							<Table style={{ textAlign: 'center' }}>
								<thead>
									<tr>
										<th>Reference</th>
										<th>Reference Reading</th>
										<th>Instrument Reading</th>
										<th>{calibrationType === 'add' ? 'Correction to be Added' : 'Correction factor'}</th>
									</tr>
								</thead>
								<tbody>
									{lastElement.calibrationItems.map((item, index) => (
										<CalibrationItemTable
											key={index}
											calibrationItem={item}
											calibrationType={calibrationType}
											pageStatus={props.pageStatus}
											onUpdateCalibrationItem={(calibrationItem) => {
												const updatedItems = lastElement.calibrationItems.map((calItem, calIndex) =>
													calIndex === index ? calibrationItem : calItem
												)
												onChange({ calibrationItems: updatedItems })
											}}
											onDelete={() => {
												console.log('delete', index)
												const updatedItems = lastElement.calibrationItems.filter((_calItem, calIndex) => calIndex !== index)
												onChange({ calibrationItems: updatedItems })
											}}
										/>
									))}
									{lastElement.calibrationItems.length === 0 && (
										<tr>
											<td colSpan={5}></td>
										</tr>
									)}
									{props.pageStatus === 'Editing' && (
										<tr>
											<td colSpan={5}>
												<Button onClick={props.onNewCalibrationItem}>Add new calibration item</Button>
											</td>
										</tr>
									)}
								</tbody>
							</Table>
						</Col>
					)}
				</Row>
			) : (
				<Row>
					<Col xs="auto">This instrument has never been calibrated</Col>
					<Col></Col>
				</Row>
			)}
		</div>
	)
}

interface CalibrationItemTableProps {
	calibrationItem: PageCalibrationItem
	calibrationType: CalibrationType
	pageStatus: PageStatus
	errors?: FormikErrors<PageCalibrationItem>
	onUpdateCalibrationItem: (calibrationItems: PageCalibrationItem) => void
	onDelete: () => void
}

const CalibrationItemTable = (props: CalibrationItemTableProps) => {
	const readOnly = props.pageStatus === 'Ready'

	const onChange = (property: string, value: string | number | boolean | null) => {
		props.onUpdateCalibrationItem({
			...props.calibrationItem,
			[property]: value,
		})
	}

	const correctionRange = getCorrection(props.calibrationItem, props.calibrationType)!.toFixed(2)
	return (
		<tr>
			<td>
				<FormText
					name={'calibrationItemReferenceNumber'}
					type="number"
					value={props.calibrationItem.calibrationItemReferenceNumber}
					onChange={(e) => onChange(e.target.name, e.target.value)}
					plaintext={readOnly}
					disabled={readOnly}
					feedback={props.errors?.calibrationItemReferenceNumber}
					isInvalid={!!props.errors?.calibrationItemReferenceNumber}
				/>
			</td>
			<td>
				<FormText
					name={'calibrationItemReferenceReading'}
					type="number"
					value={props.calibrationItem.calibrationItemReferenceReading}
					onChange={(e) => onChange(e.target.name, e.target.value)}
					plaintext={readOnly}
					disabled={readOnly}
					feedback={props.errors?.calibrationItemReferenceReading}
					isInvalid={!!props.errors?.calibrationItemReferenceReading}
				/>
			</td>
			<td>
				<FormText
					name={'calibrationItemInstrumentReading'}
					type="number"
					value={props.calibrationItem.calibrationItemInstrumentReading}
					onChange={(e) => onChange(e.target.name, e.target.value)}
					plaintext={readOnly}
					disabled={readOnly}
					feedback={props.errors?.calibrationItemInstrumentReading}
					isInvalid={!!props.errors?.calibrationItemInstrumentReading}
				/>
			</td>
			<td>{correctionRange}</td>
			<td>{props.pageStatus === 'Editing' && <DeleteButton id={''} customOnClick={props.onDelete} />}</td>
		</tr>
	)
}

const getCorrection = (calibrationItem: Partial<CalibrationItem>, calibrationType: CalibrationType) => {
	if (calibrationItem.calibrationItemInstrumentReading !== undefined && calibrationItem.calibrationItemReferenceReading !== undefined) {
		switch (calibrationType) {
			case 'add':
				return calibrationItem.calibrationItemReferenceReading - calibrationItem.calibrationItemInstrumentReading
			case 'multiply':
				if (Number(calibrationItem.calibrationItemInstrumentReading) === 0) {
					return 1
				}
				return calibrationItem.calibrationItemReferenceReading / calibrationItem.calibrationItemInstrumentReading
			default:
				return calibrationItem.calibrationItemReferenceReading
		}
	}
}

const displayRange = (calibrationItems: Partial<CalibrationItem>[], instrument: Partial<Instrument>) => {
	if (calibrationItems.length > 0 && calibrationItems.length !== 1) {
		const highRange = getHighRange(calibrationItems)
		const lowRange = getLowRange(calibrationItems)
		return lowRange + ' to ' + highRange + ' ' + getInstrumentCalibrationUnits(instrument)
	} else if (calibrationItems.length === 1) {
		return calibrationItems[0].calibrationItemInstrumentReading
	} else {
		return 'No range...'
	}
}

const getLowRange = (calibrationItems: Partial<CalibrationItem>[]) => {
	if (calibrationItems !== undefined) {
		let lowRange = Number.MAX_SAFE_INTEGER
		calibrationItems.forEach(function (calibrationItem) {
			if (calibrationItem.calibrationItemReferenceReading !== undefined) {
				const instrumentReading = Number(calibrationItem.calibrationItemReferenceReading)
				if (instrumentReading < lowRange) {
					lowRange = instrumentReading
				}
			}
		})
		return lowRange
	}
}

const getHighRange = (calibrationItems: Partial<CalibrationItem>[]) => {
	if (calibrationItems !== undefined) {
		let highRange = Number.MIN_SAFE_INTEGER
		calibrationItems.forEach(function (calibrationItem) {
			if (calibrationItem.calibrationItemReferenceReading !== undefined) {
				const instrumentReading = Number(calibrationItem.calibrationItemReferenceReading)
				if (instrumentReading > highRange) {
					highRange = instrumentReading
				}
			}
		})
		return highRange
	}
}

const buttonStyle = {
	marginTop: '40px',
}

export { InstrumentCalibration, getInstrumentCalibrationFunction }
