import React, { useContext } from 'react'
import { Row, Col, Form, Image, Modal } from 'react-bootstrap'
import { ChevronRight } from 'react-feather'
import Camera, { FACING_MODES } from 'react-html5-camera-photo'
import { CameraFilled } from '@ant-design/icons'
import { Formik } from 'formik'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { Calibration } from '../../../../back-end/common/calibration'
import { DocumentResult } from '../../../../back-end/common/document'
import { Instrument } from '../../../../back-end/common/instruments'
import { InstrumentSet } from '../../../../back-end/common/instrumentSet'
import { Question } from '../../../../back-end/common/question'
import { QuestionInstrument } from '../../../../back-end/common/questionInstrument'
import { Test, TestResult } from '../../../../back-end/common/test'
import { TestInstrumentResult } from '../../../../back-end/common/testInstrument'
import { User } from '../../../../back-end/common/user'
import { Asset } from 'dynaflow/common/asset'
import { STANDARD_ASSET_TYPE } from 'dynaflow/constants/assetType'

import { AppContext } from '../../App'
import { Attributes, AuthState } from '../../App.d'
import { DropdownSelect, DropdownSelectOption } from '../../components/Forms/Dropdown'
import { Button } from '../../components/UI/Button/Button'
import { Card } from '../../components/UI/Card/Card'
import { FormText } from '../../components/UI/Form/Text'
import { Header } from '../../components/UI/Header/Header'
import { Loading } from '../../components/UI/Loading/Loading'
import { MessageAction } from '../../components/UI/Messages/Message'
import { Uploader, uploadToSignedUrl } from '../../components/UI/Uploader/Uploader'

import * as Role from '../../constants/role'
import * as Request from '../../utilities/request'
import generateUuid from '../../utilities/uuid'
import { DetailedQuestion } from './TestWrapper'
import { QuestionElement, TabRow } from './Questions/QuestionComponents'
import uploadImageIcon from '../../images/icons/imageUpload.svg'

import 'antd/dist/antd.min.css'
import 'react-html5-camera-photo/build/css/index.css'
import { QUESTION } from 'dynaflow/constants/question'
import { SmokeTestOutput } from 'dynaflow/utilities/question'
import { errorObjectHasAnError } from '../../utilities/assetValidationSchemas'
import { INSTRUMENT_TYPE } from '../../constants/instrumentType'

const getInstrumentSnapshot = (instrument: Instrument): string => {
	const redactedInstrument = { ...instrument } as Partial<Instrument>
	delete redactedInstrument.calibrations
	return JSON.stringify(redactedInstrument)
}

export const getLatestCalibration = (
	calibrations:
		| Pick<Calibration, 'calibrationID' | 'calibrationDate' | 'calibrationCertificateNumber' | 'calibrationCertificateS3Path' | 'calibrationItems'>[]
		| null
) => {
	if (!calibrations) {
		return null
	}
	return (
		calibrations
			?.sort((a, b) => {
				if (a.calibrationDate < b.calibrationDate) {
					return 1
				}
				if (a.calibrationDate > b.calibrationDate) {
					return -1
				}
				return 0
			})
			.at(0) || null
	)
}

const saveSelectedInstruments = async (instruments: Instrument[], test: Partial<Test>, auth?: AuthState) => {
	if (!instruments || !test || !auth) {
		console.log('WARNING: Trying to save instrument without instruments or a testID')
		return
	}
	return Promise.all(
		instruments.map((instrument) => {
			const matchingTestInstrument = test?.testInstruments?.find((i) => i.instrumentTypeID === instrument.instrumentTypeID)
			if (matchingTestInstrument) {
				// Update the matching testInstrument with the new one
				return Request.put<TestInstrumentResult>(
					`testInstrument?where=testInstrumentID==${matchingTestInstrument.testInstrumentID}`,
					{
						testID: test.testID,
						instrumentID: instrument.instrumentID,
						testInstrumentSnapshot: getInstrumentSnapshot(instrument),
						testInstrumentCalibrationSnapshot: JSON.stringify(getLatestCalibration(instrument.calibrations)),
					},
					auth
				)
			} else {
				// Make a new one
				return Request.post<TestInstrumentResult>(
					'testInstrument',
					{
						testID: test.testID,
						instrumentID: instrument.instrumentID,
						testInstrumentSnapshot: getInstrumentSnapshot(instrument),
						testInstrumentCalibrationSnapshot: JSON.stringify(getLatestCalibration(instrument.calibrations)),
					},
					auth
				)
			}
		})
	)
}

const ImagePreviewModal = (props: { imageUrl: string | null; show: boolean; handleClose: () => void }) => (
	<Modal show={props.show} onHide={props.handleClose} centered className="modal-fit-content">
		<Modal.Body>{props.imageUrl ? <img src={props.imageUrl} alt="Asset" style={{ maxWidth: '85vw' }} /> : <Loading spinner />}</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={props.handleClose}>
				Close
			</Button>
		</Modal.Footer>
	</Modal>
)

interface Setup {
	performedTs: string
	requiresComplianceCheck: boolean
	photoIsApplicable: boolean
	photoS3Path: string
	technicianUserID: DropdownSelectOption<string, Partial<User>>
	instruments: DropdownSelectOption<string, Partial<Instrument>>[]
	instrumentSet: DropdownSelectOption<string, Partial<InstrumentSet>>
}

const noTechnicianSelect = { value: '', text: 'No valid technician available' }

const defaultSetup = (
	currentUser: Partial<Attributes>,
	testUsers: Pick<User, 'userID' | 'firstName' | 'lastName' | 'fullName' | 'email'>[],
	questionInstruments: Partial<Instrument>[],
	availableInstruments: Partial<Instrument>[],
	instrumentSets: InstrumentSet[],
	assetHasComplianceQuestions: boolean,
	extraParams: ExtraParams
): Partial<Setup> => {
	let testUser: { value: string; text: string }
	if (testUsers.length === 0) {
		testUser = noTechnicianSelect
	} else if (testUsers.find((u) => u.userID === currentUser.userID)) {
		// Set the current user as the test user if they have a competency
		testUser = { value: currentUser.userID || '', text: `${currentUser.given_name} ${currentUser.family_name}` || '' }
	} else if (currentUser.roleID === Role.TestingTechnician) {
		// If the current user is a technician, and no competency, they cannot set another user to perform the test
		testUser = noTechnicianSelect
	} else {
		// Otherwise, set the first user in the list as the test user
		testUser = { value: testUsers[0].userID, text: testUsers[0].fullName }
	}

	// Get the instrument set for the test user
	const instrumentSet = instrumentSets.find((i) => i.instrumentSetOwnerUserID === testUser.value)

	const defaultInstruments = questionInstruments.map((questionInstrument) => {
		const availableInstrumentsOfType = availableInstruments.filter((instrument) => instrument.instrumentTypeID === questionInstrument.instrumentTypeID)
		if (instrumentSet) {
			const instrumentsInSet = availableInstrumentsOfType.filter(
				(instrument) => !!instrumentSet!.instruments.find((i) => i.instrumentID === instrument.instrumentID)
			)
			if (instrumentsInSet) {
				return instrumentsInSet[0]
			}
		}
		return null
	})

	return {
		performedTs: DateTime.now().toFormat('yyyy-MM-dd'),
		requiresComplianceCheck: assetHasComplianceQuestions,
		photoIsApplicable: true,
		photoS3Path: '',
		technicianUserID: testUser,
		instruments: defaultInstruments.map((i) => {
			if (!i) {
				return {
					value: '',
					text: '-',
				}
			}
			return {
				...i,
				value: i.instrumentID || '',
				text: `${i.instrumentMake} ${i.instrumentModel} ${i.instrumentSerialNumber} (${i.instrumentSetName})`,
			}
		}),
		instrumentSet: instrumentSet
			? {
					...instrumentSet,
					value: instrumentSet.instrumentSetID,
					text: `${instrumentSet.instrumentSetName}${
						instrumentSet.instrumentSetOwnerUserID ? ` (${instrumentSet.instrumentSetOwnerUserFullName})` : ''
					}`,
			  }
			: { value: '', text: '-' },
		...extraParams,
	}
}

const getSmokeTestAndEmptiness = (test: Test | null): boolean => {
	if (!test) {
		return false
	}
	const smokeTestIndex = test.testQuestions.findIndex((tq) => tq.questionID === QUESTION.SMOKE_TEST)
	if (smokeTestIndex >= 0) {
		const response = test.testQuestions[smokeTestIndex].testQuestionResponse as SmokeTestOutput
		return response.assetEmpty === 'no'
	}
	return false
}

interface ExtraParams {
	typicalUse?: 'General use' | 'Acidic substances' | 'Radioactive' | 'Biological'
	decontamination?: 'Select one' | 'Hydrogen peroxide' | 'Chlorine dioxide' | 'Not performed'
	location?: 'NSW' | 'VIC' | 'SA'
}

const getExtraParams = (asset: Asset | null): ExtraParams => {
	const extraParams = {} as ExtraParams
	if (asset) {
		switch (asset.parentAssetTypeID || asset.assetTypeID) {
			case STANDARD_ASSET_TYPE.FUME_CUPBOARD:
				extraParams.typicalUse = 'General use'
				break
			case STANDARD_ASSET_TYPE.BIOLOGICAL_SAFETY_CABINET_2:
			case STANDARD_ASSET_TYPE.CYTOTOXIC_DRUG_SAFETY_CABINET:
				extraParams.decontamination = 'Select one'
				break
			default:
				break
		}
		// dynaflow specific location for template DYN-220
		extraParams.location = 'NSW'
	}
	return extraParams
}

const validateInstruments = (value: unknown) => {
	const instruments = value as Instrument[]
	return instruments.every((instrument) => {
		if ([INSTRUMENT_TYPE.SOUND_LEVEL_METER].includes(instrument.instrumentTypeID)) {
			return true // sound level meters aren't calibrated
		}
		const latestCalibration = getLatestCalibration(instrument.calibrations)
		if (latestCalibration) {
			const calibDate = DateTime.fromISO(latestCalibration.calibrationDate)
			if (calibDate.diffNow('months').months < -12) {
				return false
			} else {
				return true
			}
		} else {
			return false
		}
	})
}

const setupValidationSchema = yup.object().shape({
	performedTs: yup.string().test({
		name: 'valid',
		exclusive: true,
		message: 'Test must have a date.',
		test: (value) => !!value && value !== '' && DateTime.fromISO(value).isValid,
	}),
	requiresComplianceCheck: yup.boolean(),
	photoIsApplicable: yup.boolean(),
	photoS3Path: yup.string().when('photoIsApplicable', { is: true, then: yup.string().required('Must take or upload a photo') }),
	decontamination: yup.string().optional().oneOf(['Hydrogen peroxide', 'Chlorine dioxide', 'Not performed'], 'Must select a decontamination option'),
	instruments: yup
		.array()
		.of(
			yup.object().shape({
				calibrations: yup.array().of(
					yup.object().shape({
						calibrationDate: yup.string(),
					})
				),
			})
		)
		.test('calibration test', 'Each instruments most recent calibration must have been within the last year', validateInstruments),
})

interface TestSetupProps {
	pageStatus: string
	asset: Asset | null
	questions: DetailedQuestion[]
	questionInstruments: QuestionInstrument[]
	availableInstruments: Instrument[]
	instrumentSets: InstrumentSet[]
	testUsers: Pick<User, 'userID' | 'firstName' | 'lastName' | 'fullName' | 'email'>[]
	test: Test | null
	setTest: React.Dispatch<React.SetStateAction<Test | null>>
	onClickNext: (testID?: string) => void
	goToQuestion: (question: string) => void
	setMessages: (action: MessageAction) => void
	tabSelectOptions: DropdownSelectOption<string, object>[]
	assetHasComplianceQuestions: boolean
}

const TestSetup = (props: TestSetupProps) => {
	const { test, setTest, availableInstruments, instrumentSets, testUsers, assetHasComplianceQuestions } = props

	// Photo upload
	const [takePhoto, setTakePhoto] = React.useState<boolean>(false)
	const [photoData, setPhotoData] = React.useState<string | null>(null)
	const [photoSignedUrl, setPhotoSignedUrl] = React.useState<string | null>(null)
	const [isUploading, setIsUploading] = React.useState<boolean>(false)

	// Preview modal
	const [show, setShow] = React.useState<boolean>(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const onSubmitCallback = React.useRef((_testID?: string) => {})
	const uniqueQuestionInstruments = React.useMemo(
		() =>
			props.questionInstruments.reduce((unique, instrument) => {
				if (!unique.find((i) => i.instrumentTypeID === instrument.instrumentTypeID)) {
					return [...unique, instrument]
				}
				return unique
			}, [] as QuestionInstrument[]),
		[props.questionInstruments]
	)

	const context = useContext(AppContext)
	const [initialValues, setInitialValues] = React.useState<Partial<Setup & ExtraParams>>(
		defaultSetup(
			context.appState.userAttributes,
			testUsers,
			uniqueQuestionInstruments,
			availableInstruments,
			instrumentSets,
			assetHasComplianceQuestions,
			getExtraParams(props.asset)
		)
	)

	React.useEffect(() => {
		const getSignedUrl = async (url?: string) => {
			if (url) {
				setPhotoSignedUrl((await getPhotoSignedUrl(url)) || null)
			}
		}
		if (test) {
			const selectedInstruments = uniqueQuestionInstruments.map((questionInstrument) =>
				test.testInstruments.find((instrument) => instrument.instrumentTypeID === questionInstrument.instrumentTypeID)
			)
			const instruments = selectedInstruments.map((i) => {
				if (!i) {
					return {
						value: '',
						text: 'No calibrated instrument available',
					}
				}
				const instrument = JSON.parse(i.testInstrumentSnapshot!) as Instrument
				return {
					...instrument,
					calibrations: [JSON.parse(i.testInstrumentCalibrationSnapshot!)],
					value: instrument.instrumentID || '',
					text: `${instrument.instrumentMake} ${instrument.instrumentModel} ${instrument.instrumentSerialNumber} (${instrument.instrumentSetName})`,
				}
			})
			const extraParams = getExtraParams(props.asset) as object // or TS will be angry for no good reason
			if (test.extraParams) {
				for (const k of Object.keys(extraParams) as (keyof typeof extraParams)[]) {
					if (Object.prototype.hasOwnProperty.call(test.extraParams, k)) {
						extraParams[k] = (test.extraParams as ExtraParams)[k]
					}
				}
			}
			const hasSmokeTestAndMarkedNotEmpty = getSmokeTestAndEmptiness(test)
			const newValues = {
				performedTs: DateTime.fromISO(test.performedTs).toFormat('yyyy-MM-dd'),
				technicianUserID: { value: test.technicianUserID || '', text: test.testTechnicianUser.fullName || '' },
				requiresComplianceCheck: test.requiresComplianceCheck,
				photoIsApplicable: hasSmokeTestAndMarkedNotEmpty || test.photoIsApplicable,
				photoS3Path: test.photoS3Path,
				instruments,
				instrumentSet: initialValues.instrumentSet,
				...extraParams,
			}
			setInitialValues(newValues)
			getSignedUrl(newValues.photoS3Path)
		}
	}, [uniqueQuestionInstruments, test])

	const onSubmit = async (setupData: Partial<Setup & ExtraParams>) => {
		const onSuccess = async (test: Test) => {
			// Replace the existing test instruments on the test with the updated ones
			const testInstrumentsResponse = await saveSelectedInstruments(setupData.instruments as Instrument[], test, context.appState.authState)
			const testInstruments = testInstrumentsResponse?.map((response) => response.data.testInstruments).flat() || []
			const currentInstruments = [...test.testInstruments]
			for (const instrument of testInstruments) {
				const currentInstIndex = currentInstruments.findIndex((i) => i.testInstrumentID === instrument.testInstrumentID)
				if (currentInstIndex >= 0) {
					currentInstruments[currentInstIndex] = instrument
				} else {
					currentInstruments.push(instrument)
				}
			}
			test.testInstruments = currentInstruments
			setTest(test)
			if (onSubmitCallback.current) {
				onSubmitCallback.current(test.testID)
			}
		}
		try {
			const {
				technicianUserID: technicianUserID,
				performedTs: performedTs,
				requiresComplianceCheck: requiresComplianceCheck,
				photoIsApplicable: photoIsApplicable,
				photoS3Path: photoS3Path,
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				instrumentSet,
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				instruments,
				...extraParams
			} = setupData
			const response = test
				? await Request.put<TestResult>(
						`test?where=testID==${test.testID}`,
						{
							testID: test.testID,
							technicianUserID: technicianUserID?.value,
							performedTs,
							requiresComplianceCheck,
							photoIsApplicable,
							photoS3Path,
							assetID: props?.asset?.assetID,
							extraParams,
						},
						context.appState.authState
				  )
				: await Request.post<TestResult>(
						'test',
						{
							isLocked: false,
							technicianUserID: technicianUserID?.value,
							performedTs,
							requiresComplianceCheck,
							photoIsApplicable,
							photoS3Path,
							assetID: props?.asset?.assetID,
							extraParams,
						},
						context.appState.authState
				  )
			props.setMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: `Success ${test ? 'editing' : 'creating'} the test`,
					dismissible: true,
					timeout: 5000,
				},
			})
			if (!response.data.success) {
				throw new Error(`Error creating/updating test: ${response.data.errors}`)
			}
			await onSuccess(response.data.tests[0])
		} catch {
			props.setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `Failed ${test ? 'editing' : 'creating'} the test`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
	}

	const getPhotoUploadSignedUrl = React.useCallback(
		async (
			setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void,
			fileType: string
		): Promise<{ signedUrl: string; s3Path: string }> => {
			// get s3 url
			const s3Path = `testPhoto/${generateUuid()}.${fileType.split('/')[1]}`
			const req = await Request.post<DocumentResult>(
				'document',
				{
					type: fileType,
					s3Location: s3Path,
					operation: 'putObject',
				},
				context.appState.authState
			)
			// set field
			setIsUploading(true)
			setFieldValue('photoS3Path', s3Path)
			return { signedUrl: req.data.file.signedUrl!, s3Path }
		},
		[context.appState.authState]
	)

	const getPhotoSignedUrl = React.useCallback(
		async (s3Path: string): Promise<string | undefined> => {
			if (!s3Path) {
				return
			}
			const req = await Request.post<DocumentResult>(
				'document',
				{
					s3Location: s3Path,
					operation: 'getObject',
				},
				context.appState.authState
			)
			return req.data.file.signedUrl
		},
		[context.appState.authState]
	)

	const technicianSelectOptions: DropdownSelectOption<string, Partial<Question>>[] = React.useMemo(
		() =>
			testUsers.length === 0
				? [noTechnicianSelect]
				: testUsers.map((user) => ({
						value: user.userID,
						text: user.fullName,
				  })),
		[testUsers]
	)

	const instrumentSetSelectOptions: DropdownSelectOption<string, InstrumentSet>[] = React.useMemo(
		() =>
			props.instrumentSets.length > 0
				? props.instrumentSets.map((set) => {
						return { ...set, value: set.instrumentSetID, text: set.instrumentSetName }
				  })
				: [],
		[props.instrumentSets]
	)

	const allInstrumentSelectOptions = React.useMemo(
		() =>
			uniqueQuestionInstruments.map((questionInstrument) => {
				const availableInstrumentsOfType = availableInstruments.filter(
					(instrument) => instrument.instrumentTypeID === questionInstrument.instrumentTypeID
				)
				const instrumentSelectOptions: DropdownSelectOption<string, Partial<Instrument>>[] =
					availableInstrumentsOfType.length > 0
						? availableInstrumentsOfType.map((instrument) => {
								return {
									...instrument,
									value: instrument.instrumentID,
									text: `${instrument.instrumentMake} ${instrument.instrumentModel} ${instrument.instrumentSerialNumber} (${instrument.instrumentSetName})`,
								}
						  })
						: []
				return instrumentSelectOptions
			}),
		[availableInstruments, uniqueQuestionInstruments]
	)

	const setInstrumentsFromSet = (instrumentSetID: string) => {
		const obj: DropdownSelectOption<string, Partial<Instrument>>[] = []
		allInstrumentSelectOptions.forEach((instruments, index) => {
			const setInst = instruments.find((i) => i.instrumentSetID === instrumentSetID)
			if (setInst) {
				obj[index] = setInst
			}
		})
		return obj
	}

	const PhotoUploader = React.useCallback(
		(props: {
			disabled?: boolean
			values: Partial<Setup>
			setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void
		}) => {
			const { disabled, values, setFieldValue } = props
			return (
				<Uploader
					name="photoS3Path"
					multiple={false}
					accept="image/png,image/jpeg,image/bmp,image/tiff"
					listType="picture"
					buttonText="Upload"
					icon={() => <img src={uploadImageIcon} alt={'Upload image icon'} />}
					maxCount={1}
					disabled={disabled}
					defaultFileList={
						// TODO: This will not refresh if a photo is taken and uploaded
						values?.photoS3Path && photoSignedUrl
							? [
									{
										name: 'Asset photo',
										percent: 100,
										status: 'done',
										thumbUrl: photoSignedUrl || '',
										uid: values.photoS3Path!,
									},
							  ]
							: []
					}
					onRemove={() => setFieldValue('photoS3Path', null)}
					action={async (file) => {
						const { signedUrl } = await getPhotoUploadSignedUrl(setFieldValue, file.type)
						return signedUrl
					}}
					customRequest={async (info) => {
						if (typeof info.file !== 'string') {
							const res = await uploadToSignedUrl(info.action, info.file, info.filename)
							info.onSuccess && info.onSuccess(res)
						}
						setIsUploading(false)
					}}
					onPreview={handleShow}
				/>
			)
		},
		[photoSignedUrl, getPhotoUploadSignedUrl]
	)

	if (props.pageStatus === 'Loading') {
		return <Loading spinner />
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={setupValidationSchema}
			validateOnChange={true}
			validateOnMount={getSmokeTestAndEmptiness(test)}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{({ handleSubmit, values, errors, handleChange, setFieldValue, setValues, dirty, isSubmitting }) => {
				if (takePhoto) {
					return (
						<Camera
							isFullscreen={true}
							idealFacingMode={FACING_MODES.ENVIRONMENT}
							onTakePhoto={async (dataUri) => {
								const { signedUrl, s3Path } = await getPhotoUploadSignedUrl(setFieldValue, 'image/png')
								await uploadToSignedUrl(signedUrl, await (await fetch(dataUri)).blob())
								setPhotoSignedUrl((await getPhotoSignedUrl(s3Path)) || null)
								setIsUploading(false)
							}}
							onTakePhotoAnimationDone={() => setTakePhoto(false)}
						/>
					)
				}
				return (
					<>
						<ImagePreviewModal show={show} handleClose={handleClose} imageUrl={photoSignedUrl} />
						<TabRow
							tabSelectOptions={props.tabSelectOptions}
							onClick={(q, save) => {
								if (save) {
									handleSubmit()
								}
								props.goToQuestion(q)
							}}
							disabled={!test || isUploading || isSubmitting}
							currentQuestion={'Setup'}
							dirty={dirty}
						/>
						<Row>
							<Col>
								<Header title={'Test setup'} subtitle={''} />
								<QuestionElement>
									<Row>
										<Col>
											<Row>
												<Col>
													<FormText
														onChange={handleChange}
														type="date"
														name="performedTs"
														label={'Test date'}
														disabled={props.pageStatus !== 'Editing' || test?.isLocked}
														value={values.performedTs}
														feedback={errors.performedTs}
														isInvalid={!!errors.performedTs}
													/>
												</Col>
												<Col>
													<DropdownSelect
														id="technicianUserID"
														name="technicianUserID"
														label="Technician"
														disabled={
															props.pageStatus !== 'Editing' || values.technicianUserID?.value === '' || test?.isLocked || false
														}
														variant="secondary"
														showSearch={technicianSelectOptions.length > 5}
														searchPlaceholder={''}
														options={technicianSelectOptions}
														value={values.technicianUserID?.text || 'No value'}
														onChange={(value, data) => setFieldValue('technicianUserID', { ...data, value })}
													/>
												</Col>
											</Row>
											<Row>
												<Col className="mt-3">
													<Form.Group controlId="requiresComplianceCheck">
														<Form.Check
															id="requiresComplianceCheck"
															label="Perform compliance check"
															type="checkbox"
															name="requiresComplianceCheck"
															checked={values.requiresComplianceCheck}
															onChange={handleChange}
															disabled={!!test?.isLocked || !assetHasComplianceQuestions}
														/>
													</Form.Group>
												</Col>
											</Row>
											{Object.prototype.hasOwnProperty.call(values, 'typicalUse') && (
												<Row>
													<Col>
														<DropdownSelect
															id="typicalUse"
															name="typicalUse"
															label="Typical use"
															disabled={props.pageStatus !== 'Editing' || test?.isLocked || false}
															showSearch={false}
															variant="secondary"
															searchPlaceholder={''}
															options={[
																{ text: 'General use', value: 'General use' },
																{ text: 'Acidic substances', value: 'Acidic substances' },
																{ text: 'Radioactive', value: 'Radioactive' },
																{ text: 'Biological', value: 'Biological' },
															]}
															value={values.typicalUse!}
															onChange={(value) => setFieldValue('typicalUse', value)}
														/>
													</Col>
												</Row>
											)}
											{Object.prototype.hasOwnProperty.call(values, 'decontamination') && (
												<Row>
													<Col>
														<DropdownSelect
															id="decontamination"
															name="decontamination"
															label="Decontamination used"
															disabled={props.pageStatus !== 'Editing' || test?.isLocked || false}
															showSearch={false}
															variant="secondary"
															searchPlaceholder={''}
															options={[
																{ text: 'Hydrogen peroxide', value: 'Hydrogen peroxide' },
																{ text: 'Chlorine dioxide', value: 'Chlorine dioxide' },
																{ text: 'Not performed', value: 'Not performed' },
															]}
															value={values.decontamination!}
															onChange={(value) => setFieldValue('decontamination', value)}
															feedback={errors.decontamination}
															isInvalid={!!errors.decontamination}
														/>
													</Col>
												</Row>
											)}
											{Object.prototype.hasOwnProperty.call(values, 'location') && (
												<Row>
													<Col>
														<DropdownSelect
															id="location"
															name="location"
															label="Location"
															disabled={props.pageStatus !== 'Editing' || test?.isLocked || false}
															showSearch={false}
															variant="secondary"
															searchPlaceholder={''}
															options={[
																{ text: 'New South Wales', value: 'NSW' },
																{ text: 'South Australia', value: 'SA' },
																{ text: 'Victoria', value: 'VIC' },
															]}
															value={values.location!}
															onChange={(value) => setFieldValue('location', value)}
															feedback={errors.location}
															isInvalid={!!errors.location}
														/>
													</Col>
												</Row>
											)}
										</Col>
										<Col sm={5} md={4} lg={4} className={`bg-light p-3 rounded ${errors.photoS3Path ? 'border border-danger' : ''}`}>
											<Row className="mb-2">
												<Col>Test photo</Col>
												<Col xs={7}>
													<Form.Group controlId="photoIsApplicable">
														<Form.Check
															id="photoIsApplicable"
															label="Photo applicable"
															type="checkbox"
															name="photoIsApplicable"
															checked={values.photoIsApplicable}
															onChange={!getSmokeTestAndEmptiness(test) ? handleChange : undefined}
															disabled={!!test?.isLocked}
														/>
													</Form.Group>
												</Col>
											</Row>
											<PhotoUploader
												values={values}
												setFieldValue={setFieldValue}
												disabled={!values.photoIsApplicable || !!test?.isLocked}
											/>
											{!values.photoS3Path && values.photoIsApplicable && (
												<>
													<Button
														style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
														disabled={!values.photoIsApplicable || !!test?.isLocked}
														className="btn btn-secondary mt-2"
														onClick={() => setTakePhoto(true)}
													>
														<CameraFilled style={{ fontSize: '22px' }} /> {photoData ? 'Ret' : 'T'}ake photo
													</Button>
													{photoData && <Image src={photoData} width="200px" alt="test photo" />}
													{photoData && (
														<Button
															disabled={!values.photoIsApplicable || !!test?.isLocked}
															className="btn btn-secondary"
															onClick={() => setPhotoData(null)}
														>
															Remove photo
														</Button>
													)}
												</>
											)}
											{errors.photoS3Path && <span className="text-danger">{errors.photoS3Path}</span>}
										</Col>
									</Row>
								</QuestionElement>
								{uniqueQuestionInstruments.length > 0 && (
									<Card
										title=""
										collapsible={false}
										headerComponent={() => (
											<Row className="pl-3 pr-3">
												<Col className="col-3">
													<h1>Instruments</h1>
												</Col>
												<Col>
													<DropdownSelect
														key={`instrumentSet`}
														id={`instrumentSet`}
														name={`instrumentSet`}
														disabled={
															props.pageStatus !== 'Editing' || instrumentSetSelectOptions.length === 0 || test?.isLocked || false
														}
														variant="secondary"
														showSearch={instrumentSetSelectOptions.length > 5}
														searchPlaceholder={''}
														options={instrumentSetSelectOptions}
														value={values.instrumentSet?.text || ''}
														onChange={(value, data) => {
															const setInstruments = setInstrumentsFromSet(data.instrumentSetID)
															setValues({
																...values,
																instrumentSet: { ...data, value },
																instruments: setInstruments,
															})
														}}
													/>
												</Col>
												<Col className="col-3">
													<h2>Calibration date</h2>
												</Col>
											</Row>
										)}
									>
										<>
											{uniqueQuestionInstruments.map((questionInstrument, index) => {
												const instrumentSelectOptions = allInstrumentSelectOptions[index]
												const latestCalibration = getLatestCalibration(values?.instruments?.at(index)?.calibrations || null)
												let calibDate: DateTime, trafficLightColour: string
												if (latestCalibration) {
													calibDate = DateTime.fromISO(latestCalibration.calibrationDate)
													trafficLightColour =
														calibDate.diffNow('months').months < -12
															? 'light-red'
															: calibDate.diffNow('months').months < -11
															? 'light-orange'
															: 'light-green'
												}
												return (
													<Row key={index}>
														<Col className="col-3" style={{ height: '50px' }}>
															<Row className="form-label mt-2" style={{ marginBottom: '.5rem' }}>
																<Col>{questionInstrument.instrumentTypeName}</Col>
															</Row>
														</Col>
														<Col style={{ height: '50px' }}>
															<DropdownSelect
																key={`questionInstrument-${questionInstrument.instrumentTypeID}`}
																id={`instruments[${index}]`}
																name={`instruments[${index}]`}
																disabled={
																	props.pageStatus !== 'Editing' ||
																	instrumentSelectOptions.length === 0 ||
																	test?.isLocked ||
																	false
																}
																variant="secondary"
																showSearch={instrumentSelectOptions.length > 5}
																searchPlaceholder={''}
																options={instrumentSelectOptions}
																value={
																	instrumentSelectOptions.length === 0
																		? 'No calibrated instruments available'
																		: values.instruments?.at(index)?.text || ''
																}
																onChange={(value, data) =>
																	setFieldValue(`instruments[${index}]`, {
																		...availableInstruments.find((i) => i.instrumentID === value),
																		...data,
																		value,
																	})
																}
															/>
														</Col>
														<Col style={{ height: '50px' }} className="col-3 mt-2">
															{latestCalibration && (
																<div>
																	<span className={`mr-3 traffic-light ${trafficLightColour!}`}></span>
																	{calibDate!.toLocaleString(DateTime.DATE_FULL)}
																</div>
															)}
														</Col>
													</Row>
												)
											})}
											{errors.instruments && (
												<Row>
													<Col>
														<Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
															{errors.instruments}
														</Form.Control.Feedback>
													</Col>
												</Row>
											)}
										</>
									</Card>
								)}
								<Row className="justify-content-end mt-3 mb-3">
									<Button
										className="btn btn-primary round"
										disabled={
											values.instruments?.filter((instrument) => instrument.value !== '').length !== uniqueQuestionInstruments.length ||
											values.technicianUserID?.value === '' ||
											isUploading ||
											errorObjectHasAnError(errors) ||
											isSubmitting
										}
										onClick={() => {
											if (test && !dirty && !errorObjectHasAnError(errors)) {
												props.onClickNext()
												return
											}
											onSubmitCallback.current = props.onClickNext
											handleSubmit()
										}}
									>
										{isSubmitting ? (
											<Loading spinner noBorder={true} />
										) : (
											<>
												NEXT
												<ChevronRight style={{ verticalAlign: 'text-bottom' }} size="16" />
											</>
										)}
									</Button>
								</Row>
							</Col>
						</Row>
					</>
				)
			}}
		</Formik>
	)
}

export { TestSetup }
