import React from 'react'
import { AppContext } from '../../App'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import Logo from '../../images/logo1.png'
import { switchEntity } from '../../components/Login/AuthComponent/EntitySwitcherFunctions'

const ScreensHomeEntityPicker = () => {
	const { appState, setAppState } = React.useContext(AppContext)

	return (
		<Container style={{ maxWidth: '550px', paddingTop: '10%', margin: 'auto' }}>
			<img
				src={Logo}
				alt="logo"
				style={{
					display: 'block',
					marginLeft: 'auto',
					marginRight: 'auto',
					padding: '20px',
					width: '80%',
				}}
			/>
			<Card className="card-login" style={{ textAlign: 'center' }}>
				<Row style={{ marginBottom: '40px' }}>
					<Col>
						<h1>Please select an Entity to continue</h1>
					</Col>
				</Row>

				{appState.userEntities.map((e) => {
					return (
						<Row key={e.entityID} style={{ marginBottom: '20px' }}>
							<Col>
								<Button
									className="btn btn-secondary"
									style={{ width: '100%', height: '50px' }}
									onClick={() => switchEntity(e.entityID, null, setAppState)}
								>
									{e.entityName}
								</Button>
							</Col>
						</Row>
					)
				})}
			</Card>
		</Container>
	)
}

export { ScreensHomeEntityPicker }
