import React, { useContext } from 'react'
import { Link, RouteChildrenProps } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { Account, AccountResult } from '../../../../back-end/common/account'
import * as Request from '../../utilities/request'
import { Header } from '../../components/UI/Header/Header'
import { Button } from '../../components/UI/Button/Button'
import { Listing } from '../../components/UI/Listing/Listing'
import { AppContext } from '../../App'
import { PageStatus } from '../../types/PageStatus'

import addIcon from '../../images/icons/add.svg'
import { canWriteAccount } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'

const ScreensAccount = (props: RouteChildrenProps) => {
	const context = useContext(AppContext)

	const [accounts, setAccounts] = React.useState<Account[] | null>(null)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	React.useEffect(() => {
		const getData = async () => {
			const [account] = await Promise.all([Request.get<AccountResult>('account', context.appState.authState)])

			if (account.data.accounts.length === 1) {
				props.history.replace(`/institution/${account.data.accounts[0].accountID}`)
			}

			setAccounts(account.data.accounts)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context]) // eslint-disable-line

	return (
		<div className="page">
			<Row>
				<Col>
					<Header title={`Institutions (${accounts?.length || 0})`} subtitle={'View a list of institutions'} />
				</Col>
				{canWriteAccount(appStateToPermissionObject(context.appState)) && (
					<Col sm={'auto'} style={{ paddingTop: '40px' }}>
						<Button className=" btn btn-primary round" onClick={() => props.history.push('/institution/new')}>
							<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
							<span className="span-bold" style={{ color: 'white' }}>
								Create new institution
							</span>
						</Button>
					</Col>
				)}
			</Row>

			<Listing
				name="Institution"
				namePlural="Institutions"
				list={accounts || []}
				getIDFunc={(item) => item.accountID}
				selectedActions={[]}
				columns={[
					{
						value: (item) => item.accountReadableID,
						render: (item) => (
							<Link to={{ pathname: `/institution/${item.accountID}`, state: { backPath: props.location.pathname, backName: 'Institutions' } }}>
								{item.accountReadableID}
							</Link>
						),
						showHeader: true,
						headerText: 'ID',
						sortColumnName: 'accountReadableID',
						filterType: 'string',
						filterOptions: {
							columnName: 'accountReadableID',
						},
					},
					{
						value: (item) => item.accountName,
						render: (item) => <>{item.accountName}</>,
						showHeader: true,
						headerText: 'Name',
						sortColumnName: 'accountName',
						filterType: 'string',
						filterOptions: {
							columnName: 'accountName',
						},
					},
					{
						value: (item) => (item.accountActive ? 'Yes' : 'No'),
						render: (item) => <>{item.accountActive ? 'Yes' : 'No'}</>,
						showHeader: true,
						headerText: 'Active',
						sortColumnName: 'accountActive',
						filterType: 'string',
						filterOptions: {
							columnName: 'accountActive',
						},
					},
					{
						value: (item) => item.accountNotes,
						render: (item) => <>{item.accountNotes}</>,
						showHeader: true,
						headerText: 'Notes',
						sortColumnName: 'accountNotes',
						filterType: 'string',
						filterOptions: {
							columnName: 'accountNotes',
						},
					},
				]}
				defaultSort={{ column: 'accountName', order: 'ASC' }}
				isLoading={pageStatus === 'Loading'}
			/>
		</div>
	)
}

export { ScreensAccount }
