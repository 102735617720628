import React from 'react'
import { Badge, Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { AppContext } from '../../App'
import * as EntityType from '../../constants/entityType'
import { Card } from '../UI/Card/Card'
import { LocationAccessDetails } from './LocationAccessDetails'

import { Account } from '../../../../back-end/common/account'
import { Asset } from '../../../../back-end/common/asset'
import { EntityWithAccountContext } from '../../../../back-end/common/entity'
import { Location } from '../../../../back-end/common/location'
import { PageBackState } from '../../types/PageBackState'
import { MessageAction } from '../UI/Messages/Message'

interface AccountEntityDetailsProps {
	accountID: string
	account: Account
	entity: Partial<EntityWithAccountContext>
	parentEntity?: Partial<EntityWithAccountContext>
	locations: Location[] | null
	assets: Asset[] | null
	setMessages: (action: MessageAction) => void
	onAccountEntityChange: (entityID: string, entity: Partial<EntityWithAccountContext>) => Promise<void>
	onRemoveAccountEntity: (entityID: string, entity: Partial<EntityWithAccountContext>, accountEntityID: string) => Promise<void>
	showTestingContractors: boolean
	testingContractors: Partial<EntityWithAccountContext>[]
	testingContractorHeaderComponent?: (entityID: string) => () => JSX.Element
	updateSubscriber: (entityID: string, makeSub: boolean) => Promise<void>
	backState: PageBackState
}

const AccountEntityDetails = (props: AccountEntityDetailsProps): JSX.Element => {
	const {
		appState: { userPermissions },
	} = React.useContext(AppContext)

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const property = e.target.name
		const value = e.target.checked

		const newEntity = { ...props.entity, [property]: value }

		props.onAccountEntityChange(props.entity.entityID!, newEntity)
	}

	if (props.entity) {
		return (
			<>
				<Row>
					<Col xs="auto">
						<h3>
							<Link to={{ pathname: `/entity/${props.entity.entityID}`, state: props.backState }}>{props.entity.entityName}</Link>
						</h3>
					</Col>
					{props.entity.accountEntityIsSubscriber && (
						<Col xs="auto">
							<Badge bg="warning">Subscriber</Badge>
						</Col>
					)}
					{userPermissions &&
						userPermissions.entityTypeID === EntityType.Admin &&
						!props.entity.accountEntityIsSubscriber &&
						props.entity.entityID &&
						props.account.entities.every((entity) => !entity.accountEntityIsSubscriber) && (
							<Col xs="auto">
								<Badge
									bg="secondary"
									onClick={() => props.updateSubscriber(props.entity.entityID!, true)}
									role="button"
									className="cursor-pointer"
								>
									Make Subscriber
								</Badge>
							</Col>
						)}
					{userPermissions && userPermissions.entityTypeID === EntityType.Admin && props.entity.accountEntityIsSubscriber && props.entity.entityID && (
						<Col xs="auto">
							<Badge
								bg="secondary"
								onClick={() => props.updateSubscriber(props.entity.entityID!, false)}
								role="button"
								className="cursor-pointer"
							>
								Revoke Subscriber
							</Badge>
						</Col>
					)}
					{userPermissions &&
						(userPermissions.accounts.find(({ accountID }) => accountID === props.entity.accountID)?.canWriteEntities ||
							userPermissions.entityTypeID === EntityType.Admin) &&
						!props.entity.accountEntityIsSubscriber && (
							<div className="col">
								<Row className="justify-content-end">
									<Button onClick={() => props.onRemoveAccountEntity(props.entity.entityID!, props.entity, props.entity.accountEntityID!)}>
										Remove from institution
									</Button>
								</Row>
							</div>
						)}
				</Row>
				<Row style={{ marginTop: '20px' }}>
					<Col xs="auto">
						<Row>
							<Col>Website</Col>
						</Row>
						<Row>
							<Col>{props.entity.entityWebsite}</Col>
						</Row>
					</Col>
					<Col xs="auto">
						<Row>
							<Col>ABN</Col>
						</Row>
						<Row>
							<Col>{props.entity.entityABN}</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{ marginTop: '20px' }}>
					<Col>
						<Row>
							<Col>
								<Form.Label>Feature Write Permission</Form.Label>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId="formAccountEntityCanWriteAssetsCheckbox">
									<Form.Check
										id="formAccountEntityCanWriteAssetsCheckbox"
										label="Assets"
										type="checkbox"
										name="accountEntityCanWriteAssets"
										checked={props.entity.accountEntityCanWriteAssets}
										onChange={onChange}
										disabled={props.entity.accountEntityIsSubscriber}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId="formAccountEntityCanWriteLocationsCheckbox">
									<Form.Check
										id="formAccountEntityCanWriteLocationsCheckbox"
										label="Locations"
										type="checkbox"
										name="accountEntityCanWriteLocations"
										checked={props.entity.accountEntityCanWriteLocations}
										onChange={onChange}
										disabled={props.entity.accountEntityIsSubscriber}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId="formAccountEntityCanWriteTestQuestionsCheckbox">
									<Form.Check
										id="formAccountEntityCanWriteTestQuestionsCheckbox"
										label="Test Questions"
										type="checkbox"
										name="accountEntityCanWriteTestQuestions"
										checked={props.entity.accountEntityCanWriteTestQuestions}
										onChange={onChange}
										disabled={props.entity.accountEntityIsSubscriber}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId="formAccountEntityCanWriteAppSettingsCheckbox">
									<Form.Check
										id="formAccountEntityCanWriteAppSettingsCheckbox"
										label="Application Settings"
										type="checkbox"
										name="accountEntityCanWriteAppSettings"
										checked={props.entity.accountEntityCanWriteAppSettings}
										onChange={onChange}
										disabled={props.entity.accountEntityIsSubscriber}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId="formAccountEntityCanWriteEntitiesCheckbox">
									<Form.Check
										id="formAccountEntityCanWriteEntitiesCheckbox"
										label="Entities"
										type="checkbox"
										name="accountEntityCanWriteEntities"
										checked={props.entity.accountEntityCanWriteEntities}
										onChange={onChange}
										disabled={props.entity.accountEntityIsSubscriber}
									/>
								</Form.Group>
							</Col>
						</Row>
					</Col>
					<Col>
						<LocationAccessDetails
							entity={props.entity}
							parentEntity={props.parentEntity}
							testingContractors={props.testingContractors}
							locations={props.locations}
							assets={props.assets}
							updateAccess={(access) => {
								const newEntity = { ...props.entity, access }
								props.onAccountEntityChange(props.entity.entityID!, newEntity)
							}}
							setMessages={props.setMessages}
							disabled={props.entity.accountEntityIsSubscriber}
						/>
					</Col>

					<Col>
						<Row>
							<Form.Label>Settings</Form.Label>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId="formAccountEntityHideTcDetailsCheckbox">
									<Form.Check
										id="formAccountEntityHideTcDetailsCheckbox"
										label="Hide testing contractor details"
										type="checkbox"
										name="accountEntityHideTcDetails"
										checked={props.entity.accountEntityHideTcDetails}
										onChange={onChange}
										disabled={props.entity.accountEntityIsSubscriber}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group controlId="formAccountEntityHideInstUserDetailsCheckbox">
									<Form.Check
										id="formAccountEntityHideInstUserDetailsCheckbox"
										label="Do not share institution user details"
										type="checkbox"
										name="accountEntityHideInstUserDetails"
										checked={props.entity.accountEntityHideInstUserDetails}
										onChange={onChange}
										disabled={props.entity.accountEntityIsSubscriber}
									/>
								</Form.Group>
							</Col>
						</Row>
					</Col>
				</Row>
				{props.showTestingContractors && (
					<Row style={{ marginTop: '20px' }}>
						<Col lg="1"></Col>
						<Col>
							<Card
								title={'Testing Contractors'}
								collapsible={true}
								headerComponent={
									props.testingContractorHeaderComponent ? props.testingContractorHeaderComponent(props.entity.entityID!) : undefined
								}
							>
								{props.testingContractors.length > 0 ? (
									props.testingContractors.map((tc) => (
										<Row key={tc.entityID}>
											<Col>
												<AccountEntityDetails
													accountID={props.accountID}
													entity={tc}
													account={props.account}
													parentEntity={props.entity}
													locations={props.locations}
													assets={props.assets}
													setMessages={props.setMessages}
													testingContractors={[]}
													showTestingContractors={false}
													updateSubscriber={props.updateSubscriber}
													onAccountEntityChange={props.onAccountEntityChange}
													onRemoveAccountEntity={props.onRemoveAccountEntity}
													backState={props.backState}
												/>
											</Col>
										</Row>
									))
								) : (
									<Row>
										<Col>No Testing Contractors</Col>
									</Row>
								)}
							</Card>
						</Col>
					</Row>
				)}
			</>
		)
	}
	return (
		<Spinner animation="border" role="status">
			<span className="visually-hidden">Loading...</span>
		</Spinner>
	)
}

export { AccountEntityDetails }
export type { AccountEntityDetailsProps }
