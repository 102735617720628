import aws from './aws.json'

export const awsConfig = {
	Auth: {
		region: aws.UserPoolRegion,
		userPoolId: aws.UserPoolId,
		userPoolWebClientId: aws.UserPoolClientId,
	},
}

export const S3Config = {
	BaseUrl: aws.DocBucketUrl,
}

export const ApiConfig = {
	BaseUrl: aws.DomainEndpoint,
}

export const signUpConfig = {
	header: 'Generic Sign Up',
	hideAllDefaults: true,
	defaultCountryCode: '1',
	signUpFields: [
		{
			label: 'Email',
			key: 'email',
			required: true,
			displayOrder: 1,
			type: 'string',
		},
		{
			label: 'Password',
			key: 'password',
			required: true,
			displayOrder: 2,
			type: 'password',
		},
		{
			label: 'Given Name',
			key: 'given_name',
			required: true,
			displayOrder: 3,
			type: 'string',
		},
		{
			label: 'Family Name',
			key: 'family_name',
			required: true,
			displayOrder: 4,
			type: 'string',
		},
	],
}
