import React from 'react'
import { FormikErrors } from 'formik'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { ChevronDown, ChevronUp } from 'react-feather'

import { DeleteButton } from '../UI/Form/DeleteButton'
import { FormText } from '../UI/Form/Text'

import { PageComplianceQuestion } from './ComplianceQuestionDetails'
import { PageStatus } from '../../types/PageStatus'
import { ComplianceAnswer } from 'dynaflow/common/complianceQuestion'

type ComplianceQuestionErrorMessages = FormikErrors<PageComplianceQuestion>
type ComplianceAnswerErrorMessages = FormikErrors<string>

interface QuestionProps {
	question: PageComplianceQuestion | ComplianceAnswer
	children?: React.ReactNode
	errors?: ComplianceQuestionErrorMessages | ComplianceAnswerErrorMessages
	context: 'answering' | 'building'
	onDelete?: (id: string) => void
	onEditChange?: (updatedComplianceQuestion: PageComplianceQuestion) => void
	onAnswerChange?: (value: string) => void
	onReorder?: (newOrder: number) => void
	showUpReorder?: boolean
	showDownReorder?: boolean
	disabled: boolean
	pageStatus: PageStatus
	parentClause?: string
}

const Question = (props: QuestionProps) => {
	const onChange = (change: Partial<PageComplianceQuestion>) => {
		props.onEditChange!({ ...(props.question as PageComplianceQuestion), ...change, isUpdated: true })
	}

	const genericChange = (e: { target: { name: string; value: unknown } }) => onChange({ [e.target.name]: e.target.value })
	const checkboxChange = (e: { target: { name: string; checked: boolean } }) => onChange({ [e.target.name]: e.target.checked })

	if (props.context === 'building') {
		const errors = props.errors as ComplianceQuestionErrorMessages
		if (!props.onEditChange) {
			console.warn('onEditChange required in building context')
		}
		if (!props.onDelete) {
			console.warn('onDelete required in building context')
		}
		return (
			<Row className={props.question.isHeading ? 'compliance-heading-row' : 'compliance-question-row'}>
				<Col xs="auto">
					<ChevronUp
						style={{ opacity: props.showUpReorder ? 1 : 0, cursor: 'pointer' }}
						onClick={() => {
							if (props.onReorder && props.showUpReorder) {
								props.onReorder(props.question.order - 1)
							}
						}}
					/>
					<ChevronDown
						style={{ opacity: props.showDownReorder ? 1 : 0, cursor: 'pointer' }}
						onClick={() => {
							if (props.onReorder && props.showDownReorder) {
								props.onReorder(props.question.order + 1)
							}
						}}
					/>
				</Col>
				<Col>
					<Row>
						<Form.Label column xs="auto">
							Clause
						</Form.Label>
						<Col sm={5} md={3} lg={2}>
							<FormText
								name="clause"
								value={props.question.clause}
								onChange={genericChange}
								plaintext={props.pageStatus !== 'Editing'}
								disabled={props.pageStatus !== 'Editing'}
								feedback={errors?.clause}
								isInvalid={!!errors?.clause}
							/>
						</Col>
						<Form.Label column xs="auto">
							{props.question.isHeading ? 'Heading' : 'Question'}
						</Form.Label>
						<Col>
							<FormText
								name="text"
								value={props.question.text}
								onChange={genericChange}
								plaintext={props.pageStatus !== 'Editing'}
								disabled={props.pageStatus !== 'Editing'}
								feedback={errors?.text}
								isInvalid={!!errors?.text}
							/>
						</Col>
						{!props.question.isHeading && (
							<>
								<Form.Label column xs="auto" htmlFor="isFreeResponse">
									Free response?
								</Form.Label>
								<Col>
									<Form.Check
										style={{ justifyContent: 'center' }}
										inline
										className="radio-large"
										type="checkbox"
										name="isFreeResponse"
										checked={props.question.isFreeResponse}
										onChange={checkboxChange}
										disabled={props.pageStatus !== 'Editing'}
									/>
								</Col>
							</>
						)}
						<Col xs={1}>
							<DeleteButton
								id={props.question.complianceQuestionID}
								customOnClick={() => props.onDelete!(props.question.complianceQuestionID)}
								overlay
							/>
						</Col>
					</Row>
					{props.children}
				</Col>
			</Row>
		)
	} else if (props.question.isHeading) {
		return (
			<Card key={props.question.complianceQuestionID}>
				<Card.Header>
					<Card.Title>{`${props.question.clause ? props.question.clause + ' - ' : ''}${props.question.text}`}</Card.Title>
				</Card.Header>
				<Card.Body>
					<Row className="mb-2">
						<Col sm={6} md={8}>
							&nbsp;
						</Col>
						<Col>
							<Row className="text-center">
								<Col>Yes</Col>
								<Col>No</Col>
								<Col>N/A</Col>
							</Row>
						</Col>
					</Row>
					{props.children}
				</Card.Body>
			</Card>
		)
	} else {
		if (!props.onAnswerChange) {
			// console.warn('onAnswerChange required in answer context')
		}

		const errors = props.errors as ComplianceAnswerErrorMessages

		const question = props.question as ComplianceAnswer
		const rowError = !!errors
		return (
			<Row className={`mb-2 ${rowError && 'border border-danger rounded'}`} key={props.question.complianceQuestionID}>
				<Col sm={6} md={8}>
					<p className="ml-5">{`${props.question.clause}.\u00A0\u00A0${props.question.text}`}</p>
				</Col>
				<Col>
					<Row className="mt-1">
						{question.isFreeResponse ? (
							<>
								<Form.Label column xs="auto">
									Response
								</Form.Label>
								<Col className="d-flex">
									<FormText
										name="response"
										value={question.response || ''}
										onChange={(e) => props.onAnswerChange!(e.target.value)}
										plaintext={props.disabled}
										disabled={props.disabled}
									/>
								</Col>
							</>
						) : (
							<>
								<Col className="d-flex justify-content-center">
									<Form.Check
										style={{ justifyContent: 'center' }}
										className="radio-large"
										inline
										type="radio"
										value={'yes'}
										checked={question?.response === 'yes'}
										onChange={() => props.onAnswerChange!('yes')}
										disabled={props.disabled}
									/>
								</Col>
								<Col className="d-flex justify-content-center">
									<Form.Check
										style={{ justifyContent: 'center' }}
										className="radio-large"
										inline
										type="radio"
										value={'no'}
										checked={question?.response === 'no'}
										onChange={() => props.onAnswerChange!('no')}
										disabled={props.disabled}
									/>
								</Col>
								<Col className="d-flex justify-content-center">
									<Form.Check
										style={{ justifyContent: 'center' }}
										className="radio-large"
										inline
										type="radio"
										value={'n/a'}
										checked={question?.response === 'n/a'}
										onChange={() => props.onAnswerChange!('n/a')}
										disabled={props.disabled}
									/>
								</Col>
							</>
						)}
					</Row>
				</Col>
			</Row>
		)
	}
}

export { Question as ComplianceQuestionForm }
