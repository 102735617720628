import React, { useContext } from 'react'
import { AppContext } from '../../App'
import { Spinner, Button, Row, Col, Form, Dropdown } from 'react-bootstrap'

import * as EntityType from '../../constants/entityType'
import { PageStatus } from '../../types/PageStatus'
import * as Request from '../../utilities/request'
import { DropdownSelect } from '../Forms/Dropdown'
import { DeleteButton } from '../UI/Form/DeleteButton'

import { Account } from '../../../../back-end/common/account'
import { Access, AccessResult } from '../../../../back-end/common/access'
import { Asset } from '../../../../back-end/common/asset'
import { BuildingWithRooms } from '../../../../back-end/common/building'
import { EntityWithAccountContext } from '../../../../back-end/common/entity'
import { Location } from '../../../../back-end/common/location'
import { Room } from '../../../../back-end/common/room'
import { User } from '../../../../back-end/common/user'

// icons
import assetIcon from '../../images/icons/assets.svg'
import roomIcon from '../../images/icons/room.svg'
import buildingIcon from '../../images/icons/building.svg'
import locationIcon from '../../images/icons/location.svg'
import { MessageAction } from '../UI/Messages/Message'

interface LocationAccessDetailsProps {
	user?: User
	account?: Account

	entity?: Partial<EntityWithAccountContext>
	parentEntity?: Partial<EntityWithAccountContext>
	testingContractors?: Partial<EntityWithAccountContext>[]

	updateAccess: (access: Partial<Access>[]) => void

	locations: Location[] | null
	assets: Asset[] | null
	setMessages: (message: MessageAction) => void
	disabled?: boolean
}

type RoomWithBuildingName = Pick<Room, 'roomID' | 'roomName'> & { buildingName?: string }

interface FlatAccessOptions {
	locations: Location[]
	buildings: Pick<BuildingWithRooms, 'buildingID' | 'rooms' | 'buildingAddress' | 'buildingName'>[]
	rooms: RoomWithBuildingName[]
	assets: Asset[]
}
interface NewLocationAccess {
	newLocation: { location: Location | undefined; display: boolean }
	newBuilding: { building: Pick<BuildingWithRooms, 'buildingID' | 'rooms' | 'buildingAddress' | 'buildingName'> | undefined; display: boolean }
	newRoom: { room: RoomWithBuildingName | undefined; display: boolean }
	newAsset: { asset: Asset | undefined; display: boolean }
}

const newAccessDefault: NewLocationAccess = {
	newLocation: { location: undefined, display: false },
	newBuilding: { building: undefined, display: false },
	newRoom: { room: undefined, display: false },
	newAsset: { asset: undefined, display: false },
}

const LocationAccessDetails = (props: LocationAccessDetailsProps): JSX.Element => {
	if (!((props.user && props.account) || props.entity)) {
		throw new Error('LocationAccessDetails must have either entity props or user and account props')
	}

	const access = (props.entity ? props.entity.access : props.user?.access) || []

	const context = useContext(AppContext)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Ready')
	const [newAccess, setNewAccess] = React.useState<NewLocationAccess>(newAccessDefault)

	//#region Create and Delete Functions
	const onAccessDelete = async (accessID: string) => {
		try {
			const req = await Request.del<AccessResult>(`access?where=accessID==${accessID}`, context.appState.authState)

			if (req.data.success) {
				// If we just deleted the last access for this entity, we need to add a 'Full Access' row

				const deletedAccess = access?.find((a) => a.accessID === accessID)
				if (access?.length === 1) {
					const newAccess: Partial<Access> = {
						accountID: deletedAccess!.accountID,
						accountEntityID: props.entity?.accountEntityID,
						entityUserID: props.user?.entities?.[0]?.entityUserID,
					}
					const createReq = await Request.post<AccessResult>(`access`, newAccess, context.appState.authState)
					access!.push(createReq.data.accesses[0])
				}

				// If a child entity has acccess to the access we just deleted, we need to remove them as well
				if (props.testingContractors) {
					await Promise.all(
						props.testingContractors?.map(async (tc) => {
							if (tc.access) {
								return await Promise.all(
									tc.access.map(async (access) => {
										if (
											(deletedAccess?.assetID && deletedAccess?.assetID === access?.assetID) ||
											(deletedAccess?.roomID && deletedAccess?.roomID === access?.roomID) ||
											(deletedAccess?.buildingID && deletedAccess?.buildingID === access?.buildingID) ||
											(deletedAccess?.locationID && deletedAccess?.locationID === access?.locationID)
										) {
											await Request.del(`access?where=accessID==${access!.accessID}`, context.appState.authState)

											// If we just deleted the last access for this entity, we need to add a 'Full Access' row
											if (tc.access?.length === 1) {
												const access: Partial<Access> = {
													accountID: deletedAccess!.accountID,
													accountEntityID: tc.accountEntityID!,
												}
												const createReq = await Request.post<AccessResult>(`access`, access, context.appState.authState)
												tc.access!.push(createReq.data.accesses[0])
											}
											tc.access = tc.access?.filter((a) => a.accessID !== access.accessID)
										}
									})
								)
							}
						})
					)
				}

				// Clean up
				props.updateAccess(access.filter((a) => a.accessID !== accessID))

				props.setMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: `Successfully deleted the permission`,
						dismissible: true,
						timeout: 5000,
					},
				})
			} else {
				props.setMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `An error occurred while deleting the permission`,
						dismissible: true,
						timeout: 5000,
					},
				})
			}
		} catch (err) {
			props.setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `An error occurred while deleting the permission`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
	}

	const onAccessCreate = async (newAccess: NewLocationAccess, options: FlatAccessOptions) => {
		setPageStatus('Submitting')
		let creatingAccess: Partial<Access> = {}
		let accountID = ''

		// If we just added an access that is higher on the hierarchy than existing accesses, we need to remove them
		let existingDuplicateAccess: Partial<Access>[] = []

		if (newAccess.newAsset.asset) {
			accountID = newAccess.newAsset.asset.accountID
			creatingAccess = {
				accountID: accountID,
				accountEntityID: props.entity?.accountEntityID,
				entityUserID: props.user?.entities?.[0]?.entityUserID,
				locationID: newAccess.newAsset.asset.locationID,
				buildingID: newAccess.newAsset.asset.buildingID,
				roomID: newAccess.newAsset.asset.roomID,
				assetID: newAccess.newAsset.asset.assetID,
			}
		} else if (newAccess.newRoom.room) {
			// existingDuplicateAccess here holds existing rows for Assets, below the hierarchy
			existingDuplicateAccess = access!.filter((a) => a.roomID === newAccess.newRoom.room?.roomID)

			const building = options.buildings.find((b) => b.rooms.includes(newAccess.newRoom.room!))
			const location = options.locations.find((l) => l.buildings.includes(building!))

			accountID = location!.accountID
			creatingAccess = {
				accountID: accountID,
				accountEntityID: props.entity?.accountEntityID,
				entityUserID: props.user?.entities?.[0]?.entityUserID,
				locationID: location?.locationID,
				buildingID: building?.buildingID,
				roomID: newAccess.newRoom.room.roomID,
				assetID: undefined,
			}
		} else if (newAccess.newBuilding.building) {
			// existingDuplicateAccess here holds existing rows for rooms, below the hierarchy
			existingDuplicateAccess = access!.filter((a) => a.buildingID === newAccess.newBuilding.building?.buildingID)

			const location = options.locations.find((l) => l.buildings.includes(newAccess.newBuilding.building!))
			accountID = location!.accountID
			creatingAccess = {
				accountID: accountID,
				accountEntityID: props.entity?.accountEntityID,
				entityUserID: props.user?.entities?.[0]?.entityUserID,
				locationID: location?.locationID,
				buildingID: newAccess.newBuilding.building.buildingID,
				roomID: undefined,
				assetID: undefined,
			}
		} else if (newAccess.newLocation.location) {
			// existingDuplicateAccess here holds existing rows for buildings, below the hierarchy
			existingDuplicateAccess = access!.filter((a) => a.locationID === newAccess.newLocation.location?.locationID)

			accountID = newAccess.newLocation.location?.accountID
			creatingAccess = {
				accountID: accountID,
				accountEntityID: props.entity?.accountEntityID,
				entityUserID: props.user?.entities?.[0]?.entityUserID,
				locationID: newAccess.newLocation.location?.locationID,
				buildingID: undefined,
				roomID: undefined,
				assetID: undefined,
			}
		}

		try {
			const req = await Request.post<AccessResult>(`access`, creatingAccess, context.appState.authState)

			if (req.data.success) {
				// If we just added an access to replace a previous 'Full Access' row, we need to remove it
				let updatedAccess = access
				if (access?.length === 1) {
					const previousAccess = access[0]

					if (!previousAccess.locationID) {
						// Last row was all Access
						await Request.del<AccessResult>(`access?where=accessID==${previousAccess.accessID}`, context.appState.authState)
						updatedAccess = updatedAccess.filter((a) => a.accessID !== previousAccess.accessID)
					}
				}

				// Remove all matching rows lower on the hierarchy
				await Promise.all(
					existingDuplicateAccess.map(async (existingAccess: Partial<Access>) => {
						await Request.del<AccessResult>(`access?where=accessID==${existingAccess.accessID}`, context.appState.authState)
						updatedAccess = updatedAccess.filter((a) => a.accessID !== existingAccess.accessID)
					})
				)

				// Clean up
				if (req.data.accesses.length > 0) {
					props.updateAccess([...updatedAccess, req.data.accesses[0]])
				}
				setNewAccess({ ...newAccessDefault })

				props.setMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: `Successfully created the permission`,
						dismissible: true,
						timeout: 5000,
					},
				})
			} else {
				props.setMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `An error occurred while creating the permission`,
						dismissible: true,
						timeout: 5000,
					},
				})
			}
		} catch (err) {
			props.setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `An error occurred while creating the permission`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
		setPageStatus('Ready')
	}
	//#endregion

	if (!(props.locations && props.assets && access)) {
		return (
			<Spinner animation="border" role="status">
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		)
	}

	const flatAccessOptions: FlatAccessOptions = calculateFlatAccessOptions(props.locations, props.assets)

	const flatAccessNewOptions: FlatAccessOptions = calculateFlatAccessNewOptions(
		access,
		props.parentEntity?.access ? props.parentEntity.access || [] : [],
		flatAccessOptions
	)

	const canEdit =
		!props.disabled &&
		((props.user && context.appState.userPermissions.roleLevel === 10) ||
			(props.entity?.entityTypeID !== EntityType.Institution && props.entity?.accountEntityIsSubscriber === false))

	return (
		<div>
			<Row>
				<Col>
					<Form.Label>Location Permissions</Form.Label>
				</Col>
			</Row>

			{pageStatus === 'Ready' ? (
				<>
					<Row>
						<Col>
							{access.map((access: Partial<Access>) => {
								return formatAccess(access, flatAccessOptions, canEdit ? onAccessDelete : undefined)
							})}
						</Col>
					</Row>
					{canEdit && (
						<Row style={{ marginTop: '20px' }}>
							<Col>
								<Button className="btn btn-outline-dark span-bold" onClick={() => setPageStatus('Editing')}>
									Add Location
								</Button>
							</Col>
						</Row>
					)}
				</>
			) : (
				<>
					<Row className="form-label" style={{ marginBottom: '.5rem' }}>
						<Col>Select Type</Col>
					</Row>
					<Row>
						<Col>
							<Dropdown>
								<Dropdown.Toggle className={`form form-control`} variant="secondary" id="dropdown-newAccessType">
									{calulateSelectedNewAccessType(newAccess)}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => setNewAccess({ ...newAccessDefault, newLocation: { location: undefined, display: true } })}>
										Location
									</Dropdown.Item>
									<Dropdown.Item onClick={() => setNewAccess({ ...newAccessDefault, newBuilding: { building: undefined, display: true } })}>
										Building
									</Dropdown.Item>
									<Dropdown.Item onClick={() => setNewAccess({ ...newAccessDefault, newRoom: { room: undefined, display: true } })}>
										Room
									</Dropdown.Item>
									<Dropdown.Item onClick={() => setNewAccess({ ...newAccessDefault, newAsset: { asset: undefined, display: true } })}>
										Asset
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Col>
					</Row>

					<Row style={{ marginTop: '20px' }}>
						{newAccess.newLocation.display ? (
							<Col>
								<DropdownSelect
									name="locationID"
									label="Location"
									value={newAccess.newLocation.location ? newAccess.newLocation.location.locationName : 'None'}
									onChange={(value) =>
										setNewAccess({
											...newAccessDefault,
											newLocation: { location: flatAccessNewOptions.locations!.find((l) => l.locationID === value), display: true },
										})
									}
									disabled={false}
									id="locationID"
									variant="secondary"
									showSearch={true}
									searchPlaceholder="Filter"
									options={flatAccessNewOptions.locations.map((l) => ({ ...l, value: l.locationID, text: l.locationName }))}
								/>
							</Col>
						) : null}

						{newAccess.newBuilding.display ? (
							<Col>
								<DropdownSelect
									name="buildingID"
									label="Building"
									value={newAccess.newBuilding.building ? newAccess.newBuilding.building.buildingName : 'None'}
									onChange={(value) =>
										setNewAccess({
											...newAccessDefault,
											newBuilding: { building: flatAccessNewOptions.buildings!.find((l) => l.buildingID === value), display: true },
										})
									}
									disabled={false}
									id="buildingID"
									variant="secondary"
									showSearch={true}
									searchPlaceholder="Filter"
									options={flatAccessNewOptions.buildings.map((l) => ({ ...l, value: l.buildingID, text: l.buildingName }))}
								/>
							</Col>
						) : null}

						{newAccess.newRoom.display ? (
							<Col>
								<DropdownSelect
									name="roomID"
									label="Room"
									value={newAccess.newRoom.room ? newAccess.newRoom.room.roomName : 'None'}
									onChange={(value) =>
										setNewAccess({
											...newAccessDefault,
											newRoom: { room: flatAccessNewOptions.rooms!.find((l) => l.roomID === value), display: true },
										})
									}
									disabled={false}
									id="roomID"
									variant="secondary"
									showSearch={true}
									searchPlaceholder="Filter"
									options={flatAccessNewOptions.rooms.map((l) => ({ ...l, value: l.roomID, text: `${l.buildingName}, ${l.roomName}` }))}
								/>
							</Col>
						) : null}

						{newAccess.newAsset.display ? (
							<Col>
								<DropdownSelect
									name="assetID"
									label="Asset"
									value={newAccess.newAsset.asset ? newAccess.newAsset.asset.assetUnitReference : 'None'}
									onChange={(value) =>
										setNewAccess({
											...newAccessDefault,
											newAsset: { asset: flatAccessNewOptions.assets!.find((l) => l.assetID === value), display: true },
										})
									}
									disabled={false}
									id="assetID"
									variant="secondary"
									showSearch={true}
									searchPlaceholder="Filter"
									options={flatAccessNewOptions.assets.map((l) => ({
										...l,
										value: l.assetID,
										text: `${l.roomName}, ${l.assetUnitReference}`,
									}))}
								/>
							</Col>
						) : null}
					</Row>
					<Row style={{ marginTop: '20px' }}>
						<Col sm="auto">
							<Button
								className="btn btn-outline-dark span-bold"
								onClick={() => {
									setNewAccess({ ...newAccessDefault })
									setPageStatus('Ready')
								}}
								disabled={pageStatus === 'Submitting'}
							>
								Cancel
							</Button>
						</Col>
						<Col sm="auto">
							<Button
								className="btn btn-outline-dark span-bold"
								onClick={() => onAccessCreate(newAccess, flatAccessOptions)}
								disabled={pageStatus === 'Submitting'}
							>
								Save
							</Button>
						</Col>
					</Row>
				</>
			)}
		</div>
	)
}

// flatAccessOptions is used to store all potential Locations, Buildings, Rooms and Assets
const calculateFlatAccessOptions = (locations: Location[], assets: Asset[]): FlatAccessOptions => {
	return {
		locations: locations,
		buildings: locations.flatMap((location) => location.buildings),
		rooms: locations.flatMap((location) => location.buildings).flatMap((building) => building.rooms),
		assets: assets,
	}
}

// flatAccessNewOptions is used to store all potential Locations, Buildings, Rooms and Assets that we dont already have access to
const calculateFlatAccessNewOptions = (access: Partial<Access>[], parentAccess: Partial<Access>[], flatAccessOptions: FlatAccessOptions) => {
	/* 
		if we are a child entity, we should only have access limited to the parent entities

		To tackle this we strip everything out at the location level to start and then add options back in as we move down the hierarchy
	*/

	// Handle case where we do have a parent but they have full access
	if (parentAccess.length === 1 && !parentAccess[0].locationID) {
		parentAccess = []
	}

	const accessLocations = flatAccessOptions.locations.filter(
		(location) =>
			!access
				.map((a) => {
					if (!a.buildingID) {
						return a.locationID
					}
					return false
				})
				.includes(location.locationID) &&
			(parentAccess.length === 0 ||
				parentAccess
					.map((a) => {
						if (!a.buildingID) {
							return a.locationID
						}
						return false
					})
					.includes(location.locationID))
	)

	// Dont allow a building to be selected if we already have it in a location
	const parentBuildings = parentAccess
		.filter((a) => a.buildingID && !a.roomID)
		.map((a) => flatAccessOptions.buildings.find((b) => b.buildingID === a.buildingID)!)
	const accessLocationsBuildings = accessLocations.flatMap((l) => l.buildings)
	const accessBuildings = flatAccessOptions.buildings
		.filter(
			(building) =>
				!access
					.map((a) => {
						if (!a.roomID) {
							return a.buildingID
						}
						return false
					})
					.includes(building.buildingID)
		)
		.filter((b) => accessLocationsBuildings.includes(b))

	// Dont allow a room to be selected if we already have it in a building
	const parentRooms = parentAccess
		.filter((a) => a.roomID && !a.assetID)
		.map((a) => flatAccessOptions.rooms.find((r) => r.roomID === a.roomID)!)
		.concat(parentBuildings.flatMap((b) => b!.rooms))
	const accessBuildingsRooms = accessBuildings.flatMap((b) => b.rooms)
	const accessRooms = flatAccessOptions.rooms
		.filter(
			(room) =>
				!access
					.map((a) => {
						if (!a.assetID) {
							return a.roomID
						}
						return false
					})
					.includes(room.roomID)
		)
		.filter((r) => accessBuildingsRooms.includes(r))
		.concat(parentRooms)

	// Dont allow a asset to be selected if we already have it in a room

	const parentAssets = parentAccess.filter((a) => a.assetID).map((a) => flatAccessOptions.assets.find((asset) => asset.assetID === a.assetID)!)
	const accessRoomsAssets = accessRooms.flatMap((r) => r.roomID)
	const accessAssets = flatAccessOptions.assets
		.filter((asset) => !access.map((a) => a.assetID).includes(asset.assetID))
		.filter((a) => {
			return accessRoomsAssets.includes(a.roomID)
		})
		.concat(parentAssets)

	return {
		locations: accessLocations,
		buildings: accessBuildings,
		rooms: accessRooms,
		assets: accessAssets,
	}
}

const formatAccess = (access: Partial<Access>, flatAccessOptions: FlatAccessOptions, onAccessDelete?: (id: string) => Promise<void>) => {
	/* Start at the bottom of the hierarchy and work our way up */
	if (access.assetID) {
		const asset = flatAccessOptions.assets.find((a) => a.assetID === access.assetID)
		return (
			<Row key={access.accessID}>
				<Col sm={1}>
					<img src={assetIcon} alt={`A Asset Icon`}></img>
				</Col>
				<Col sm={5}>
					<i>Asset:</i> {asset?.roomName}, {asset?.assetUnitReference}
				</Col>
				{onAccessDelete && (
					<Col sm={'auto'}>
						<DeleteButton id={access.accessID!} onClick={onAccessDelete} />
					</Col>
				)}
			</Row>
		)
	} else if (access.roomID) {
		const room = flatAccessOptions.rooms.find((r) => r.roomID === access.roomID)
		return (
			<Row key={access.accessID}>
				<Col sm={1}>
					<img src={roomIcon} alt={`A Room Icon`}></img>
				</Col>
				<Col sm={5}>
					<i>Room:</i> {room?.buildingName}, {room?.roomName}
				</Col>
				{onAccessDelete && (
					<Col sm={'auto'}>
						<DeleteButton id={access.accessID!} onClick={onAccessDelete} />
					</Col>
				)}
			</Row>
		)
	} else if (access.buildingID) {
		return (
			<Row key={access.accessID}>
				<Col sm={1}>
					<img src={buildingIcon} alt={`A Building Icon`}></img>
				</Col>
				<Col sm={5}>
					<i>Building:</i> {flatAccessOptions.buildings.find((b) => b.buildingID === access.buildingID)?.buildingName}
				</Col>
				{onAccessDelete && (
					<Col sm={'auto'}>
						<DeleteButton id={access.accessID!} onClick={onAccessDelete} />
					</Col>
				)}
			</Row>
		)
	} else if (access.locationID) {
		return (
			<Row key={access.accessID}>
				<Col sm={1}>
					<img src={locationIcon} alt={`A Location Icon`}></img>
				</Col>
				<Col sm={5}>
					<i>Location:</i> {flatAccessOptions.locations.find((l) => l.locationID === access.locationID)?.locationName}
				</Col>
				{onAccessDelete && (
					<Col sm={'auto'}>
						<DeleteButton id={access.accessID!} onClick={onAccessDelete} />
					</Col>
				)}
			</Row>
		)
	}
	return 'Full Access'
}

const calulateSelectedNewAccessType = (newAccess: NewLocationAccess): string => {
	if (newAccess.newAsset.display) {
		return 'Asset'
	} else if (newAccess.newRoom.display) {
		return 'Room'
	} else if (newAccess.newBuilding.display) {
		return 'Building'
	} else if (newAccess.newLocation.display) {
		return 'Location'
	}
	return 'Select'
}

export { LocationAccessDetails }
export type { LocationAccessDetailsProps }
