import React, { useContext } from 'react'
import { Row, Col, Button, Form, InputGroup } from 'react-bootstrap'
import { User, UserResult } from '../../../../back-end/common/user'
import * as Request from '../../utilities/request'
import { AppContext } from '../../App'
import { RouteChildrenProps } from 'react-router'
import { Header } from '../../components/UI/Header/Header'
import { Card } from '../../components/UI/Card/Card'
import { PageStatus } from '../../types/PageStatus'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { FormText } from '../../components/UI/Form/Text'
import { Auth } from '@aws-amplify/auth'
import * as yup from 'yup'
import { Formik, FormikErrors } from 'formik'

import eyeOffIcon from '../../images/icons/eye-off.svg'
import eyeOnIcon from '../../images/icons/eye.svg'
import { MessageAction } from '../../components/UI/Messages/Message'

type UserErrorMessages = FormikErrors<Partial<User>>

const userValidationSchema = yup.object().shape({
	email: yup.string().email().required('Email address is required.'),
	firstName: yup.string().required('First name is required.'),
	lastName: yup.string().required('Last name is required.'),
})

interface changePasswordValuesProps {
	changePasswordScreen: boolean
	oldPassword: string
	newPassword: string
	viewOldPassword: boolean
	viewNewPassword: boolean
}

const resetPassword = async (
	changePasswordValues: changePasswordValuesProps,
	setChangePasswordValues: React.Dispatch<React.SetStateAction<changePasswordValuesProps>>,
	setMessages: (action: MessageAction) => void
) => {
	Auth.currentAuthenticatedUser()
		.then((user) => {
			return Auth.changePassword(user, changePasswordValues.oldPassword, changePasswordValues.newPassword)
		})
		.then(() => {
			setMessages({
				type: 'add',
				data: {
					severity: 'success',
					timeout: 3000,
					message: 'Password successfully updated',
					dismissible: true,
				},
			})
			setChangePasswordValues({
				changePasswordScreen: false,
				oldPassword: '',
				newPassword: '',
				viewOldPassword: false,
				viewNewPassword: false,
			})
		})
		.catch((err) => {
			setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					timeout: 3000,
					message: err.message,
					dismissible: true,
				},
			})
		})
}

// eslint-disable-next-line
const MyProfile = (props: RouteChildrenProps) => {
	const context = useContext(AppContext)
	const [messages, setMessages] = useMessageReducer([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [user, setUser] = React.useState<User | null>(null)

	const [changePasswordValues, setChangePasswordValues] = React.useState<changePasswordValuesProps>({
		changePasswordScreen: false,
		oldPassword: '',
		newPassword: '',
		viewOldPassword: false,
		viewNewPassword: false,
	})

	React.useEffect(() => {
		const getData = async () => {
			const [userResponse] = await Promise.all([
				Request.get<UserResult>(`user?where=userID==${context.appState.userAttributes.userID}`, context.appState.authState),
			])
			const user = userResponse.data.users[0]
			setUser(user)

			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, []) // eslint-disable-line

	const onUserEdit = async (user: User) => {
		if (changePasswordValues.changePasswordScreen) {
			resetPassword(changePasswordValues, setChangePasswordValues, setMessages)
		} else {
			try {
				const req = await Request.put<UserResult>(`user/${user.userID}`, user, context.appState.authState)

				if (req.data.success) {
					setMessages({
						type: 'add',
						data: {
							severity: 'success',
							message: `Successfully edited user`,
							dismissible: true,
							timeout: 5000,
						},
					})
				} else {
					setMessages({
						type: 'add',
						data: {
							severity: 'danger',
							message: `An error occurred while editing the user`,
							dismissible: true,
							timeout: 5000,
						},
					})
				}
			} catch (err) {
				setMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `An error occurred while editing the user`,
						dismissible: true,
						timeout: 5000,
					},
				})
			}
			setPageStatus('Ready')
		}
	}

	return (
		<div className="page">
			<Messages messages={messages} updateMessage={setMessages} />
			<Header title={'My Profile'} subtitle={''} />

			{user ? (
				<Formik initialValues={user} validationSchema={userValidationSchema} onSubmit={onUserEdit} enableReinitialize>
					{({ handleSubmit, errors, values, setFieldValue }) => (
						<Card
							title={user ? `${user.firstName} ${user.lastName}` : 'Loading...'}
							collapsible={true}
							headerComponent={() =>
								pageStatus !== 'Loading' && user ? (
									pageStatus === 'Ready' ? (
										<Row className="justify-content-end">
											<Col sm="auto">
												<Button
													className="btn btn-secondary"
													onClick={() => {
														setPageStatus('Editing')
														setChangePasswordValues({ ...changePasswordValues, changePasswordScreen: true })
													}}
												>
													Change Password
												</Button>
											</Col>
											<Col sm="auto">
												<Button className="btn btn-secondary" onClick={() => setPageStatus('Editing')}>
													Edit
												</Button>
											</Col>
										</Row>
									) : (
										<Row className="justify-content-end">
											<Col sm={'auto'}>
												<Button className="btn btn-secondary" onClick={() => handleSubmit()}>
													Save
												</Button>
											</Col>
											<Col sm={'auto'}>
												<Button
													className="btn btn-secondary"
													onClick={() => {
														setPageStatus('Ready')
														setChangePasswordValues({ ...changePasswordValues, changePasswordScreen: false })
													}}
												>
													Cancel
												</Button>
											</Col>
											<Col sm={'auto'}>{/* <DeleteButton id={entity.entityID} onClick={onEntityDelete} /> */}</Col>
										</Row>
									)
								) : (
									<></>
								)
							}
						>
							{changePasswordValues.changePasswordScreen ? (
								<div>
									<Row>
										<Col>
											<Form.Label>Enter Old Password</Form.Label>
											<InputGroup>
												<Form.Control
													style={{ width: '90%' }}
													type={changePasswordValues.viewOldPassword ? 'text' : 'password'}
													name={'oldPassword'}
													value={changePasswordValues.oldPassword}
													onChange={(e) => {
														setChangePasswordValues({ ...changePasswordValues, oldPassword: e.target.value })
													}}
													required
												/>
												<InputGroup.Text className="form-control">
													<img
														src={changePasswordValues.viewOldPassword ? eyeOffIcon : eyeOnIcon}
														alt={changePasswordValues.viewOldPassword ? 'A Closed Eye' : 'An Open Eye'}
														onClick={() =>
															setChangePasswordValues({
																...changePasswordValues,
																viewOldPassword: !changePasswordValues.viewOldPassword,
															})
														}
													></img>
												</InputGroup.Text>
											</InputGroup>
										</Col>
									</Row>

									<Row>
										<Col>
											<Form.Label>Enter New Password</Form.Label>
											<InputGroup>
												<Form.Control
													style={{ width: '90%' }}
													type={changePasswordValues.viewNewPassword ? 'text' : 'password'}
													name={'newPassword'}
													value={changePasswordValues.newPassword}
													onChange={(e) => {
														setChangePasswordValues({ ...changePasswordValues, newPassword: e.target.value })
													}}
													required
												/>
												<InputGroup.Text className="form-control">
													<img
														src={changePasswordValues.viewNewPassword ? eyeOffIcon : eyeOnIcon}
														alt={changePasswordValues.viewNewPassword ? 'A Closed Eye' : 'An Open Eye'}
														onClick={() =>
															setChangePasswordValues({
																...changePasswordValues,
																viewNewPassword: !changePasswordValues.viewNewPassword,
															})
														}
													></img>
												</InputGroup.Text>
											</InputGroup>
										</Col>
									</Row>
								</div>
							) : (
								<MyProfileDetails user={values} onChange={setFieldValue} pageStatus={pageStatus} errors={errors} />
							)}
						</Card>
					)}
				</Formik>
			) : null}
		</div>
	)
}

interface MyProfileDetailsProps {
	user: User | null
	onChange: (field: string, value: string | number | boolean | null) => void
	pageStatus: PageStatus
	errors?: UserErrorMessages
}

const MyProfileDetails = (props: MyProfileDetailsProps) => {
	const readOnly = props.pageStatus !== 'Editing'

	if (props.user) {
		return (
			<div style={{ flexDirection: 'column' }}>
				<div>
					<Row>
						<Col>
							<FormText
								name={'email'}
								value={props.user.email}
								label={'Email'}
								onChange={(e) => props.onChange(e.target.name, e.target.value)}
								plaintext={readOnly}
								disabled={readOnly}
								required
								feedback={props.errors?.email}
								isInvalid={!!props.errors?.email}
							/>
						</Col>
						<Col>
							<FormText
								name={'firstName'}
								value={props.user.firstName}
								label={'First Name'}
								onChange={(e) => props.onChange(e.target.name, e.target.value)}
								plaintext={readOnly}
								disabled={readOnly}
								required
								feedback={props.errors?.firstName}
								isInvalid={!!props.errors?.firstName}
							/>
						</Col>
						<Col>
							<FormText
								name={'lastName'}
								value={props.user.lastName}
								label={'Last Name'}
								onChange={(e) => props.onChange(e.target.name, e.target.value)}
								plaintext={readOnly}
								disabled={readOnly}
								required
								feedback={props.errors?.lastName}
								isInvalid={!!props.errors?.lastName}
							/>
						</Col>
					</Row>
				</div>
			</div>
		)
	}
	return null
}

export { MyProfile }
