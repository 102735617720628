const AdminOwner = '2df8c558-7914-48e4-996c-6f9df768ce69'
const AdminManager = '5cd6601a-1250-445a-a29a-470f77ca782e'

const InstitutionOwner = '6ec54684-6360-4719-bd85-1197b6f85ba7'
const InstitutionManager = '06924a98-11f7-4d38-86e4-4bd43aadfaec'
const InstitutionSupervisor = 'ff854c83-bf4d-4460-a939-00e50ea3c49e'
const InstitutionLabUser = 'e9838224-8c6b-41a1-9cdf-41bad12089bb'

const FacilitiesOwner = 'b290fa9f-6581-4cd2-ad29-77f86753666e'
const FacilitiesManager = '40ddaf4a-c270-4e65-ad16-beac0f1d86a9'

const TestingOwner = '198d2ea1-766e-4bc3-abd3-0d0d55bae149'
const TestingManager = 'c390c00f-6562-486e-8bce-c7214f320220'
const TestingSupervisor = '481c092b-0c68-4d9f-be28-ddfee79ea0a1'
const TestingTechnician = '19d8a70b-f998-4f32-98fe-e6a5c0bc3742'

const Level: Record<string, number> = {
	'2df8c558-7914-48e4-996c-6f9df768ce69': 10,
	'5cd6601a-1250-445a-a29a-470f77ca782e': 8,

	'6ec54684-6360-4719-bd85-1197b6f85ba7': 10,
	'06924a98-11f7-4d38-86e4-4bd43aadfaec': 8,
	'ff854c83-bf4d-4460-a939-00e50ea3c49e': 6,
	'e9838224-8c6b-41a1-9cdf-41bad12089bb': 4,

	'b290fa9f-6581-4cd2-ad29-77f86753666e': 10,
	'40ddaf4a-c270-4e65-ad16-beac0f1d86a9': 8,

	'198d2ea1-766e-4bc3-abd3-0d0d55bae149': 10,
	'c390c00f-6562-486e-8bce-c7214f320220': 8,
	'481c092b-0c68-4d9f-be28-ddfee79ea0a1': 6,
	'19d8a70b-f998-4f32-98fe-e6a5c0bc3742': 4,
}

export {
	AdminOwner,
	AdminManager,
	InstitutionOwner,
	InstitutionManager,
	InstitutionSupervisor,
	InstitutionLabUser,
	FacilitiesOwner,
	FacilitiesManager,
	TestingOwner,
	TestingManager,
	TestingSupervisor,
	TestingTechnician,
	Level,
}
