import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'

interface MenuItemProps {
	menuIsOpen: boolean
	isSelected: boolean
	link: string
	icon: string
	title: string
}
export const MenuItem = (props: MenuItemProps) => {
	return (
		<OverlayTrigger delay={{ show: 600, hide: 400 }} placement="auto" overlay={<Tooltip id={`toolTip_${props.title}`}>{props.title}</Tooltip>}>
			<Link to={props.link} className={`sideNav-row-${props.title}`}>
				<Row className={props.isSelected ? `sideNav-row selected` : `sideNav-row`}>
					<Col>
						<img src={props.icon} alt={`A ${props.icon} Icon`} className={props.isSelected ? 'sideNav-icon selected' : 'sideNav-icon'}></img>
						{props.menuIsOpen ? <span className={props.isSelected ? 'sideNav-text selected' : 'sideNav-text'}>{props.title}</span> : null}
					</Col>
				</Row>
			</Link>
		</OverlayTrigger>
	)
}
