import React from 'react'
import { Auth } from '@aws-amplify/auth'
import { useTheme, View, Image, Text, Heading, Button, useAuthenticator, Theme } from '@aws-amplify/ui-react'
import logo from '../../../images/logo1.png'

const Services = {
	handleSignIn: (formData: { username: string; password: string }) => {
		const { username, password } = formData
		return Auth.signIn(username.toLowerCase(), password)
	},
}

const Components = {
	Header() {
		const { tokens } = useTheme()

		return (
			<View textAlign="center" padding={tokens.space.large}>
				<Image alt="Safetick logo" src={logo} />
			</View>
		)
	},

	Footer() {
		const { tokens } = useTheme()

		return (
			<View textAlign="center" padding={tokens.space.large}>
				<Text color={tokens.colors.neutral[80]}>&copy; All Rights Reserved</Text>
			</View>
		)
	},

	SignIn: {
		Header() {
			const { tokens } = useTheme()

			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Sign in to your account
				</Heading>
			)
		},
		Footer() {
			const { toResetPassword } = useAuthenticator()

			return (
				<View textAlign="center">
					<View>
						Forgot your password?
						<Button style={{ textDecoration: 'underline' }} fontWeight="normal" onClick={toResetPassword} size="small" variation="link">
							Reset password
						</Button>
					</View>
				</View>
			)
		},
	},

	SignUp: {
		Header() {
			const { tokens } = useTheme()

			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Create a new account
				</Heading>
			)
		},
		Footer() {
			const { toSignIn } = useAuthenticator()

			return (
				<View textAlign="center">
					<Button style={{ textDecoration: 'underline' }} fontWeight="normal" onClick={toSignIn} size="small" variation="link">
						Back to Sign In
					</Button>
				</View>
			)
		},
	},
	ConfirmSignUp: {
		Header() {
			const { tokens } = useTheme()
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			)
		},
	},
	SetupTOTP: {
		Header() {
			const { tokens } = useTheme()
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			)
		},
	},
	ConfirmSignIn: {
		Header() {
			const { tokens } = useTheme()
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			)
		},
	},
	ResetPassword: {
		Header() {
			const { tokens } = useTheme()
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			)
		},
	},
	ConfirmResetPassword: {
		Header() {
			const { tokens } = useTheme()
			return (
				<Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
					Enter Information:
				</Heading>
			)
		},
	},
}

const theme = {
	name: 'safetick-theme',
	tokens: {
		colors: {
			font: {
				primary: { value: '#212529' },
			},
		},
		components: {
			button: {
				_hover: {
					backgroundColor: { value: '{colors.red.20}' },
				},
				_focus: {
					backgroundColor: { value: '{colors.red.20}' },
				},
				_active: {
					backgroundColor: { value: '{colors.red.30}' },
				},
				primary: {
					backgroundColor: {
						value: '#ec1a21',
					},
					_hover: {
						backgroundColor: { value: '{colors.red.80}' },
					},
					_focus: {
						backgroundColor: { value: '{colors.red.80}' },
					},
					_active: {
						backgroundColor: { value: '{colors.red.90}' },
					},
				},
				link: {
					color: { value: '#212529' },
					_hover: {
						backgroundColor: { value: 'none' },
					},
					_focus: {
						backgroundColor: { value: 'none' },
					},
					_active: {
						backgroundColor: { value: 'none' },
					},
				},
			},
		},
	},
} as Theme

export { Services, Components, theme as AmplifyTheme }
