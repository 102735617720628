import React, { useContext } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { FormText } from '../../components/UI/Form/Text'
import { Location, LocationResult } from '../../../../back-end/common/location'
import { BuildingResult, BuildingWithRooms } from '../../../../back-end/common/building'
import { Room, RoomResult } from '../../../../back-end/common/room'
import * as Request from '../../utilities/request'
import { AppContext } from '../../App'
import { RouteComponentProps, StaticContext } from 'react-router'
import { Header } from '../../components/UI/Header/Header'
import { Card } from '../../components/UI/Card/Card'
import { PageStatus } from '../../types/PageStatus'
import { DeleteButton } from '../../components/UI/Form/DeleteButton'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { getUrlSearchParam } from '../../utilities/url'
import { PageBackState } from '../../types/PageBackState'
import { Link } from 'react-router-dom'
import { HistoryCardBottom } from '../../components/UI/HistoryCardBottom/HistoryCardBottom'
import MultiText from '../../components/Forms/MultiText'
import RoomPill from '../../components/Location/RoomPill'
import generateUuid from '../../utilities/uuid'
import * as yup from 'yup'
import { Formik, FormikErrors } from 'formik'
import { TestingTechnician } from 'dynaflow/constants/role'
import { canWriteBuilding } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'

const locationValidationSchema = yup.object().shape({
	locationName: yup.string().required('Location Name is required.'),
	locationAddress: yup.string().optional().nullable(),
	buildings: yup.array().of(
		yup.object().shape({
			isDelete: yup.boolean(),
			buildingName: yup.string().when('isDelete', {
				is: false,
				then: yup.string().required('Building Name is required'),
			}),
			buildingAddress: yup.string().optional().nullable(),
		})
	),
})

interface ScreensLocationMatchParams {
	id: string
}

interface ScreensLocationStateParams extends PageBackState {}
interface LocationWithNewBuildings extends Location {
	isTouched: boolean
	buildings: Pick<BuildingWithNewRooms, 'buildingID' | 'buildingName' | 'buildingAddress' | 'rooms' | 'locationID' | 'isNew' | 'isDelete' | 'isTouched'>[]
}
interface BuildingWithNewRooms extends BuildingWithRooms {
	isTouched: boolean
	isNew: boolean
	isDelete: boolean
	rooms: PageRoom[]
}
type PageRoom = Pick<Room, 'roomName' | 'buildingID' | 'roomID' | 'buildingName'> & { isNew: boolean; isDelete: boolean }

interface LocationWithNewBuildingsResult extends LocationResult {
	locations: LocationWithNewBuildings[]
}

type LocationErrorMessages = FormikErrors<Partial<LocationWithNewBuildings>>
type BuildingErrorMessages = FormikErrors<Partial<BuildingWithNewRooms>>[]

const newLocation = (location: Partial<LocationWithNewBuildings>): Partial<LocationWithNewBuildings> => ({
	locationName: location.locationName || '',
	locationAddress: location.locationAddress || '',
	buildings: location.buildings || [],
	accountID: location.accountID || '',
	isTouched: false,
})

const newBuilding = (
	building: Partial<BuildingWithNewRooms>
): Pick<BuildingWithNewRooms, 'buildingID' | 'buildingName' | 'buildingAddress' | 'rooms' | 'locationID' | 'isNew' | 'isDelete' | 'isTouched'> => ({
	buildingID: building.buildingID || '',
	buildingName: building.buildingName || '',
	buildingAddress: building.buildingAddress || '',
	rooms: building.rooms || [],
	locationID: building.locationID || '',
	isNew: true,
	isDelete: false,
	isTouched: false,
})

const newRoom = (room: Partial<Room>): PageRoom => ({
	roomID: room.roomID || '',
	roomName: room.roomName || '',
	buildingID: room.buildingID || '',
	buildingName: room.buildingName || '',
	isNew: true,
	isDelete: false,
})

const ScreensLocationDetails = (props: RouteComponentProps<ScreensLocationMatchParams, StaticContext, ScreensLocationStateParams>) => {
	const context = useContext(AppContext)
	const locationID = props.match!.params.id
	const isNew = locationID === 'new'
	const accountID = getUrlSearchParam(props.location.search, 'account')

	const [messages, setMessages] = useMessageReducer([])
	const [location, setLocation] = React.useState<Partial<LocationWithNewBuildings> | null>(
		isNew ? newLocation({ accountID: accountID || '', locationID: generateUuid() }) : null
	)
	const [initialState, setInitialState] = React.useState<Partial<LocationWithNewBuildings> | null>(
		isNew ? newLocation({ accountID: accountID || '', locationID: generateUuid() }) : null
	)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>(isNew ? 'Editing' : 'Loading')

	React.useEffect(() => {
		const getData = async () => {
			const [locationReq] = await Promise.all([
				Request.get<LocationWithNewBuildingsResult>(`location?where=locationID==${locationID}`, context.appState.authState),
			])
			setLocation(locationReq.data.locations[0])
			setInitialState(locationReq.data.locations[0])
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn && !isNew) {
			getData()
		}
	}, []) // eslint-disable-line

	//#region Create, Update and Delete Functions
	const onLocationCreate = async (location: Partial<LocationWithNewBuildings>) => {
		setPageStatus('Submitting')
		try {
			// clean up buildings and rooms for isDelete items
			if (location.buildings) {
				location.buildings = location.buildings
					.filter((b) => !b.isDelete)
					.map((b) => {
						b.rooms = b.rooms.filter((r) => !r.isDelete)
						return b
					})
			}

			const req = await Request.post<LocationWithNewBuildingsResult>(`location`, location, context.appState.authState)

			if (req.data.success && req.data.locations.length > 0) {
				setLocation(req.data.locations[0])
				setMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: `Successfully created the location`,
						dismissible: true,
						timeout: 5000,
					},
				})
				props.history.push(`/location/${req.data.locations[0].locationID}`, {
					backPath: props.location.state.backPath,
					backName: props.location.state.backName,
				})
			} else {
				setMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `An error occurred while creating the location`,
						dismissible: true,
						timeout: 5000,
					},
				})
			}
		} catch (err) {
			setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `An error occurred while creating the location`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
		setPageStatus('Ready')
	}

	const onLocationEdit = async (location: Partial<LocationWithNewBuildings>) => {
		setPageStatus('Submitting')
		try {
			if (location.isTouched) {
				await Request.put<LocationWithNewBuildingsResult>(`location?where=locationID==${locationID}`, location, context.appState.authState)
			}

			// buildings
			if (location.buildings) {
				const buildings = location.buildings.map((bld) => ({ ...bld, locationID: location.locationID, accountID: location.accountID }))
				const newBuildings = buildings.filter((bld) => bld.isNew && !bld.isDelete)
				const oldBuildings = buildings.filter((bld) => !bld.isNew && !bld.isDelete)
				const delBuildings = buildings.filter((bld) => bld.isDelete && !bld.isNew)
				await Promise.all([
					...newBuildings.map((building) => Request.post<BuildingResult>(`building`, building, context.appState.authState)),
					...oldBuildings
						.filter((building) => building.isTouched)
						.map((building) =>
							Request.put<BuildingResult>(`building?where=buildingID==${building.buildingID}`, building, context.appState.authState)
						),
					...delBuildings.map((building) =>
						Request.del<BuildingResult>(`building?where=buildingID==${building.buildingID}`, context.appState.authState)
					),
				])

				// rooms
				const rooms = location.buildings.map((building) => building.rooms.map((room) => ({ ...room, buildingID: building.buildingID }))).flat()
				const newRooms = rooms.filter((room) => room.isNew && !room.isDelete && !newBuildings.find((b) => b.buildingID === room.buildingID))
				const delRooms = rooms.filter((room) => room.isDelete && !room.isNew)
				await Promise.all([
					...newRooms.map((room) => Request.post<RoomResult>(`room`, room, context.appState.authState)),
					...delRooms.map((room) => Request.del<RoomResult>(`room?where=roomID==${room.roomID}`, context.appState.authState)),
				])
			}

			setMessages({
				type: 'add',
				data: {
					severity: 'success',
					message: `Successfully edited the location`,
					dismissible: true,
					timeout: 5000,
				},
			})

			// Data Clean up, remove all isNew and isDeletemarkers
			const buildingsCleanUp = location.buildings?.map((b) => {
				b.rooms = b.rooms.map((r) => ({ ...r, isNew: false, isDelete: false, isTouched: false }))
				return { ...b, isNew: false, isDelete: false }
			})
			setLocation({ ...location, isTouched: false, buildings: buildingsCleanUp })
		} catch (err) {
			setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `An error occurred while editing the location`,
					dismissible: true,
					timeout: 5000,
				},
			})
			setLocation(initialState)
		}
		setPageStatus('Ready')
	}

	const onLocationDelete = async (locationID: string) => {
		setPageStatus('Submitting')
		try {
			const req = await Request.del<LocationResult>(`location?where=locationID==${locationID}`, context.appState.authState)

			if (req.data.success) {
				setMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: `Successfully deleted the location`,
						dismissible: true,
						timeout: 5000,
					},
				})
			} else {
				setMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `An error occurred while deleting the location`,
						dismissible: true,
						timeout: 5000,
					},
				})
			}
		} catch (err) {
			setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `An error occurred while deleting the location`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
	}
	//#endregion

	const onAddBuilding = () => {
		if (location) {
			const newLocation = { ...location, buildings: [newBuilding({ buildingID: generateUuid() }), ...(location.buildings || [])] }
			setLocation(newLocation)
		}
	}

	const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		const property = e.target.name
		const value = e.target.value
		if (location) {
			setLocation({ ...location, isTouched: true, [property]: value })
		}
	}

	const onBuildingChange = (buildingID: string, e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: boolean } }) => {
		const property = e.target.name
		const value = e.target.value
		if (location && location.buildings) {
			const newBuildings = location.buildings.map((building) => {
				if (buildingID === building.buildingID) {
					return { ...building, isTouched: true, [property]: value }
				} else {
					return building
				}
			})
			const newLocation = {
				...location,
				buildings: newBuildings,
			}
			setLocation(newLocation)
		}
	}

	const onRoomAdd = (buildingID: string) => {
		return (roomName: string) => {
			if (location && location.buildings) {
				const newBuildings = location.buildings.map((building) => {
					if (buildingID === building.buildingID) {
						return { ...building, rooms: [...building.rooms, newRoom({ roomID: generateUuid(), roomName: roomName })] }
					} else {
						return building
					}
				})
				const newLocation = {
					...location,
					buildings: newBuildings,
				}
				setLocation(newLocation)
			}
		}
	}

	const onRoomDelete = (buildingID: string) => {
		return (roomID: string) => {
			if (location && location.buildings) {
				const newBuildings = location.buildings.map((building) => {
					if (buildingID === building.buildingID) {
						return {
							...building,
							rooms: building.rooms.map((room) => {
								if (room.roomID === roomID) {
									return { ...room, isDelete: true }
								} else {
									return room
								}
							}),
						}
					} else {
						return building
					}
				})
				const newLocation = {
					...location,
					buildings: newBuildings,
				}
				setLocation(newLocation)
			}
		}
	}

	return (
		<div className="page">
			<Messages messages={messages} updateMessage={setMessages} />

			{props.location.state && props.location.state.backPath && (
				<Row>
					<Col>
						<Link className="breadcrumb-back" to={props.location.state.backPath}>{`< Back to ${props.location.state.backName}`}</Link>
					</Col>
				</Row>
			)}

			<Header title={'Location Details'} subtitle={''} />

			{location ? (
				<Formik
					initialValues={location}
					onSubmit={(e) => {
						console.log('here', e)
						return isNew ? onLocationCreate(e) : onLocationEdit(e)
					}}
					validationSchema={locationValidationSchema}
					enableReinitialize
				>
					{({ handleSubmit, errors }) => (
						<Card
							title={!isNew && location ? 'Location Information' : 'New Location'}
							collapsible={false}
							headerComponent={() =>
								pageStatus !== 'Loading' &&
								location &&
								canWriteBuilding(appStateToPermissionObject(context.appState), { accountID: location.accountID! }) ? (
									pageStatus === 'Ready' ? (
										<Row className="justify-content-end">
											<Col sm={'auto'}>
												<Button className="btn btn-outline-dark span-bold" onClick={() => setPageStatus('Editing')}>
													Edit
												</Button>
											</Col>
										</Row>
									) : (
										<Row className="justify-content-end">
											<Col sm={'auto'}>
												<Button
													className="btn btn-secondary"
													onClick={() => {
														console.log('submit a')
														handleSubmit()
													}}
												>
													Save
												</Button>
											</Col>
											<Col sm={'auto'}>
												<Button className="btn btn-secondary" onClick={() => setPageStatus('Ready')}>
													Cancel
												</Button>
											</Col>
											{location.locationID && (
												<Col sm={'auto'}>
													<DeleteButton id={location.locationID} onClick={onLocationDelete} />
												</Col>
											)}
										</Row>
									)
								) : (
									<></>
								)
							}
						>
							<LocationDetails
								location={location}
								pageStatus={pageStatus}
								isNew={isNew}
								errors={errors}
								buildingErrors={errors.buildings as unknown as BuildingErrorMessages} // formik doesn't handle inner object for errors well
								canEditOrDelete={context.appState.userPermissions.roleID !== TestingTechnician}
								onAddBuilding={onAddBuilding}
								onBuildingChange={onBuildingChange}
								onRoomAdd={onRoomAdd}
								onRoomDelete={onRoomDelete}
								onChange={onChange}
							/>
						</Card>
					)}
				</Formik>
			) : null}
		</div>
	)
}

interface LocationDetailsProps {
	location: Partial<LocationWithNewBuildings> | null
	pageStatus: PageStatus
	isNew: boolean
	errors?: LocationErrorMessages
	buildingErrors?: BuildingErrorMessages
	canEditOrDelete: boolean
	onAddBuilding: () => void
	onBuildingChange: (buildingID: string, e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: boolean } }) => void
	onRoomAdd: (buildingID: string) => (roomID: string) => void
	onRoomDelete: (buildingID: string) => (roomID: string) => void
	onChange: React.ChangeEventHandler<HTMLInputElement>
}

const LocationDetails = (props: LocationDetailsProps) => {
	const readOnly = props.pageStatus !== 'Editing' || !props.canEditOrDelete
	const roomAndBuildingReadOnly = props.pageStatus !== 'Editing'

	if (props.location) {
		return (
			<>
				<Row>
					<Col>
						<FormText
							name={'locationName'}
							value={props.location.locationName}
							label={'Name'}
							onChange={props.onChange}
							plaintext={readOnly}
							disabled={readOnly}
							placeholder={`Add location name...`}
							feedback={props.errors?.locationName}
							isInvalid={!!props.errors?.locationName}
						/>
					</Col>
					<Col>
						<FormText
							name={'locationAddress'}
							value={props.location.locationAddress}
							label={'Address'}
							onChange={props.onChange}
							plaintext={readOnly}
							disabled={readOnly}
							placeholder={`Add address...`}
							feedback={props.errors?.locationAddress}
							isInvalid={!!props.errors?.locationAddress}
						/>
					</Col>
				</Row>
				<hr />
				{!roomAndBuildingReadOnly && (
					<Row style={{ marginBottom: '10px' }}>
						<Col>
							<Button className="btn btn-outline-dark span-bold" onClick={props.onAddBuilding}>
								Add a new building
							</Button>
						</Col>
					</Row>
				)}

				<Row>
					<Col xs="3">
						<Form.Label>Building Name</Form.Label>
					</Col>
					<Col xs="3">
						<Form.Label>Building Address</Form.Label>
					</Col>
					<Col xs="5">
						<Form.Label>Rooms</Form.Label>
					</Col>
					<Col xs="1"></Col>
				</Row>

				<>
					{props.location &&
						props.location.buildings &&
						props
							.location!.buildings!.filter((building) => !building.isDelete)
							.map((building, index) => (
								<Row key={building.buildingID || index} style={{ marginBottom: '10px' }}>
									<Col xs="3">
										<FormText
											name={'buildingName'}
											value={building.buildingName}
											onChange={(e) => props.onBuildingChange(building.buildingID, e as React.ChangeEvent<HTMLInputElement>)}
											plaintext={roomAndBuildingReadOnly || (!props.canEditOrDelete && !building.isNew)}
											disabled={roomAndBuildingReadOnly || (!props.canEditOrDelete && !building.isNew)}
											placeholder={`Add building name...`}
											feedback={props.buildingErrors && props.buildingErrors[index]?.buildingName}
											isInvalid={!!(props.buildingErrors && props.buildingErrors[index]?.buildingName)}
										/>
									</Col>
									<Col xs="3">
										<FormText
											name={'buildingAddress'}
											value={building.buildingAddress}
											onChange={(e) => props.onBuildingChange(building.buildingID, e as React.ChangeEvent<HTMLInputElement>)}
											plaintext={roomAndBuildingReadOnly || (!props.canEditOrDelete && !building.isNew)}
											disabled={roomAndBuildingReadOnly || (!props.canEditOrDelete && !building.isNew)}
											placeholder={`Add address...`}
											feedback={props.buildingErrors && props.buildingErrors[index]?.buildingAddress}
											isInvalid={!!(props.buildingErrors && props.buildingErrors[index]?.buildingAddress)}
										/>
									</Col>
									<Col xs="4">
										{!roomAndBuildingReadOnly ? (
											<MultiText
												onNewValue={props.onRoomAdd(building.buildingID)}
												id="roomName"
												name="roomName"
												placeholder="Separate rooms with commas"
												disabled={props.pageStatus !== 'Editing'}
												values={building.rooms.filter((room) => !room.isDelete)}
												valueComponent={(room) => (
													<RoomPill
														key={room.roomID}
														className="room-pill"
														showDelete={!readOnly}
														onDelete={props.onRoomDelete(building.buildingID)}
														room={room}
													/>
												)}
											/>
										) : (
											building.rooms
												.filter((room) => !room.isDelete)
												.map((room) => <RoomPill key={room.roomID} className="room-pill" showDelete={false} room={room} />)
										)}
									</Col>
									<Col xs={'2'}>
										{(!readOnly || building.isNew) && (
											<DeleteButton
												id={building.buildingID}
												customOnClick={() => props.onBuildingChange(building.buildingID, { target: { name: 'isDelete', value: true } })}
											/>
										)}
									</Col>
								</Row>
							))}
				</>
				{!props.isNew && props.location.create && props.location.modified && (
					<HistoryCardBottom
						historyReferenceID={props.location.locationID!}
						created={props.location.create}
						modified={props.location.modified}
						backState={{ backPath: `/location/${props.location.locationID!}`, backName: `${props.location.locationName}` }}
					/>
				)}
			</>
		)
	}
	return null
}

export { ScreensLocationDetails }
