import React, { ChangeEvent, useContext } from 'react'
import { AppContext } from '../../../App'
import { Row, Col, Card, Form } from 'react-bootstrap'
import { Calibration } from '../../../../../back-end/common/calibration'
import { FormText } from '../../../components/UI/Form/Text'
import { TestProps } from './QuestionLookup.d'
import { Formik, FormikErrors, validateYupSchema, yupToFormErrors } from 'formik'
import * as yup from 'yup'
import { calibrateAdditiveValue } from 'dynaflow/utilities/calibration'
import { AirVelocityTestOutput } from 'dynaflow/utilities/question'
import { getAssetPointCount } from 'dynaflow/utilities/questionResponse'
import { getPassValueOverrides } from 'dynaflow/utilities/testOverride'
import {
	onQuestionSubmit,
	QuestionElement,
	QuestionNavigationButtons,
	QuestionResult,
	QuestionResultElementGroup,
	TabRow,
	useLoadExistingQuestionResponse,
	QuestionHeader,
} from './QuestionComponents'

const VELOCITY_READING_CARD_WIDTH = 150
export interface VelocityReadingProps {
	index: number
	formikKey: string
	calibration: Pick<Calibration, 'calibrationID' | 'calibrationDate' | 'calibrationCertificateNumber' | 'calibrationCertificateS3Path' | 'calibrationItems'>
	values?: GridResult
	errors?: FormikErrors<GridResult> | string | string[]
	handleChange: (e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
	disabled: boolean
}

export const VelocityReading = (props: VelocityReadingProps) => {
	const calibValue = calibrateAdditiveValue(Number(props.values?.value), props.calibration)
	return (
		<Card className="ml-2 mr-2" style={{ width: `${VELOCITY_READING_CARD_WIDTH}px` }} title={props.index.toString()}>
			<div className="text-center rounded-top border-bottom bg-light">{props.index + 1}</div>
			<Col>
				<FormText
					name={`${props.formikKey}.gridResults[${props.index}].value`}
					label="Result"
					value={props.values?.value || ''}
					onChange={props.handleChange}
					disabled={props.disabled}
					feedback={(props?.errors as FormikErrors<GridResult>)?.value}
					isInvalid={!!(props?.errors as FormikErrors<GridResult>)?.value}
				/>
			</Col>
			<Col className="ml-2 mt-2 mb-1">{calibValue || ''}&nbsp;</Col>
		</Card>
	)
}

export interface VelocityGridProps {
	formikKey: string
	calibration: Pick<Calibration, 'calibrationID' | 'calibrationDate' | 'calibrationCertificateNumber' | 'calibrationCertificateS3Path' | 'calibrationItems'>
	values: {
		horizontalPoints: number
		verticalPoints: number
		gridResults: GridResult[]
	}
	errors?: FormikErrors<GridResult>[] | string | string[]
	handleChange: (e: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
	disabled: boolean
}

export const VelocityGrid = (props: VelocityGridProps) => {
	const rows = []
	const numberHorizontal = props.values.horizontalPoints
	const numberVertical = props.values.verticalPoints
	for (let v = 0; v < numberVertical; v += 1) {
		rows.push(
			<Card
				key={v * numberHorizontal + 'label'}
				className="border-none"
				style={{ display: 'flex', alignSelf: 'flex-end', boxShadow: 'none', border: 'none' }}
			>
				<div>&nbsp;</div>
				<div className="text-right" style={{ display: 'grid', gridTemplateColumns: '100%' }}>
					<Col className="pr-1 mb-2 font-weight-bold" style={{ textAlign: 'end', alignSelf: 'end' }}>
						Raw data (m/s):
					</Col>
					<Col className="pr-1 mb-2 font-weight-bold" style={{ textAlign: 'end', alignSelf: 'end' }}>
						Calibrated (m/s):
					</Col>
				</div>
			</Card>
		)
		for (let h = 0; h < numberHorizontal; h += 1) {
			const index = v * numberHorizontal + h
			rows.push(<VelocityReading key={index} {...props} values={props.values.gridResults.at(index)} errors={props?.errors?.at(index)} index={index} />)
		}
	}
	const gridStyle = {
		display: 'grid',
		gridTemplateColumns: '146px ' + `${VELOCITY_READING_CARD_WIDTH + 16}px `.repeat(numberHorizontal),
		overflow: 'auto',
	}
	return <div style={gridStyle}>{rows}</div>
}

export interface GridResult {
	point: number
	value: string
}

interface PressureLocation {
	mainFilter: string
	exhaust: string
}

interface PressureResult {
	measured: PressureLocation
	fittedGauge: PressureLocation
}

interface QuestionValues {
	airVelocity: {
		horizontalPoints: number
		verticalPoints: number
		gridResults: GridResult[]
	}
	adjustments: {
		required: boolean
	}
	filterPressure: {
		initial: PressureResult
		final: PressureResult
		inletPortPresent: boolean
		pressureGaugesFitted: boolean
	}
	comment: string
	forcePass: boolean
}

export const velocityInputValidation = yup.number().label('Velocity reading').required('All readings are required')
const pressureInput2Validation = yup.object().shape({
	mainFilter: yup.string().label('Main filter').required('All readings are required'),
	exhaust: yup.string().label('Exhaust').required('All readings are required'),
})
const pressureInput3Validation = yup.object().shape({
	mainFilter: yup.string().label('Main filter').optional(),
	exhaust: yup.string().label('Exhaust').optional(),
})
export const pressureInputValidation = yup.object().shape({
	measured: yup.object().when('$inletPortPresent', {
		is: (inletPortPresent: boolean | undefined) => inletPortPresent !== false,
		then: pressureInput2Validation,
		otherwise: pressureInput3Validation,
	}),
	fittedGauge: yup.object().when('$pressureGaugesFitted', {
		is: (pressureGaugesFitted: boolean | undefined) => pressureGaugesFitted !== false,
		then: pressureInput2Validation,
		otherwise: pressureInput3Validation,
	}),
})
const validationSchema = yup.object().shape({
	airVelocity: yup.object().shape({
		gridResults: yup.array().of(
			yup.object().shape({
				value: velocityInputValidation,
			})
		),
	}),
	adjustments: yup.object().shape({
		required: yup.boolean().required(),
	}),
	filterPressure: yup.object().shape({
		pressureGaugesFitted: yup.boolean().required(),
		inletPortPresent: yup.boolean().required(),
		initial: pressureInputValidation,
		final: pressureInputValidation,
	}),
})

const AirVelocityUniformityTest = (props: TestProps) => {
	const { test } = props
	const context = useContext(AppContext)
	const submitCallback = React.useRef(() => {})

	const [numberHorizontal, numberVertical] = React.useMemo(() => getAssetPointCount(props.asset), [props.asset])

	const getVelocityReadingInputs = React.useCallback((numHorizontal, numVertical) => {
		const inp = [] as GridResult[]
		for (let i = 0; i < (numHorizontal || 1) * (numVertical || 1); i++) {
			inp.push({
				point: i + 1,
				value: '',
			})
		}
		return inp
	}, [])

	const [questionValues, setQuestionValues] = React.useState<QuestionValues>({
		airVelocity: {
			horizontalPoints: numberHorizontal,
			verticalPoints: numberVertical,
			gridResults: getVelocityReadingInputs(numberHorizontal, numberVertical),
		},
		adjustments: {
			required: false,
		},
		filterPressure: {
			initial: {
				measured: {
					mainFilter: '',
					exhaust: '',
				},
				fittedGauge: {
					mainFilter: '',
					exhaust: '',
				},
			},
			final: {
				measured: {
					mainFilter: '',
					exhaust: '',
				},
				fittedGauge: {
					mainFilter: '',
					exhaust: '',
				},
			},
			inletPortPresent: false,
			pressureGaugesFitted: false,
		},
		comment: '',
		forcePass: false,
	})

	useLoadExistingQuestionResponse({
		...props,
		setQuestionValues,
		formatQuestionValues: React.useCallback((existingValues: AirVelocityTestOutput): QuestionValues => {
			return {
				...existingValues,
				airVelocity: {
					horizontalPoints: existingValues.airVelocity.horizontalPoints,
					verticalPoints: existingValues.airVelocity.verticalPoints,
					gridResults: existingValues.airVelocity.gridResults.map(({ instrumentValue }, index) => {
						return { point: index + 1, value: instrumentValue.toString() }
					}),
				},
				adjustments: {
					required: existingValues.adjustments.required || false,
				},
				filterPressure: {
					...existingValues.filterPressure,
					inletPortPresent: existingValues.filterPressure.inletPortPresent || false,
					pressureGaugesFitted: existingValues.filterPressure.pressureGaugesFitted || false,
				},
				comment: '',
			}
		}, []),
	})

	// Assume there's only one instrument type for this question
	const testInstrument =
		test?.testInstruments?.find((instrument) => instrument.instrumentTypeID === props.question?.instrumentTypes[0].instrumentTypeID) || null
	const calibration =
		testInstrument && testInstrument.testInstrumentCalibrationSnapshot ? JSON.parse(testInstrument?.testInstrumentCalibrationSnapshot) : null

	const PASS_VALUES = getPassValueOverrides(props.question)

	if (!calibration || !PASS_VALUES) {
		return null
	}

	return (
		<Formik
			initialValues={questionValues}
			validate={(values) => {
				try {
					validateYupSchema<QuestionValues>(values, validationSchema, true, {
						inletPortPresent: values.filterPressure.inletPortPresent,
						pressureGaugesFitted: values.filterPressure.pressureGaugesFitted,
					})
				} catch (err) {
					console.log('form errors', err)
					return yupToFormErrors(err)
				}

				return {}
			}}
			validateOnChange={false}
			validateOnBlur={true}
			onSubmit={(values) => {
				onQuestionSubmit({ ...props, values, authState: context.appState.authState, submitCallback })
			}}
			enableReinitialize={true}
		>
			{({ handleSubmit, errors, values, handleChange, handleReset, dirty, isSubmitting }) => {
				// Fill out the calculated fields for display only (it's recalculated on create/update)
				let failed = null
				let averageVelocity = null
				let lowestAcceptableReading = null
				let lowestActualReading = null
				let highestAcceptableReading = null
				let highestActualReading = null
				const completeResults = values.airVelocity.gridResults.filter((i) => !!i.value)
				if (completeResults.length === values.airVelocity.gridResults.length) {
					failed = false
					const calibratedValues = values.airVelocity.gridResults.map((i) => calibrateAdditiveValue(Number(i.value), calibration)!)
					averageVelocity = calibratedValues.reduce((partialSum, i) => partialSum! + i!, 0)! / values.airVelocity.gridResults.length
					lowestAcceptableReading = 0.75 * averageVelocity
					highestAcceptableReading = 1.25 * averageVelocity
					lowestActualReading = Math.min(...calibratedValues!)
					highestActualReading = Math.max(...calibratedValues!)
					if (averageVelocity < PASS_VALUES.minimumAverageVelocity || averageVelocity > PASS_VALUES.maximumAverageVelocity) {
						failed = true
					}
					if (values.forcePass) {
						failed = false
					}
					if (
						(lowestActualReading < lowestAcceptableReading || highestActualReading > highestAcceptableReading) &&
						!(averageVelocity < PASS_VALUES.minimumAverageVelocity || averageVelocity > PASS_VALUES.maximumAverageVelocity)
					) {
						if (!values.comment.includes('Airflow is adequate but unstable')) {
							values.comment = 'Airflow is adequate but unstable\n' + values.comment
						}
					} else {
						values.comment = values.comment.replaceAll('Airflow is adequate but unstable', '')
					}
				}

				return (
					<>
						<TabRow
							tabSelectOptions={props.tabSelectOptions}
							onClick={(q, save) => {
								if (save) {
									submitCallback.current = () => props.goToQuestion(q)
									handleSubmit()
								} else {
									props.goToQuestion(q)
								}
							}}
							currentQuestion={props.question?.questionID}
							dirty={dirty}
							isSubmitting={isSubmitting}
							errors={errors}
						/>
						<Row>
							<Col>
								<QuestionHeader question={props.question} />
								<QuestionElement title="Air velocity">
									<Row>
										<Col className="col-sm-6 col-md-3">
											<FormText
												name={'horizontalPoints'}
												value={values.airVelocity.horizontalPoints}
												label={'Horizontal readings'}
												plaintext={!!test?.isLocked}
												disabled={!!test?.isLocked}
												feedback={errors?.airVelocity?.horizontalPoints}
												isInvalid={!!errors?.airVelocity?.horizontalPoints}
												onChange={(e) => {
													const n = Number(e.target.value)
													setQuestionValues({
														...values,
														airVelocity: {
															...values.airVelocity,
															horizontalPoints: n,
															gridResults: getVelocityReadingInputs(n, values.airVelocity.verticalPoints),
														},
													})
													handleChange(e)
												}}
												type="number"
												min={1}
											/>
										</Col>
										<Col className="col-sm-6 col-md-3">
											<FormText
												name={'verticalPoints'}
												value={values.airVelocity.verticalPoints}
												label={'Vertical readings'}
												plaintext={!!test?.isLocked}
												disabled={!!test?.isLocked}
												feedback={errors?.airVelocity?.verticalPoints}
												isInvalid={!!errors?.airVelocity?.verticalPoints}
												onChange={(e) => {
													const n = Number(e.target.value)
													setQuestionValues({
														...values,
														airVelocity: {
															...values.airVelocity,
															verticalPoints: n,
															gridResults: getVelocityReadingInputs(values.airVelocity.horizontalPoints, n),
														},
													})
													handleChange(e)
												}}
												type="number"
												min={1}
											/>
										</Col>
									</Row>
									<Col>
										<Row className="mb-3 mt-4">
											{values.airVelocity.horizontalPoints && values.airVelocity.verticalPoints && (
												<VelocityGrid
													values={values.airVelocity}
													errors={errors?.airVelocity?.gridResults}
													formikKey="airVelocity"
													handleChange={handleChange}
													calibration={calibration}
													disabled={!!test?.isLocked}
												/>
											)}
										</Row>
									</Col>
									<QuestionResultElementGroup
										results={[
											{ title: 'Average face velocity', value: `${averageVelocity?.toFixed(2) || '-'} m/s` },
											{ title: 'Lowest acceptable reading', value: `${lowestAcceptableReading?.toFixed(2) || '-'} m/s` },
											{ title: 'Lowest actual reading', value: `${lowestActualReading?.toFixed(2) || '-'} m/s` },
											{ title: 'Highest acceptable reading', value: `${highestAcceptableReading?.toFixed(2) || '-'} m/s` },
											{ title: 'Highest actual reading', value: `${highestActualReading?.toFixed(2) || '-'} m/s` },
										]}
									/>
								</QuestionElement>
								<QuestionElement title="Adjustments">
									<Row className="mb-4">
										<Col>
											<Form.Group controlId="adjustments.required">
												<Form.Check
													id="adjustments.required"
													label="Adjustments required"
													type="checkbox"
													name="adjustments.required"
													checked={values.adjustments.required}
													onChange={handleChange}
													disabled={!!test?.isLocked}
												/>
											</Form.Group>
										</Col>
									</Row>
								</QuestionElement>
								<QuestionElement title="Filter pressure">
									<Row className="mb-4">
										<Col>
											<Form.Group controlId="filterPressure.inletPortPresent">
												<Form.Check
													id="filterPressure.inletPortPresent"
													label="Inlet port is present"
													type="checkbox"
													name="filterPressure.inletPortPresent"
													checked={values.filterPressure.inletPortPresent}
													onChange={handleChange}
													disabled={!!test?.isLocked}
												/>
											</Form.Group>
											<Form.Group controlId="filterPressure.pressureGaugesFitted">
												<Form.Check
													id="filterPressure.pressureGaugesFitted"
													label="Pressure gauges fitted"
													type="checkbox"
													name="filterPressure.pressureGaugesFitted"
													checked={values.filterPressure.pressureGaugesFitted}
													onChange={handleChange}
													disabled={!!test?.isLocked}
												/>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col className="bg-light rounded p-2 pb-3 mr-2">
											<Col className="header-subtitle" sm={'auto'}>
												Initial
											</Col>
											<div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 2fr', columnGap: '10px', rowGap: '10px' }}>
												<div />
												<span>Main filter</span>
												<span>Exhaust</span>
												<span className="text-right">Measured</span>
												<FormText
													className="mr-2"
													name="filterPressure.initial.measured.mainFilter"
													value={values.filterPressure.initial.measured.mainFilter}
													onChange={handleChange}
													isInvalid={!!errors?.filterPressure?.initial?.measured?.mainFilter}
													feedback={errors?.filterPressure?.initial?.measured?.mainFilter}
													disabled={!!test?.isLocked || !values.filterPressure.inletPortPresent}
												/>
												<FormText
													name="filterPressure.initial.measured.exhaust"
													value={values.filterPressure.initial.measured.exhaust}
													onChange={handleChange}
													isInvalid={!!errors?.filterPressure?.initial?.measured?.exhaust}
													feedback={errors?.filterPressure?.initial?.measured?.exhaust}
													disabled={!!test?.isLocked || !values.filterPressure.inletPortPresent}
												/>
												<span className="text-right">Fitted gauge</span>
												<FormText
													className="mr-2"
													name="filterPressure.initial.fittedGauge.mainFilter"
													value={values.filterPressure.initial.fittedGauge.mainFilter}
													onChange={handleChange}
													isInvalid={!!errors?.filterPressure?.initial?.fittedGauge?.mainFilter}
													feedback={errors?.filterPressure?.initial?.fittedGauge?.mainFilter}
													disabled={!!test?.isLocked || !values.filterPressure.pressureGaugesFitted}
												/>
												<FormText
													name="filterPressure.initial.fittedGauge.exhaust"
													value={values.filterPressure.initial.fittedGauge.exhaust}
													onChange={handleChange}
													isInvalid={!!errors?.filterPressure?.initial?.fittedGauge?.exhaust}
													feedback={errors?.filterPressure?.initial?.fittedGauge?.exhaust}
													disabled={!!test?.isLocked || !values.filterPressure.pressureGaugesFitted}
												/>
											</div>
										</Col>
										<Col className="bg-light rounded p-2 pb-3 ml-2">
											<Col className="header-subtitle" sm={'auto'}>
												Final
											</Col>
											<div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 2fr', columnGap: '10px', rowGap: '10px' }}>
												<div />
												<span>Main filter</span>
												<span>Exhaust</span>
												<span className="text-right">Measured</span>
												<FormText
													className="mr-2"
													name="filterPressure.final.measured.mainFilter"
													value={values.filterPressure.final.measured.mainFilter}
													onChange={handleChange}
													isInvalid={!!errors?.filterPressure?.final?.measured?.mainFilter}
													feedback={errors?.filterPressure?.final?.measured?.mainFilter}
													disabled={!!test?.isLocked || !values.filterPressure.inletPortPresent}
												/>
												<FormText
													name="filterPressure.final.measured.exhaust"
													value={values.filterPressure.final.measured.exhaust}
													onChange={handleChange}
													isInvalid={!!errors?.filterPressure?.final?.measured?.exhaust}
													feedback={errors?.filterPressure?.final?.measured?.exhaust}
													disabled={!!test?.isLocked || !values.filterPressure.inletPortPresent}
												/>
												<span className="text-right">Fitted gauge</span>
												<FormText
													className="mr-2"
													name="filterPressure.final.fittedGauge.mainFilter"
													value={values.filterPressure.final.fittedGauge.mainFilter}
													onChange={handleChange}
													isInvalid={!!errors?.filterPressure?.final?.fittedGauge?.mainFilter}
													feedback={errors?.filterPressure?.final?.fittedGauge?.mainFilter}
													disabled={!!test?.isLocked || !values.filterPressure.pressureGaugesFitted}
												/>
												<FormText
													name="filterPressure.final.fittedGauge.exhaust"
													value={values.filterPressure.final.fittedGauge.exhaust}
													onChange={handleChange}
													isInvalid={!!errors?.filterPressure?.final?.fittedGauge?.exhaust}
													feedback={errors?.filterPressure?.final?.fittedGauge?.exhaust}
													disabled={!!test?.isLocked || !values.filterPressure.pressureGaugesFitted}
												/>
											</div>
										</Col>
									</Row>
								</QuestionElement>
								<QuestionResult
									failed={failed}
									questionHasComment={true}
									questionHasForcePass={true}
									commentValue={values.comment}
									forcePassValue={values.forcePass}
									handleChange={handleChange}
									disabled={!!test?.isLocked}
									isStandardsBased={props.asset?.assetTypeIsStandardsBased}
								/>
								<QuestionNavigationButtons
									{...props}
									handleReset={handleReset}
									handleSubmit={handleSubmit}
									submitCallback={submitCallback}
									setSubmitCallback={(fn) => (submitCallback.current = fn)}
									dirty={dirty}
									isSubmitting={isSubmitting}
								/>
							</Col>
						</Row>
					</>
				)
			}}
		</Formik>
	)
}

export { AirVelocityUniformityTest }
