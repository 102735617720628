const QUESTION = {
	VELOCITY_TEST: 'a4594c5a-2ffa-4d82-90f2-082be818f717',
	SMOKE_TEST: 'c6972331-4a37-4466-ad30-bd704a978c7d',
	AIR_VELOCITY_TEST: '99efea35-b2d6-476a-82a2-212f9ab97f19',
	WORK_ZONE_INTEGRITY_TEST: 'a7ff2ac9-387c-49be-8bac-7d740c6f1b59',
	FILTER_INTEGRITY_TEST: '4d59a6aa-6ba0-42a7-91f0-af6fd717a585',
	LIGHTING_TEST: '35edaeab-015c-4cf4-b510-58999d9fbf29',
	FUME_LIGHTING_TEST: 'd4df6868-6512-4a65-a76d-264cf321ed5c',
	SOUND_LEVEL_TEST: '51cf53dd-3780-4679-9691-4a1eb8e18ef0',
	AIR_BARRIER_CONTAINMENT_TEST: '1439cf99-c063-4a53-b390-4976ad94b01c',
	ULTRA_VIOLET_TEST: 'dd0387b1-12cc-4068-b801-1888dc0d7743',
	INWARD_AIR_VELOCITY_TEST: '2a65fb70-635d-4b0c-b0d0-4bff17d143fc',
	VELOCITY_AND_SMOKE_TEST: 'dfe9590f-c315-4fbb-bbc3-36e26c592e14',
}

const QUESTION_VERSION = {
	VELOCITY_TEST: {
		V1: '6e875860-28dc-46d0-811f-a48be38a817d',
	},
	SMOKE_TEST: {
		V1: '59f1cd71-eb32-4c05-bc3c-a74e462e6602',
	},
	AIR_VELOCITY_TEST: {
		V1: '566a6847-9124-4425-8aa1-2b190af7209a',
	},
	WORK_ZONE_INTEGRITY_TEST: {
		V1: '57142cc9-2627-4c39-b569-1bb6c406dbd4',
	},
	FILTER_INTEGRITY_TEST: {
		V1: '4c123d35-a856-428c-9268-e4c3cde4d080',
	},
	LIGHTING_TEST: {
		V1: '994a1251-73f3-4a99-8988-bf90d57fe121',
	},
	SOUND_LEVEL_TEST: {
		V1: '756d8e1d-29c2-4c90-ada4-1a54f9ade457',
	},
	AIR_BARRIER_CONTAINMENT_TEST: {
		V1: '465c7709-4581-4981-a83a-f134c5445738',
	},
	ULTRA_VIOLET_TEST: {
		V1: 'a9cf9d6e-0693-4a6d-afcc-4b5e32007f9a',
	},
	INWARD_AIR_VELOCITY_TEST: {
		V1: '4e8f2587-0756-4784-ab6c-7e422a7856c2',
	},
	VELOCITY_AND_SMOKE_TEST: {
		V1: 'f60cdea1-9c69-42a0-b8bb-a701ba8b6741',
	},
	FUME_LIGHTING_TEST: {
		V1: 'b7303707-8f74-49c9-bb4d-b7185e072138',
	},
}

interface OverrideLookup {
	[key: string]: Overrides
}

interface Overrides {
	[key: string]: {
		value: number
		description: string
	}
}

// Lookup by question ID
const QUESTION_OVERRIDES: OverrideLookup = {
	[QUESTION.VELOCITY_TEST]: {
		minimumAverageFaceVelocity: {
			value: 0.5,
			description: 'Minimum average face velocity (m/s)',
		},
		minimumFaceVelocityReading: {
			value: 0.3,
			description: 'Minimum face velocity reading (m/s)',
		},
	},
	[QUESTION.SMOKE_TEST]: {},
	[QUESTION.AIR_VELOCITY_TEST]: {
		minimumAverageVelocity: {
			value: 0.4,
			description: 'Minimum average velocity (m/s)',
		},
		maximumAverageVelocity: {
			value: 0.45,
			description: 'Maximum average velocity (m/s)',
		},
	},
	[QUESTION.WORK_ZONE_INTEGRITY_TEST]: {},
	[QUESTION.FILTER_INTEGRITY_TEST]: {
		minimumDownflowVelocity: {
			value: 0.6,
			description: 'Minimum downflow velocity (m/s)',
		},
		maximumIndividualPatchSize: {
			value: 1000,
			description: 'Maximum individual patch size (mm²)',
		},
		maximumTotalPatchSizePercent: {
			value: 3,
			description: 'Maximum total patch size (%)',
		},
	},
	[QUESTION.LIGHTING_TEST]: {
		minimumLightReading: {
			value: 430,
			description: 'Minimum light reading (lux)',
		},
		minimumAverageLightReading: {
			value: 650,
			description: 'Minimum average light reading (lux)',
		},
	},
	[QUESTION.SOUND_LEVEL_TEST]: {
		maximumSoundLevel: {
			value: 65,
			description: 'Maximum sound level (dB)',
		},
	},
	[QUESTION.AIR_BARRIER_CONTAINMENT_TEST]: {},
	[QUESTION.ULTRA_VIOLET_TEST]: {
		minimumAverageUVReading: {
			value: 40,
			description: 'Minimum average UV reading (lux)',
		},
	},
	[QUESTION.INWARD_AIR_VELOCITY_TEST]: {
		minimumVelocityReading: {
			value: 0.5,
			description: 'Minimum velocity reading (m/s)',
		},
		maximumVelocityReading: {
			value: 0.8,
			description: 'Maximum velocity reading (m/s)',
		},
	},
	[QUESTION.VELOCITY_AND_SMOKE_TEST]: {
		minimumFaceVelocityReading: {
			value: 0,
			description: 'Minimum face velocity reading (m/s)',
		},
	},
	[QUESTION.FUME_LIGHTING_TEST]: {
		minimumLightReading: {
			value: 400,
			description: 'Minimum light reading (lux)',
		},
		minimumAverageLightReading: {
			value: 400,
			description: 'Minimum average light reading (lux)',
		},
	},
}

export { QUESTION, QUESTION_VERSION, QUESTION_OVERRIDES }
export type { Overrides }
