const INSTRUMENT_TYPE = {
	AEROSOL_GENERATOR: '0085747b-f49f-47d8-9478-9afc2bd3e61f',
	AEROSOL_PHOTOMETER: 'c3add942-5518-4320-b9c3-0d729547d787',
	CARBON_FILTER_FAN_KIT: '076f779c-ca73-4069-b333-fc05e4429856',
	DIFFERENTIAL_PRESSURE_METER: 'ee1dad9c-c824-460c-b486-20a81c8fe86d',
	ELECTRICAL_MULTI_FUNCTION_TESTERS: 'b64ed708-c09a-455a-8e67-c6aa0d7028d9',
	H202_PHILEAS_GENIUS: 'ae7493c0-29f9-4961-a132-14e1186a2912',
	HOTWIRE_ANEMOMETER: '1c546860-6d56-4027-8ad0-3429682d13ea',
	LUX_METER: '468a060e-0614-4bc0-b180-75eb08059b24',
	PRESSURE_GAUGE: '8a622569-d62b-4b6a-a819-f8e0898edff0',
	SOUND_LEVEL_METER: '70e9d03e-e1f8-4824-9585-94a252a42997',
	SOUND_LEVEL_METER_CALIBRATOR: 'cb0da69d-a6fb-4a79-8a72-2b74aca7dc31',
	UV_METER: '7fbb9a68-d7c6-4683-a825-83abc29632cd',
	VANE_ANEMOMETER: 'ef391038-0f5b-4689-80c9-f46f866edf3c',
}

export { INSTRUMENT_TYPE }
