import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Row, Col, Form, Alert, Badge } from 'react-bootstrap'
import { TestProps } from './QuestionLookup.d'
import { Formik } from 'formik'
import * as yup from 'yup'
import { CheckGrid, checkLeak } from './WorkZoneIntegrity'
import { FormText } from '../../../components/UI/Form/Text'
import { roundTo } from 'dynaflow/utilities/calibration'
import { getPassValueOverrides } from 'dynaflow/utilities/testOverride'
import {
	onQuestionSubmit,
	useLoadExistingQuestionResponse,
	QuestionNavigationButtons,
	TabRow,
	QuestionElement,
	QuestionResult,
	QuestionHeader,
} from './QuestionComponents'
import { FilterInstallationIntegrityOutput } from 'dynaflow/utilities/question'
import { STANDARD_ASSET_TYPE } from 'dynaflow/constants/assetType'

interface GridResult {
	point: number
	leak: 'false' | 'filled' | 'notFilled' | 'maxVelocity'
}

interface LeakTest {
	gridResults: GridResult[]
	filterVelocity: string
	patchArea: string
	filterArea: string
}

interface QuestionValues {
	mainHEPA: LeakTest
	mainDownflowVelocityBelowThreshold: boolean
	mainIndividualPatchSizeAboveThreshold: boolean
	mainTotalPatchSizeAboveThreshold: boolean
	mainAdjustmentsOrRepairs: boolean
	mainFilterGuardRemoved: boolean
	exhaustHEPA: LeakTest
	exhaustVelocityBelowThreshold: boolean
	exhaustIndividualPatchSizeAboveThreshold: boolean
	exhaustTotalPatchSizeAboveThreshold: boolean
	exhaustFilterRequiresChange: boolean
	rangeOfConcentrationUpstreamMix: number
	rangeOfConcentrationVariationFromMin: number
	comment: string
}

const createGridInputs = (nH: number, nV: number): LeakTest => {
	const input = [] as GridResult[]
	for (let i = 0; i < nH * nV; i++) {
		input.push({
			point: i + 1,
			leak: 'false',
		})
	}
	return { gridResults: input, filterVelocity: '', patchArea: '', filterArea: '' }
}

const leakTestValidation = yup.object().shape({
	filterVelocity: yup.number().required('Filter velocity is required'),
})

const validationSchema = (assetTypeID: string) =>
	yup.object().shape({
		mainHEPA: leakTestValidation,
		exhaustHEPA:
			assetTypeID === STANDARD_ASSET_TYPE.LAMINAR_FLOW || assetTypeID === STANDARD_ASSET_TYPE.BIOLOGICAL_SAFETY_CABINET_1
				? yup.object()
				: leakTestValidation,
		rangeOfConcentrationUpstreamMix: yup.number().min(0),
		rangeOfConcentrationVariationOfMin: yup.number().min(0),
	})

const FilterInstallationIntegrity = (props: TestProps) => {
	const { test } = props
	const context = useContext(AppContext)
	const submitCallback = React.useRef(() => {})

	const [questionValues, setQuestionValues] = React.useState<QuestionValues>(() => {
		return {
			mainHEPA: createGridInputs(18, 6),
			mainDownflowVelocityBelowThreshold: true,
			mainIndividualPatchSizeAboveThreshold: false,
			mainTotalPatchSizeAboveThreshold: false,
			mainAdjustmentsOrRepairs: false,
			mainFilterGuardRemoved: false,
			exhaustHEPA: createGridInputs(10, 6),
			exhaustVelocityBelowThreshold: true,
			exhaustIndividualPatchSizeAboveThreshold: false,
			exhaustTotalPatchSizeAboveThreshold: false,
			exhaustFilterRequiresChange: false,
			rangeOfConcentrationUpstreamMix: 0,
			rangeOfConcentrationVariationFromMin: 0,
			comment: '',
		}
	})

	useLoadExistingQuestionResponse({
		...props,
		setQuestionValues,
		formatQuestionValues: React.useCallback((existingValues: FilterInstallationIntegrityOutput): QuestionValues => {
			return {
				...existingValues,
				mainHEPA: {
					...existingValues.mainHEPA,
					filterVelocity: existingValues.mainHEPA.filterVelocity.toString(),
					patchArea: existingValues.mainHEPA.patchArea.toString(),
					filterArea: existingValues.mainHEPA.filterArea.toString(),
					gridResults: existingValues.mainHEPA.gridResults.map((r, i) => ({ point: i + 1, leak: r })),
				},
				mainDownflowVelocityBelowThreshold: existingValues.mainDownflowVelocityBelowThreshold || false,
				mainIndividualPatchSizeAboveThreshold: existingValues.mainIndividualPatchSizeAboveThreshold || false,
				mainTotalPatchSizeAboveThreshold: existingValues.mainTotalPatchSizeAboveThreshold || false,
				mainAdjustmentsOrRepairs: existingValues.mainAdjustmentsOrRepairs || false,
				mainFilterGuardRemoved: existingValues.mainFilterGuardRemoved || false,
				exhaustHEPA: {
					...existingValues.exhaustHEPA,
					filterVelocity: existingValues.exhaustHEPA.filterVelocity.toString(),
					patchArea: existingValues.exhaustHEPA.patchArea.toString(),
					filterArea: existingValues.exhaustHEPA.filterArea.toString(),
					gridResults: existingValues.exhaustHEPA.gridResults.map((r, i) => ({ point: i + 1, leak: r })),
				},
				exhaustVelocityBelowThreshold: existingValues.exhaustVelocityBelowThreshold || false,
				exhaustIndividualPatchSizeAboveThreshold: existingValues.exhaustIndividualPatchSizeAboveThreshold || false,
				exhaustTotalPatchSizeAboveThreshold: existingValues.exhaustTotalPatchSizeAboveThreshold || false,
				exhaustFilterRequiresChange: existingValues.exhaustFilterRequiresChange || false,
				rangeOfConcentrationUpstreamMix: existingValues.rangeOfConcentrationUpstreamMix,
				rangeOfConcentrationVariationFromMin: existingValues.rangeOfConcentrationVariationFromMin,
				comment: '',
			}
		}, []),
	})

	const PASS_VALUES = getPassValueOverrides(props.question)

	if (!PASS_VALUES) {
		return null
	}

	return (
		<Formik
			initialValues={questionValues}
			onSubmit={(values) => {
				onQuestionSubmit({ ...props, values, authState: context.appState.authState, submitCallback })
			}}
			enableReinitialize={true}
			validationSchema={validationSchema(props.asset?.parentAssetTypeID || props.asset?.assetTypeID || '')}
			validateOnMount={true}
		>
			{({ handleSubmit, errors, values, handleChange, handleReset, setFieldValue, dirty, isSubmitting }) => {
				let failed = null
				if (Object.keys(errors).length === 0) {
					failed = false
				}
				if (values.mainHEPA.gridResults.some(checkLeak)) {
					failed = true
				} else if (values.exhaustHEPA.gridResults.some(checkLeak)) {
					failed = true
				}
				if (
					values.mainTotalPatchSizeAboveThreshold ||
					values.exhaustTotalPatchSizeAboveThreshold ||
					values.mainIndividualPatchSizeAboveThreshold ||
					values.exhaustIndividualPatchSizeAboveThreshold ||
					!values.mainDownflowVelocityBelowThreshold ||
					!values.exhaustVelocityBelowThreshold
				) {
					failed = true
				}

				return (
					<>
						<TabRow
							tabSelectOptions={props.tabSelectOptions}
							onClick={(q, save) => {
								if (save) {
									submitCallback.current = () => props.goToQuestion(q)
									handleSubmit()
								} else {
									props.goToQuestion(q)
								}
							}}
							currentQuestion={props.question?.questionID}
							dirty={dirty}
							isSubmitting={isSubmitting}
							errors={errors}
						/>
						<Row>
							<Col>
								<QuestionHeader question={props.question} />
								<Alert variant="warning" className="text-dark">
									Place aerosol before HEPA filter and scan using photometer 25 mm from exhaust. Travel across no more than 50 mm/s. Readings
									shall not exceed 0.01% once every 10 seconds.
								</Alert>
								<QuestionElement title="Main HEPA">
									<Row>
										<Col>
											<Row>
												<span className="m-3">
													If applicable, indicate presence of leak (filled ={' '}
													<Badge className="text-white" bg="danger">
														✖
													</Badge>{' '}
													or not filled ={' '}
													<Badge className="text-white" bg="danger">
														〇
													</Badge>{' '}
													) by marking the appropriate area below.
												</span>
											</Row>
										</Col>
									</Row>
									<Col>
										<Row>
											<Col className="d-flex justify-content-center">
												<CheckGrid
													numberHorizontal={18}
													numberVertical={6}
													values={values.mainHEPA}
													errors={errors.mainHEPA}
													label="Main HEPA (workzone)"
													handleChange={setFieldValue}
													disabled={!!test?.isLocked}
													formikKey="mainHEPA"
												/>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col className="col-sm-12 col-md-6">
												<FormText
													name="mainHEPA.filterVelocity"
													value={values.mainHEPA.filterVelocity}
													label="Downflow filter velocity (m/s)"
													onChange={handleChange}
													disabled={!!test?.isLocked}
													isInvalid={!!errors.mainHEPA?.filterVelocity}
													feedback={errors.mainHEPA?.filterVelocity}
												/>
											</Col>
											{values.mainHEPA.gridResults.some((r) => r.leak === 'filled') && (
												<Col className="col-sm-12 col-md-6">
													<FormText
														name="mainHEPA.patchArea"
														value={values.mainHEPA.patchArea}
														label="Patch area if present (mm²)"
														type="number"
														min={0}
														onChange={handleChange}
														disabled={!!test?.isLocked}
													/>
													<FormText
														name="mainHEPA.filterArea"
														value={values.mainHEPA.filterArea}
														label="Area of filter (L × W) (m²)"
														type="number"
														min={0}
														onChange={handleChange}
														disabled={!!test?.isLocked}
													/>
													<FormText
														name="mainHEPA.filterAreaFraction"
														value={values.mainHEPA.filterArea ? roundTo(Number(values.mainHEPA.filterArea) * 0.03, 4) : ''}
														label="Area of filter × 0.03 (m²)"
														type="number"
														onChange={handleChange}
														plaintext={true}
														disabled={true}
													/>
												</Col>
											)}
										</Row>
									</Col>
								</QuestionElement>
								<QuestionElement>
									<Col className="mb-3">
										<Row>
											<Col className="text-right">
												<span>{`Downflow velocity less than ${PASS_VALUES.minimumDownflowVelocity} m/s`}</span>
											</Col>
											<Col>
												<Form.Check
													type="radio"
													inline
													id={'mainDownflowVelocityBelowThreshold.true'}
													value={1}
													checked={values.mainDownflowVelocityBelowThreshold}
													onChange={() => setFieldValue('mainDownflowVelocityBelowThreshold', true)}
													name="mainDownflowVelocityBelowThreshold"
													label="Yes"
													disabled={!!test?.isLocked}
												/>
												<Form.Check
													type="radio"
													inline
													id={'mainDownflowVelocityBelowThreshold.false'}
													value={0}
													checked={!values.mainDownflowVelocityBelowThreshold}
													onChange={() => setFieldValue('mainDownflowVelocityBelowThreshold', false)}
													label="No"
													name="mainDownflowVelocityBelowThreshold"
													disabled={!!test?.isLocked}
												/>
											</Col>
										</Row>
									</Col>
									<Col className="mb-3">
										<Row>
											<Col className="text-right">
												<span>{`Any individual patch sizes greater than ${PASS_VALUES.maximumIndividualPatchSize} mm²`}</span>
											</Col>
											<Col>
												<Form.Check
													type="radio"
													inline
													id={'mainIndividualPatchSizeAboveThreshold.true'}
													value={1}
													checked={values.mainIndividualPatchSizeAboveThreshold}
													onChange={() => setFieldValue('mainIndividualPatchSizeAboveThreshold', true)}
													name="mainIndividualPatchSizeAboveThreshold"
													label="Yes"
													disabled={!!test?.isLocked}
												/>
												<Form.Check
													type="radio"
													inline
													id={'mainIndividualPatchSizeAboveThreshold.false'}
													value={0}
													checked={!values.mainIndividualPatchSizeAboveThreshold}
													onChange={() => setFieldValue('mainIndividualPatchSizeAboveThreshold', false)}
													label="No"
													name="mainIndividualPatchSizeAboveThreshold"
													disabled={!!test?.isLocked}
												/>
											</Col>
										</Row>
									</Col>
									<Col className="mb-3">
										<Row>
											<Col className="text-right">
												<span>{`Total visible patch greater than ${PASS_VALUES.maximumTotalPatchSizePercent}% of face area`}</span>
											</Col>
											<Col>
												<Form.Check
													type="radio"
													inline
													id={'mainTotalPatchSizeAboveThreshold.true'}
													value={1}
													checked={values.mainTotalPatchSizeAboveThreshold}
													onChange={() => setFieldValue('mainTotalPatchSizeAboveThreshold', true)}
													name="mainTotalPatchSizeAboveThreshold"
													label="Yes"
													disabled={!!test?.isLocked}
												/>
												<Form.Check
													type="radio"
													inline
													id={'mainTotalPatchSizeAboveThreshold.false'}
													value={0}
													checked={!values.mainTotalPatchSizeAboveThreshold}
													onChange={() => setFieldValue('mainTotalPatchSizeAboveThreshold', false)}
													label="No"
													name="mainTotalPatchSizeAboveThreshold"
													disabled={!!test?.isLocked}
												/>
											</Col>
										</Row>
									</Col>
									<Col className="mb-3">
										<Row>
											<Col className="text-right">
												<span>Any adjustments or repairs</span>
											</Col>
											<Col>
												<Form.Check
													type="radio"
													inline
													id={'mainAdjustmentsOrRepairs.true'}
													value={1}
													checked={values.mainAdjustmentsOrRepairs}
													onChange={() => setFieldValue('mainAdjustmentsOrRepairs', true)}
													name="mainAdjustmentsOrRepairs"
													label="Yes"
													disabled={!!test?.isLocked}
												/>
												<Form.Check
													type="radio"
													inline
													id={'mainAdjustmentsOrRepairs.false'}
													value={0}
													checked={!values.mainAdjustmentsOrRepairs}
													onChange={() => setFieldValue('mainAdjustmentsOrRepairs', false)}
													name="mainAdjustmentsOrRepairs"
													label="No"
													disabled={!!test?.isLocked}
												/>
											</Col>
										</Row>
									</Col>
									<Col className="mb-3">
										<Row>
											<Col className="text-right">
												<span>Filter guard removed before reading</span>
											</Col>
											<Col>
												<Form.Check
													type="radio"
													inline
													id={'mainFilterGuardRemoved.true'}
													value={1}
													checked={values.mainAdjustmentsOrRepairs}
													onChange={() => setFieldValue('mainFilterGuardRemoved', true)}
													name="mainFilterGuardRemoved"
													label="Yes"
													disabled={!!test?.isLocked}
												/>
												<Form.Check
													type="radio"
													inline
													id={'mainFilterGuardRemoved.false'}
													value={0}
													checked={!values.mainAdjustmentsOrRepairs}
													onChange={() => setFieldValue('mainFilterGuardRemoved', false)}
													name="mainFilterGuardRemoved"
													label="No"
													disabled={!!test?.isLocked}
												/>
											</Col>
										</Row>
									</Col>
								</QuestionElement>
								{props.asset?.parentAssetTypeID !== STANDARD_ASSET_TYPE.LAMINAR_FLOW &&
									props.asset?.parentAssetTypeID !== STANDARD_ASSET_TYPE.BIOLOGICAL_SAFETY_CABINET_1 &&
									props.asset?.assetTypeID !== STANDARD_ASSET_TYPE.LAMINAR_FLOW &&
									props.asset?.assetTypeID !== STANDARD_ASSET_TYPE.BIOLOGICAL_SAFETY_CABINET_1 && (
										<>
											<QuestionElement title="Exhaust HEPA">
												<Row>
													<Col>
														<Row>
															<span className="m-3">
																If applicable, indicate presence of leak (filled ={' '}
																<Badge className="text-white" bg="danger">
																	✖
																</Badge>{' '}
																or not filled ={' '}
																<Badge className="text-white" bg="danger">
																	〇
																</Badge>{' '}
																) by marking the appropriate area below. Mark test location on grid with{' '}
																<Badge className="text-white" bg="danger">
																	a
																</Badge>{' '}
																for highest recorded velocity.
															</span>
														</Row>
													</Col>
												</Row>
												<Row>
													<Col className="d-flex justify-content-center">
														<CheckGrid
															numberHorizontal={10}
															numberVertical={6}
															values={values.exhaustHEPA}
															errors={errors.exhaustHEPA}
															recordMax={true}
															label="Exhaust HEPA"
															handleChange={setFieldValue}
															disabled={!!test?.isLocked}
															formikKey="exhaustHEPA"
														/>
													</Col>
												</Row>
												<Row className="mb-3">
													<Col className="col-sm-12 col-md-6">
														<FormText
															name="exhaustHEPA.filterVelocity"
															value={values.exhaustHEPA.filterVelocity}
															label="Exhaust filter velocity (m/s)"
															onChange={handleChange}
															disabled={!!test?.isLocked}
															isInvalid={!!errors.exhaustHEPA?.filterVelocity}
															feedback={errors.exhaustHEPA?.filterVelocity}
														/>
													</Col>
													{values.exhaustHEPA.gridResults.some((r) => r.leak === 'filled') && (
														<Col className="col-sm-12 col-md-6">
															<FormText
																name="exhaustHEPA.patchArea"
																value={values.exhaustHEPA.patchArea}
																label="Patch area if present (mm²)"
																type="number"
																min={0}
																onChange={handleChange}
																disabled={!!test?.isLocked}
															/>
															<FormText
																name="exhaustHEPA.filterArea"
																value={values.exhaustHEPA.filterArea}
																label="Area of filter (L x W) (m²)"
																type="number"
																min={0}
																onChange={handleChange}
																disabled={!!test?.isLocked}
															/>
															<FormText
																name="exhaustHEPA.filterAreaFraction"
																value={
																	values.exhaustHEPA.filterArea
																		? roundTo(Number(values.exhaustHEPA.filterArea) * 0.03, 4)
																		: ''
																}
																label="Area of filter × 0.03 (m²)"
																type="number"
																onChange={handleChange}
																plaintext={true}
																disabled={true}
															/>
														</Col>
													)}
												</Row>
											</QuestionElement>
											<QuestionElement>
												<Col className="mb-3">
													<Row>
														<Col className="text-right">
															<span>{`Exhaust velocity less than ${PASS_VALUES.minimumDownflowVelocity} m/s`}</span>
														</Col>
														<Col>
															<Form.Check
																type="radio"
																inline
																id={'exhaustVelocityBelowThreshold.true'}
																value={1}
																checked={values.exhaustVelocityBelowThreshold}
																onChange={() => setFieldValue('exhaustVelocityBelowThreshold', true)}
																name="exhaustVelocityBelowThreshold"
																label="Yes"
																disabled={!!test?.isLocked}
															/>
															<Form.Check
																type="radio"
																inline
																id={'exhaustVelocityBelowThreshold.false'}
																value={0}
																checked={!values.exhaustVelocityBelowThreshold}
																onChange={() => setFieldValue('exhaustVelocityBelowThreshold', false)}
																label="No"
																name="exhaustVelocityBelowThreshold"
																disabled={!!test?.isLocked}
															/>
														</Col>
													</Row>
												</Col>
												<Col className="mb-3">
													<Row>
														<Col className="text-right">
															<span>{`Any individual patch sizes greater than ${PASS_VALUES.maximumIndividualPatchSize} mm²`}</span>
														</Col>
														<Col>
															<Form.Check
																type="radio"
																inline
																id={'exhaustIndividualPatchSizeAboveThreshold.true'}
																value={1}
																checked={values.exhaustIndividualPatchSizeAboveThreshold}
																onChange={() => setFieldValue('exhaustIndividualPatchSizeAboveThreshold', true)}
																name="exhaustIndividualPatchSizeAboveThreshold"
																label="Yes"
																disabled={!!test?.isLocked}
															/>
															<Form.Check
																type="radio"
																inline
																id={'exhaustIndividualPatchSizeAboveThreshold.false'}
																value={0}
																checked={!values.exhaustIndividualPatchSizeAboveThreshold}
																onChange={() => setFieldValue('exhaustIndividualPatchSizeAboveThreshold', false)}
																label="No"
																name="exhaustIndividualPatchSizeAboveThreshold"
																disabled={!!test?.isLocked}
															/>
														</Col>
													</Row>
												</Col>
												<Col className="mb-3">
													<Row>
														<Col className="text-right">
															<span>{`Total visible patch greater than ${PASS_VALUES.maximumTotalPatchSizePercent}% of face area`}</span>
														</Col>
														<Col>
															<Form.Check
																type="radio"
																inline
																id={'exhaustTotalPatchSizeAboveThreshold.true'}
																value={1}
																checked={values.exhaustTotalPatchSizeAboveThreshold}
																onChange={() => setFieldValue('exhaustTotalPatchSizeAboveThreshold', true)}
																name="exhaustTotalPatchSizeAboveThreshold"
																label="Yes"
																disabled={!!test?.isLocked}
															/>
															<Form.Check
																type="radio"
																inline
																id={'exhaustTotalPatchSizeAboveThreshold.false'}
																value={0}
																checked={!values.exhaustTotalPatchSizeAboveThreshold}
																onChange={() => setFieldValue('exhaustTotalPatchSizeAboveThreshold', false)}
																label="No"
																name="exhaustTotalPatchSizeAboveThreshold"
																disabled={!!test?.isLocked}
															/>
														</Col>
													</Row>
												</Col>
												<Col className="mb-3">
													<Row>
														<Col className="text-right">
															<span>Filter guard removed before reading</span>
														</Col>
														<Col>
															<Form.Check
																type="radio"
																inline
																id={'exhaustFilterRequiresChange.true'}
																value={1}
																checked={values.exhaustFilterRequiresChange}
																onChange={() => setFieldValue('exhaustFilterRequiresChange', true)}
																name="exhaustFilterRequiresChange"
																label="Yes"
																disabled={!!test?.isLocked}
															/>
															<Form.Check
																type="radio"
																inline
																id={'exhaustFilterRequiresChange.false'}
																value={0}
																checked={!values.exhaustFilterRequiresChange}
																onChange={() => setFieldValue('exhaustFilterRequiresChange', false)}
																name="exhaustFilterRequiresChange"
																label="No"
																disabled={!!test?.isLocked}
															/>
														</Col>
													</Row>
												</Col>
											</QuestionElement>
										</>
									)}
								<QuestionElement title="Range of Concentration">
									<Row>
										<Col>
											<FormText
												name="rangeOfConcentrationUpstreamMix"
												value={values.rangeOfConcentrationUpstreamMix}
												label="Upstream concentration mix (mg/m³)"
												type="number"
												min={0}
												onChange={handleChange}
												disabled={!!test?.isLocked}
											/>
											<FormText
												name="rangeOfConcentrationVariationFromMin"
												value={values.rangeOfConcentrationVariationFromMin}
												label="Variation from minimum (percentage penetration of upstream concentration)"
												type="number"
												min={0}
												onChange={handleChange}
												disabled={!!test?.isLocked}
											/>
										</Col>
									</Row>
								</QuestionElement>
								<QuestionResult
									failed={failed}
									questionHasComment={true}
									questionHasForcePass={false}
									commentValue={values.comment}
									handleChange={handleChange}
									disabled={!!test?.isLocked}
									isStandardsBased={props.asset?.assetTypeIsStandardsBased}
								/>
								<QuestionNavigationButtons
									{...props}
									handleReset={handleReset}
									handleSubmit={handleSubmit}
									submitCallback={submitCallback}
									setSubmitCallback={(fn) => (submitCallback.current = fn)}
									dirty={dirty}
									isSubmitting={isSubmitting}
								/>
							</Col>
						</Row>
					</>
				)
			}}
		</Formik>
	)
}

export { FilterInstallationIntegrity }
