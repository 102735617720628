import { Auth } from '@aws-amplify/auth'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { AppStateAction } from '../../../App.d'
import { MessageAction } from '../../UI/Messages/Message'

const refreshSession = async () => {
	const user = await Auth.currentAuthenticatedUser()
	const session = await Auth.currentSession()

	return new Promise((resolve, reject) => {
		user.refreshSession(session.getRefreshToken(), (err: Error | undefined, session: CognitoUserSession) => {
			if (err) {
				reject(err)
			} else {
				resolve(session)
			}
		})
	})
}

const switchEntity = async (entityID: string, setMessages: ((action: MessageAction) => void) | null, setAppState: React.Dispatch<AppStateAction>) => {
	try {
		const user = await Auth.currentAuthenticatedUser()
		await Auth.updateUserAttributes(user, { 'custom:entityID': entityID })

		await refreshSession()

		setAppState({
			state: 'updateUserEntity',
			data: {
				entityID: entityID,
			},
		})

		window.localStorage.setItem(`isFirstLogin`, 'false')

		window.location.href = '/'
		window.location.reload()
	} catch (e) {
		console.log(e)
		if (setMessages) {
			setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `An error occurred while changing the Entity`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
	}
}

export { switchEntity }
