import React, { useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Header } from '../../components/UI/Header/Header'
import { StaticContext } from 'react-router'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { AppContext } from '../../App'
import { PageStatus } from '../../types/PageStatus'
import * as Request from '../../utilities/request'
import { Asset, AssetResult } from '../../../../back-end/common/asset'
import { QuestionInstrument, QuestionInstrumentResult } from '../../../../back-end/common/questionInstrument'
import { AssetTypeQuestion, AssetTypeQuestionResult } from '../../../../back-end/common/assetTypeQuestion'
import { QuestionLookup } from './Questions/QuestionLookup'
import { DropdownSelectOption } from '../../components/Forms/Dropdown'
import { Instrument, InstrumentResult } from '../../../../back-end/common/instruments'
import { TestSetup } from './TestSetup'
import { LockScreen } from './LockScreen/GenericLockScreen'
import { Test, TestResult } from '../../../../back-end/common/test'
import { Loading } from '../../components/UI/Loading/Loading'
import { PageBackState } from '../../types/PageBackState'
import { User } from '../../../../back-end/common/user'
import { CompetencyResult } from '../../../../back-end/common/competency'
import { DateTime } from 'luxon'
import { ScreensTestMatchParams } from './TestDetails'
import { InstrumentSet, InstrumentSetResult } from '../../../../back-end/common/instrumentSet'
import { ComplianceQuestion, ComplianceQuestionResult } from '../../../../back-end/common/complianceQuestion'
import { ComplianceTestQuestion } from './Questions/ComplianceTestQuestion'
import { TabRow } from './Questions/QuestionComponents'
import { Button } from '../../components/UI/Button/Button'

// Instruments that don't require a calibration to be used
const INSTRUMENT_CALIBRATION_EXCEPTIONS = [
	// Sound Level Meter
	'70e9d03e-e1f8-4824-9585-94a252a42997',
	// Sound Level Meter Calibrator
	'cb0da69d-a6fb-4a79-8a72-2b74aca7dc31',
]

export interface TestLocationStateParams extends PageBackState {}

interface TestWrapperProps extends RouteComponentProps<ScreensTestMatchParams, StaticContext, TestLocationStateParams> {
	testID?: string
	assetID?: string
	header: string
}

export interface DetailedQuestion extends AssetTypeQuestion {
	instrumentTypes: Partial<QuestionInstrument>[]
}

type TestUsers = Pick<User, 'userID' | 'firstName' | 'lastName' | 'fullName' | 'email'>[]

const TestWrapper = (props: TestWrapperProps) => {
	const assetID = props.assetID
	const context = useContext(AppContext)
	const [messages, setMessages] = useMessageReducer([])

	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [currentQuestion, setCurrentQuestion] = React.useState<string>('Setup')
	const [asset, setAsset] = React.useState<Asset | null>(null)
	const [questions, setQuestions] = React.useState<DetailedQuestion[]>([])
	const [complianceQuestions, setComplianceQuestions] = React.useState<ComplianceQuestion[]>([])
	const [questionInstruments, setQuestionInstruments] = React.useState<QuestionInstrument[]>([])
	const [availableInstruments, setAvailableInstruments] = React.useState<Instrument[]>([])
	const [instrumentSets, setInstrumentSets] = React.useState<InstrumentSet[]>([])
	const [testUsers, setTestUsers] = React.useState<TestUsers>([])
	const [lockUsers, setLockUsers] = React.useState<TestUsers>([])
	const [test, setTest] = React.useState<Test | null>(null)

	const getTest = React.useCallback(
		async (testID: string): Promise<Test> => {
			setPageStatus('Loading')
			const testResult = await Request.get<TestResult>(`test?where=testID==${testID}`, context.appState.authState)
			const newTest = testResult.data.tests[0]
			setTest(newTest)
			if (props.history.location.hash) {
				setCurrentQuestion(props.history.location.hash.replace('#', ''))
			} else if (newTest.isLocked || newTest.isLegacy) {
				setCurrentQuestion('Lock')
			}
			return newTest
		},
		[context.appState.authState, setCurrentQuestion]
	)

	React.useEffect(() => {
		// If we start with an AssetID, the test will be created on submit of the setup page
		// Otherwise load in the test and fill out the form with previous data
		const getData = async () => {
			let testAssetID
			let _test: Test | null = null
			if (props.testID) {
				// We are loading an existing test
				_test = await getTest(props.testID)
				testAssetID = _test.assetID
			} else {
				// This is a new test, and the test item will be created later
				testAssetID = assetID
			}
			if (_test?.isLegacy) {
				setPageStatus('Ready')
				return // save us time not getting info we don't need
			}
			const [assetResponse, instrumentResponse, instrumentSetResponse] = await Promise.all([
				Request.get<AssetResult>(`asset?where=assetID==${testAssetID}`, context.appState.authState),
				Request.get<InstrumentResult>(`instrument?where=instrumentisactive==1`, context.appState.authState),
				Request.get<InstrumentSetResult>(`instrumentSet`, context.appState.authState),
			])
			const instruments = instrumentResponse.data.instruments.filter((instrument) => {
				if (instrument.calibrations.length === 0 && !INSTRUMENT_CALIBRATION_EXCEPTIONS.includes(instrument.instrumentTypeID)) {
					return false
				}
				return true
			})
			setAvailableInstruments(instruments)
			setInstrumentSets(instrumentSetResponse.data.instrumentSets)

			const asset = assetResponse.data.assets[0]
			setAsset(asset)

			const now = DateTime.now().toISO()
			const competencyTestResponse = await Request.get<CompetencyResult>(
				`competency?where=assetTypeID=in(${asset.assetTypeID},${asset.parentAssetTypeID})&where=accountID==${asset.accountID}&where=competencyFromDate<=${now}&where=competencyToDate>=${now}`,
				context.appState.authState
			)
			const assetTestUsers = [] as TestUsers
			const assetLockUsers = [] as TestUsers
			competencyTestResponse.data.competencies.forEach((competency) => {
				if (!competency.user) {
					return
				}
				const targetArray = competency.type === 'test' ? assetTestUsers : assetLockUsers
				if (!targetArray.find((user) => user.userID === competency.userID)) {
					targetArray.push(competency.user)
				}
			})

			setTestUsers(assetTestUsers)
			setLockUsers(assetLockUsers)

			const [assetTypeQuestionsResult, complianceQuestionsResult] = await Promise.all([
				Request.get<AssetTypeQuestionResult>(
					`assetTypeQuestion?where=assetTypeID=in(${asset.assetTypeID},${asset.parentAssetTypeID || ''})`,
					context.appState.authState
				),
				Request.get<ComplianceQuestionResult>(`complianceQuestion?where=assetTypeID==${asset.assetTypeID}`, context.appState.authState),
			])
			const questionInstrumentsResults = await Request.get<QuestionInstrumentResult>(
				`questionInstrument?where=questionVersionID=in(${assetTypeQuestionsResult.data.assetTypeQuestions.map((q) => q.questionVersionID).join(',')}`,
				context.appState.authState
			)

			setQuestions(
				assetTypeQuestionsResult.data.assetTypeQuestions.map((question) => {
					return {
						...question,
						instrumentTypes: questionInstrumentsResults.data.questionInstruments.filter(
							(instrumentType) => instrumentType.questionVersionID === question.questionVersionID
						),
					}
				})
			)
			if (complianceQuestionsResult.data.complianceQuestion.length > 0) {
				setComplianceQuestions(complianceQuestionsResult.data.complianceQuestion)
			}
			setQuestionInstruments(questionInstrumentsResults.data.questionInstruments.sort((a, b) => (a.instrumentTypeName < b.instrumentTypeName ? -1 : 1)))
			setPageStatus('Editing')
		}

		if (props.testID && test) {
			return // we already have everything no need to get again
		}
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [assetID, context.appState.authState, getTest, props.testID])

	const tabSelectOptions: DropdownSelectOption<string, object>[] = test?.isLegacy
		? [{ value: 'Lock', text: 'Lock' }]
		: [
				{ value: 'Setup', text: 'Test setup' },
				...(questions || [])
					.sort((a, b) => a.assetTypeQuestionOrder - b.assetTypeQuestionOrder)
					.map((q) => ({ ...q, value: q.questionID!, text: q.questionName })),
				...(test?.requiresComplianceCheck ? [{ value: 'Compliance', text: 'Compliance Check' }] : []),
				{ value: 'Lock', text: 'Lock' },
		  ]

	const goToQuestion = (question: string) => {
		const questionIndex = tabSelectOptions.findIndex((opt) => opt.value === question)
		props.history.push({ ...props.history.location, hash: tabSelectOptions[questionIndex].value })
		setCurrentQuestion(tabSelectOptions[questionIndex].value)
	}

	const onClickNext = (testID?: string) => {
		const currentPage = tabSelectOptions.findIndex((opt) => opt.value === currentQuestion)
		if (testID) {
			props.history.replace({ ...props.history.location, pathname: `/test/${testID}`, hash: tabSelectOptions[currentPage + 1].value })
		} else {
			props.history.push({ ...props.history.location, hash: tabSelectOptions[currentPage + 1].value })
		}
		setCurrentQuestion(tabSelectOptions[currentPage + 1].value)
	}

	const onClickBack = () => {
		const currentPage = tabSelectOptions.findIndex((opt) => opt.value === currentQuestion)
		setCurrentQuestion(tabSelectOptions[currentPage - 1].value)
	}

	const headerText = asset ? `${props.header} - ${asset.assetTypeName || ''} ${asset.assetUnitReference || asset.assetClientRef || ''}` : props.header

	return (
		<div className="page">
			<Messages messages={messages} updateMessage={setMessages} />
			<Row>
				<Col>
					{props.location.state && props.location.state.backPath && (
						<Link className="breadcrumb-back" to={props.location.state.backPath}>{`< Back to ${props.location.state.backName}`}</Link>
					)}
				</Col>
			</Row>

			<Row>
				<Col>
					<Header title={headerText} subtitle={''} />
				</Col>
			</Row>
			{pageStatus === 'Loading' ? (
				<Loading spinner />
			) : (
				<Row>
					<Col>
						{currentQuestion === 'Setup' ? (
							<TestSetup
								pageStatus={pageStatus}
								asset={asset}
								questions={questions}
								questionInstruments={questionInstruments}
								availableInstruments={availableInstruments}
								instrumentSets={instrumentSets}
								testUsers={testUsers}
								onClickNext={onClickNext}
								goToQuestion={goToQuestion}
								test={test}
								setTest={setTest}
								setMessages={setMessages}
								tabSelectOptions={tabSelectOptions}
								assetHasComplianceQuestions={complianceQuestions.length > 0}
							/>
						) : test?.testInstruments.length === 0 && !test?.isLegacy ? (
							<>
								<TabRow
									tabSelectOptions={tabSelectOptions}
									onClick={(q) => {
										goToQuestion(q)
									}}
									currentQuestion={currentQuestion}
								/>
								<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
									<p style={{ fontSize: '30px' }}>Test Setup must be completed before test sections can be answered</p>

									<Button
										className="btn btn-primary round"
										onClick={() => {
											goToQuestion('Setup')
										}}
									>
										Return to
										<br />
										Test Setup
									</Button>
								</div>
							</>
						) : currentQuestion === 'Lock' ? (
							<LockScreen
								asset={asset}
								onClickBack={onClickBack}
								goToQuestion={goToQuestion}
								setMessages={setMessages}
								tabSelectOptions={tabSelectOptions}
								lockUsers={lockUsers}
								test={test}
								questions={questions}
								setTest={setTest}
								historyPush={props.history.push}
							/>
						) : currentQuestion === 'Compliance' ? (
							<ComplianceTestQuestion
								onClickBack={onClickBack}
								onClickNext={onClickNext}
								goToQuestion={goToQuestion}
								setMessages={setMessages}
								tabSelectOptions={tabSelectOptions}
								test={test}
								setTest={setTest}
								refetchTest={async () => {
									if (test?.testID) {
										await getTest(test.testID)
									}
									setPageStatus('Editing')
								}}
								complianceQuestions={complianceQuestions}
							/>
						) : (
							<QuestionLookup
								question={questions.find((q) => q.questionID === currentQuestion) || null}
								asset={asset}
								onClickNext={onClickNext}
								onClickBack={onClickBack}
								goToQuestion={goToQuestion}
								test={test}
								refetchTest={async () => {
									if (test && test.testID) {
										await getTest(test.testID)
										setPageStatus('Editing')
									}
								}}
								setMessages={setMessages}
								tabSelectOptions={tabSelectOptions}
							/>
						)}
					</Col>
				</Row>
			)}
		</div>
	)
}

export { TestWrapper }
