import React, { useContext } from 'react'
import { RouteComponentProps, StaticContext } from 'react-router'
import { Spinner, Col, Row, Table, Form, Dropdown } from 'react-bootstrap'
import * as Request from '../../utilities/request'
import { Header } from '../../components/UI/Header/Header'
import { Button } from '../../components/UI/Button/Button'
import { Paginator, FiltersState, pageFilter, calculatePageStart, calculatePageTo } from '../../components/UI/Paginator/Paginator'
import { AppContext } from '../../App'
import { Link } from 'react-router-dom'
import { PageStatus } from '../../types/PageStatus'
import { History, Created, HistoryResult } from '../../../../back-end/common/history'
import { PageBackState } from '../../types/PageBackState'

import sortAsc from '../../images/icons/sort-asc.svg'
import { formatDateTime } from '../../utilities/formatDate'

interface ScreensHistoryMatchParams {
	id: string
}

interface ScreensHistoryStateParams extends PageBackState {}

const ScreensHistory = (props: RouteComponentProps<ScreensHistoryMatchParams, StaticContext, ScreensHistoryStateParams | undefined>) => {
	const context = useContext(AppContext)
	const historyReferenceID = props.match!.params.id

	const [histories, setHistories] = React.useState<History[] | null>(null)
	const [filters, setFilters] = React.useState<FiltersState>({ page: 1, pageSize: 10 })
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	React.useEffect(() => {
		const getData = async () => {
			const [historyResponse] = await Promise.all([
				Request.get<HistoryResult>(
					historyReferenceID ? `history?where=historyReferenceID==${historyReferenceID}` : `history`,
					context.appState.authState
				),
			])

			/* Format the time here so its usable for searching */
			const history = historyResponse.data.history.map((h: History) => ({
				...h,
				create: { ...h.create, ts: formatDateTime({ date: h.create.ts, format: 'DateAndTimeAndTimezone' }) },
			}))

			setHistories(history)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, []) // eslint-disable-line

	// Filters
	const [searchHistoryCreatedTimestamp, setSearchHistoryCreatedTimestamp] = React.useState<string>('')
	const [searchHistoryCreatedUser, setSearchHistoryCreatedUser] = React.useState<string>('')
	const [searchHistoryTable, setSearchHistoryTable] = React.useState<string>('')
	const [searchHistoryAction, setSearchHistoryAction] = React.useState<string>('')
	const [searchHistoryDetail, setSearchHistoryDetail] = React.useState<string>('')
	const [selectHistory] = React.useState<string[]>([])

	React.useEffect(() => {
		setFilters({ ...filters, page: 1 })
	}, [searchHistoryCreatedUser, searchHistoryCreatedTimestamp, searchHistoryTable, searchHistoryAction, searchHistoryDetail, selectHistory]) // eslint-disable-line

	/* Apply filters */
	let filteredRecords: History[] = histories ? histories : []
	const unFilteredRecords: History[] = filteredRecords
	let paginatedFilteredRecords: History[] = []
	if (filteredRecords.length > 0) {
		// Filter by createdUser
		filteredRecords = filterCreate('ts')(filteredRecords, searchHistoryCreatedTimestamp)

		// Filter by createdTimestamp
		filteredRecords = filterCreate('fullName')(filteredRecords, searchHistoryCreatedUser)

		// Filter by Table
		filteredRecords = filter('historyTable')(filteredRecords, searchHistoryTable)

		// Filter by Action
		filteredRecords = filter('historyAction')(filteredRecords, searchHistoryAction)

		// Filter by Detail
		filteredRecords = filter('historyDetail')(filteredRecords, searchHistoryDetail)

		// Pagination
		paginatedFilteredRecords = filteredRecords.filter((_record: unknown, index: number) => pageFilter(filters, index))
	}

	return (
		<div className="page">
			{props.location.state && props.location.state.backPath && (
				<Row>
					<Col>
						<Link className="breadcrumb-back" to={props.location.state.backPath}>{`< Back to ${props.location.state.backName}`}</Link>
					</Col>
				</Row>
			)}

			<Row>
				<Col>
					<Header title={`History (${unFilteredRecords.length})`} subtitle={''} />
				</Col>
				<Col></Col>

				<Col sm={'auto'} style={{ padding: '30px 0' }}>
					<div>
						<span className="span-bold">Show</span>
						<Dropdown style={{ float: 'right' }}>
							<Dropdown.Toggle className="form" variant="secondary" id="dropdown-action">
								{filters.pageSize} records
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => setFilters({ page: 1, pageSize: 10 })}>10 records</Dropdown.Item>
								<Dropdown.Item onClick={() => setFilters({ page: 1, pageSize: 25 })}>25 records</Dropdown.Item>
								<Dropdown.Item onClick={() => setFilters({ page: 1, pageSize: 50 })}>50 records</Dropdown.Item>
								<Dropdown.Item onClick={() => setFilters({ page: 1, pageSize: 100 })}>100 records</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</Col>
			</Row>

			<Row>
				<Col>
					<Table borderless style={{ backgroundColor: 'white' }}>
						<thead>
							<tr>
								{/* <th style={{ textAlign: 'center' }}>
									<Row>
										<Col className="upper-table-header" style={{ paddingBottom: '8px' }}>
											select
										</Col>
									</Row>
									<Row className="card-title-filter">
										<Col>
											<Form.Control
												onChange={(event: any) => {
													setSelectHistoryWrapper(event.target.value, event.target.checked)
												}}
												type="checkbox"
												id="Search"
												key="Search"
												name="Search"
												value={'All'}
											/>
										</Col>
									</Row>
								</th> */}
								<th>
									<Row>
										<Col className="upper-table-header">timestamp</Col>
										<Button className="sort-table-btn">
											<img src={sortAsc} alt={'A sort ascending icon'}></img>
										</Button>
									</Row>
									<Row className="card-title-filter">
										<Col>
											<Form.Control
												onChange={(event) => {
													setSearchHistoryCreatedTimestamp(event.target.value)
												}}
												type="text"
												id="Search"
												key="Search"
												name="Search"
												value={searchHistoryCreatedTimestamp}
												placeholder="Type to filter"
											/>
										</Col>
									</Row>
								</th>
								<th>
									<Row>
										<Col className="upper-table-header">user</Col>
										<Button className="sort-table-btn">
											<img src={sortAsc} alt={'A sort ascending icon'}></img>
										</Button>
									</Row>
									<Row className="card-title-filter">
										<Col>
											<Form.Control
												onChange={(event) => {
													setSearchHistoryCreatedUser(event.target.value)
												}}
												type="text"
												id="Search"
												key="Search"
												name="Search"
												value={searchHistoryCreatedUser}
												placeholder="Type to filter"
											/>
										</Col>
									</Row>
								</th>
								<th>
									<Row>
										<Col className="upper-table-header">table</Col>
										<Button className="sort-table-btn">
											<img src={sortAsc} alt={'A sort ascending icon'}></img>
										</Button>
									</Row>
									<Row className="card-title-filter">
										<Col>
											<Form.Control
												onChange={(event) => {
													setSearchHistoryTable(event.target.value)
												}}
												type="text"
												id="Search"
												key="Search"
												name="Search"
												value={searchHistoryTable}
												placeholder="Type to filter"
											/>
										</Col>
									</Row>
								</th>
								<th>
									<Row>
										<Col className="upper-table-header">action</Col>
										<Button className="sort-table-btn">
											<img src={sortAsc} alt={'A sort ascending icon'}></img>
										</Button>
									</Row>
									<Row className="card-title-filter">
										<Col>
											<Form.Control
												onChange={(event) => {
													setSearchHistoryAction(event.target.value)
												}}
												type="text"
												id="Search"
												key="Search"
												name="Search"
												value={searchHistoryAction}
												placeholder="Type to filter"
											/>
										</Col>
									</Row>
								</th>
								<th>
									<Row>
										<Col className="upper-table-header" style={{ paddingBottom: '8px' }}>
											detail
										</Col>
									</Row>
									<Row className="card-title-filter">
										<Col>
											<Form.Control
												onChange={(event) => {
													setSearchHistoryDetail(event.target.value)
												}}
												type="text"
												id="Search"
												key="Search"
												name="Search"
												value={searchHistoryDetail}
												placeholder="Type to filter"
											/>
										</Col>
									</Row>
								</th>
							</tr>
						</thead>

						<tbody>{pageStatus !== 'Loading' && renderHistoryList(paginatedFilteredRecords)}</tbody>

						<tfoot>
							<tr>
								<td colSpan={8}>
									<Row>
										<Col>
											<span className="span-grey" style={{ verticalAlign: 'middle' }}>
												Displaying{' '}
												<span className="span-blue">
													{calculatePageStart(filters)} to {calculatePageTo(filters, unFilteredRecords.length)}
												</span>{' '}
												of {unFilteredRecords.length} history records
											</span>
										</Col>
										<Col sm="auto">
											<Paginator filters={filters} setFilterDispatch={setFilters} allPaginatedRecordsLength={filteredRecords.length} />
										</Col>
									</Row>
								</td>
							</tr>
						</tfoot>
					</Table>

					{pageStatus === 'Loading' ? (
						<Row>
							<Col sm="auto">
								<Spinner size={'sm'} animation={'border'} />
							</Col>
						</Row>
					) : null}
				</Col>
			</Row>
		</div>
	)
}

/* Filters */
const filter = (propertyToFilter: keyof History) => {
	return (list: History[], filterText?: string) => {
		if (filterText) {
			return list.filter(
				(history) => history[propertyToFilter] && history[propertyToFilter].toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1
			)
		}
		return list
	}
}

const filterCreate = (propertyToFilter: keyof Created) => {
	return (list: History[], filterText?: string) => {
		if (filterText) {
			return list.filter(
				(history) =>
					history.create[propertyToFilter] && history.create[propertyToFilter]!.toString().toLowerCase().indexOf(filterText.toLowerCase()) !== -1
			)
		}
		return list
	}
}

const renderHistoryList = (historyRecords: History[]) => {
	if (historyRecords.length > 0) {
		return historyRecords.map((history: History, i: number) => {
			return (
				<tr key={i}>
					<td>{history.create.ts}</td>
					<td>
						<Link to={`/user/${history.create.userID}`}>{history.create.fullName}</Link>
					</td>
					<td>{history.historyTable}</td>
					<td>{history.historyAction}</td>
					<td>{history.historyDetail}</td>
				</tr>
			)
		})
	} else {
		return (
			<tr>
				<td>No History to list...</td>
			</tr>
		)
	}
}

export { ScreensHistory }
