import React, { ErrorInfo, ReactChild, ReactChildren } from 'react'
import { Row, Col, Card, Container } from 'react-bootstrap'
import { put } from '../../utilities/request'
import { Frown } from 'react-feather'
import { Auth } from '@aws-amplify/auth'

class ErrorBoundary extends React.Component<{ children: ReactChildren | ReactChild }, { hasError: boolean; url: string }> {
	constructor(props: never) {
		super(props)
		this.state = {
			hasError: false,
			url: '',
		}
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	async componentDidCatch(error: Error, info: ErrorInfo) {
		console.log('error', error)
		console.log('info', info)

		// Log error
		const currentSession = await Auth.currentSession()
		const authState = {
			isLoggedIn: true,
			apiToken: currentSession.getIdToken().getJwtToken(),
		}

		const errorObject = {
			ErrorLogType: 'WebsiteError',
			ErrorLogDescription: JSON.stringify(error.message).concat(' | ', JSON.stringify(info)),
			ErrorLogPage: window.location.href,
		}

		if (!window.location.href.includes('localhost')) {
			put('error', errorObject, authState)
		}
	}

	UNSAFE_componentWillReceiveProps() {
		// Used so when the navbar is used we try the request again
		this.setState({
			hasError: false,
			url: window.location.href,
		})
	}

	render() {
		if (this.state.hasError) {
			return (
				<Container>
					<Card style={{ marginTop: '20%', textAlign: 'center' }}>
						<Row style={{ marginBottom: '20px' }}>
							<Col>
								<Frown />
							</Col>
						</Row>
						<Row>
							<Col>
								<h1>Ooops! We&apos;re sorry, it looks like something has gone wrong</h1>
							</Col>
						</Row>
						<Row>
							<Col>
								<h1>Our development team has been notified</h1>
							</Col>
						</Row>
					</Card>
				</Container>
			)
		}
		return this.props.children
	}
}

export { ErrorBoundary }
