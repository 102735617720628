import React, { useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { AppContext } from '../../App'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Header } from '../../components/UI/Header/Header'
import { PageStatus } from '../../types/PageStatus'
import { User, UserResult } from '../../../../back-end/common/user'
import * as Request from '../../utilities/request'
import { Listing, FilterDropdownOption } from '../../components/UI/Listing/Listing'
import addIcon from '../../images/icons/add.svg'
import { Entity } from 'dynaflow/common/entity'
import { canWriteUser } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'
import { Level } from '../../constants/entityType'

interface EntityUsersProps {
	pageProps: RouteComponentProps
	entity: Entity
}

const EntityUsers = (props: EntityUsersProps) => {
	const context = useContext(AppContext)

	const [entityUsers, setEntityUsers] = React.useState<User[] | null>(null)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	React.useEffect(() => {
		const getData = async () => {
			const [EntityUsersResponse] = await Promise.all([
				Request.get<UserResult>(`user?where=entityID==${props.entity.entityID}`, context.appState.authState),
			])
			const EntityUsers = EntityUsersResponse.data.users
			setEntityUsers(EntityUsers)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, []) // eslint-disable-line

	const roles =
		entityUsers?.reduce((accumulator, user) => {
			const typeIndex = accumulator.findIndex((acc) => acc.value === user.roleID)
			if (typeIndex < 0) {
				accumulator.push({ value: user.roleID!, text: user.roleName! })
			}
			return accumulator
		}, [] as FilterDropdownOption[]) || []

	if (entityUsers !== null) {
		return (
			<div id={'EntityUsers'}>
				<div id={'EntityUsersHeader'}>
					<Row>
						<Col>
							<Header title={`Users (${entityUsers.length})`} subtitle={''} />
						</Col>
						{canWriteUser(appStateToPermissionObject(context.appState), {
							entityID: props.entity.entityID,
							entityLevel: Level[props.entity.entityTypeID],
						}) && (
							<Col sm={'auto'} style={{ paddingTop: '40px' }}>
								<Button
									className=" btn btn-primary round"
									onClick={() =>
										props.pageProps.history.push(`/user/new?entity=${props.entity.entityID}`, {
											backPath: props.pageProps.location.pathname,
											backName: props.entity.entityName,
										})
									}
								>
									<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
									<span className="span-bold" style={{ color: 'white' }}>
										Add User
									</span>
								</Button>
							</Col>
						)}
					</Row>
				</div>
				<div id={'EntityUsersBody'}>
					<Listing
						name="User"
						namePlural="Users"
						list={entityUsers || []}
						getIDFunc={(item) => item.userID}
						selectedActions={[]}
						columns={[
							{
								value: (item) => item.fullName!,
								render: (item) => (
									<Link
										to={{
											pathname: `/user/${item.userID}`,
											state: { backPath: props.pageProps.location.pathname, backName: props.entity.entityName },
										}}
									>
										{item.fullName}
									</Link>
								),
								showHeader: true,
								headerText: 'Name',
								sortColumnName: 'fullName',
								filterType: 'string',
								filterOptions: {
									columnName: 'fullName',
								},
							},
							{
								value: (item) => item.email,
								render: (item) => <>{item.email}</>,
								showHeader: true,
								headerText: 'Email',
								sortColumnName: 'email',
								filterType: 'string',
								filterOptions: {
									columnName: 'email',
								},
							},
							{
								value: (item) => item.roleID!,
								render: (item) => <>{item.roleName}</>,
								showHeader: true,
								headerText: 'Role',
								sortColumnName: 'roleName',
								filterType: 'dropdown',
								filterOptions: {
									columnName: 'roleName',
									options: roles,
								},
							},
						]}
						defaultSort={{ column: 'fullName', order: 'ASC' }}
						isLoading={pageStatus === 'Loading'}
					/>
				</div>
			</div>
		)
	}
	return null
}

export { EntityUsers }
