import React from 'react'
import { MessageAction } from '../../../components/UI/Messages/Message'
import { Test, TestResult } from 'dynaflow/common/test'
import { DropdownSelectOption } from '../../../components/Forms/Dropdown'
import { Formik, FormikErrors } from 'formik'
import * as yup from 'yup'
import { QuestionNavigationButtons, TabRow } from './QuestionComponents'
import { Col, Row } from 'react-bootstrap'
import { Header } from '../../../components/UI/Header/Header'
import { AppContext } from '../../../App'
import { ComplianceAnswer, ComplianceQuestion, ComplianceResponse, FilteredCompliance } from 'dynaflow/common/complianceQuestion'
import * as Request from '../../../utilities/request'
import { ComplianceQuestionForm } from '../../../components/ComplianceQuestion/Question'

const complianceQuestionOrder = (a: Pick<ComplianceQuestion, 'order'>, b: Pick<ComplianceQuestion, 'order'>) => a.order - b.order

interface StructuredComplianceQuestion extends FilteredCompliance {
	children: FilteredCompliance[]
}

interface ComplianceTestAnswersProps {
	onClickBack: () => void
	onClickNext: () => void
	refetchTest: () => void
	goToQuestion: (question: string) => void
	setMessages: (action: MessageAction) => void
	test: Test | null
	setTest: React.Dispatch<React.SetStateAction<Test | null>>
	tabSelectOptions: DropdownSelectOption<string, object>[]
	complianceQuestions: ComplianceQuestion[]
}

const complianceSchema = yup.array().of(
	yup.object({
		children: yup.array().of(
			yup.object({
				response: yup
					.string()
					.when('isFreeResponse', {
						is: true,
						then: (schema) => schema.min(1),
						otherwise: (schema) => schema.oneOf(['yes', 'no', 'n/a']),
					})
					.required(),
			})
		),
	})
)

const validationSchema = yup.object({
	compQuestionValues: complianceSchema,
	instQuestionValues: complianceSchema,
})

const mapFilteredValues = (value: ComplianceResponse) => ({
	complianceQuestionID: value.complianceQuestionID,
	text: value.text,
	order: value.order,
	clause: value.clause,
	isHeading: value.isHeading,
	parentHeadingID: value.parentHeadingID,
	children: value.children.map((child) => ({
		complianceQuestionID: child.complianceQuestionID,
		text: child.text,
		order: child.order,
		clause: child.clause,
		isHeading: child.isHeading,
		isFreeResponse: child.isFreeResponse,
		parentHeadingID: child.parentHeadingID,
		response: child.response,
	})),
})

const ComplianceTestQuestion = (props: ComplianceTestAnswersProps) => {
	const { test, complianceQuestions } = props
	const context = React.useContext(AppContext)
	const submitCallback = React.useRef(() => {})

	const compQuestions = complianceQuestions.filter((q) => q.isFromParentAssetType)
	const instQuestions = complianceQuestions.filter((q) => !q.isFromParentAssetType)

	const structuredCompQuestions = React.useMemo<StructuredComplianceQuestion[]>(() => {
		return compQuestions
			.filter((q) => q.isHeading)
			.sort(complianceQuestionOrder)
			.map((parentQuestion) => ({
				...parentQuestion,
				children: compQuestions.filter((q) => q.parentHeadingID === parentQuestion.complianceQuestionID).sort(complianceQuestionOrder),
			})) as StructuredComplianceQuestion[]
	}, [complianceQuestions])

	const structuredInstQuestions = React.useMemo<StructuredComplianceQuestion[]>(() => {
		return instQuestions
			.filter((q) => q.isHeading)
			.sort(complianceQuestionOrder)
			.map((parentQuestion) => ({
				...parentQuestion,
				children: instQuestions.filter((q) => q.parentHeadingID === parentQuestion.complianceQuestionID).sort(complianceQuestionOrder),
			})) as StructuredComplianceQuestion[]
	}, [complianceQuestions])

	const [compQuestionValues, setCompQuestionValues] = React.useState<ComplianceResponse[]>(
		structuredCompQuestions.map((q) => ({
			...q,
			children: q.children.map((c) => ({ ...c, response: null })),
		}))
	)
	const [instQuestionValues, setInstQuestionValues] = React.useState<ComplianceResponse[]>(
		structuredInstQuestions.map((q) => ({
			...q,
			children: q.children.map((c) => ({ ...c, response: null })),
		}))
	)

	React.useEffect(() => {
		if (test && test.complianceCheckResponse) {
			try {
				setCompQuestionValues(test.complianceCheckResponse)
			} catch (e) {
				console.error('Error loading question values')
				console.error(e)
			}
		}
		if (test && test.complianceCheckResponseInstSpecific) {
			try {
				setInstQuestionValues(test.complianceCheckResponseInstSpecific)
			} catch (e) {
				console.error('Error loading question values')
				console.error(e)
			}
		}
	}, [test])

	return (
		<Formik
			initialValues={{ compQuestionValues, instQuestionValues }}
			validationSchema={validationSchema}
			onSubmit={async (values) => {
				// Filter the compliance values to only have keys from FilteredCompliance
				const compFilteredValues = values.compQuestionValues.map(mapFilteredValues)
				const instFilteredValues = values.instQuestionValues.map(mapFilteredValues)
				// Update the test with the new compliance answers
				const updatedTest = await Request.put<TestResult>(
					`test?where=testID==${test?.testID}`,
					{
						testID: test?.testID,
						isLocked: false,
						technicianUserID: test?.technicianUserID,
						performedTs: test?.performedTs,
						requiresComplianceCheck: test?.requiresComplianceCheck,
						photoIsApplicable: test?.photoIsApplicable,
						photoS3Path: test?.photoS3Path,
						assetID: test?.assetID,
						complianceCheckResponse: JSON.stringify(compFilteredValues),
						complianceCheckResponseInstSpecific: JSON.stringify(instFilteredValues),
					},
					context.appState.authState
				)
				props.setTest(updatedTest.data.tests[0])
				submitCallback.current()
			}}
			validateOnBlur={false}
			validateOnChange={false}
			enableReinitialize={true}
		>
			{({ handleSubmit, errors, values, handleReset, setFieldValue, dirty, isSubmitting }) => {
				return (
					<>
						<TabRow
							tabSelectOptions={props.tabSelectOptions}
							onClick={(q, save) => {
								if (save) {
									submitCallback.current = () => props.goToQuestion(q)
									handleSubmit()
								} else {
									props.goToQuestion(q)
								}
							}}
							currentQuestion={'Compliance'}
							dirty={dirty}
							isSubmitting={isSubmitting}
							errors={errors}
						/>
						<Row>
							<Col>
								<Header title={'Compliance Check'} subtitle={props.test?.assetTypeComplianceClause || ''} />
								{structuredCompQuestions.map((parentQuestion, index) => (
									<ComplianceQuestionForm
										key={index}
										question={values.compQuestionValues[index] as ComplianceAnswer}
										context="answering"
										disabled={!!test?.isLocked}
										pageStatus="Editing"
									>
										{parentQuestion.children.map((childQuestion, childIndex) => (
											<ComplianceQuestionForm
												key={childIndex}
												question={values.compQuestionValues[index].children[childIndex] as ComplianceAnswer}
												context="answering"
												disabled={!!test?.isLocked}
												pageStatus="Editing"
												parentClause={parentQuestion.clause}
												onAnswerChange={(value: string) =>
													setFieldValue(`compQuestionValues.${index}.children.${childIndex}.response`, value)
												}
												errors={
													errors.compQuestionValues
														? (errors.compQuestionValues as FormikErrors<ComplianceResponse>[])[index]?.children![childIndex]
														: undefined
												}
											/>
										))}
									</ComplianceQuestionForm>
								))}
							</Col>
						</Row>
						{structuredInstQuestions.length > 0 && (
							<Row>
								<Col>
									<Header title={'Institution Specific Questions'} subtitle={''} />
									{structuredInstQuestions.map((parentQuestion, index) => (
										<ComplianceQuestionForm
											key={index}
											question={values.instQuestionValues[index] as ComplianceAnswer}
											context="answering"
											disabled={!!test?.isLocked}
											pageStatus="Editing"
										>
											{parentQuestion.children.map((childQuestion, childIndex) => (
												<ComplianceQuestionForm
													key={childIndex}
													question={values.instQuestionValues[index].children[childIndex] as ComplianceAnswer}
													context="answering"
													disabled={!!test?.isLocked}
													pageStatus="Editing"
													parentClause={parentQuestion.clause}
													onAnswerChange={(value: string) =>
														setFieldValue(`instQuestionValues.${index}.children.${childIndex}.response`, value)
													}
													errors={
														errors.instQuestionValues
															? (errors.instQuestionValues as FormikErrors<ComplianceResponse>[])[index]?.children![childIndex]
															: undefined
													}
												/>
											))}
										</ComplianceQuestionForm>
									))}
								</Col>
							</Row>
						)}
						<QuestionNavigationButtons
							{...props}
							handleReset={handleReset}
							handleSubmit={handleSubmit}
							submitCallback={submitCallback}
							setSubmitCallback={(fn) => (submitCallback.current = fn)}
							dirty={dirty}
							isSubmitting={isSubmitting}
							question={null}
						/>
					</>
				)
			}}
		</Formik>
	)
}

export { ComplianceTestQuestion }
