import React from 'react'
// import * as Request from '../../../utilities/request'
// import { Asset, AssetResult } from '../../../../../back-end/common/asset'
// import { AppContext } from '../../../App'
// import { DropdownSelect } from '../../Forms/Dropdown'

export const Search = () => {
	// const context = useContext(AppContext)

	// // const [searchText, setSearchTest] = React.useState<string>('')
	// const [assets, setAssets] = React.useState<Asset[] | null>(null)

	// React.useEffect(() => {
	// 	const getData = async () => {
	// 		// const [asset] = await Promise.all([Request.get<AssetResult>(`asset`, context.appState.authState)])
	// 		// setAssets(asset.data.assets)
	// 	}

	// 	if (context.appState.authState.isLoggedIn) {
	// 		getData()
	// 	}
	// }, []) // eslint-disable-line

	// if (assets) {
	// 	return (
	// 		<DropdownSelect
	// 			id="SearchAssetDropdown"
	// 			name="assetID"
	// 			value={'Click to search...'}
	// 			onChange={(value) => (window.location.href = `/asset/${value}`)}
	// 			disabled={false}
	// 			variant="secondary"
	// 			showSearch={true}
	// 			searchPlaceholder="Filter"
	// 			options={assets.map((l) => ({
	// 				...l,
	// 				value: l.assetID,
	// 				text: `${l.roomName}, ${l.assetUnitReference}`,
	// 			}))}
	// 		/>
	// 	)
	// }
	return <></>
}
