import React, { useContext } from 'react'
import { Link, RouteChildrenProps } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { Asset, AssetResult } from '../../../../back-end/common/asset'
import * as Request from '../../utilities/request'
import { Header } from '../../components/UI/Header/Header'
import { Button } from '../../components/UI/Button/Button'
import { AppContext } from '../../App'
import { PageStatus } from '../../types/PageStatus'
import addIcon from '../../images/icons/add.svg'
import { Listing, FilterDropdownOption, ListingAction } from '../../components/UI/Listing/Listing'
import { formatDateTime } from '../../utilities/formatDate'
import { BatchTestResult } from 'dynaflow/common/test'
import { CheckForZipResponse, DocumentResult } from 'dynaflow/common/document'
import BatchTestModal from '../../components/Test/BatchTestModal'
import { DropdownSelect } from '../../components/Forms/Dropdown'
import { Account, AccountResult } from '../../../../back-end/common/account'
import { DateTime } from 'luxon'
import { canWriteAsset, canWriteTest } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'
import { DeleteModal } from '../../components/UI/DeleteModal/deleteModal'
const ScreensAsset = (props: RouteChildrenProps) => {
	const context = useContext(AppContext)

	const [messages, setMessages] = useMessageReducer([])
	const [assets, setAssets] = React.useState<Asset[] | null>(null)
	const [accounts, setAccounts] = React.useState<Account[]>([])
	const [account, setAccount] = React.useState<Account | undefined>()
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Ready')
	const [toDelete, setToDelete] = React.useState<string[]>([])
	const [batchTestLink, setBatchTestLink] = React.useState<boolean | string>(false)

	React.useEffect(() => {
		const getData = async () => {
			const [account] = await Promise.all([Request.get<AccountResult>(`account`, context.appState.authState)])
			setAccounts(account.data.accounts)
			if (account.data.accounts.length === 1) {
				setAccount(account.data.accounts[0])
				getAssets(account.data.accounts[0].accountID)
			}
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, [context])

	const getAssets = async (accountID: string) => {
		setPageStatus('Loading')

		const asset = await Request.get<AssetResult>(`asset?where=accountID==${accountID}`, context.appState.authState)
		setAssets(asset.data.assets)

		setPageStatus('Ready')
	}

	const checkNoneSelected = (selected: string[], message: string) => {
		if (selected.length === 0) {
			setMessages({
				data: { id: 1, severity: 'danger', message: message, dismissible: true, timeout: 5000 },
				type: 'add',
			})
			return true
		}
		return false
	}

	const handleDeleteSelected = async (selected: string[]) => {
		if (checkNoneSelected(selected, 'Please select one or more assets to delete')) {
			return
		}

		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.del<AssetResult>(`asset?where=assetID=in(${selected.join(',')})`, context.appState.authState), {
			successFunction: () => {
				setToDelete([])
				setAssets(assets?.filter((asset) => !selected.includes(asset.assetID)) || [])
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'assets',
		})
	}

	const handleBlankTestReports = async (selected: string[]) => {
		if (checkNoneSelected(selected, 'Please select one or more assets to generate reports')) {
			return
		}

		setPageStatus('Submitting')
		setBatchTestLink(true)

		Request.handleRequest(() => Request.post<BatchTestResult>(`test/batch`, { assets: selected }, context.appState.authState), {
			successFunction: async (data) => {
				await new Promise((r) => setTimeout(r, 15000))
				handleFetchReportsZip(data.zipURL)
			},
			errorFunction: (err) => {
				console.log(JSON.stringify(err))
				setBatchTestLink('Error: please retry')
			},
			setMessageFunction: setMessages,
			messageAction: 'creating',
			messageObject: 'tests',
		})
	}

	const handleFetchReportsZip = async (zipURL: string, initTime = DateTime.now()) => {
		Request.handleRequest(() => Request.post<CheckForZipResponse>(`test/zip`, { zipURL: zipURL }, context.appState.authState), {
			successFunction: async (data) => {
				if (data.success && data.message) {
					setBatchTestLink(data.message)
				} else if (data.success && data.ready) {
					let fileLink = undefined
					do {
						const doc = await Request.post<DocumentResult>(
							'document',
							{ operation: 'getObject', type: 'application/zip', s3Location: zipURL },
							context.appState.authState
						)
						fileLink = doc.data.file.signedUrl
						setBatchTestLink(fileLink || '')
					} while (!fileLink)
				} else if (data.success && !data.ready && initTime.plus({ minutes: 10 }) > DateTime.now()) {
					await new Promise((r) => setTimeout(r, 3000))
					handleFetchReportsZip(zipURL, initTime)
				} else {
					setBatchTestLink('Error: The report generation timed out, please select less reports and try again.')
				}
			},
			errorFunction: () => {
				setBatchTestLink('Error: Could not create blank test reports, ensure each asset has valid details and try again.')
			},
		})
	}

	const assetTypes =
		assets?.reduce((accumulator, asset) => {
			const typeIndex = accumulator.findIndex((acc) => acc.value === (asset.parentAssetTypeID || asset.assetTypeID))
			if (typeIndex < 0) {
				accumulator.push({
					value: asset.parentAssetTypeID || asset.assetTypeID,
					text: !asset.parentAssetTypeID ? `${asset.assetTypeName} (${asset.accountName})` : asset.assetTypeName,
				})
			}
			return accumulator
		}, [] as FilterDropdownOption[]) || []

	const selectedActions: ListingAction[] = [{ name: 'Generate Blank Test Reports', method: handleBlankTestReports }]

	if (account && canWriteAsset(appStateToPermissionObject(context.appState), { accountID: account.accountID, cud: 'delete' })) {
		selectedActions.push({ name: 'Delete selected', method: (selected) => setToDelete(selected) })
	}

	return (
		<div className="page">
			<Messages messages={messages} updateMessage={setMessages} />

			<BatchTestModal
				show={batchTestLink !== false}
				onHide={() => setBatchTestLink(false)}
				loadingMessage="Generating reports can take some time, please be patient."
				link={batchTestLink === true || batchTestLink === false ? '' : batchTestLink}
				setBatchTestLink={setBatchTestLink}
			/>

			<Row>
				<Col>
					<Header title={`Assets (${assets?.length || 0})`} subtitle={'View a list of assets'} />
				</Col>
				{account && canWriteAsset(appStateToPermissionObject(context.appState), { accountID: account.accountID, cud: 'create' }) && (
					<Col sm={'auto'} style={{ paddingTop: '40px' }}>
						<Button className=" btn btn-primary round" onClick={() => props.history.push('/asset/new')}>
							<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
							<span className="span-bold" style={{ color: 'white' }}>
								Create new asset
							</span>
						</Button>
					</Col>
				)}
			</Row>

			{accounts.length > 1 && (
				<Row style={{ marginBottom: '20px' }}>
					<Col>
						<DropdownSelect
							name="accountID"
							label="Institution"
							value={account?.accountName || ''}
							onChange={(value, account) => {
								setAccount(account)
								getAssets(value)
							}}
							disabled={accounts.length === 1}
							id="accountID"
							variant="secondary"
							showSearch={true}
							searchPlaceholder="Filter"
							options={accounts.map((account) => ({ ...account, value: account.accountID, text: account.accountName }))}
						/>
					</Col>
				</Row>
			)}

			<DeleteModal show={toDelete.length > 0} noun="Instruments" onDeletePress={() => handleDeleteSelected(toDelete)} onNoPress={() => setToDelete([])} />

			{account && (
				<Listing
					name="Asset"
					namePlural="Assets"
					list={assets || []}
					getIDFunc={(item) => item.assetID}
					selectedActions={selectedActions}
					columns={[
						{
							value: (item) => item.assetUnitReference,
							render: (item) => (
								<Link to={{ pathname: `/asset/${item.assetID}`, state: { backPath: props.location.pathname, backName: 'Assets' } }}>
									{item.assetUnitReference}
								</Link>
							),
							showHeader: true,
							headerText: 'Unit Ref',
							sortColumnName: 'assetUnitReference',
							filterType: 'string',
							filterOptions: {
								columnName: 'assetUnitReference',
							},
						},
						{
							value: (item) => item.parentAssetTypeID || item.assetTypeID,
							render: (item) => <>{item.assetTypeName}</>,
							showHeader: true,
							headerText: 'Type',
							sortColumnName: 'assetTypeName',
							filterType: 'dropdown',
							filterOptions: {
								columnName: 'assetTypeName',
								options: assetTypes,
							},
						},
						{
							value: (item) => item.locationName,
							render: (item) => <>{item.locationName}</>,
							showHeader: true,
							headerText: 'Location',
							sortColumnName: 'locationName',
							filterType: 'string',
							filterOptions: {
								columnName: 'locationName',
							},
						},
						{
							value: (item) => item.buildingName,
							render: (item) => <>{item.buildingName}</>,
							showHeader: true,
							headerText: 'Building',
							sortColumnName: 'buildingName',
							filterType: 'string',
							filterOptions: {
								columnName: 'buildingName',
							},
						},
						{
							value: (item) => item.roomName,
							render: (item) => <>{item.roomName}</>,
							showHeader: true,
							headerText: 'Room',
							sortColumnName: 'roomName',
							filterType: 'string',
							filterOptions: {
								columnName: 'roomName',
							},
						},
						{
							value: (item) => (item.assetActive ? 'Yes' : 'No'),
							render: (item) => <>{item.assetActive ? 'Yes' : 'No'}</>,
							showHeader: true,
							headerText: 'Active',
							sortColumnName: 'assetActive',
							filterType: 'dropdown',
							filterOptions: {
								columnName: 'assetActive',
								options: [
									{ value: 'Yes', text: 'Yes' },
									{ value: 'No', text: 'No' },
								],
							},
						},
						{
							value: (item) => item.assetClientRef,
							render: (item) => <>{item.assetClientRef}</>,
							showHeader: true,
							headerText: 'Client Ref',
							sortColumnName: 'assetClientRef',
							filterType: 'string',
							filterOptions: {
								columnName: 'assetClientRef',
							},
						},
						{
							value: (item) => item.accountName,
							render: (item) => <>{item.accountName}</>,
							showHeader: true,
							headerText: 'Institution',
							sortColumnName: 'accountName',
							filterType: 'string',
							filterOptions: {
								columnName: 'accountName',
							},
						},
						{
							value: (item) => (item.lastTestTs ? formatDateTime({ date: item.lastTestTs, format: 'Date' }) : 'No Tests'),
							render: (item) => (
								<div className="d-flex flex-wrap align-items-center justify-content-center">
									{item.lastTestTs ? (
										<Link to={`test/${item.assetLastTestID}`}>{formatDateTime({ date: item.lastTestTs, format: 'Date' })}</Link>
									) : (
										'No Tests'
									)}
									{canWriteTest(appStateToPermissionObject(context.appState)) && (
										<Button
											variant="secondary"
											className="ml-2"
											onClick={() => props.history.push(`test/new/${item.assetID}`)}
											disabled={!item.assetActive}
										>
											New test
										</Button>
									)}
								</div>
							),
							showHeader: true,
							headerText: 'Last Test',
							sortColumnName: 'lastTestTs',
							filterType: 'dateRange',
							filterOptions: {
								columnName: 'lastTestTs',
							},
						},
					]}
					defaultSort={{ column: 'assetUnitReference', order: 'ASC' }}
					isLoading={pageStatus === 'Loading'}
				/>
			)}
		</div>
	)
}

export { ScreensAsset }
