import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Row, Col, Card } from 'react-bootstrap'
import { Calibration } from 'dynaflow/common/calibration'
import { FormText } from '../../../components/UI/Form/Text'
import { TestProps } from './QuestionLookup.d'
import { Formik, FormikErrors } from 'formik'
import * as yup from 'yup'
import { DropdownSelect, DropdownSelectOption } from '../../../components/Forms/Dropdown'
import {
	onQuestionSubmit,
	useLoadExistingQuestionResponse,
	QuestionNavigationButtons,
	TabRow,
	QuestionElement,
	QuestionResult,
	QuestionHeader,
} from './QuestionComponents'
import { AirBarrierContainmentTestOutput } from 'dynaflow/utilities/question'
import { getBSCReadingsCount } from 'dynaflow/utilities/questionResponse'
import { calibrateAdditiveValue } from 'dynaflow/utilities/calibration'

const LEAKS_READING_CARD_WIDTH = 150
interface LeakReadingProps {
	index: number
	formikKey: string
	values?: GridResult
	handleChange: (key: string, value: boolean) => void
	disabled: boolean
}

const LeakReading = (props: LeakReadingProps) => {
	return (
		<Card className="ml-2 mr-2 pb-5" style={{ width: `${LEAKS_READING_CARD_WIDTH}px` }} title={props.index.toString()}>
			<div className="text-center border-bottom bg-light mb-1">{props.index + 1}</div>
			<Col>
				<DropdownSelect
					id={`${props.formikKey}.results[${props.index}].value`}
					name={`${props.formikKey}.results[${props.index}].value`}
					disabled={props.disabled}
					variant="secondary"
					showSearch={false}
					searchPlaceholder={''}
					options={
						[
							{ value: 'pass', text: 'Pass' },
							{ value: 'fail', text: 'Fail' },
						] as DropdownSelectOption<boolean, never>[]
					}
					value={props?.values?.passed ? 'Pass' : 'Fail'}
					onChange={(value) => props.handleChange(`${props.formikKey}.results[${props.index}].passed`, value === 'pass' ? true : false)}
				/>
			</Col>
		</Card>
	)
}

interface LeaksProps {
	formikKey: string
	calibration: Pick<Calibration, 'calibrationID' | 'calibrationDate' | 'calibrationCertificateNumber' | 'calibrationCertificateS3Path' | 'calibrationItems'>
	values: {
		horizontalPoints: number
		results: GridResult[]
	}
	errors?: FormikErrors<GridResult>[] | string | string[]
	handleChange: (key: string, value: boolean) => void
	disabled: boolean
}

const Leaks = (props: LeaksProps) => {
	const rows = []
	for (let h = 0; h < props.values.horizontalPoints; h += 1) {
		rows.push(<LeakReading key={h} {...props} values={props.values.results.at(h)} index={h} />)
	}

	const gridStyle = {
		display: 'grid',
		gridTemplateColumns: `repeat(${props.values.horizontalPoints}, ${LEAKS_READING_CARD_WIDTH + 16}px)`,
		overflow: 'auto',
	}
	return (
		<div style={gridStyle} className="pb-4">
			{rows}
		</div>
	)
}

interface GridResult {
	point: number
	passed: boolean
}

interface QuestionValues {
	leaks: {
		horizontalPoints: number
		results: GridResult[]
	}
	exhaustFilterVelocity: string
	comment: string
}

const validationSchema = yup.object().shape({})

const AirBarrierContainmentTest = (props: TestProps) => {
	const { test } = props
	const context = useContext(AppContext)
	const submitCallback = React.useRef(() => {})

	const numberHorizontal = React.useMemo(() => getBSCReadingsCount(props.asset), [props.asset])

	const [questionValues, setQuestionValues] = React.useState<QuestionValues>(() => {
		const inp = [] as GridResult[]
		for (let i = 0; i < (numberHorizontal || 1); i++) {
			inp.push({
				point: i + 1,
				passed: true,
			})
		}
		return {
			leaks: {
				horizontalPoints: numberHorizontal,
				results: inp,
			},
			exhaustFilterVelocity: '',
			comment: '',
		}
	})

	useLoadExistingQuestionResponse({
		...props,
		setQuestionValues,
		formatQuestionValues: React.useCallback((values: AirBarrierContainmentTestOutput) => {
			return {
				leaks: {
					horizontalPoints: values.leaks.horizontalPoints,
					results: values.leaks.results.map((res) => ({ ...res, passed: typeof res.passed === 'boolean' ? res.passed : false })),
				},
				exhaustFilterVelocity: values.exhaustFilterVelocity.instrument.toString(),
				comment: '',
			}
		}, []),
	})

	// Assume there's only one instrument type for this question
	const testInstrument =
		test?.testInstruments?.find((instrument) => instrument.instrumentTypeID === props.question?.instrumentTypes[0].instrumentTypeID) || null
	const calibration =
		testInstrument && testInstrument.testInstrumentCalibrationSnapshot ? JSON.parse(testInstrument?.testInstrumentCalibrationSnapshot) : null

	if (!calibration) {
		return null
	}

	return (
		<Formik
			initialValues={questionValues}
			validationSchema={validationSchema}
			validateOnChange={true}
			validateOnBlur={true}
			onSubmit={(values) => {
				onQuestionSubmit({ ...props, values, authState: context.appState.authState, submitCallback })
			}}
			enableReinitialize={true}
		>
			{({ handleSubmit, errors, values, handleChange, setFieldValue, handleReset, dirty, isSubmitting }) => {
				let failed = null
				if (values.exhaustFilterVelocity) {
					failed = values.leaks.results.some((result) => !result.passed)
				}
				return (
					<>
						<TabRow
							tabSelectOptions={props.tabSelectOptions}
							onClick={(q, save) => {
								if (save) {
									submitCallback.current = () => props.goToQuestion(q)
									handleSubmit()
								} else {
									props.goToQuestion(q)
								}
							}}
							currentQuestion={props.question?.questionID}
							dirty={dirty}
							isSubmitting={isSubmitting}
							errors={errors}
						/>
						<Row>
							<Col>
								<QuestionHeader question={props.question} />
								<QuestionElement title="Leaks">
									<Row className="mb-4">
										{values.leaks.horizontalPoints && (
											<Leaks
												values={values.leaks}
												errors={errors?.leaks?.results}
												formikKey="leaks"
												handleChange={setFieldValue}
												calibration={calibration}
												disabled={!!test?.isLocked}
											/>
										)}
									</Row>
								</QuestionElement>
								<QuestionElement title="Exhaust filter velocity">
									<Row>
										<Col>
											<FormText
												name="exhaustFilterVelocity"
												label="Exhaust filter velocity (m/s)"
												value={values.exhaustFilterVelocity}
												onChange={handleChange}
												isInvalid={!!errors?.exhaustFilterVelocity}
												feedback={errors?.exhaustFilterVelocity}
												disabled={!!test?.isLocked}
											/>
										</Col>
										<Col>
											<FormText
												name="exhaustFilterVelocityCalibrated"
												label="Calibrated exhaust filter velocity (m/s)"
												value={calibrateAdditiveValue(Number(values.exhaustFilterVelocity), calibration) || ''}
												onChange={handleChange}
												plaintext={true}
											/>
										</Col>
									</Row>
								</QuestionElement>
								<QuestionResult
									failed={failed}
									questionHasComment={true}
									questionHasForcePass={false}
									commentValue={values.comment}
									handleChange={handleChange}
									isStandardsBased={props.asset?.assetTypeIsStandardsBased}
								/>
								<QuestionNavigationButtons
									{...props}
									handleReset={handleReset}
									handleSubmit={handleSubmit}
									submitCallback={submitCallback}
									setSubmitCallback={(fn) => (submitCallback.current = fn)}
									dirty={dirty}
									isSubmitting={isSubmitting}
								/>
							</Col>
						</Row>
					</>
				)
			}}
		</Formik>
	)
}

export { AirBarrierContainmentTest }
