import { Asset } from '../common/asset'
import { STANDARD_ASSET_TYPE } from '../constants/assetType'
import { QUESTION_VERSION } from '../constants/question'

// These are based on Fume Cupboard AS2243.8
// May be different for other asset types requiring a velocity test
export const getAssetHorizontalCount = (asset: Asset | null): number => {
	if (!asset) {
		return 1
	}
	if (asset.assetTypeIsStandardsBased) {
		const width = asset.assetWidth!
		let spacing
		switch (asset.parentAssetTypeID || asset.assetTypeID) {
			case STANDARD_ASSET_TYPE.FUME_CABINET:
				spacing = 200
				break
			case STANDARD_ASSET_TYPE.FUME_CUPBOARD:
			default:
				spacing = 500
				break
		}
		return Math.max(Math.ceil((width - 200) / spacing) + 1, 2)
	} else {
		return asset.assetHorizontalReadings || 1
	}
}

export const getAssetVerticalCount = (asset: Asset | null): number => {
	if (!asset) {
		return 1
	}
	if (asset.assetTypeIsStandardsBased) {
		const height = asset.assetHeight!
		let spacing
		switch (asset.parentAssetTypeID || asset.assetTypeID) {
			case STANDARD_ASSET_TYPE.FUME_CABINET:
				spacing = 200
				break
			case STANDARD_ASSET_TYPE.FUME_CUPBOARD:
			default:
				spacing = 250
				break
		}
		return Math.max(Math.ceil((height - 200) / spacing) + 1, 2)
	} else {
		return asset.assetVerticalReadings || 1
	}
}

const getGridResults = (nX: number, nY: number, properties: { [key: string]: string | boolean | { [key: string]: string } }) => {
	const inp = []
	for (let i = 0; i < (nX || 1) * (nY || 1); i++) {
		inp.push({
			point: i + 1,
			...properties,
		})
	}
	return inp
}

export const VelocityTestBlankOutput = (asset: Asset) => {
	const horz = getAssetHorizontalCount(asset)
	const vert = getAssetVerticalCount(asset)
	return {
		faceVelocity: {
			gridResults: getGridResults(horz, vert, { instrument: { high: '', low: '' }, calibrated: { high: '', low: '', mean: '' } }),
			horizontalPoints: horz,
			verticalPoints: vert,
			aggregateResults: {
				totalFaceVelocity: '',
				averageFaceVelocity: '',
				lowestAcceptableReading: '',
				highestAcceptableReading: '',
				lowestActualReading: '',
				highestActualReading: '',
				minimumPassRequirement: '',
			},
		},
		loweredFaceVelocity: {
			gridResults: getGridResults(horz, 1, { instrument: { high: '', low: '' }, calibrated: { high: '', low: '', mean: '' } }),
			horizontalPoints: horz,
			notRequired: false,
			sashClosedHeight: '',
			aggregateResults: {
				minimumAcceptableAverageFaceVelocity: '',
				actualAverageFaceVelocity: '',
			},
		},
		comment: '',
	}
}

export const SmokeTestBlankOutput = () => ({
	testPosition: {
		faceOpeningAirflowPattern: { radioSelect: '', comment: '' },
		assetAirflowPattern: { radioSelect: '', comment: '' },
		containmentAlongBottomOfAsset: { radioSelect: '', comment: '' },
		containmentAlongWallsOfAsset: { radioSelect: '', comment: '' },
		containmentWithSashFullyOpen: { radioSelect: '', comment: '' },
		containmentWithSashHalfOpen: { radioSelect: '', comment: '' },
		containmentWithSashAtMinimumOpening: { radioSelect: '', comment: '' },
		containmentUnderAbnormalConditions: { radioSelect: '', comment: '' },
	},
	smokeUsed: '',
	assetEmpty: '',
})

export const getAssetPointCount = (asset: Asset | null): [number, number] => {
	// Find the number of test points to use based on asset width and height using standard AS1807:
	// 200 - 225 mm intervals, 75 - 100 mm in from each edge. Intervals must be equal on both vertical and horizontal
	if (!asset) {
		return [1, 1]
	}
	if (asset.assetTypeIsStandardsBased) {
		const width = asset.assetWidth || 0
		const height = asset.assetHeight || 0
		// Offset by minimum distance in from edges to simplify calc ranges
		const x = width - 150
		const y = height - 150
		// Max and min widths of test range
		const maxX = x
		const maxY = y
		const minX = x - 50
		const minY = y - 50
		// Possible number of points in each direction
		const xs = [maxX / 225, maxX / 200, minX / 225, minX / 200]
		const ys = [maxY / 225, maxY / 200, minY / 225, minY / 200]
		// Get (overestimate) bounds for number of points in each direction
		const maxNumX = Math.ceil(Math.max(...xs))
		const minNumX = Math.floor(Math.min(...xs))
		const maxNumY = Math.ceil(Math.max(...ys))
		const minNumY = Math.floor(Math.min(...ys))

		const closestResults = []

		// Go through the grid points and check if they meet all standard requirements
		for (let nX = minNumX; nX <= maxNumX; nX++) {
			for (let nY = minNumY; nY <= maxNumY; nY++) {
				const minDx = (x - 50) / nX
				const minDy = (y - 50) / nY
				const maxDx = x / nX
				const maxDy = y / nY
				const overlapMin = Math.max(minDx, minDy)
				const overlapMax = Math.min(maxDx, maxDy)
				if (overlapMin < overlapMax && (overlapMin <= 225 || overlapMax >= 200)) {
					// Overlap is within the bounds of the standard
					const exactNx = nX + 1
					const exactNy = nY + 1
					const overlap = Math.min(Math.max(overlapMin, 200), Math.min(overlapMax, 225))
					console.log(
						`Found exact solution: ${nX + 1} x ${nY + 1} (${overlap} mm spacing leaving ${(width - nX * overlap) / 2} mm and ${
							(height - nY * overlap) / 2
						} on each side)`
					)
					return [Math.max(1, exactNx), Math.max(1, exactNy)]
				}
				// Overlap is outside the bounds of the standard (or no overlap)
				closestResults.push({
					nX,
					nY,
					minDx,
					minDy,
					maxDx,
					maxDy,
					ratio: overlapMax / overlapMin,
				})
			}
		}
		if (closestResults.length === 0) {
			console.error('No valid solution found')
			return [1, 1]
		}
		// TODO: figure out how to handle situations where no exact solution can be found
		const r = closestResults.sort((a, b) => Math.abs(a.ratio - 1) - Math.abs(b.ratio - 1))[0]
		console.warn(
			`Found approx solution: ${r.nX + 1} x ${r.nY + 1} (${r.minDx} - ${r.maxDx} mm horizontal spacing, ${r.minDy} - ${r.maxDy} mm vertical spacing)`
		)
		return [Math.max(1, r.nX + 1), Math.max(1, r.nY + 1)]
	} else {
		return [Math.max(1, asset.assetHorizontalReadings || 1), Math.max(1, asset.assetVerticalReadings || 1)]
	}
}

// air velocity uniformity
export const AirVelocityTestBlankOutput = (asset: Asset) => {
	const [horz, vert] = getAssetPointCount(asset)
	return {
		airVelocity: {
			horizontalPoints: horz,
			verticalPoints: vert,
			gridResults: getGridResults(horz, vert, { instrumentValue: '', calibratedValue: '' }),
			aggregateResults: {
				lowestAverageReading: '',
				highestAverageReading: '',
				averageVelocity: '',
				lowestAcceptableReading: '',
				highestAcceptableReading: '',
				lowestActualReading: '',
				highestActualReading: '',
			},
		},
		adjustments: {
			required: null,
		},
		filterPressure: {
			initial: {
				measured: {
					mainFilter: '',
					exhaust: '',
				},
				fittedGauge: {
					mainFilter: '',
					exhaust: '',
				},
			},
			final: {
				measured: {
					mainFilter: '',
					exhaust: '',
				},
				fittedGauge: {
					mainFilter: '',
					exhaust: '',
				},
			},
			inletPortPresent: null,
			pressureGaugesFitted: null,
		},
		comment: '',
		forcePass: false,
	}
}

export const getInwardAirVelocityHorizontalCount = (asset: Asset | null): number => {
	if (!asset) {
		return 1
	}
	if (asset.assetTypeIsStandardsBased) {
		const width = asset.assetWidth!
		// 50 mm in from the sides, and assuming a 110 mm diameter of the anenometer
		return Math.max(Math.ceil((width - (50 + 55) * 2) / 100) + 1, 1)
	} else {
		return asset.assetHorizontalReadings || 1
	}
}

export const InwardAirVelocityBlankOutput = (asset: Asset) => {
	const horz = getInwardAirVelocityHorizontalCount(asset)
	const vert = 2
	return {
		airVelocity: {
			horizontalPoints: horz,
			verticalPoints: vert,
			gridResults: getGridResults(horz, vert, { instrumentValue: '', calibratedValue: '' }),
			aggregateResults: {
				averageVelocity: '',
				lowestAcceptableAverageReading: '',
				highestAcceptableAverageReading: '',
				lowestActualAverageReading: '',
				highestActualAverageReading: '',
			},
		},
		comment: '',
	}
}

export const WorkZoneIntegrityTestBlankOutput = () => {
	return {
		areaSuitableForTest: true,
		leftSide: { gridResults: Array.from({ length: 6 * 6 }).fill('false'), noLeaks: false },
		rightSide: { gridResults: Array.from({ length: 6 * 6 }).fill('false'), noLeaks: false },
		frontCabinet: { gridResults: Array.from({ length: 16 * 4 }).fill('false'), noLeaks: false },
		workAccessOpening: { gridResults: Array.from({ length: 16 * 2 }).fill('false'), noLeaks: false },
		workFloor: { gridResults: Array.from({ length: 16 * 2 }).fill('false'), noLeaks: false },
	}
}

export const FilterInstallationIntegrityBlankOutput = () => {
	return {
		mainHEPA: { gridResults: Array.from({ length: 18 * 6 }).fill('false'), filterVelocity: '', patchArea: '', filterArea: '', filterGuardRemoved: false },
		mainDownflowVelocityBelowThreshold: null,
		mainIndividualPatchSizeAboveThreshold: null,
		mainTotalPatchSizeAboveThreshold: null,
		mainAdjustmentsOrRepairs: null,
		mainFilterGuardRemoved: null,
		exhaustHEPA: {
			gridResults: Array.from({ length: 10 * 6 }).fill('false'),
			filterVelocity: '',
			patchArea: '',
			filterArea: '',
		},
		exhaustVelocityBelowThreshold: null,
		exhaustIndividualPatchSizeAboveThreshold: null,
		exhaustTotalPatchSizeAboveThreshold: null,
		exhaustFilterRequiresChange: null,
		downflowVelocityThreshold: '',
		individualPatchSizeThreshold: '',
		totalPatchSizeThreshold: '',
		comment: '',
	}
}

export const getLightingReadingsCount = (asset: Asset | null): number => {
	if (!asset) {
		return 1
	}
	if (asset.assetTypeIsStandardsBased) {
		const width = asset.assetWidth!
		// AS1807 Test Method 5 - approx. 300 mm divisions, 150 mm in from each side
		// points = (w - 300) / 300 + 1 = w/300
		return Math.round(width / 300)
	} else {
		return asset.assetHorizontalReadings || 1
	}
}

export const LightingTestBlankOutput = (asset: Asset) => {
	const horz = getLightingReadingsCount(asset)
	return {
		lightingReadings: {
			horizontalPoints: horz,
			results: getGridResults(horz, 1, { instrumentValue: '', calibrationFactor: '', calibratedValue: '' }),
			aggregateResults: {
				totalReading: '',
				averageReading: '',
				lowestAcceptableReading: '',
				lowestAcceptableAverageReading: '',
			},
		},
		comment: '',
	}
}

export const SoundTestBlankOutput = () => {
	return {
		operatingSoundLevel: '',
		ambientSoundLevel: '',
		soundLevelDifference: '',
		correctedSoundLevel: '',
		highestAcceptableSoundLevel: '',
		roomDimensions: '',
		roomLayout: '',
		surfaceFinishes: '',
		otherFactors: '',
	}
}

export const getBSCReadingsCount = (asset: Asset | null): number => {
	if (!asset) {
		return 1
	}
	if (asset.assetTypeIsStandardsBased) {
		const width = asset.assetWidth!
		// AS1807 Test Method 9 - approx. 100 mm divisions, 50 mm in from each side
		// points = (w - 100) / 100 + 1 = w/100
		return Math.round(width / 100)
	} else {
		return asset.assetHorizontalReadings || 1
	}
}

export const AirBarrierContainmentTestBlankOutput = (asset: Asset) => {
	const horz = getBSCReadingsCount(asset)
	return {
		leaks: {
			horizontalPoints: horz,
			results: getGridResults(horz, 1, { passed: true }).map((gr) => ({ ...gr, passed: null })),
		},
		filterPressure: {
			initial: {
				measured: {
					mainFilter: '',
					exhaust: '',
				},
				fittedGauge: {
					mainFilter: '',
					exhaust: '',
				},
			},
			final: {
				measured: {
					mainFilter: '',
					exhaust: '',
				},
				fittedGauge: {
					mainFilter: '',
					exhaust: '',
				},
			},
		},
		exhaustFilterVelocity: {
			instrument: '',
			calibrated: '',
		},
		comment: '',
	}
}

export const UltraVioletRadiationTestBlankOutput = (asset: Asset) => {
	const horz = getLightingReadingsCount(asset)
	return {
		lightStatus: '',
		lightingReadings: {
			horizontalPoints: horz,
			results: getGridResults(horz, 1, { instrumentValue: '', calibratedValue: '', calibrationFactor: '' }),
			aggregateResults: {
				totalReady: '',
				averageReading: '',
				averageScaledReading: '',
				lowestAcceptableScaledReading: '',
			},
		},
	}
}

export const VelocityAndSmoketestBlankOutput = (asset: Asset) => {
	const horz = asset.assetHorizontalReadings || 1
	const vert = asset.assetVerticalReadings || 1
	return {
		velocity: {
			horizontalPoints: horz,
			verticalPoints: vert,
			gridResults: getGridResults(horz, vert, { instrument: { high: '', low: '' }, calibrated: { high: '', low: '', mean: '' } }),
			aggregateResults: {
				totalMeanValue: '',
				averageFlow: '',
			},
		},
		smoke: {
			smokeContained: '',
			averageFlowRequired: true,
			averageFlow: '',
		},
		comment: '',
	}
}

export const getBlankQuestionResponse = (questionVersionID: string) => {
	switch (questionVersionID) {
		case QUESTION_VERSION.AIR_BARRIER_CONTAINMENT_TEST.V1:
			return AirBarrierContainmentTestBlankOutput
		case QUESTION_VERSION.AIR_VELOCITY_TEST.V1:
			return AirVelocityTestBlankOutput
		case QUESTION_VERSION.FILTER_INTEGRITY_TEST.V1:
			return FilterInstallationIntegrityBlankOutput
		case QUESTION_VERSION.INWARD_AIR_VELOCITY_TEST.V1:
			return InwardAirVelocityBlankOutput
		case QUESTION_VERSION.LIGHTING_TEST.V1:
		case QUESTION_VERSION.FUME_LIGHTING_TEST.V1:
			return LightingTestBlankOutput
		case QUESTION_VERSION.SMOKE_TEST.V1:
			return SmokeTestBlankOutput
		case QUESTION_VERSION.SOUND_LEVEL_TEST.V1:
			return SoundTestBlankOutput
		case QUESTION_VERSION.ULTRA_VIOLET_TEST.V1:
			return UltraVioletRadiationTestBlankOutput
		case QUESTION_VERSION.VELOCITY_AND_SMOKE_TEST.V1:
			return VelocityAndSmoketestBlankOutput
		case QUESTION_VERSION.VELOCITY_TEST.V1:
			return VelocityTestBlankOutput
		case QUESTION_VERSION.WORK_ZONE_INTEGRITY_TEST.V1:
			return WorkZoneIntegrityTestBlankOutput
		default:
			return () => ({})
	}
}
