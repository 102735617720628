import React, { useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Entity, EntityResult } from '../../../../back-end/common/entity'
import { User, UserResult } from '../../../../back-end/common/user'
import * as Request from '../../utilities/request'
import { AppContext } from '../../App'
import { RouteComponentProps } from 'react-router'
import { Header } from '../../components/UI/Header/Header'
import { Card } from '../../components/UI/Card/Card'
import { PageStatus } from '../../types/PageStatus'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { FormText } from '../../components/UI/Form/Text'

interface ScreensEntityMatchParams {
	id: string
}

interface Settings {
	user: User
	entity: Entity
}

// eslint-disable-next-line
const ScreensSettings = (props: RouteComponentProps<ScreensEntityMatchParams>) => {
	const context = useContext(AppContext)
	const [messages, setMessages] = useMessageReducer([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [settings, setSettings] = React.useState<Settings | null>(null)

	React.useEffect(() => {
		const getData = async () => {
			const [user, entity] = await Promise.all([
				Request.get<UserResult>(`user?where=userID==${context.appState.userAttributes.userID}`, context.appState.authState),
				Request.get<EntityResult>(`entity?where=entityID==${context.appState.userAttributes.entityID}`, context.appState.authState),
			])

			setSettings({ user: user.data.users[0], entity: entity.data.entities[0] })
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, []) // eslint-disable-line

	const onSettingsEdit = async (settings: Settings) => {
		try {
			const req = await Request.put<UserResult>(`user/${settings.user.userID}`, settings.user, context.appState.authState)

			if (req.data.success) {
				setMessages({
					type: 'add',
					data: {
						severity: 'success',
						message: `Successfully edited user`,
						dismissible: true,
						timeout: 5000,
					},
				})
			} else {
				setMessages({
					type: 'add',
					data: {
						severity: 'danger',
						message: `An error occurred while editing the user`,
						dismissible: true,
						timeout: 5000,
					},
				})
			}
		} catch (err) {
			setMessages({
				type: 'add',
				data: {
					severity: 'danger',
					message: `An error occurred while editing the user`,
					dismissible: true,
					timeout: 5000,
				},
			})
		}
		setPageStatus('Ready')
	}

	const onUserChange = (e: { target: { name: string; value: string } }) => {
		const property = e.target.name
		const value = e.target.value

		if (settings) {
			const user = { ...settings.user, [property]: value }
			setSettings({ ...settings, user: user })
		}
	}

	return (
		<div className="page">
			<Messages messages={messages} updateMessage={setMessages} />
			<Header title={'Settings'} subtitle={''} />

			<Card
				title={settings ? `${settings?.entity.entityName}, (${settings?.user.firstName} ${settings?.user.lastName})` : 'Loading...'}
				collapsible={true}
				headerComponent={() =>
					pageStatus !== 'Loading' && settings ? (
						pageStatus === 'Ready' ? (
							<Row className="justify-content-end">
								<Col sm="auto">
									<Button className="btn btn-secondary" disabled={true} onClick={() => setPageStatus('Editing')}>
										Edit User
									</Button>
								</Col>
							</Row>
						) : (
							<Row className="justify-content-end">
								<Col sm={'auto'}>
									<Button className="btn btn-secondary" onClick={() => onSettingsEdit(settings)}>
										Save
									</Button>
								</Col>
								<Col sm={'auto'}>
									<Button
										className="btn btn-secondary"
										onClick={() => {
											setPageStatus('Ready')
										}}
									>
										Cancel
									</Button>
								</Col>
								<Col sm={'auto'}>{/* <DeleteButton id={entity.entityID} onClick={onEntityDelete} /> */}</Col>
							</Row>
						)
					) : (
						<></>
					)
				}
			>
				<SettingsDetails settings={settings} onChange={onUserChange} pageStatus={pageStatus} />
			</Card>
		</div>
	)
}

interface SettingsDetailsProps {
	settings: Settings | null
	onChange: (e: {
		target: {
			name: string
			value: string
		}
	}) => void
	pageStatus: PageStatus
}

const SettingsDetails = (props: SettingsDetailsProps) => {
	const readOnly = props.pageStatus !== 'Editing'

	if (props.settings) {
		return (
			<div style={{ flexDirection: 'column' }}>
				<div>
					<Row>
						<Col>
							<FormText
								name={'email'}
								value={props.settings.user.email}
								label={'Email'}
								onChange={props.onChange}
								plaintext={readOnly}
								disabled={readOnly}
								required
								feedback={'Email is required.'}
							/>
						</Col>
						<Col>
							<FormText
								name={'firstName'}
								value={props.settings.user.firstName}
								label={'First Name'}
								onChange={props.onChange}
								plaintext={readOnly}
								disabled={readOnly}
								required
								feedback={'First Name is required.'}
							/>
						</Col>
						<Col>
							<FormText
								name={'lastName'}
								value={props.settings.user.lastName}
								label={'Last Name'}
								onChange={props.onChange}
								plaintext={readOnly}
								disabled={readOnly}
								required
								feedback={'Last Name is required.'}
							/>
						</Col>
					</Row>
				</div>
			</div>
		)
	}
	return null
}

export { ScreensSettings, SettingsDetails }
