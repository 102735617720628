import React, { useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { AppContext } from '../../App'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Header } from '../../components/UI/Header/Header'
import { Listing } from '../../components/UI/Listing/Listing'
import { PageStatus } from '../../types/PageStatus'
import { InstrumentSet, InstrumentSetResult } from '../../../../back-end/common/instrumentSet'

import * as Request from '../../utilities/request'
import { formatDateTime } from '../../utilities/formatDate'

import addIcon from '../../images/icons/add.svg'

interface EntityInstrumentsHandlerProps {
	pageProps: RouteComponentProps
	entityID: string
}

const EntityInstruments = (props: EntityInstrumentsHandlerProps) => {
	const context = useContext(AppContext)

	const [entityInstruments, setEntityInstruments] = React.useState<InstrumentSet[] | null>(null)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	React.useEffect(() => {
		const getData = async () => {
			const [entityInstrumentsResponse] = await Promise.all([Request.get<InstrumentSetResult>(`instrumentset`, context.appState.authState)])
			const entityInstruments = entityInstrumentsResponse.data.instrumentSets

			// Because we filter and search by timestamp, convert it into human readable form here so the readable form is searchable
			const entityInstrumentsFormatted = entityInstruments.map((ei: InstrumentSet) => {
				return { ...ei, modifiedTs: formatDateTime({ date: ei.modified.ts, format: 'DateAndTimeAndTimezone' }) }
			})

			setEntityInstruments(entityInstrumentsFormatted)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, []) // eslint-disable-line

	if (entityInstruments !== null) {
		// const instrumentsFlat = entityInstruments.flatMap((ei) => ei.instruments)

		return (
			<div>
				<InstrumentSets pageProps={props.pageProps} entityInstruments={entityInstruments} pageStatus={pageStatus} />
				{/* <Instruments pageProps={props.pageProps} entityInstruments={instrumentsFlat} pageStatus={pageStatus} /> */}
			</div>
		)
	}
	return null
}

// interface InstrumentProps {
// 	pageProps: RouteComponentProps
// 	entityInstruments: Pick<Instrument, 'instrumentID' | 'instrumentTypeID' | 'instrumentTypeName' | 'instrumentSerialNumber'>[]
// 	pageStatus: PageStatus
// }

// const Instruments = (props: InstrumentProps) => {
// 	const instrumentTypes =
// 		props.entityInstruments?.reduce((accumulator, instrument) => {
// 			const typeIndex = accumulator.findIndex((acc) => acc.value === instrument.instrumentTypeID)
// 			if (typeIndex < 0) {
// 				accumulator.push({ value: instrument.instrumentTypeID, text: instrument.instrumentTypeName })
// 			}
// 			return accumulator
// 		}, [] as FilterDropdownOption[]) || []

// 	if (props.entityInstruments !== null) {
// 		return (
// 			<div id={'EntityInstruments'}>
// 				<div id={'EntityInstrumentsHeader'}>
// 					<Row>
// 						<Col>
// 							<Header title={`Instruments (${props.entityInstruments.length})`} subtitle={''} />
// 						</Col>
// 						<Col sm={'auto'} style={{ paddingTop: '40px' }}>
// 							<Button className=" btn btn-primary round" onClick={() => props.pageProps.history.push('/instrument/new')}>
// 								<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
// 								<span className="span-bold" style={{ color: 'white' }}>
// 									Add an Instrument
// 								</span>
// 							</Button>
// 						</Col>
// 					</Row>
// 				</div>
// 				<div id={'EntityInstrumentsBody'}>
// 					<Listing
// 						name="Instrument"
// 						namePlural="Instruments"
// 						list={props.entityInstruments || []}
// 						getIDFunc={(item) => item.instrumentID}
// 						handleDeleteSelected={async (selected) => {}}
// 						columns={[
// 							{
// 								value: (item) => item.instrumentSerialNumber,
// 								render: (item) => <>{item.instrumentSerialNumber}</>,
// 								showHeader: true,
// 								headerText: 'Serial',
// 								sortColumnName: 'instrumentSerialNumber',
// 								filterType: 'string',
// 								filterOptions: {
// 									columnName: 'instrumentSerialNumber',
// 								},
// 							},
// 							{
// 								value: (item) => item.instrumentTypeID,
// 								render: (item) => <>{item.instrumentTypeName}</>,
// 								showHeader: true,
// 								headerText: 'Type',
// 								sortColumnName: 'instrumentTypeName',
// 								filterType: 'dropdown',
// 								filterOptions: {
// 									columnName: 'instrumentTypeName',
// 									options: instrumentTypes,
// 								},
// 							},
// 						]}
// 						defaultSort={{ column: 'instrumentSerialNumber', order: 'ASC' }}
// 						isLoading={props.pageStatus === 'Loading'}
// 					/>
// 				</div>
// 			</div>
// 		)
// 	}
// 	return null
// }

interface InstrumentSetProps {
	pageProps: RouteComponentProps
	entityInstruments: InstrumentSet[]
	pageStatus: PageStatus
}

const InstrumentSets = (props: InstrumentSetProps) => {
	if (props.entityInstruments !== null) {
		return (
			<div id={'EntityInstruments'}>
				<div id={'EntityInstrumentsHeader'}>
					<Row>
						<Col>
							<Header title={`Instrument Sets (${props.entityInstruments.length})`} subtitle={''} />
						</Col>
						<Col sm={'auto'} style={{ paddingTop: '40px' }}>
							<Button className=" btn btn-primary round" onClick={() => props.pageProps.history.push('/instrumentset/new')}>
								<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
								<span className="span-bold" style={{ color: 'white' }}>
									Add an Instrument kit
								</span>
							</Button>
						</Col>
					</Row>
				</div>
				<div id={'EntityInstrumentsBody'}>
					<Listing
						name="Instrument Set"
						namePlural="Instrument Sets"
						list={props.entityInstruments || []}
						getIDFunc={(item) => item.instrumentSetID}
						selectedActions={[]}
						columns={[
							{
								value: (item) => item.instrumentSetName,
								render: (item) => (
									<Link
										to={{
											pathname: `/instrumentset/${item.instrumentSetID}`,
											state: { backPath: props.pageProps.location.pathname, backName: 'Instruments' },
										}}
									>
										{item.instrumentSetName}
									</Link>
								),
								showHeader: true,
								headerText: 'Name',
								sortColumnName: 'instrumentSetName',
								filterType: 'string',
								filterOptions: {
									columnName: 'instrumentSetName',
								},
							},
							{
								value: (item) => item.instrumentSetOwner,
								render: (item) => <>{item.instrumentSetOwner}</>,
								showHeader: true,
								headerText: 'Owner',
								sortColumnName: 'instrumentSetOwner',
								filterType: 'string',
								filterOptions: {
									columnName: 'instrumentSetOwner',
								},
							},
						]}
						defaultSort={{ column: 'instrumentSetName', order: 'ASC' }}
						isLoading={props.pageStatus === 'Loading'}
					/>
				</div>
			</div>
		)
	}
	return null
}

export { EntityInstruments }
