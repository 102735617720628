import { DateTime } from 'luxon'

type DateDisplay = 'Date' | 'DateAndTime' | 'DateAndTimeAndTimezone' | 'DateAndTimeIfNotMidnight' | 'Time'

type FormatDateTimeProps = {
	date?: string
	format: DateDisplay
}

// for ActionStartTs and InstanceWhenTs
const validateDate = (date: Date, timezone: string): string => {
	date = new Date(date)
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()
	const dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}T00:00:00.000`
	const momentDate = DateTime.fromISO(dateString).setZone(timezone).plus({ hours: 24 }).toUTC().toFormat('YYYY-MM-DDTHH:mm:ss.SSS')
	const dateWithoutTimezone = momentDate.substring(0, 24) + 'Z'
	return dateWithoutTimezone
}

const formatDateTime = (props: FormatDateTimeProps) => {
	const now = DateTime.utc().toISO()
	const userTimezone = DateTime.local().zoneName

	const timeIsMidnight =
		DateTime.fromISO(props.date || now).hour === 0 && DateTime.fromISO(props.date || now).minute === 0 && DateTime.fromISO(props.date || now).second === 0

	const date = timeIsMidnight
		? DateTime.fromISO(props.date || now)
				.minus({ seconds: 1 })
				.toISO()
		: DateTime.fromISO(props.date || now).toISO()

	switch (props.format) {
		case 'Date':
			return DateTime.fromISO(date).toFormat('dd-MMM-yyyy')
		case 'DateAndTime':
			return DateTime.fromISO(date).toFormat('dd-MMM-yyyy hh:mma')
		case 'DateAndTimeAndTimezone':
			return `${DateTime.fromISO(date).toFormat('dd-MMM-yyyy hh:mma')}\n${userTimezone}`
		case 'DateAndTimeIfNotMidnight':
			return DateTime.fromISO(date).toFormat(timeIsMidnight ? 'dd-MMM-yyyy' : 'dd-MMM-yyyy hh:mma')
		case 'Time':
			return timeIsMidnight ? '' : DateTime.fromISO(date).toFormat('hh:mma')
		default:
			return ''
	}
}

export { formatDateTime, validateDate }
