import React, { useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { AppContext } from '../../App'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Header } from '../../components/UI/Header/Header'
import { PageStatus } from '../../types/PageStatus'
import { Test, TestResult } from '../../../../back-end/common/test'
import { Listing } from '../../components/UI/Listing/Listing'
import * as Request from '../../utilities/request'

import addIcon from '../../images/icons/add.svg'
import { ScreensAssetMatchParams } from './AssetDetails'
import { formatDateTime } from '../../utilities/formatDate'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { canWriteTest } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'
import { DeleteModal } from '../../components/UI/DeleteModal/deleteModal'

interface TestReportsProps {
	pageProps: RouteComponentProps<ScreensAssetMatchParams>
	assetIsActive: boolean
}

const TestReports = (props: TestReportsProps) => {
	const context = useContext(AppContext)
	const assetID = props.pageProps.match.params.id

	const [testReports, setTestReports] = React.useState<Test[] | null>(null)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')
	const [toDelete, setToDelete] = React.useState<string[]>([])
	const [messages, setMessages] = useMessageReducer([])

	React.useEffect(() => {
		const getData = async () => {
			const [testsResponse] = await Promise.all([Request.get<TestResult>(`test?where=assetID==${assetID}&minify`, context.appState.authState)])
			setTestReports(testsResponse.data.tests)
			setPageStatus('Ready')
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, []) // eslint-disable-line

	const handleDeleteSelected = async (selected: string[]) => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.del<TestResult>(`test?where=testID=in(${selected.join(',')})`, context.appState.authState), {
			successFunction: () => {
				setToDelete([])
				setTestReports(testReports?.filter((test) => !selected.includes(test.testID)) || [])
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'tests',
		})
	}

	if (testReports !== null) {
		return (
			<div id={'TestReports'}>
				<div id={'TestReportsHeader'}>
					<Row>
						<Col>
							<Header title={`Test Reports (${testReports.length})`} subtitle={''} />
						</Col>
						{canWriteTest(appStateToPermissionObject(context.appState)) && (
							<Col sm={'auto'} style={{ paddingTop: '40px' }}>
								<Button
									className=" btn btn-primary round"
									onClick={() =>
										props.pageProps.history.push(`/test/new/${assetID}`, {
											backPath: props.pageProps.location.pathname,
											backName: 'Asset',
										})
									}
									disabled={!props.assetIsActive}
								>
									<img src={addIcon} style={{ marginRight: '15px', marginBottom: '3px' }} alt={'A Plus Icon'}></img>
									<span className="span-bold" style={{ color: 'white' }}>
										Create new test report
									</span>
								</Button>
							</Col>
						)}
					</Row>
					<DeleteModal
						show={toDelete.length > 0}
						noun="Tests"
						onDeletePress={() => handleDeleteSelected(toDelete)}
						onNoPress={() => setToDelete([])}
					/>
					<Listing
						name="Test"
						namePlural="Tests"
						list={testReports || []}
						getIDFunc={(item) => item.testID}
						selectedActions={
							canWriteTest(appStateToPermissionObject(context.appState))
								? [{ name: 'Delete selected', method: (selected) => setToDelete(selected) }]
								: undefined
						}
						columns={[
							{
								value: (item) => item.performedTs,
								render: (item) => (
									<Link to={{ pathname: `/test/${item.testID}`, state: { backPath: props.pageProps.location.pathname, backName: 'Asset' } }}>
										{formatDateTime({ date: item.performedTs, format: 'Date' })}
									</Link>
								),
								showHeader: true,
								headerText: 'Test date',
								sortColumnName: 'testPerformedTs',
							},
							{
								value: (item) => item.create.ts,
								render: (item) => <>{formatDateTime({ date: item.create.ts, format: 'DateAndTime' })}</>,
								showHeader: true,
								headerText: 'Created at',
								sortColumnName: 'testCreatedTs',
							},
							{
								value: (item) => (item.isLocked ? 'Locked' : ' '),
								render: (item) => <>{item.isLocked ? 'Locked' : ' '}</>,
								showHeader: true,
								headerText: 'Locked',
								sortColumnName: 'testIsLocked',
								filterType: 'dropdown',
								filterOptions: {
									columnName: 'testIsLocked',
									options: [
										{ value: 'Locked', text: 'Locked' },
										{ value: ' ', text: 'Not locked' },
									],
								},
							},
							{
								value: (item) => (item.isLocked ? (item.passed || item.testForcePassed ? 'Pass' : 'Fail') : '-'),
								render: (item) => <>{item.isLocked ? (item.passed || item.testForcePassed ? 'Pass' : 'Fail') : '-'}</>,
								showHeader: true,
								headerText: 'Result',
								sortColumnName: 'testPassed',
								filterType: 'dropdown',
								filterOptions: {
									columnName: 'testPassed',
									options: [
										{ value: 'Pass', text: 'Pass' },
										{ value: 'Fail', text: 'Fail' },
										{ value: '-', text: '-' },
									],
								},
							},
						]}
						defaultSort={{ column: 'testCreatedTs', order: 'DSC' }}
						isLoading={pageStatus === 'Loading'}
					/>
					<Messages messages={messages} updateMessage={setMessages} />
				</div>
			</div>
		)
	}
	return null
}

export { TestReports }
