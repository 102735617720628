import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Row, Col, Form } from 'react-bootstrap'
import { FormText } from '../../../components/UI/Form/Text'
import { TestProps } from './QuestionLookup.d'
import { Formik } from 'formik'
import * as yup from 'yup'
import { STANDARD_ASSET_TYPE } from 'dynaflow/constants/assetType'
import { onQuestionSubmit, QuestionNavigationButtons, useLoadExistingQuestionResponse, QuestionHeader } from './QuestionComponents'
import { QuestionElement, QuestionResult, TabRow } from './QuestionComponents'
import { Asset } from '../../../../../back-end/common/asset'

const keyToLabel = (key: string, asset: Asset) => {
	let assetTypeString = ''
	switch (asset.parentAssetTypeID || asset.assetTypeID) {
		case STANDARD_ASSET_TYPE.FUME_CUPBOARD:
			assetTypeString = 'Cupboard'
			break
		case STANDARD_ASSET_TYPE.FUME_CABINET:
			assetTypeString = 'Cabinet'
			break
		default:
			break
	}
	return {
		faceOpeningAirflowPattern: 'Face opening airflow pattern',
		assetAirflowPattern: `${assetTypeString} airflow pattern`,
		containmentAlongBottomOfAsset: `Containment along bottom of ${assetTypeString?.toLowerCase()}`,
		containmentAlongWallsOfAsset: `Containment along walls of ${assetTypeString?.toLowerCase()}`,
		containmentWithSashFullyOpen: 'Containment with sash fully open',
		containmentWithSashHalfOpen: 'Containment with sash half open',
		containmentWithSashAtMinimumOpening: 'Containment with sash at minimum opening',
		containmentUnderAbnormalConditions: 'Containment under abnormal conditions',
	}[key]
}

interface positionResponse {
	radioSelect: 'good' | 'satisfactory' | 'dangerous' | ''
	comment: string
}

interface QuestionValues {
	testPosition: {
		faceOpeningAirflowPattern: positionResponse
		assetAirflowPattern: positionResponse
		containmentAlongBottomOfAsset: positionResponse
		containmentAlongWallsOfAsset: positionResponse
		containmentWithSashFullyOpen: positionResponse
		containmentWithSashHalfOpen: positionResponse
		containmentWithSashAtMinimumOpening: positionResponse
		containmentUnderAbnormalConditions: positionResponse
	}
	assetEmpty: string
}

const positionResponseValidation = yup.object().shape({
	radioSelect: yup.string().oneOf(['good', 'satisfactory', 'dangerous']).required(),
	comment: yup.string().optional(),
})

const smokeTestInputValidation = yup.object().shape({
	testPosition: yup.object().shape({
		faceOpeningAirflowPattern: positionResponseValidation,
		assetAirflowPattern: positionResponseValidation,
		containmentAlongBottomOfAsset: positionResponseValidation,
		containmentAlongWallsOfAsset: positionResponseValidation,
		containmentWithSashFullyOpen: positionResponseValidation,
		containmentWithSashHalfOpen: positionResponseValidation,
		containmentWithSashAtMinimumOpening: positionResponseValidation,
		containmentUnderAbnormalConditions: positionResponseValidation,
	}),
	assetEmpty: yup.string().oneOf(['yes', 'no']).required(),
})

const SmokeTest = (props: TestProps) => {
	const context = useContext(AppContext)
	const submitCallback = React.useRef(() => {})

	const [questionValues, setQuestionValues] = React.useState<QuestionValues>({
		testPosition: {
			faceOpeningAirflowPattern: { radioSelect: '', comment: '' },
			assetAirflowPattern: { radioSelect: '', comment: '' },
			containmentAlongBottomOfAsset: { radioSelect: '', comment: '' },
			containmentAlongWallsOfAsset: { radioSelect: '', comment: '' },
			containmentWithSashFullyOpen: { radioSelect: '', comment: '' },
			containmentWithSashHalfOpen: { radioSelect: '', comment: '' },
			containmentWithSashAtMinimumOpening: { radioSelect: '', comment: '' },
			containmentUnderAbnormalConditions: { radioSelect: '', comment: '' },
		},
		assetEmpty: '',
	})

	useLoadExistingQuestionResponse({
		...props,
		setQuestionValues,
	})

	let smokeUsed: string

	switch (props.asset?.parentAssetTypeID || props.asset?.assetTypeID) {
		case STANDARD_ASSET_TYPE.FUME_CUPBOARD:
		case STANDARD_ASSET_TYPE.FUME_CABINET:
			smokeUsed = 'Drager tube'
			break
		case STANDARD_ASSET_TYPE.BIOLOGICAL_SAFETY_CABINET_1:
		case STANDARD_ASSET_TYPE.BIOLOGICAL_SAFETY_CABINET_2:
		case STANDARD_ASSET_TYPE.LAMINAR_FLOW:
			smokeUsed = 'Aerosol generator'
			break
		default:
			smokeUsed = 'Unknown'
			break
	}

	return (
		<Formik
			initialValues={questionValues}
			validationSchema={smokeTestInputValidation}
			validateOnChange={false}
			validateOnBlur={true}
			onSubmit={(values) => {
				onQuestionSubmit({ ...props, values, authState: context.appState.authState, submitCallback, params: { smokeUsed } })
			}}
			enableReinitialize={true}
		>
			{({ handleSubmit, errors, values, handleChange, setFieldValue, handleReset, dirty, isSubmitting }) => {
				let failed = null

				const completedFields = Object.keys(values.testPosition).filter(
					(key) => values.testPosition[key as keyof typeof values.testPosition].radioSelect !== ''
				)
				if (completedFields.length === Object.keys(values.testPosition).length) {
					failed = false
					Object.values(values.testPosition).forEach((value) => {
						if (value.radioSelect === 'dangerous') {
							failed = true
						}
					})
				}
				// Haven't selected the asset empty question, so incomplete
				if (values.assetEmpty === '') {
					failed = null
				}

				return (
					<>
						<TabRow
							tabSelectOptions={props.tabSelectOptions}
							onClick={(q, save) => {
								if (save) {
									submitCallback.current = () => props.goToQuestion(q)
									handleSubmit()
								} else {
									props.goToQuestion(q)
								}
							}}
							currentQuestion={props.question?.questionID}
							dirty={dirty}
							isSubmitting={isSubmitting}
							errors={errors}
						/>
						<Row>
							<Col>
								<QuestionHeader question={props.question} />
								<QuestionElement>
									<div className="mb-3" style={{ display: 'grid', gridTemplateColumns: '330px 120px 120px 120px 300px' }}>
										<div className="font-weight-bold">Test position</div>
										<div className="text-center font-weight-bold">Good</div>
										<div className="text-center font-weight-bold">Satisfactory</div>
										<div className="text-center font-weight-bold">Dangerous</div>
										<div className="font-weight-bold mb-2">Comment</div>
										{Object.entries(values.testPosition).map(([key, value]) => {
											const rowError =
												(errors?.testPosition && !!errors?.testPosition[key as keyof typeof errors.testPosition]?.radioSelect) || false
											return (
												<React.Fragment key={key}>
													<div className={`m-1 ${rowError && 'border border-danger rounded'}`}>
														{keyToLabel(key as keyof typeof keyToLabel, props.asset!)}
													</div>
													<Form.Check
														style={{ justifyContent: 'center' }}
														className="radio-large"
														inline
														type="radio"
														value={'good'}
														checked={value.radioSelect === 'good'}
														onChange={() => setFieldValue(`testPosition.${key}.radioSelect`, 'good')}
														name={`testPosition.${key}.radioSelect`}
														disabled={!!props.test?.isLocked}
													/>
													<Form.Check
														style={{ justifyContent: 'center' }}
														className="radio-large"
														inline
														type="radio"
														value={'satisfactory'}
														checked={value.radioSelect === 'satisfactory'}
														onChange={() => setFieldValue(`testPosition.${key}.radioSelect`, 'satisfactory')}
														name={`testPosition.${key}.radioSelect`}
														disabled={!!props.test?.isLocked}
													/>
													<Form.Check
														style={{ justifyContent: 'center' }}
														className="radio-large"
														inline
														type="radio"
														value={'dangerous'}
														checked={value.radioSelect === 'dangerous'}
														onChange={() => setFieldValue(`testPosition.${key}.radioSelect`, 'dangerous')}
														name={`testPosition.${key}.radioSelect`}
														disabled={!!props.test?.isLocked}
													/>
													<FormText
														name={`testPosition.${key}.comment`}
														value={value.comment || ''}
														disabled={!!props.test?.isLocked}
														onChange={handleChange}
													/>
												</React.Fragment>
											)
										})}
									</div>
									<Col>
										<Row>
											{props.asset?.assetTypeName} empty:
											<Form.Group controlId="assetEmptyYes">
												<Form.Check
													className="ml-3 radio-large"
													inline
													label="Yes"
													type="radio"
													value={'yes'}
													checked={values.assetEmpty === 'yes'}
													onChange={() => setFieldValue(`assetEmpty`, 'yes')}
													name={`assetEmptyYes`}
													disabled={!!props.test?.isLocked}
												/>
											</Form.Group>
											<Form.Group controlId="assetEmptyNo">
												<Form.Check
													className="ml-3 radio-large"
													inline
													label="No"
													type="radio"
													value={'no'}
													checked={values.assetEmpty === 'no'}
													onChange={() => setFieldValue(`assetEmpty`, 'no')}
													name={`assetEmptyNo`}
													disabled={!!props.test?.isLocked}
												/>
											</Form.Group>
										</Row>
										{errors.assetEmpty && (
											<Row>
												<span className="text-danger">Select an option</span>
											</Row>
										)}
										{values.assetEmpty === 'no' && props.test?.photoIsApplicable === false && (
											<Row>
												<span className="text-warning">You must attach a photo on the setup screen.</span>
											</Row>
										)}
									</Col>
								</QuestionElement>
								<QuestionResult
									failed={failed}
									questionHasComment={false}
									questionHasForcePass={false}
									isStandardsBased={props.asset?.assetTypeIsStandardsBased}
								/>
								<QuestionNavigationButtons
									{...props}
									handleReset={handleReset}
									handleSubmit={handleSubmit}
									submitCallback={submitCallback}
									setSubmitCallback={(fn) =>
										values.assetEmpty === 'no' && props.test?.photoIsApplicable === false
											? (submitCallback.current = () => props.goToQuestion('Setup'))
											: (submitCallback.current = fn)
									}
									dirty={dirty}
									isSubmitting={isSubmitting}
								/>
							</Col>
						</Row>
					</>
				)
			}}
		</Formik>
	)
}

export { SmokeTest }
