import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

interface MultiTextProps<T> {
	values: T[]
	valueComponent: (value: T) => JSX.Element
	onNewValue: (value: string) => void
	disabled: boolean
	placeholder: string
	id: string
	name: string
}

const MultiText = <T,>(props: MultiTextProps<T>) => {
	const [newValue, setNewValue] = React.useState<string>('')

	const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		const rawValue = e.target.value

		if (rawValue.includes(',')) {
			const value = rawValue.replaceAll(',', '').trim()

			if (value !== '') {
				props.onNewValue(value)
			}
			setNewValue('')
		} else {
			setNewValue(rawValue)
		}
	}

	return (
		<Row>
			<Col>
				<Form.Control
					onChange={onChange}
					type="text"
					id={props.id}
					name={props.name}
					placeholder={props.placeholder}
					disabled={props.disabled}
					value={newValue}
				/>
			</Col>
			<Col> {props.values.map(props.valueComponent)}</Col>
		</Row>
	)
}

export default MultiText
export type { MultiTextProps }
