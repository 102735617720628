import React, { useContext } from 'react'
import { AppContext } from '../../../App'
import { Row, Col, Alert } from 'react-bootstrap'
import { TestProps } from './QuestionLookup.d'
import { Formik } from 'formik'
import { FormText } from '../../../components/UI/Form/Text'
import { roundTo } from 'dynaflow/utilities/calibration'
import { SoundTestOutput } from 'dynaflow/utilities/question'
import * as yup from 'yup'
import { getPassValueOverrides } from 'dynaflow/utilities/testOverride'
import {
	onQuestionSubmit,
	QuestionElement,
	QuestionNavigationButtons,
	QuestionResult,
	QuestionResultElementGroup,
	TabRow,
	QuestionHeader,
	useLoadExistingQuestionResponse,
} from './QuestionComponents'

interface QuestionValues {
	operatingSoundLevel: string
	ambientSoundLevel: string
	roomDimensions: string
	roomLayout: string
	surfaceFinishes: string
	otherFactors: string
}

const validationSchema = yup.object().shape({
	operatingSoundLevel: yup.number().required('Required'),
	ambientSoundLevel: yup.number().required('Required'),
	roomDimensions: yup.string().required('Required'),
	roomLayout: yup.string().required('Required'),
	surfaceFinishes: yup.string().required('Required'),
	otherFactors: yup.string().optional(),
})

const SoundLevelTest = (props: TestProps) => {
	const { test } = props
	const context = useContext(AppContext)
	const submitCallback = React.useRef(() => {})

	const [questionValues, setQuestionValues] = React.useState<QuestionValues>({
		operatingSoundLevel: '',
		ambientSoundLevel: '',
		roomDimensions: '',
		roomLayout: '',
		surfaceFinishes: '',
		otherFactors: '',
	})

	useLoadExistingQuestionResponse({
		...props,
		setQuestionValues,
		formatQuestionValues: React.useCallback((existingValues: SoundTestOutput): QuestionValues => {
			return {
				...existingValues,
				operatingSoundLevel: existingValues.operatingSoundLevel.toString(),
				ambientSoundLevel: existingValues.ambientSoundLevel.toString(),
			}
		}, []),
	})

	const PASS_VALUES = getPassValueOverrides(props.question)

	if (!PASS_VALUES) {
		return null
	}

	return (
		<Formik
			initialValues={questionValues}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				onQuestionSubmit({ ...props, values, authState: context.appState.authState, submitCallback })
			}}
			enableReinitialize={true}
		>
			{({ handleSubmit, errors, values, handleChange, handleReset, dirty, isSubmitting }) => {
				let failed = null
				let difference
				let correction = 0
				let correctedSoundLevel
				if (values.operatingSoundLevel && values.ambientSoundLevel) {
					failed = false
					difference = roundTo(Number(values.operatingSoundLevel) - Number(values.ambientSoundLevel), 0)
					correction = 0
					if (difference >= 10) {
						correction = 0
					} else if (difference >= 6) {
						correction = -1
					} else if (difference >= 4) {
						correction = -2
					}
					correctedSoundLevel = roundTo(Number(values.operatingSoundLevel) + correction, 0)
					if (correctedSoundLevel >= PASS_VALUES.maximumSoundLevel) {
						failed = true
					}
				}

				return (
					<>
						<TabRow
							tabSelectOptions={props.tabSelectOptions}
							onClick={(q, save) => {
								if (save) {
									submitCallback.current = () => props.goToQuestion(q)
									handleSubmit()
								} else {
									props.goToQuestion(q)
								}
							}}
							currentQuestion={props.question?.questionID}
							dirty={dirty}
							isSubmitting={isSubmitting}
							errors={errors}
						/>
						<Row>
							<Col>
								<QuestionHeader question={props.question} />
								<QuestionElement title="Sound reading">
									<Alert variant="warning" className="text-dark">
										Measure reading at operator work height
									</Alert>
									<Col>
										<Row className="mb-3">
											<Col className="col-sm-12 col-md-6">
												<FormText
													name="operatingSoundLevel"
													value={values.operatingSoundLevel}
													label="Operating (dB)"
													type="number"
													min={0}
													onChange={handleChange}
													disabled={!!test?.isLocked}
													feedback={errors?.operatingSoundLevel}
													isInvalid={!!errors?.operatingSoundLevel}
												/>
											</Col>
											<Col className="col-sm-12 col-md-6">
												<FormText
													name="ambientSoundLevel"
													value={values.ambientSoundLevel}
													label="Ambient (dB)"
													type="number"
													min={0}
													onChange={handleChange}
													disabled={!!test?.isLocked}
													feedback={errors?.ambientSoundLevel}
													isInvalid={!!errors?.ambientSoundLevel}
												/>
											</Col>
										</Row>
										<QuestionResultElementGroup
											center={true}
											results={[
												{ title: 'Difference', value: `${difference?.toFixed(0) || '-'} dB` },
												{ title: 'Correction', value: `${difference !== undefined ? correction.toFixed(0) : '-'} dB` },
												{ title: 'Corrected', value: `${correctedSoundLevel?.toFixed(0) || '-'} dB` },
											]}
										/>
									</Col>
								</QuestionElement>
								<QuestionElement title="Factors which may affect results">
									<Col>
										<Row className="mb-3">
											<Col>
												<FormText
													name="roomDimensions"
													value={values.roomDimensions}
													label="Room dimensions L (m) × W (m)"
													onChange={handleChange}
													disabled={!!test?.isLocked}
													isInvalid={!!errors?.roomDimensions}
													feedback={errors?.roomDimensions}
												/>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col>
												<FormText
													name="roomLayout"
													value={values.roomLayout}
													label="Room layout"
													onChange={handleChange}
													disabled={!!test?.isLocked}
													isInvalid={!!errors?.roomLayout}
													feedback={errors?.roomLayout}
												/>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col>
												<FormText
													name="surfaceFinishes"
													value={values.surfaceFinishes}
													label="Surface finishes"
													onChange={handleChange}
													disabled={!!test?.isLocked}
													isInvalid={!!errors?.surfaceFinishes}
													feedback={errors?.surfaceFinishes}
												/>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col>
												<FormText
													name="otherFactors"
													value={values.otherFactors}
													label="Other factors affecting results"
													onChange={handleChange}
													disabled={!!test?.isLocked}
												/>
											</Col>
										</Row>
									</Col>
								</QuestionElement>
								<QuestionResult
									failed={failed}
									questionHasComment={false}
									questionHasForcePass={false}
									isStandardsBased={props.asset?.assetTypeIsStandardsBased}
								/>
								<QuestionNavigationButtons
									{...props}
									handleReset={handleReset}
									handleSubmit={handleSubmit}
									submitCallback={submitCallback}
									setSubmitCallback={(fn) => (submitCallback.current = fn)}
									dirty={dirty}
									isSubmitting={isSubmitting}
								/>
							</Col>
						</Row>
					</>
				)
			}}
		</Formik>
	)
}

export { SoundLevelTest }
