import React, { useContext } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { RouteComponentProps, StaticContext } from 'react-router'
import { Formik, FormikErrors } from 'formik'
import * as yup from 'yup'

import { Calibration, CalibrationResult } from '../../../../back-end/common/calibration'
import { CalibrationItem } from '../../../../back-end/common/calibrationItem'
import { Instrument, InstrumentResult } from '../../../../back-end/common/instruments'
import { InstrumentSet, InstrumentSetResult } from '../../../../back-end/common/instrumentSet'
import { InstrumentType, InstrumentTypeResult } from '../../../../back-end/common/instrumentType'

import { InstrumentCalibration } from './InstrumentCalibration'
import { DropdownSelect } from '../../components/Forms/Dropdown'
import { FormText } from '../../components/UI/Form/Text'
import { Card } from '../../components/UI/Card/Card'
import { DeleteButton } from '../../components/UI/Form/DeleteButton'
import { Header } from '../../components/UI/Header/Header'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'

import { AppContext } from '../../App'
import * as EntityType from '../../constants/entityType'
import { PageStatus } from '../../types/PageStatus'
import { PageBackState } from '../../types/PageBackState'
import * as Request from '../../utilities/request'
import { getUrlSearchParam } from '../../utilities/url'
import { HistoryCardBottom } from '../../components/UI/HistoryCardBottom/HistoryCardBottom'
import { canWriteInstrument } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'

type InstrumentErrorMessages = FormikErrors<Partial<Instrument>>

const instrumentValidationSchema = yup.object().shape({
	instrumentID: yup.string().optional(),
	instrumentSerialNumber: yup.string().required('Serial No. is required'),
	instrumentTypeID: yup.string().required('Type is required'),
	instrumentMake: yup.string().required('Make is required'),
	instrumentModel: yup.string().required('Model is required'),
	instrumentAssetNumber: yup.string().required('Asset No. is required'),
	instrumentIsActive: yup.boolean().required('Active is required'),
	instrumentSetID: yup.string().required('Set is required'),
	instrumentNotes: yup.string().optional(),
})

const calibrationSchema = yup.object().shape({
	calibrationCertificateFileName: yup.string().optional(),
	calibrationCertificateNumber: yup.string().required('Certificate Number is required'),
	calibrationCertificateS3Path: yup.string().optional(),
	calibrationDate: yup.date().required('Calibration Date is required'),
	calibrationItems: yup
		.array()
		.of(
			yup.object().shape({
				calibrationItemInstrumentReading: yup.number().required().min(0, 'Instrument Reading must be above 0'),
				calibrationItemReferenceNumber: yup.number().required().min(0, 'Reference Number must be above 0'),
				calibrationItemReferenceReading: yup.number().required().min(0, 'Referece Reading must be above 0'),
			})
		)
		.min(1, 'Must have at least 1 calibration item'),
})

interface ScreensInstrumentMatchParams {
	id: string
}
interface ScreensInstrumentStateParams extends PageBackState {}

const newInstrument = (instrument: Partial<Instrument>): Partial<Instrument> => ({
	instrumentID: instrument.instrumentID || undefined,
	instrumentSerialNumber: instrument.instrumentSerialNumber || undefined,
	instrumentMake: instrument.instrumentMake || undefined,
	instrumentModel: instrument.instrumentModel || undefined,
	instrumentAssetNumber: instrument.instrumentAssetNumber || undefined,
	instrumentIsActive: instrument.instrumentIsActive || undefined,
	instrumentNotes: instrument.instrumentNotes || undefined,

	instrumentSetID: instrument.instrumentSetID || undefined,

	instrumentTypeID: instrument.instrumentTypeID || undefined,
})

type PageCalibration = Pick<
	Calibration,
	| 'calibrationID'
	| 'calibrationDate'
	| 'calibrationCertificateNumber'
	| 'calibrationCertificateS3Path'
	| 'calibrationCertificateFileName'
	| 'calibrationItems'
>

const newCalibration = (calibration: Partial<Calibration>): PageCalibration => ({
	calibrationID: calibration.calibrationID || '',
	calibrationDate: calibration.calibrationDate || '',
	calibrationCertificateNumber: calibration.calibrationCertificateNumber || '',
	calibrationCertificateS3Path: calibration.calibrationCertificateS3Path || null,
	calibrationCertificateFileName: calibration.calibrationCertificateFileName || null,
	calibrationItems: calibration.calibrationItems || [],
})

const newCalibrationItem = (
	calibrationItem: Partial<CalibrationItem>
): Pick<CalibrationItem, 'calibrationItemID' | 'calibrationItemReferenceNumber' | 'calibrationItemReferenceReading' | 'calibrationItemInstrumentReading'> => ({
	calibrationItemID: calibrationItem.calibrationItemID || '',
	calibrationItemReferenceNumber: calibrationItem.calibrationItemReferenceNumber || 0,
	calibrationItemReferenceReading: calibrationItem.calibrationItemReferenceReading || 0,
	calibrationItemInstrumentReading: calibrationItem.calibrationItemInstrumentReading || 0,
})

const ScreensInstrumentDetails = (props: RouteComponentProps<ScreensInstrumentMatchParams, StaticContext, ScreensInstrumentStateParams | undefined>) => {
	const context = useContext(AppContext)
	const instrumentID = props.match!.params.id
	const isNew = instrumentID === 'new'
	const queryInstrumentSet = getUrlSearchParam(props.location.search, 'instrumentset')

	const [messages, setMessages] = useMessageReducer([])
	const [instrument, setInstrument] = React.useState<Partial<Instrument> | null>(
		isNew ? newInstrument({ instrumentSetID: queryInstrumentSet!, instrumentIsActive: true }) : null
	)
	const [instrumentSets, setInstrumentSets] = React.useState<InstrumentSet[] | null>(null)
	const [instrumentTypes, setInstrumentTypes] = React.useState<InstrumentType[] | null>(null)
	const [pageStatus, setPageStatus] = React.useState<PageStatus>(isNew ? 'Editing' : 'Loading')
	const [calibrationPageStatus, setCalibrationPageStatus] = React.useState<PageStatus>('Ready')

	const getInstrumentData = async () => {
		const [instrumentResponse] = await Promise.all([
			Request.get<InstrumentResult>(`instrument?where=instrumentID==${instrumentID}`, context.appState.authState),
		])
		const instrument = instrumentResponse.data.instruments[0]
		setInstrument(instrument)

		setPageStatus('Ready')
	}

	React.useEffect(() => {
		const getData = async () => {
			const [instrumentSetReq, instrumentTypeReq] = await Promise.all([
				Request.get<InstrumentSetResult>(`instrumentset`, context.appState.authState),
				Request.get<InstrumentTypeResult>(`instrumenttype`, context.appState.authState),
			])

			setInstrumentSets(instrumentSetReq.data.instrumentSets)
			setInstrumentTypes(instrumentTypeReq.data.instrumentTypes)
		}

		if (context.appState.authState.isLoggedIn && !isNew) {
			getInstrumentData()
		}
		if (context.appState.authState.isLoggedIn) {
			getData()
		}
	}, []) // eslint-disable-line

	const onInstrumentCreate = async (instrument: Partial<Instrument>) => {
		setPageStatus('Submitting')
		setInstrument(instrument)
		await Request.handleRequest(() => Request.post<InstrumentResult>(`instrument`, instrument, context.appState.authState), {
			successFunction: (data) => {
				if (data.instruments.length > 0) {
					props.history.push(`/instrument/${data.instruments[0].instrumentID}`)
				}
			},
			setMessageFunction: setMessages,
			messageAction: 'creating',
			messageObject: 'instrument',
		})
		setPageStatus('Ready')
	}

	const onInstrumentEdit = async (instrument: Partial<Instrument>) => {
		setPageStatus('Submitting')
		setInstrument(instrument)
		await Request.handleRequest(
			() => Request.put<InstrumentResult>(`instrument?where=instrumentID==${instrumentID}`, instrument, context.appState.authState),
			{
				setMessageFunction: setMessages,
				messageAction: 'editing',
				messageObject: 'instrument',
			}
		)
		setPageStatus('Ready')
	}

	const onInstrumentDelete = async (instrumentID: string) => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.del<InstrumentResult>(`instrument?where=instrumentID==${instrumentID}`, context.appState.authState), {
			successFunction: () => {
				if (props.location.state && props.location.state.backPath) {
					props.history.push(props.location.state.backPath)
				} else {
					props.history.push('/instrument')
				}
			},
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'instrument',
		})
		setPageStatus('Ready')
	}

	const onCalibrationSave = async (calibration: PageCalibration) => {
		setCalibrationPageStatus('Submitting')
		if (!calibration.calibrationID) {
			await Request.handleRequest(
				() => Request.post<CalibrationResult>(`calibration`, cleanUpCalibrationObject(calibration, instrumentID), context.appState.authState),
				{
					successFunction: () => getInstrumentData(),
					setMessageFunction: setMessages,
					messageAction: 'creating',
					messageObject: 'calibration',
				}
			)
		} else {
			await Request.handleRequest(
				() =>
					Request.put<CalibrationResult>(
						`calibration?where=calibrationID==${calibration.calibrationID}`,
						{ ...calibration, instrumentID },
						context.appState.authState
					),
				{
					successFunction: () => getInstrumentData(),
					setMessageFunction: setMessages,
					messageAction: 'editing',
					messageObject: 'calibration',
				}
			)
		}
		setCalibrationPageStatus('Ready')
	}

	return (
		<div className="page">
			<Messages messages={messages} updateMessage={setMessages} />

			{props.location.state && props.location.state.backPath && (
				<Row>
					<Col>
						<Link className="breadcrumb-back" to={props.location.state.backPath}>{`< Back to ${props.location.state.backName}`}</Link>
					</Col>
				</Row>
			)}

			<Row>
				<Col>
					<Header title={instrument ? instrument.instrumentSerialNumber || 'Instrument' : 'New Instrument'} subtitle={''} />
				</Col>
			</Row>

			{instrument ? (
				<>
					<Formik
						initialValues={instrument}
						validationSchema={instrumentValidationSchema}
						onSubmit={isNew ? onInstrumentCreate : onInstrumentEdit}
						enableReinitialize
					>
						{({ handleSubmit, errors, values, setFieldValue }) => (
							<Card
								title={'Instrument Information'}
								collapsible={false}
								headerComponent={() =>
									pageStatus !== 'Loading' && instrument && canWriteInstrument(appStateToPermissionObject(context.appState)) ? (
										pageStatus === 'Ready' ? (
											context.appState.userPermissions.entityTypeID === EntityType.TestingContractor ||
											context.appState.userPermissions.entityTypeID === EntityType.Admin ? (
												<Row className="justify-content-end">
													<Col sm={'auto'}>
														<Button className="btn btn-outline-dark span-bold" onClick={() => setPageStatus('Editing')}>
															Edit instrument
														</Button>
													</Col>
												</Row>
											) : (
												<></>
											)
										) : (
											<Row className="justify-content-end">
												<Col sm={'auto'}>
													<Button className="btn btn-secondary" onClick={() => handleSubmit()} disabled={pageStatus === 'Submitting'}>
														Save
													</Button>
												</Col>
												<Col sm={'auto'}>
													<Button
														className="btn btn-secondary"
														onClick={() => {
															if (
																isNew &&
																props.location.state &&
																props.location.state.backPath &&
																props.location.state.backName
															) {
																props.history.push(`${props.location.state.backPath}`, {
																	backPath: props.location.state.backPath,
																	backName: props.location.state.backName,
																})
															} else {
																setPageStatus('Ready')
															}
														}}
														disabled={pageStatus === 'Submitting'}
													>
														Cancel
													</Button>
												</Col>
												{!isNew && (
													<Col sm={'auto'}>
														<DeleteButton id={instrument.instrumentID || ''} onClick={onInstrumentDelete} />
													</Col>
												)}
											</Row>
										)
									) : (
										<></>
									)
								}
							>
								<InstrumentDetails
									instrument={values}
									instrumentTypes={instrumentTypes || []}
									instrumentSets={instrumentSets || []}
									pageStatus={pageStatus}
									errors={errors}
									onChange={setFieldValue}
								/>

								<Row>
									<Col>
										{!isNew ? (
											<>
												<InstrumentCalibration
													calibrations={values.calibrations || []}
													instrument={instrument}
													pageStatus={calibrationPageStatus}
													onSave={async (calibrationType) => {
														if (values.calibrations && values.calibrations.length > 0) {
															if (calibrationType !== 'dated') {
																try {
																	await calibrationSchema.validate(values.calibrations[values.calibrations.length - 1])
																} catch (err) {
																	setMessages({
																		type: 'add',
																		data: {
																			severity: 'danger',
																			message: String(err),
																			dismissible: true,
																			timeout: 5000,
																		},
																	})
																	return
																}
															}

															onCalibrationSave(values.calibrations[values.calibrations.length - 1])
														}
													}}
													onUpdateCalibration={(calibrations) => {
														setFieldValue('calibrations', calibrations)
													}}
													onNewCalibration={() => {
														setFieldValue('calibrations', [...(values.calibrations || []), newCalibration({})])
														setCalibrationPageStatus('Editing')
													}}
													onEditCalibration={() => {
														setCalibrationPageStatus('Editing')
													}}
													onNewCalibrationItem={() => {
														if (values.calibrations) {
															setFieldValue(
																'calibrations',
																values.calibrations.map((calibration, index) => {
																	if (index === values.calibrations!.length - 1) {
																		return {
																			...calibration,
																			calibrationItems: [...calibration.calibrationItems, newCalibrationItem({})],
																		}
																	}
																	return calibration
																})
															)
														}
													}}
												/>
											</>
										) : null}
									</Col>
								</Row>

								<>
									{!isNew && instrument && instrument.create && instrument.modified && (
										<HistoryCardBottom
											historyReferenceID={instrument.instrumentID!}
											created={instrument.create}
											modified={instrument.modified}
											backState={{
												backPath: `/instrument/${instrument.instrumentID!}`,
												backName: `${instrument.instrumentSerialNumber}`,
											}}
										/>
									)}
								</>
							</Card>
						)}
					</Formik>

					{instrument!.calibrations && instrument.calibrations.length > 1 && (
						<Card title="Historic Calibrations" collapsible={true} initialCollapsedState={true}>
							<>
								{instrument.calibrations.slice(1).map((calibration) => (
									<InstrumentCalibration
										key={calibration.calibrationID}
										calibrations={instrument.calibrations || []}
										instrument={instrument}
										pageStatus={calibrationPageStatus === 'Loading' ? 'Loading' : 'Ready'}
										readOnly={true}
									/>
								))}
							</>
						</Card>
					)}
				</>
			) : null}
		</div>
	)
}

interface InstrumentDetailsProps {
	instrument: Partial<Instrument>
	instrumentTypes: InstrumentType[]
	instrumentSets: InstrumentSet[]
	pageStatus: PageStatus
	errors?: InstrumentErrorMessages
	onChange: (field: string, value: string | number | null | boolean) => void
}

const InstrumentDetails = (props: InstrumentDetailsProps) => {
	const readOnly = props.pageStatus !== 'Editing'
	const currentType = props.instrumentTypes.find((type) => type.instrumentTypeID === props.instrument.instrumentTypeID)
	const currentSet = props.instrumentSets.find((set) => set.instrumentSetID === props.instrument.instrumentSetID)

	if (props.instrument) {
		return (
			<div style={{ flexDirection: 'column' }}>
				<Row style={{ marginBottom: '20px' }}>
					<Col>
						<DropdownSelect
							name="instrumentTypeID"
							label="Type"
							value={currentType ? currentType.instrumentTypeName : 'None'}
							onChange={(value) => props.onChange('instrumentTypeID', value)}
							disabled={readOnly}
							id="instrumentTypeID"
							variant="secondary"
							showSearch={true}
							searchPlaceholder="Filter"
							options={props.instrumentTypes
								.map((type) => ({ ...type, value: type.instrumentTypeID, text: type.instrumentTypeName }))
								.sort((a, b) => a.text.localeCompare(b.text))}
							feedback={props.errors?.instrumentTypeID}
							isInvalid={!!props.errors?.instrumentTypeID}
						/>
					</Col>
					<Col>
						<FormText
							name={'instrumentSerialNumber'}
							value={props.instrument.instrumentSerialNumber}
							label={'Serial No.'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
							feedback={props.errors?.instrumentSerialNumber}
							isInvalid={!!props.errors?.instrumentSerialNumber}
						/>
					</Col>
					<Col>
						<FormText
							name={'instrumentMake'}
							value={props.instrument.instrumentMake}
							label={'Make'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
							feedback={props.errors?.instrumentMake}
							isInvalid={!!props.errors?.instrumentMake}
						/>
					</Col>
					<Col>
						<FormText
							name={'instrumentModel'}
							value={props.instrument.instrumentModel}
							label={'Model'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
							feedback={props.errors?.instrumentModel}
							isInvalid={!!props.errors?.instrumentModel}
						/>
					</Col>
				</Row>
				<Row style={{ marginBottom: '20px' }}>
					<Col>
						<DropdownSelect
							name="instrumentSetID"
							label="Owner/Set"
							value={
								currentSet
									? `${currentSet.instrumentSetName} (${
											currentSet.instrumentSetOwnerEntityName || currentSet.instrumentSetOwnerUserEntityName
									  })`
									: 'None'
							}
							onChange={(value) => props.onChange('instrumentSetID', value)}
							disabled={readOnly}
							id="instrumentSetID"
							variant="secondary"
							showSearch={true}
							searchPlaceholder="Filter"
							options={props.instrumentSets
								.map((type) => ({
									...type,
									value: type.instrumentSetID,
									text: `${type.instrumentSetName} (${type.instrumentSetOwnerEntityName || type.instrumentSetOwnerUserEntityName})`,
								}))
								.sort((a, b) => a.text.localeCompare(b.text))}
							feedback={props.errors?.instrumentSetID}
							isInvalid={!!props.errors?.instrumentSetID}
						/>
					</Col>
					<Col>
						<FormText
							name={'instrumentAssetNumber'}
							value={props.instrument.instrumentAssetNumber}
							label={'Asset No.'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
							feedback={props.errors?.instrumentAssetNumber}
							isInvalid={!!props.errors?.instrumentAssetNumber}
						/>
					</Col>
					<Col>
						<DropdownSelect
							name="instrumentIsActive"
							label="Active?"
							value={props.instrument.instrumentIsActive ? 'Yes' : 'No'}
							onChange={(value) => props.onChange('instrumentIsActive', value)}
							disabled={readOnly}
							id="instrumentIsActive"
							variant="secondary"
							showSearch={false}
							searchPlaceholder="Filter"
							options={[
								{ value: true, text: 'Yes' },
								{ value: false, text: 'No' },
							]}
							feedback={props.errors?.instrumentIsActive}
							isInvalid={!!props.errors?.instrumentIsActive}
						/>
					</Col>
					<Col>
						<FormText
							name={'instrumentNotes'}
							value={props.instrument.instrumentNotes}
							label={'Notes'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
							feedback={props.errors?.instrumentNotes}
							isInvalid={!!props.errors?.instrumentNotes}
						/>
					</Col>
				</Row>
			</div>
		)
	}
	return null
}

const cleanUpCalibrationObject = (calibration: PageCalibration, instrumentID: string) => {
	const cleanedItems = calibration.calibrationItems.map((item) => ({
		...item,
		calibrationItemID: undefined,
	}))

	return {
		...calibration,
		calibrationID: undefined,
		instrumentID,
		calibrationItems: cleanedItems,
	}
}

export { ScreensInstrumentDetails, InstrumentDetails, instrumentValidationSchema }
