const STANDARD_ASSET_TYPE = {
	BIOLOGICAL_SAFETY_CABINET_1: 'a1c3649a-51de-4f1f-af63-f15071f04a60',
	BIOLOGICAL_SAFETY_CABINET_2: '8f7deab9-bbc1-4da3-a42d-65d9afd98057',
	CYTOTOXIC_DRUG_SAFETY_CABINET: 'b215d7f1-97a6-467d-98f6-e075ac1d33c4',
	EXTRACTION_HOOD: '0c6c46fe-3afd-41d1-b809-351f0bf077a8',
	FUME_CABINET: '43969324-0a26-4af9-b369-74e286749cbb',
	FUME_CUPBOARD: '7a4a54e7-f561-4b2a-8eb8-8f36fc6fb999',
	LAMINAR_FLOW: 'a663fbdc-d1d7-43d4-b64c-03980695d5cd',
}

export { STANDARD_ASSET_TYPE }
