import React, { ChangeEvent, useContext } from 'react'
import { AppContext } from '../../../App'
import { Row, Col, Card, Form } from 'react-bootstrap'
import { Calibration } from '../../../../../back-end/common/calibration'
import { FormText } from '../../../components/UI/Form/Text'
import { TestProps } from './QuestionLookup.d'
import { Formik, FormikErrors } from 'formik'
import * as yup from 'yup'
import { calibrateAdditiveValue } from 'dynaflow/utilities/calibration'
import { VelocityTestOutput } from 'dynaflow/utilities/question'
import { getAssetHorizontalCount, getAssetVerticalCount } from 'dynaflow/utilities/questionResponse'
import { getPassValueOverrides } from 'dynaflow/utilities/testOverride'
import {
	onQuestionSubmit,
	QuestionHeader,
	QuestionNavigationButtons,
	useLoadExistingQuestionResponse,
	QuestionElement,
	QuestionResult,
	QuestionResultElementGroup,
	TabRow,
} from './QuestionComponents'

const VELOCITY_READING_CARD_WIDTH = 300
interface VelocityReadingProps {
	index: number
	formikKey: string
	calibration: Pick<Calibration, 'calibrationID' | 'calibrationDate' | 'calibrationCertificateNumber' | 'calibrationCertificateS3Path' | 'calibrationItems'>
	values?: GridResult
	errors?: FormikErrors<GridResult> | string | string[]
	handleChange: (e: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
	disabled: boolean
	minReading: number
}

const VelocityReading = (props: VelocityReadingProps) => {
	const calibHigh = calibrateAdditiveValue(props.values?.high, props.calibration)
	const calibLow = calibrateAdditiveValue(props.values?.low, props.calibration)
	const highValueError = calibHigh?.toFixed(2) ? Number(calibHigh?.toFixed(2)) < props.minReading : false
	const lowValueError = calibLow?.toFixed(2) ? Number(calibLow?.toFixed(2)) < props.minReading : false
	return (
		<Card className="ml-2 mr-2" style={{ width: `${VELOCITY_READING_CARD_WIDTH}px` }} title={props.index.toString()}>
			<div className="text-center rounded-top border-bottom bg-light">{props.index + 1}</div>
			<div style={{ display: 'grid', gridTemplateColumns: '37.5% 37.5% 25%' }}>
				<Col>
					<FormText
						name={`${props.formikKey}.gridResults[${props.index}].high`}
						label="High"
						value={props.values?.high || ''}
						onChange={props.handleChange}
						disabled={props.disabled}
						feedback={(props?.errors as FormikErrors<GridResult>)?.high}
						isInvalid={!!(props?.errors as FormikErrors<GridResult>)?.high} // || highValueError}
						// Just have the red border for min value warning
						style={{ backgroundImage: highValueError ? 'none' : '' }}
					/>
				</Col>
				<Col>
					<FormText
						name={`${props.formikKey}.gridResults[${props.index}].low`}
						label="Low"
						value={props.values?.low || ''}
						onChange={props.handleChange}
						disabled={props.disabled}
						feedback={(props?.errors as FormikErrors<GridResult>)?.low}
						isInvalid={!!(props?.errors as FormikErrors<GridResult>)?.low || lowValueError}
						// Just have the red border for min value warning
						style={{ backgroundImage: lowValueError ? 'none' : '' }}
					/>
				</Col>
				<Col>Mean</Col>
				<Col className={`ml-2 mt-2 mb-1 ${highValueError && 'text-danger'}`}>{calibHigh?.toFixed(2)}</Col>
				<Col className={`ml-2 mt-2 mb-1 ${lowValueError && 'text-danger'}`}>{calibLow?.toFixed(2)}</Col>
				<Col className="ml-2 mt-2 mb-1">{calibHigh !== null && calibLow !== null ? ((calibHigh + calibLow) / 2).toFixed(2) : '\u00A0'}</Col>
			</div>
		</Card>
	)
}

interface VelocityGridProps {
	formikKey: string
	numberHorizontal: number
	numberVertical: number
	calibration: Pick<Calibration, 'calibrationID' | 'calibrationDate' | 'calibrationCertificateNumber' | 'calibrationCertificateS3Path' | 'calibrationItems'>
	values: GridResult[]
	errors?: FormikErrors<GridResult>[] | string | string[]
	handleChange: (e: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
	disabled: boolean
	minReading: number
}

export const HighLowVelocityGrid = (props: VelocityGridProps) => {
	const rows = []
	for (let v = 0; v < props.numberVertical; v += 1) {
		rows.push(
			<Card
				key={v * props.numberHorizontal + 'label'}
				className="border-none"
				style={{ display: 'flex', alignSelf: 'flex-end', boxShadow: 'none', border: 'none' }}
			>
				<div>&nbsp;</div>
				<div className="text-right font-weight-bold" style={{ display: 'grid', gridTemplateColumns: '100%' }}>
					<Col className="pr-1 mb-2" style={{ textAlign: 'end', alignSelf: 'end' }}>
						Raw data (m/s):
					</Col>
					<Col className="pr-1 mb-2" style={{ textAlign: 'end', alignSelf: 'end' }}>
						Calibrated (m/s):
					</Col>
				</div>
			</Card>
		)
		for (let h = 0; h < props.numberHorizontal; h += 1) {
			const index = v * props.numberHorizontal + h
			rows.push(<VelocityReading key={index} {...props} values={props.values.at(index)} errors={props?.errors?.at(index)} index={index} />)
		}
	}
	const gridStyle = {
		display: 'grid',
		gridTemplateColumns: '146px ' + `${VELOCITY_READING_CARD_WIDTH + 16}px `.repeat(props.numberHorizontal),
		overflow: 'auto',
	}
	return <div style={gridStyle}>{rows}</div>
}

interface GridResult {
	point: number
	high: string
	low: string
}

interface QuestionValues {
	faceVelocity: {
		gridResults: GridResult[]
		horizontalPoints: number
		verticalPoints: number
	}
	loweredFaceVelocity: {
		gridResults: GridResult[]
		notRequired: boolean
		sashClosedHeight: string
	}
	comment: string
}

const velocityInputValidation = yup.number().label('Velocity reading').required('All readings are required')

const velocityValidationSchema = yup.object().shape({
	faceVelocity: yup.object().shape({
		gridResults: yup.array().of(
			yup.object().shape({
				high: velocityInputValidation,
				low: velocityInputValidation.when('high', (high, schema) => schema.max(high || 0, 'Must be lower than "high" reading')),
			})
		),
	}),
	loweredFaceVelocity: yup.object().shape({
		notRequired: yup.boolean(),
		sashClosedHeight: yup.number().when('notRequired', { is: false, then: yup.number().label('Sash closed height').required('Closed height is required') }),
		gridResults: yup.array().when('notRequired', {
			is: false,
			then: yup.array().of(
				yup.object().shape({
					high: velocityInputValidation,
					low: velocityInputValidation,
				})
			),
		}),
	}),
})

const VelocityTest = (props: TestProps) => {
	const { test } = props
	const context = useContext(AppContext)
	const submitCallback = React.useRef(() => {})

	const numberHorizontal = React.useMemo(() => getAssetHorizontalCount(props.asset), [props.asset])
	const numberVertical = React.useMemo(() => getAssetVerticalCount(props.asset), [props.asset])
	const chamberWidth = React.useMemo(() => props.asset?.assetChamberWidth, [props.asset?.assetChamberWidth])
	const chamberHeight = React.useMemo(() => props.asset?.assetChamberHeight, [props.asset?.assetChamberHeight])
	const chamberDepth = React.useMemo(() => props.asset?.assetChamberDepth, [props.asset?.assetChamberDepth])

	const getGridResults = React.useCallback((nX: number, nY: number) => {
		const inp = [] as GridResult[]
		for (let i = 0; i < (nX || 1) * (nY || 1); i++) {
			inp.push({
				point: i + 1,
				high: '',
				low: '',
			})
		}
		return inp
	}, [])

	const velocityReadingOpenInputs = React.useMemo(() => getGridResults(numberHorizontal, numberVertical), [numberHorizontal, numberVertical, getGridResults])
	const velocityReadingClosedInputs = React.useMemo(() => getGridResults(numberHorizontal, 1), [numberHorizontal, getGridResults])

	const [questionValues, setQuestionValues] = React.useState<QuestionValues>({
		faceVelocity: {
			gridResults: velocityReadingOpenInputs,
			horizontalPoints: numberHorizontal,
			verticalPoints: numberVertical,
		},
		loweredFaceVelocity: {
			gridResults: velocityReadingClosedInputs,
			sashClosedHeight: '',
			notRequired: false,
		},
		comment: '',
	})

	useLoadExistingQuestionResponse({
		...props,
		setQuestionValues,
		formatQuestionValues: React.useCallback(
			(existingValues: VelocityTestOutput): QuestionValues => {
				let gridResults
				// If the grid points are inconsistent, reset to blank fields
				if (existingValues.faceVelocity.horizontalPoints !== numberHorizontal || existingValues.faceVelocity.verticalPoints !== numberVertical) {
					gridResults = getGridResults(numberHorizontal, numberVertical)
				} else {
					gridResults = existingValues.faceVelocity.gridResults.map(({ instrument }, index) => ({
						point: index + 1,
						high: instrument.high.toString(),
						low: instrument.low.toString(),
					}))
				}
				return {
					faceVelocity: {
						...existingValues.faceVelocity,
						gridResults,
					},
					loweredFaceVelocity: {
						sashClosedHeight: existingValues.loweredFaceVelocity.sashClosedHeight?.toString() || '',
						notRequired: existingValues.loweredFaceVelocity.notRequired,
						gridResults:
							!existingValues.loweredFaceVelocity.notRequired && existingValues.loweredFaceVelocity.gridResults !== null
								? existingValues.loweredFaceVelocity.gridResults.map(({ instrument }, index) => {
										return { point: index + 1, high: instrument.high.toString(), low: instrument.low.toString() }
								  })
								: velocityReadingClosedInputs,
					},
					comment: '',
				}
			},
			[velocityReadingClosedInputs, getGridResults, numberHorizontal, numberVertical]
		),
	})

	// Assume there's only one instrument type for this question
	const testInstrument =
		test?.testInstruments?.find((instrument) => instrument.instrumentTypeID === props.question?.instrumentTypes[0].instrumentTypeID) || null
	const calibration =
		testInstrument && testInstrument.testInstrumentCalibrationSnapshot ? JSON.parse(testInstrument?.testInstrumentCalibrationSnapshot) : null

	const PASS_VALUES = getPassValueOverrides(props.question)

	if (!calibration || !PASS_VALUES) {
		return null
	}

	return (
		<Formik
			initialValues={questionValues}
			validationSchema={velocityValidationSchema}
			validateOnChange={false}
			validateOnBlur={true}
			onSubmit={(values) => {
				// make sure all fields that should be numbers are numbers
				const response = {
					...values,
					faceVelocity: {
						...values.faceVelocity,
						gridResults: values.faceVelocity.gridResults.map((result) => ({
							point: result.point,
							high: Number(result.high),
							low: Number(result.low),
						})),
					},
					loweredFaceVelocity: {
						...values.loweredFaceVelocity,
						gridResults: values.loweredFaceVelocity.gridResults.map((result) => ({
							point: result.point,
							high: Number(result.high),
							low: Number(result.low),
						})),
					},
				}
				onQuestionSubmit({
					...props,
					values: response,
					authState: context.appState.authState,
					submitCallback,
					params: { chamberWidth, chamberHeight, chamberDepth },
				})
			}}
			enableReinitialize={true}
		>
			{({ handleSubmit, errors, values, handleChange, handleReset, dirty, isSubmitting, setFieldValue }) => {
				// Fill out the calculated fields for display only (it's recalculated on create/update)
				let failed = null
				let averageFaceVelocity = null
				let lowestAcceptableReading = null
				let lowestActualReading = null
				let highestAcceptableReading = null
				let highestActualReading = null
				const completeRaisedResults = values.faceVelocity.gridResults.filter((i) => i.high && i.low)
				if (completeRaisedResults.length === values.faceVelocity.gridResults.length) {
					failed = false
					const calibratedValues = values.faceVelocity.gridResults.map((i) => {
						const val = (calibrateAdditiveValue(Number(i.high), calibration)! + calibrateAdditiveValue(Number(i.low), calibration)!) / 2
						if (
							val < PASS_VALUES.minimumFaceVelocityReading ||
							calibrateAdditiveValue(Number(i.low), calibration)! < PASS_VALUES.minimumFaceVelocityReading ||
							calibrateAdditiveValue(Number(i.high), calibration)! < PASS_VALUES.minimumFaceVelocityReading
						) {
							failed = true
						}
						return val
					})
					averageFaceVelocity = calibratedValues.reduce((partialSum, i) => partialSum! + i!, 0)! / values.faceVelocity.gridResults.length
					lowestAcceptableReading = 0.8 * averageFaceVelocity
					highestAcceptableReading = 1.2 * averageFaceVelocity
					lowestActualReading = Math.min(...calibratedValues!)
					highestActualReading = Math.max(...calibratedValues!)
					if (averageFaceVelocity < PASS_VALUES.minimumAcceptableFaceVelocity) {
						failed = true
					}
					if (
						(lowestActualReading < lowestAcceptableReading || highestActualReading > highestAcceptableReading) &&
						!(averageFaceVelocity < PASS_VALUES.minimumAverageVelocity || averageFaceVelocity > PASS_VALUES.maximumAverageVelocity)
					) {
						if (!values.comment.includes('Airflow is adequate but unstable')) {
							values.comment = 'Airflow is adequate but unstable\n' + values.comment
						}
					} else {
						values.comment = values.comment.replaceAll('Airflow is adequate but unstable', '')
					}
				}

				let averageLoweredFaceVelocity = null

				// To calculate the minimum acceptable face velocity, we first take the volume of the chamber (width * depth * height in mm), then
				// divide it by the area of the opening with the sash closed (chamber width * sash closed height in mm) to calculate how many openings
				// inserted into the chamber it would take to fill it. This is then multiplied by 5 to meet spec of filling 5 times per minute, then divided
				// by 1000 to convert mm to metres, and 60 to covert minutes to seconds, to gain our final minimum speed value in metres/second that the air
				// traveling through the opening must meet to fill the minimum volume per minute.
				const minAcceptableAFV =
					(((chamberWidth! * chamberHeight! * chamberDepth!) / (chamberWidth! * Number(values?.loweredFaceVelocity?.sashClosedHeight))) * 5) /
						1000 /
						60 || null

				const completeLoweredResults = values.loweredFaceVelocity.gridResults.filter((i) => i.high && i.low)
				if (completeLoweredResults.length === values.loweredFaceVelocity.gridResults.length) {
					const calibratedValuesLowered = values.loweredFaceVelocity.gridResults.map((i) => {
						const val = (calibrateAdditiveValue(Number(i.high), calibration)! + calibrateAdditiveValue(Number(i.low), calibration)!) / 2
						if (
							val < PASS_VALUES.minimumFaceVelocityReading ||
							calibrateAdditiveValue(Number(i.low), calibration)! < PASS_VALUES.minimumFaceVelocityReading ||
							calibrateAdditiveValue(Number(i.high), calibration)! < PASS_VALUES.minimumFaceVelocityReading
						) {
							failed = true
						}
						return val
					})
					averageLoweredFaceVelocity =
						calibratedValuesLowered.reduce((partialSum, i) => partialSum! + i!, 0)! / values.loweredFaceVelocity.gridResults.length
					if (minAcceptableAFV && averageLoweredFaceVelocity < minAcceptableAFV) {
						failed = true
					}
				}

				return (
					<>
						<TabRow
							tabSelectOptions={props.tabSelectOptions}
							onClick={(q, save) => {
								if (save) {
									submitCallback.current = () => props.goToQuestion(q)
									handleSubmit()
								} else {
									props.goToQuestion(q)
								}
							}}
							currentQuestion={props.question?.questionID}
							dirty={dirty}
							isSubmitting={isSubmitting}
							errors={errors}
						/>
						<Row>
							<Col>
								<QuestionHeader question={props.question} />
								<QuestionElement title="Face velocity readings - sash open">
									<Row className="mb-4">
										{numberHorizontal && numberVertical && (
											<HighLowVelocityGrid
												values={values.faceVelocity.gridResults}
												errors={errors?.faceVelocity?.gridResults}
												formikKey="faceVelocity"
												handleChange={handleChange}
												calibration={calibration}
												numberHorizontal={numberHorizontal}
												numberVertical={numberVertical}
												disabled={!!test?.isLocked}
												minReading={PASS_VALUES.minimumFaceVelocityReading}
											/>
										)}
									</Row>
									<QuestionResultElementGroup
										results={[
											{ title: 'Average face velocity', value: `${averageFaceVelocity?.toFixed(2) || '-'} m/s` },
											{ title: 'Lowest acceptable reading', value: `${lowestAcceptableReading?.toFixed(2) || '-'} m/s` },
											{ title: 'Lowest actual reading', value: `${lowestActualReading?.toFixed(2) || '-'} m/s` },
											{ title: 'Highest acceptable reading', value: `${highestAcceptableReading?.toFixed(2) || '-'} m/s` },
											{ title: 'Highest actual reading', value: `${highestActualReading?.toFixed(2) || '-'} m/s` },
										]}
									/>
								</QuestionElement>
								<QuestionElement title="Face velocity readings - sash closed">
									<Row className="mb-3">
										<Col>
											<Form.Group controlId="loweredFaceVelocity.notRequired">
												<Form.Check
													id="loweredFaceVelocity.notRequired"
													label="Sash closed reading not required"
													type="checkbox"
													name="loweredFaceVelocity.notRequired"
													checked={values.loweredFaceVelocity.notRequired}
													onChange={(e) => {
														handleChange(e)
														setFieldValue('loweredFaceVelocity.sashClosedHeight', '')
														values.loweredFaceVelocity.gridResults.forEach((_gridRes, index) => {
															setFieldValue(`loweredFaceVelocity.gridResults[${index}].low`, '')
															setFieldValue(`loweredFaceVelocity.gridResults[${index}].high`, '')
														})
													}}
													disabled={!!test?.isLocked}
												/>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col className="col-md-3 col-sm-6">
											<FormText
												label="Sash closed height (mm)"
												name={'loweredFaceVelocity.sashClosedHeight'}
												value={values.loweredFaceVelocity.sashClosedHeight || ''}
												onChange={handleChange}
												disabled={values.loweredFaceVelocity.notRequired || !!test?.isLocked}
												feedback={!values.loweredFaceVelocity.notRequired ? errors?.loweredFaceVelocity?.sashClosedHeight : undefined}
												isInvalid={!!(!values.loweredFaceVelocity.notRequired && errors.loweredFaceVelocity?.sashClosedHeight)}
											/>
										</Col>
									</Row>
									<Row className="mt-4 mb-4">
										{numberHorizontal && (
											<HighLowVelocityGrid
												values={values.loweredFaceVelocity.gridResults}
												errors={!values.loweredFaceVelocity.notRequired ? errors?.loweredFaceVelocity?.gridResults : undefined}
												formikKey="loweredFaceVelocity"
												handleChange={handleChange}
												calibration={calibration}
												numberHorizontal={numberHorizontal}
												numberVertical={1}
												disabled={values.loweredFaceVelocity.notRequired || !!test?.isLocked}
												minReading={PASS_VALUES.minimumFaceVelocityReading}
											/>
										)}
									</Row>
									<QuestionResultElementGroup
										results={[
											{
												title: 'Minimum acceptable average face velocity',
												value: `${minAcceptableAFV === Number.POSITIVE_INFINITY ? '-' : minAcceptableAFV?.toFixed(2) || '-'} m/s`,
											},
											{ title: 'Actual average face velocity', value: `${averageLoweredFaceVelocity?.toFixed(2) || '-'} m/s` },
										]}
									/>
								</QuestionElement>
								<QuestionResult
									failed={failed}
									questionHasComment={true}
									questionHasForcePass={false}
									commentValue={values.comment}
									handleChange={handleChange}
									disabled={!!test?.isLocked}
									isStandardsBased={props.asset?.assetTypeIsStandardsBased}
								/>
								<QuestionNavigationButtons
									{...props}
									handleReset={handleReset}
									handleSubmit={handleSubmit}
									submitCallback={submitCallback}
									setSubmitCallback={(fn) => (submitCallback.current = fn)}
									dirty={dirty}
									isSubmitting={isSubmitting}
								/>
							</Col>
						</Row>
					</>
				)
			}}
		</Formik>
	)
}

export { VelocityTest }
