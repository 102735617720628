import React, { useContext } from 'react'
import { Row, Col, Button, Dropdown } from 'react-bootstrap'
import { Asset, AssetResult } from '../../../../back-end/common/asset'
import * as Request from '../../utilities/request'
import { AppContext } from '../../App'
import { RouteComponentProps } from 'react-router'
import { Header } from '../../components/UI/Header/Header'
import { Card } from '../../components/UI/Card/Card'
import { PageStatus } from '../../types/PageStatus'
import { DeleteButton } from '../../components/UI/Form/DeleteButton'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { FormText } from '../../components/UI/Form/Text'
import { Formik, FormikErrors } from 'formik'
import { formatDateTime } from '../../utilities/formatDate'
import { Link } from 'react-router-dom'
import { AssetType, AssetTypeResult } from '../../../../back-end/common/assetType'
import { TestReports } from './AssetTestReports'
import { Location, LocationResult } from '../../../../back-end/common/location'
import { AssetLocationDropdown, AssetBuildingDropdown, AssetRoomDropdown } from './AssetHelperFunctions'
import { HistoryCardBottom } from '../../components/UI/HistoryCardBottom/HistoryCardBottom'
import {
	getAssetValidationSchemaFromParentAssetTypeID,
	ExtractionHood,
	BiologicalSafetyCabinet1,
	BiologicalSafetyCabinet2,
	LaminarFlow,
	CytotoxicDrugSafetyCabinet,
	FumeCabinet,
	FumeCupboard,
} from '../../utilities/assetValidationSchemas'
import { DateTime } from 'luxon'
import { Loading } from '../../components/UI/Loading/Loading'
import { canWriteAsset } from 'dynaflow/utilities/permission'
import { appStateToPermissionObject } from '../../utilities/permission'

type AssetErrorMessages = FormikErrors<Asset>

export interface ScreensAssetMatchParams {
	id: string
}

const ScreensAssetDetails = (props: RouteComponentProps<ScreensAssetMatchParams>) => {
	const context = useContext(AppContext)
	const assetID = props.match!.params.id

	const [messages, setMessages] = useMessageReducer([])
	const [pageStatus, setPageStatus] = React.useState<PageStatus>('Loading')

	const [asset, setAsset] = React.useState<Asset | null>(null)
	const [assetType, setAssetType] = React.useState<AssetType | null>(null)
	const [locations, setLocations] = React.useState<Location[]>([])

	React.useEffect(() => {
		const getData = async () => {
			const assetResponse = await Request.get<AssetResult>(`asset?where=assetID==${assetID}`, context.appState.authState)

			const asset = assetResponse.data.assets[0]
			setAsset({
				...asset,
				assetInstallationDate: asset.assetInstallationDate ? DateTime.fromISO(asset.assetInstallationDate).toFormat('yyyy-MM-dd') : null,
			})

			const assetTypeResponse = await Request.get<AssetTypeResult>(`assetType?where=assetTypeID==${asset.assetTypeID}`, context.appState.authState)
			const assetType = assetTypeResponse.data.assetTypes[0]
			setAssetType(assetType)
			setPageStatus('Ready')
		}
		const getLocationData = async () => {
			const locationResponse = await Request.get<LocationResult>(`location`, context.appState.authState)

			const locations = locationResponse.data.locations
			setLocations(locations)
		}

		if (context.appState.authState.isLoggedIn) {
			getData()
			getLocationData()
		}
	}, []) // eslint-disable-line

	//#region Update and Delete Functions
	const onAssetEdit = async (asset: Asset) => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.put<AssetResult>(`asset?where=assetID==${assetID}`, asset, context.appState.authState), {
			setMessageFunction: setMessages,
			messageAction: 'editing',
			messageObject: 'asset',
		})
		setPageStatus('Ready')
	}

	const onAssetDelete = async (assetID: string) => {
		setPageStatus('Submitting')
		await Request.handleRequest(() => Request.del<AssetResult>(`asset?where=assetID==${assetID}`, context.appState.authState), {
			setMessageFunction: setMessages,
			messageAction: 'deleting',
			messageObject: 'asset',
		})
		setPageStatus('Ready')
	}
	//#endregion

	return (
		<div className="page">
			<Messages messages={messages} updateMessage={setMessages} />

			<Row>
				<Col>
					<Link className="breadcrumb-back" to="/asset">{`< Back to Assets`}</Link>
				</Col>
			</Row>

			<Row>
				<Col>
					<Header title={asset ? asset.assetUnitReference : ''} subtitle={''} />
				</Col>
				<Col sm={'auto'} style={{ paddingTop: '40px' }}>
					<span className="span-bold">Status:</span> {asset ? (asset.assetActive ? 'Active' : 'Inactive') : ''}{' '}
					<span className={`dot ${asset ? (asset.assetActive ? 'activeGreen' : 'activeRed') : 'grey'}`}></span>
					<span className="span-grey">
						(Last Test:{' '}
						{asset ? (asset.lastTestTs ? formatDateTime({ date: asset.lastTestTs, format: 'DateAndTimeAndTimezone' }) : 'No Tests') : ''})
					</span>
				</Col>
			</Row>

			{asset ? (
				<div>
					<Formik
						initialValues={asset}
						validationSchema={getAssetValidationSchemaFromParentAssetTypeID(asset.parentAssetTypeID)}
						onSubmit={onAssetEdit}
						enableReinitialize
					>
						{({ handleSubmit, errors, values, setFieldValue }) => (
							<Card
								title={'Asset Information'}
								collapsible={false}
								headerComponent={() =>
									pageStatus !== 'Loading' &&
									asset &&
									canWriteAsset(appStateToPermissionObject(context.appState), { accountID: asset.accountID!, cud: 'update' }) ? (
										pageStatus === 'Ready' ? (
											<Row className="justify-content-end">
												<Col sm={'auto'}>
													<Link to={`/asset/movetests/${asset.accountID}?to=${assetID}`}>
														<Button className="btn btn-outline-dark span-bold">Move Tests</Button>
													</Link>
												</Col>
												<Col sm={'auto'}>
													<Button className="btn btn-outline-dark span-bold" onClick={() => setPageStatus('Editing')}>
														Edit asset
													</Button>
												</Col>
											</Row>
										) : (
											<Row className="justify-content-end">
												<Col sm={'auto'}>
													<Button className="btn btn-secondary" onClick={() => handleSubmit()}>
														Save
													</Button>
												</Col>
												<Col sm={'auto'}>
													<Button className="btn btn-secondary" onClick={() => setPageStatus('Ready')}>
														Cancel
													</Button>
												</Col>
												<Col sm={'auto'}>
													<DeleteButton id={asset.assetID} onClick={onAssetDelete} />
												</Col>
											</Row>
										)
									) : (
										<></>
									)
								}
							>
								<AssetDetails
									asset={values}
									assetType={assetType}
									locations={locations}
									pageStatus={pageStatus}
									setAsset={setAsset}
									onChange={setFieldValue}
									errors={errors}
								/>
							</Card>
						)}
					</Formik>
					<TestReports pageProps={props} assetIsActive={asset.assetActive} />
				</div>
			) : null}
		</div>
	)
}

interface AssetDetailsProps {
	asset: Asset | null
	assetType: AssetType | null
	locations: Location[]
	pageStatus: PageStatus
	errors?: AssetErrorMessages
	setAsset: (asset: Asset) => void
	onChange: (field: string, value: string | number | boolean | null | { key: string; value: string }[]) => void
}

const AssetDetails = (props: AssetDetailsProps) => {
	const readOnly = props.pageStatus !== 'Editing'

	if (props.asset && props.assetType) {
		return (
			<div style={{ flexDirection: 'column' }}>
				<Row style={{ marginBottom: '20px' }}>
					<Col>
						<FormText name={'assetTypeName'} value={props.asset.assetTypeName} label={'Asset Type'} plaintext={true} />
					</Col>
					<Col>
						<FormText
							name={'assetUnitReference'}
							value={props.asset.assetUnitReference}
							label={'Asset Unit Reference'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
						/>
					</Col>
					<Col>
						{readOnly ? (
							<FormText
								name={'assetActive'}
								value={props.asset.assetActive ? 'Yes' : 'No'}
								label={'Active'}
								onChange={(e) => props.onChange(e.target.name, e.target.value)}
								plaintext={readOnly}
								disabled={readOnly}
							/>
						) : (
							<>
								<Row className="form-label" style={{ marginBottom: '.5rem' }}>
									<Col>Active</Col>
								</Row>
								<Row>
									<Col>
										<Dropdown>
											<Dropdown.Toggle className={`form form-control`} variant="secondary" id="dropdown-assetActive">
												{props.asset.assetActive ? 'Yes' : 'No'}
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item onClick={() => props.setAsset({ ...(props.asset as Asset), assetActive: true })}>
													Yes
												</Dropdown.Item>
												<Dropdown.Item onClick={() => props.setAsset({ ...(props.asset as Asset), assetActive: false })}>
													No
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</Col>
								</Row>
							</>
						)}
					</Col>
				</Row>
				<Row style={{ marginBottom: '20px' }}>
					<Col>
						{readOnly ? (
							<FormText
								name={'locationName'}
								value={props.asset.locationName}
								label={'Location'}
								onChange={(e) => props.onChange(e.target.name, e.target.value)}
								plaintext={readOnly}
								disabled={readOnly}
							/>
						) : (
							<AssetLocationDropdown asset={props.asset} locations={props.locations} setAsset={props.setAsset} errors={props.errors} />
						)}
					</Col>
					<Col>
						{readOnly ? (
							<FormText
								name={'buildingName'}
								value={props.asset.buildingName}
								label={'Building'}
								onChange={(e) => props.onChange(e.target.name, e.target.value)}
								plaintext={readOnly}
								disabled={readOnly}
							/>
						) : (
							<AssetBuildingDropdown asset={props.asset} locations={props.locations} setAsset={props.setAsset} errors={props.errors} />
						)}
					</Col>
					<Col>
						{readOnly ? (
							<FormText
								name={'roomName'}
								value={props.asset.roomName}
								label={'Room'}
								onChange={(e) => props.onChange(e.target.name, e.target.value)}
								plaintext={readOnly}
								disabled={readOnly}
							/>
						) : (
							<AssetRoomDropdown asset={props.asset} locations={props.locations} setAsset={props.setAsset} errors={props.errors} />
						)}
					</Col>
				</Row>
				<Row style={{ marginBottom: '80px' }}>
					<div className="col-5-dynamic">
						<FormText
							name={'assetSerialNumber'}
							value={props.asset.assetSerialNumber || undefined}
							label={'Serial'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
							feedback={props.errors?.assetSerialNumber}
							isInvalid={!!props.errors?.assetSerialNumber}
						/>
					</div>

					<div className="col-5-dynamic">
						<FormText
							name={'assetMake'}
							value={props.asset.assetMake || undefined}
							label={'Make'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
							feedback={props.errors?.assetMake}
							isInvalid={!!props.errors?.assetMake}
						/>
					</div>

					<div className="col-5-dynamic">
						<FormText
							name={'assetModel'}
							value={props.asset.assetModel || undefined}
							label={'Model'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
							feedback={props.errors?.assetModel}
							isInvalid={!!props.errors?.assetModel}
						/>
					</div>
					<div className="col-5-dynamic">
						<FormText
							name={'assetClientRef'}
							value={props.asset.assetClientRef}
							label={'Client Asset Reference'}
							onChange={(e) => props.onChange(e.target.name, e.target.value)}
							plaintext={readOnly}
							disabled={readOnly}
							feedback={props.errors?.assetClientRef}
							isInvalid={!!props.errors?.assetClientRef}
						/>
					</div>
				</Row>
				<Row style={{ marginBottom: '20px' }}>
					<Col sm={8}>
						<Row style={{ marginBottom: '20px' }}>
							<Col sm={3}>
								<FormText
									name={'assetWidth'}
									value={props.asset.assetWidth!}
									label={getAssetWidthLabel(props.assetType?.parentAssetTypeID)}
									onChange={(e) => props.onChange(e.target.name, e.target.value === '' ? null : e.target.value)}
									plaintext={readOnly}
									disabled={readOnly}
									feedback={props.errors?.assetWidth}
									isInvalid={!!props.errors?.assetWidth}
								/>
							</Col>
							<Col sm={3}>
								<FormText
									name={'assetHeight'}
									value={props.asset.assetHeight!}
									label={getAssetHeightLabel(props.assetType?.parentAssetTypeID)}
									onChange={(e) => props.onChange(e.target.name, e.target.value === '' ? null : e.target.value)}
									plaintext={readOnly}
									disabled={readOnly}
									feedback={props.errors?.assetHeight}
									isInvalid={!!props.errors?.assetHeight}
								/>
							</Col>
							{props.assetType?.parentAssetTypeID === ExtractionHood || props.assetType?.parentAssetTypeID === null ? (
								<>
									<Col sm={3}>
										<FormText
											name={'assetHorizontalReadings'}
											value={props.asset.assetHorizontalReadings!}
											label={'Horizontal Readings'}
											onChange={(e) => props.onChange(e.target.name, e.target.value)}
											plaintext={readOnly}
											disabled={readOnly}
											type="number"
											min={0}
										/>
									</Col>
									<Col sm={3}>
										<FormText
											name={'assetVerticalReadings'}
											value={props.asset.assetVerticalReadings!}
											label={'Vertical Readings'}
											onChange={(e) => props.onChange(e.target.name, e.target.value)}
											plaintext={readOnly}
											disabled={readOnly}
											type="number"
											min={0}
										/>
									</Col>
								</>
							) : null}
						</Row>
						<Row style={{ marginBottom: '20px' }}>
							{props.assetType.showChamberWidth ? (
								<Col sm={3}>
									<FormText
										name={'assetChamberWidth'}
										value={props.asset.assetChamberWidth!}
										label={'Chamber Width (mm)'}
										onChange={(e) => props.onChange(e.target.name, e.target.value)}
										plaintext={readOnly}
										disabled={readOnly}
										feedback={props.errors?.assetChamberWidth}
										isInvalid={!!props.errors?.assetChamberWidth}
									/>
								</Col>
							) : null}
							{props.assetType.showChamberHeight ? (
								<Col sm={3}>
									<FormText
										name={'assetChamberHeight'}
										value={props.asset.assetChamberHeight!}
										label={'Chamber Height (mm)'}
										onChange={(e) => props.onChange(e.target.name, e.target.value)}
										plaintext={readOnly}
										disabled={readOnly}
										feedback={props.errors?.assetChamberHeight}
										isInvalid={!!props.errors?.assetChamberHeight}
									/>
								</Col>
							) : null}
							{props.assetType.showChamberDepth ? (
								<Col sm={3}>
									<FormText
										name={'assetChamberDepth'}
										value={props.asset.assetChamberDepth!}
										label={'Chamber Depth (mm)'}
										onChange={(e) => props.onChange(e.target.name, e.target.value)}
										plaintext={readOnly}
										disabled={readOnly}
										feedback={props.errors?.assetChamberDepth}
										isInvalid={!!props.errors?.assetChamberDepth}
									/>
								</Col>
							) : null}
						</Row>
					</Col>
				</Row>
				<Row style={{ marginBottom: '20px' }}>
					{props.asset &&
						props.assetType &&
						props.assetType.customProperties &&
						props.assetType.customProperties.map((atCustomProp, index) => {
							const aCustomProp = props.asset!.assetCustomProperties?.find((prop) => prop.key === atCustomProp)
							return (
								<Col key={index}>
									<FormText
										name={`assetCustomProperties.${atCustomProp}`}
										label={atCustomProp}
										value={aCustomProp?.value || ''}
										onChange={(e) => {
											const newArr = [...(props.asset!.assetCustomProperties || [])]
											const foundIndex = newArr.findIndex((prop) => prop.key === atCustomProp)
											if (foundIndex >= 0) {
												newArr[foundIndex].value = e.target.value
											} else {
												newArr.push({ key: atCustomProp, value: e.target.value })
											}
											props.onChange('assetCustomProperties', newArr)
										}}
										plaintext={readOnly}
										disabled={readOnly}
									/>
								</Col>
							)
						})}
				</Row>

				{props.asset.create && props.asset.modified && (
					<HistoryCardBottom
						historyReferenceID={props.asset.assetID!}
						created={props.asset.create}
						modified={props.asset.modified}
						backState={{ backPath: `/asset/${props.asset.assetID!}`, backName: `${props.asset.assetUnitReference}` }}
					/>
				)}
			</div>
		)
	}
	return <Loading size={4} spinner={true} />
}

const getAssetWidthLabel = (parentAssetTypeID: string | null | undefined) => {
	switch (parentAssetTypeID) {
		case CytotoxicDrugSafetyCabinet:
		case LaminarFlow:
		case BiologicalSafetyCabinet1:
		case BiologicalSafetyCabinet2:
			return 'Filter Width (mm)'
		case FumeCabinet:
		case FumeCupboard:
			return 'Sash Width (mm)'
		case ExtractionHood:
		default:
			return 'Unit Width (mm)'
	}
}

const getAssetHeightLabel = (parentAssetTypeID: string | null | undefined) => {
	switch (parentAssetTypeID) {
		case CytotoxicDrugSafetyCabinet:
		case LaminarFlow:
		case BiologicalSafetyCabinet1:
		case BiologicalSafetyCabinet2:
			return 'Filter Depth (mm)'
		case FumeCabinet:
		case FumeCupboard:
			return 'Sash Height (mm)'
		case ExtractionHood:
		default:
			return 'Unit Height (mm)'
	}
}

export { ScreensAssetDetails, AssetDetails }
