import React, { useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import { StaticContext, RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { Menu } from '../../components/UI/Menu/Menu'
import { UserInfo } from '../../components/UI/UserInfo/UserInfo'
import { Search } from '../../components/UI/Search/Search'
import menu from '../../images/icons/menu.svg'
import { Messages, useMessageReducer } from '../../components/UI/Messages/Messages'
import { AppContext } from '../../App'

interface PageWrapperProps
	extends RouteComponentProps<
		{
			[x: string]: string | undefined
		},
		StaticContext,
		unknown
	> {
	renderPage: (
		props: RouteComponentProps<{
			[x: string]: string | undefined
		}>
	) => React.ReactElement<
		{
			[x: string]: string | undefined
		},
		| string
		| React.JSXElementConstructor<{
				[x: string]: string | undefined
		  }>
	>
}

export const PageWrapper = (props: PageWrapperProps) => {
	const context = useContext(AppContext)
	const RenderPage = props.renderPage

	const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false)
	const [messages, setMessages] = useMessageReducer([])

	return (
		<div style={{ overflow: 'hidden' }}>
			<Row className="page-header">
				<Col className="page-header-burger-menu" sm={'auto'}>
					<img onClick={() => setMenuIsOpen(!menuIsOpen)} src={menu} className="sideNav-icon" alt={'A Menu Icon'}></img>
				</Col>
				<Col sm={'auto'} style={{ marginTop: '5px' }}>
					<Link to="/" className="page-header-title">
						{context.appState.userPermissions.subscriberEntityName}
					</Link>
				</Col>
				<Col></Col>
				<Col style={{ paddingTop: '17px' }} sm={'auto'}>
					<Search />
				</Col>
				<Col className="page-header-divider" sm={'auto'}></Col>
				<Col style={{ paddingTop: '7px', paddingLeft: '0px' }} sm={'auto'}>
					<UserInfo setMessages={setMessages} />
				</Col>
			</Row>

			<Row style={{ marginLeft: '0px' }}>
				<Menu menuIsOpen={menuIsOpen} menuSelectedItem={props.location.pathname.toString()} />
				<Col>
					<div className="page-body">
						<Messages messages={messages} updateMessage={setMessages} />
						<RenderPage {...props} />
					</div>
				</Col>
			</Row>
		</div>
	)
}
