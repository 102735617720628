import { Calibration } from '../common/calibration'

export const roundTo = (val: number | string, places: number): number => {
	return Math.round(Number(val) * 10 ** places) / 10 ** places
}

const getNumberOrNull = (value: string | number | undefined | null): number | null => {
	if (value === undefined || value === null) {
		return null
	}
	if (typeof value === 'string') {
		if (value === '') {
			return null
		}
		return Number(value)
	} else {
		return value
	}
}

export const calibrateAdditiveValue = (value: number | string | undefined | null, calibration: Pick<Calibration, 'calibrationItems'>) => {
	const val = getNumberOrNull(value)
	if (val === null) {
		return null
	}
	const correction = getAdditiveCalibrationValueForReading(val, calibration)
	if (correction === null || !Number.isFinite(correction) || Number.isNaN(correction)) {
		return null
	}
	return roundTo(val - correction, 2)
}

export const getAdditiveCalibrationValueForReading = (value: number | undefined | null, calibration: Pick<Calibration, 'calibrationItems'>): number | null => {
	if (value === undefined || value === null) {
		return null
	}
	const sorted = calibration.calibrationItems.sort((a, b) => a.calibrationItemInstrumentReading - b.calibrationItemInstrumentReading)
	// If the value is lower than the first calibration value, use the first calibration value
	if (value < sorted[0].calibrationItemInstrumentReading)
		return roundTo(Number(sorted[0].calibrationItemInstrumentReading) - Number(sorted[0].calibrationItemReferenceReading), 2)
	// if the value if higher then the highest calibration value, use the highest calibration value
	if (value >= sorted[sorted.length - 1].calibrationItemInstrumentReading)
		return roundTo(
			Number(sorted[sorted.length - 1].calibrationItemInstrumentReading) - Number(sorted[sorted.length - 1].calibrationItemReferenceReading),
			2
		)
	let lowerBound = 0
	while (lowerBound < sorted.length && sorted[lowerBound + 1].calibrationItemInstrumentReading <= value) {
		lowerBound += 1
	}
	const upperBound = lowerBound + 1
	// find which calibration value the value is closest to, and use that one
	if (value - sorted[lowerBound].calibrationItemReferenceReading <= sorted[upperBound].calibrationItemReferenceReading - value) {
		return roundTo(Number(sorted[lowerBound].calibrationItemInstrumentReading) - Number(sorted[lowerBound].calibrationItemReferenceReading), 2)
	} else return roundTo(Number(sorted[upperBound].calibrationItemInstrumentReading) - Number(sorted[upperBound].calibrationItemReferenceReading), 2)
}

export const calibrateMultiplicativeValue = (value: string | number | undefined | null, calibration: Pick<Calibration, 'calibrationItems'>) => {
	const val = getNumberOrNull(value)
	if (val === null) {
		return null
	}
	const correction = getMultiplicativeCalibrationValueForReading(val, calibration)
	if (correction === null || !Number.isFinite(correction) || Number.isNaN(correction)) {
		return null
	}
	return roundTo(val * correction, 2)
}

export const getMultiplicativeCalibrationValueForReading = (
	value: number | undefined | null,
	calibration: Pick<Calibration, 'calibrationItems'>
): number | null => {
	if (value === undefined || value === null) {
		return null
	}
	const sorted = calibration.calibrationItems.sort((a, b) => a.calibrationItemInstrumentReading - b.calibrationItemInstrumentReading)
	// If the value is lower than the first calibration value, use the first calibration value
	if (value < sorted[0].calibrationItemInstrumentReading)
		return roundTo(Number(sorted[0].calibrationItemReferenceReading) / Number(sorted[0].calibrationItemInstrumentReading), 2)
	// if the value if higher then the highest calibration value, use the highest calibration value
	if (value >= sorted[sorted.length - 1].calibrationItemInstrumentReading)
		return roundTo(
			Number(sorted[sorted.length - 1].calibrationItemReferenceReading) / Number(sorted[sorted.length - 1].calibrationItemInstrumentReading),
			2
		)
	let lowerBound = 0
	while (lowerBound < sorted.length && sorted[lowerBound + 1].calibrationItemInstrumentReading <= value) {
		lowerBound += 1
	}
	const upperBound = lowerBound + 1
	// find which calibration value the value is closest to, and use that one
	if (value - sorted[lowerBound].calibrationItemReferenceReading <= sorted[upperBound].calibrationItemReferenceReading - value) {
		return roundTo(Number(sorted[lowerBound].calibrationItemReferenceReading) / Number(sorted[lowerBound].calibrationItemInstrumentReading), 2)
	} else return roundTo(Number(sorted[upperBound].calibrationItemReferenceReading) / Number(sorted[upperBound].calibrationItemInstrumentReading), 2)
}

export const calibrateNoChangeInValue = (value: number | undefined | null) => (value ? roundTo(value, 2) : null)
