import React from 'react'
import { Col } from 'react-bootstrap'
import { MenuItem } from './MenuItem'
import { useContext } from 'react'
import { AppContext } from '../../../App'

import dashboard from '../../../images/icons/dashboard.svg'
import accounts from '../../../images/icons/accounts.svg'
import assets from '../../../images/icons/assets.svg'
import tests from '../../../images/icons/tests.svg'
import instruments from '../../../images/icons/instruments.svg'
import settings from '../../../images/icons/settings.svg'

interface MenuProps {
	menuIsOpen: boolean
	menuSelectedItem: string
}

export const Menu = (props: MenuProps) => {
	const context = useContext(AppContext)

	return (
		<Col className={props.menuIsOpen ? 'sideNav open' : 'sideNav closed'} sm={'auto'}>
			<MenuItem title="Assets" link="/asset" icon={assets} isSelected={props.menuSelectedItem.startsWith('/asset')} menuIsOpen={props.menuIsOpen} />

			<MenuItem
				title="Institutions"
				link="/institution"
				icon={accounts}
				isSelected={props.menuSelectedItem.startsWith('/institution')}
				menuIsOpen={props.menuIsOpen}
			/>

			<MenuItem
				title="Entity"
				link={`/entity/${context.appState.userAttributes.entityID}`}
				icon={dashboard}
				isSelected={props.menuSelectedItem.startsWith('/entity')}
				menuIsOpen={props.menuIsOpen}
			/>

			<MenuItem title="Tests" link="/test" icon={tests} isSelected={props.menuSelectedItem.startsWith('/test')} menuIsOpen={props.menuIsOpen} />

			<MenuItem
				title="Instruments"
				link="/instrument"
				icon={instruments}
				isSelected={props.menuSelectedItem.startsWith('/instrument')}
				menuIsOpen={props.menuIsOpen}
			/>

			<MenuItem
				title="Settings"
				link="/settings"
				icon={settings}
				isSelected={props.menuSelectedItem.startsWith('/settings')}
				menuIsOpen={props.menuIsOpen}
			/>
		</Col>
	)
}
