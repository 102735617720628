import React from 'react'
import { Form, FormControlProps } from 'react-bootstrap'

interface FormTextProps extends FormControlProps {
	name: string
	label?: string
	required?: boolean
	feedback?: string
	min?: number
	max?: number
	step?: number
	rows?: number
}

const FormText = (props: FormTextProps) => {
	return (
		<Form.Group controlId={props.name}>
			{props.label ? (
				<Form.Label>
					{props.label}
					{props.required ? ' *' : ''}
				</Form.Label>
			) : null}
			<Form.Control {...props} />
			{props.feedback ? <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback> : null}
		</Form.Group>
	)
}

export { FormText }
