import React from 'react'
import { Badge } from 'react-bootstrap'
import { Room } from '../../../../back-end/common/room'
import deleteIcon from '../../images/icons/delete.svg'

interface RoomPillProps {
	showDelete: boolean
	onDelete?: (roomID: string) => void
	className?: string
	room: Partial<Room>
}

const RoomPill = (props: RoomPillProps) => {
	return (
		<Badge pill bg="secondary" className={props.className}>
			{props.room.roomName}
			{props.showDelete && props.onDelete && (
				<span onClick={() => props.onDelete!(props.room.roomID!)} className="room-pill-delete">
					<img src={deleteIcon} style={{ marginLeft: '5px', marginBottom: '3px', height: '15px' }} alt={'A Delete Icon'}></img>
				</span>
			)}
		</Badge>
	)
}

export default RoomPill
export type { RoomPillProps }
